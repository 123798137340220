


.root-class-design20 {

  .game-stats {
    &-row {
      position: absolute;
      // width: auto !important;
      height: 4vh;
      top: 7px;
      //width: 100%;
      //margin: 0 auto;
      transform: translate(-50%, 0);
      left: 50%;
    }

    &-inner {
      position: relative;
      top: 0px !important;
      width: fit-content;
      // right: 0px;
      //height: 4vh;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      pointer-events: none;
      background: #0C0C0C;
      border-radius: 19px;
      padding: 0 10px;
      white-space: nowrap;
      transform: translate(-50%,0);
      left: 50%;

      &-tournament-header {
        &-time {
          color: black !important;
          &-light {
            color: black !important;
          }
          &-normal {
            color: black !important;
          }
          &-dark {
            color: white !important;
          }
        }

        &-optional {
          display: flex;
          flex-direction: row;
        }
      }
    }

    &-label {
      display: inline-block;
      color: #FFFFFF;
      opacity: 0.5;
      font-size: var(--smallFontSize);
    }

    &-value {
      display: inline-block;
      color: #FFFFFF;
      font-size: var(--defaultFontSize);
    }

    &-divider {
      display: inline-block;
      content: ' ';
      background-image: url('../../../../../images/redesign/common/karavs.svg');
      background-size: $sectionDividerSize $sectionDividerSize;
      background-repeat: no-repeat;
      background-position: center;

      width: $sectionDividerSize;
      margin-right: 3px;
      margin-left: 3px;
      vertical-align: middle;

      height: 100%;
    }
  }

  @media screen and (orientation : portrait) {
    .game-stats-row-mobile {
      display: none !important;
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .game-stats-row-mobile {
      display: none !important;
    }
  }

}


.root-class-design20-light {
  .game-stats-row {
    .game-stats-inner {
      .game-stats-inner-tournament-header-optional {
        .game-stats-label,
        .game-stats-value.game-stats-room-value {
          color: #FF8A1A !important;
          opacity: 1 !important;
        }
      }
    }
  }
}
