$mobileLgSize: 1012px;
$mobileSmSize: 574px;
$mobileXsSize: 374px;

.landing {
  &-container {
    width: 100vw;
    height: 100vh;
    max-width: 100vw !important;
    padding: 35px;

    .splide__slide {
      text-align: center;
    }

    .slide-img {
      max-width: 100%;
      max-height: 100%;
    }

    &-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &-container-mobile {
    height: 100% !important;
    position: relative;
  }

  &-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    position: relative;

    &-termsOfUse {
      position: absolute;
      bottom: 20px;
      left: 100px;
      z-index: 100;
      top: auto;
    }

    &-homepage {
      position: absolute;
      bottom: 20px;
      left: 325px;
      z-index: 100;
      top: auto;
      width: fit-content;
    }

    @media only screen and (max-width: $mobileSmSize) {
      &-termsOfUse {
        position: absolute;
        bottom: 0px;
        left: 0;
        z-index: 100;
        top: auto;
      }
    }

    .language {
      position: absolute;
      bottom: 10px;
      right: 0px;
      z-index: 100;
      top: auto;
    }

    @media only screen and (max-width: $mobileSmSize) {
      .language {
        position: absolute;
        bottom: auto;
        left: calc(100% - 10px);
        z-index: 100;
        top: -36px;
      }
    }

    &-title {
      padding-left: 5%;

      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: var(--xxxxLargeFontSize);
        line-height: 75px;
        color: #FFFFFF;
        margin-bottom: 0.4em;
      }

      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: var(--xxLargeFontSize);
        // font-size: 28px;
        line-height: 33px;
        color: #FFFFFF;
        margin-bottom: 1.8em;
      }

      button {
        font-size: var(--mLargeFontSize);
        height: 40px;
        width: 180px;
      }

      @media only screen and (max-width: $mobileSmSize) {
        h1 {
          font-size: 48px;
          line-height: 65px;
          margin-bottom: 0;
        }

        h3 {
          font-size: 20px;
          line-height: 36px;
          margin-bottom: 20px;
        }

        button {
          font-size: 16px;
          float: right;
          height: 40px;
          width: 100px;
        }
      }

      @media only screen and (max-width: $mobileXsSize) {
        h1 {
          font-size: 32px;
          line-height: 51px;
          margin-bottom: 0;
        }

        h3 {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 10px;
        }

        button {
          font-size: 16px;
          float: right;
          height: 35px;
          width: 100px;
        }
      }
    }

    &-slider {
      overflow: hidden;
      max-width: 100%;
      margin-top: 120px;
      align-self: center;

      .splide {
        width: 100%;
        max-width: 1100px;
      }
    }

    @media only screen and (max-width: $mobileSmSize) {
      &-slider {
        margin-top: 30px;
      }
    }

    @media only screen and (max-width: $mobileXsSize) {
      &-slider {
        margin-top: 20px;
      }
    }
  }

  &-content-mobile {
    min-height: 100vh;
  }

  &-header {
    flex-shrink: 1;
    width: 100%;

    &-logo {
      width: 192px;
      height: 39px;
      float: left;
    }

    @media only screen and (max-width: $mobileSmSize) {
      &-logo {
        width: 100%;
        object-fit: contain;
      }
    }

    &-links {
      text-align: right;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      a {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: var(--headerFontSize);
        // font-size: 17px;
        line-height: 19px;
        color: #FFFFFF;
        text-transform: uppercase;
        display: inline-block;

        img {
          margin-right: 5px;
          margin-top: -2px;
          display: inline-block;
          height: 14px;
        }
      }

      a:hover {
        text-decoration: none;
        color: #ffd34e;

        img {
          color: #ffd34e;
        }
      }
    }

    @media only screen and (max-width: $mobileSmSize) {
      &-links {
        align-items: center;

        a {
          font-size: var(--largeFontSize);

          img {
            height: 13px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $mobileSmSize) {
    &-header {
      & > div {
        padding: 0;
      }
    }
  }

  &-slider {
    display: inline-block;
  }

  &-bg {
    width: 100%;
    height: 100%;
    background-image: url('../../../images/landing-view/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    left: 0;
    top: 0;
    // background-color: #2c5955;
  }

  &-transaction-bg {
    width: 200%;
    height: 100%;
    background-image: url('../../../images/landing-view/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: fixed;
    left: 0;
    top: 0;
    // background-color: #2c5955;
  }

  &-transaction-logo {
    background-image: url('../../../images/Menu/zole_logo.webp');
    background-size: 284px, 57px;
    background-position: center center;
    position: fixed;
    top: 50px;
    left: 80px;
    width: 284px;
    height: 57px;
    z-index: 200;
  }
}

.common-form .landing-content-rules-button {
    display: inline-block;
    padding: 0;
    margin: 0;
    font-size: var(--headerFontSize);
    width: auto;
    height: auto;
    padding-left: 3px;
    line-height: 18px;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    border: 0;
}

.rules-link-tag {
    text-decoration: underline;
    color: #FFC900;
}

.landing-content-register-button {
  width: 250px!important;
}

.landing-content .common-form .landing-content-rules-checkbox {
  display: inline-block;
  height: auto;
  margin-top: 7px;
  margin-left: 6px;
}

// Login
.login-container,
.sign-up-container,
.forgot-pass-container {
    .landing {

        &-header-links {
            a {
                font-family: Open Sans;
                font-style: normal;
                font-weight: bold;
                font-size: var(--xLargeFontSize);
                line-height: 25px;
                text-align: center;
                color: #FFD34F;
                text-transform: none;

                img {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }

        &-bg {
            background-image: url('../../../images/Menu/zole_bg.webp');
        }

        &-form-title{

            display: inline-block;
            // margin-top: -30px;

            h1 {
                font-family: Open Sans;
                font-style: normal;
                font-weight: bold;
                font-size: var(--xxxLargeFontSize);
                // font-size: 40px;
                line-height: 49px;
                text-align: center;
                color: #FFFFFF;
                text-transform: uppercase;
                margin-bottom: 50px;;
            }

        }

        &-content{
            > * {
                width: 100%;
                text-align: center;
            }
        }

        &-login-with {
            a {
                display: inline-block;
                padding-left: 25px;

                img {
                    max-width: 140px;
                    max-height: 90px;
                }
            }

            a.disabled {
                cursor: not-allowed;
                opacity: 0.5;
                text-decoration: none;
            }

            a:first-child {
                padding-left: 0;
            }
        }

        @media only screen and (max-width: $mobileSmSize) {
          &-login-with {
            display: flex;
            column-gap: 10px;
            a {
              width: 33.33333%;
              padding-left: 0;
              img {
                width: 100%;
              }
            }
          }
        }

        &-login-or {

            margin-bottom: 20px;
            margin-top: 30px;
            position: relative;

            hr {
                background-color: #FFFFFF;
            }

            span {
                display: inline-block;
                position: absolute;
                top: 0;
                color: #FFF;
                background-color: #345A54;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                width: 50px;
            }
        }
    }
}

.forgot-pass-container {
    .landing {
        &-content{
            flex-grow: 0;
            margin-top: 50px;
        }
    }
}

.common-form {
  .common-button.btn {
    &:active {
      background-color: #2c5955;
      color: #ffd34e;
      border-color: #ffd34e;
    }
  }
  input.landing-content-rules-checkbox {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 574px) {
  .login-container,
  .sign-up-container,
  .forgot-pass-container {
    .landing-header-links {
      position: absolute;
      right: 12px;
      top: 10px;
    }
  }
}

.login-containter-body {
  background-color: #2c5955;
}
