.support-chat {
  bottom: 42px;
  left: 30px;
  width: 30%;
//  height: 50%;
  position: absolute;
  background-color: #000;

  &-open {
    height: 50%;
    border-radius: 3px;
  }

  &-closed {
    height: 5%;
    background-image: linear-gradient(180deg,transparent,#383838);
    border-radius: 3px;
  }

  &-header {
     height: 10%;
     margin: 0;
     // border-bottom: 1px solid #fcffb6;
     background-image: linear-gradient(180deg,transparent,#383838);

    &-image {
      padding-left: 5px;
      padding-top:2px;
    }

    &-name {
      font-size: var(--smallFontSize);
      padding-right: 5px;
      padding-left: 20px;
      padding-top: 9px;
      color: #fcffb6;
    }

    &-button {
      width: 100%;
      color: #007b00;
      background-color: transparent;
      border: 0;
      padding: 0;
      cursor: default;

      &:hover {
        color: #007b00!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:active {
        color: #007b00!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:focus {
        color: #007b00!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }
    }
  }

  &-body {
    line-height: 24px;
     height: 400px;
     margin: 0;
     overflow-y: scroll;
     overflow-x: hidden;
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 20%, #383838 100%);
     scrollbar-color: #123d3d transparent;
     scrollbar-width: thin;

     &::-webkit-scrollbar {
       width: 6px;
     }

     &::-webkit-scrollbar-track {
       background: transparent;
    //   border-radius: 0px;
      // border-left: 0px solid #000;
      // border-right: 0px solid #000;
     }

     &::-webkit-scrollbar-thumb {
       background: #123d3d;
       border-radius: 0px;
       border-left: 1px solid transparent;
       border-right: 3px solid transparent;
     }

     &-wrapper {
       padding: 0;
     }
  }

  &-footer {
     height: 10%;
     margin: 0;
     border: 1px solid #fff;
     margin-top: 3px;

    &-input {
      background-color: transparent;
      padding-left: 10px;
      padding-right: 10px;
      color: #fff;
      border: 0;
      resize: none;

      &:focus {
        background-color: transparent;
        color: #fff;
        border: 0;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #fff;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #fff;
      }
    }

    &-button {
      width: 100%;
      color: #007b00;
      background-color: transparent;
      border: 0;
      padding: 0;
      cursor: default;

      &:hover {
        color: #007b00!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:active {
        color: #007b00!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:focus {
        color: #007b00!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }
    }
  }

}



.contact-support {

  &-modal {
    margin-top: 16px;
    top: 45px!important;
    font-weight: bold;

    text-align: center;

    & .modal-content {
    //  background-color: transparent;
    //  box-shadow: none;
    //  border: 0;
      border-radius: 0;

      background-color: #2c5955;
      box-shadow: 3px 3px 0px 0px #380000;
      border: 1px solid #fff;
    }

    & .modal-header {
    //  border: 1px solid #fff;
    //  border-bottom: 0;
      border-radius: 0;
    }

    &-header {
      font-size: var(--mLargeFontSize);
      line-height: 24px;

      color: #fff;
    //  background-color: #2c5955;
    //  box-shadow: 3px 3px 0px 0px #380000;
   //   border: 1px solid #fff;
    //  border-bottom: 0;
    //  text-transform: uppercase;

      & .modal-title {
        width: 100%;
        text-align: center;
      }

      &-close {
        width: 21px;
        height: 21px;
        color: #fed65f;
      }
    }

    &-body {
      font-size: var(--xxLargeFontSize);
      padding-top: 8px;

      color: #fff;
    //  background-color: #2c5955;
    //  box-shadow: 3px 3px 0px 0px #380000;
    //  border: 1px solid #fff;
    //  border-top: 0;
    //  text-transform: uppercase;
    }

    &-footer {
      padding-top: 8px;
      border: 0;
      width: 100%;
      display: block;

    //  background-color: transparent;
    //  box-shadow: none;
    //  text-transform: uppercase;

      &-button {
        width: 20%;
        min-width: fit-content;
        margin:auto;
        margin-left: 10px;
        margin-right: 10px;
        background-color: #2c5955;
        border-radius: 10px;
        height: 30px;
        font-size: var(--defaultFontSize);
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        -webkit-box-shadow: 5px 5px 0px 0px #380000;
        -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
        box-shadow: 5px 5px 0px 0px #380000;

        &:hover {
          color: #fed65f;
          border-color: #fed65f;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #fed65f;
          border-color: #fed65f;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }
  }


  &-chat {
    bottom: 42px;
    left: 30px;
    width: 30%;
  //  height: 50%;
    position: absolute;
    background-color: #000;

    &-body {
      line-height: 24px;
       height: 370px;
       margin: 0;
       overflow-y: scroll;
       overflow-x: hidden;
       scrollbar-color: #123d3d transparent;
       scrollbar-width: thin;

      /* &::-webkit-scrollbar {
         width: 6px;
       }

       &::-webkit-scrollbar-track {
         background: transparent;
       }

       &::-webkit-scrollbar-thumb {
         background: #123d3d;
         border-radius: 0px;
         border-left: 1px solid transparent;
         border-right: 3px solid transparent;
       } */

       &-scroll-area {
          height: 100%;
          margin-right: 4px;
          overflow-x: hidden;
          overflow-y: auto;

          &-no-overflow {
            overflow: hidden;
          }
         &-content {
           padding: 0;
           margin: 0;
           margin-right: 11px;
           display: block !important;
         }
         &-limited {
          height: 70vh !important;
         }
       }

       &-wrapper {
          padding: 0;

          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: white;
            border-radius: 0px;
        }
       }
    }

    &-footer {
       height: 10%;
       margin: 0;
       border: 1px solid #fff;
       margin-top: 3px;

      &-input {
        background-color: transparent;
        padding-left: 10px;
        padding-right: 10px;
        color: #fff;
        border: 0;
        resize: none;

        &:focus {
          background-color: transparent;
          color: #fff;
          border: 0;
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #fff;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #fff;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: #fff;
        }
      }

      &-button {
        width: 100%;
        color: #007b00;
        background-color: transparent;
        border: 0;
        padding: 0;
        cursor: default;

        &:hover {
          color: #007b00!important;
          background-color: transparent!important;
          border: 0!important;
          box-shadow: none!important;
        }

        &:active {
          color: #007b00!important;
          background-color: transparent!important;
          border: 0!important;
          box-shadow: none!important;
        }

        &:focus {
          color: #007b00!important;
          background-color: transparent!important;
          border: 0!important;
          box-shadow: none!important;
        }
      }
    }

    &-message {
      text-transform: none;

      &-file {
        color: #ff9734 !important;
        word-wrap: break-word;
      }

      & a {
        color: #fed75f;
      //  font-size: 16px;
      }

      &-col {
    //    padding-right: 10px;
      //  padding-left: 10px;
      }

      &-row {
        padding-left: 10px;
      }

      &-user {
        background-color: transparent;
        color: #fed65f;
        float: right;
        min-width: 10%;
        max-width: 65%;
        border: 1px solid #fed65f;
        border-radius: 8px;
        margin-left: 5px;
        margin-bottom: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: var(--mSmallFontSize);
        text-align: left;

        &-time {
          background-color: transparent;
          color: #fed65f;
          float: right;
          min-width: 10%;
          max-width: 50%;
          border: 0px;
          margin-left: 5px;
          margin-top: 0;
          margin-bottom: 2px;
          padding-left: 5px;
          padding-right: 5px;
          font-size: var(--xSmallFontSize);
        }
      }

      &-other {
        background-color: transparent;
        color: #fff;
        float: left;
        min-width: 10%;
        max-width: 65%;
        border: 1px solid #fff;
        border-radius: 8px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: var(--mSmallFontSize);
        text-align: left;

        &-time {
          background-color: transparent;
          color: #fff;
          float: left;
          min-width: 10%;
          max-width: 50%;
          border: 0px;
          margin-left: 5px;
          margin-top: 0;
          margin-bottom: 2px;
          padding-left: 5px;
          padding-right: 5px;
          font-size: var(--xSmallFontSize);
        }
      }
    }

  }

  &-button {
    height: 32px;
    width: 75%;
    margin-top: 10px;
    background: linear-gradient(180deg, #B4EC51 0%, #429321 100%);
    border-radius: 3px;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: var(--defaultFontSize);
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.2px;

    color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
    padding: 0;

    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

    &-dot {
      padding-left: 5px;
      height: 7px;
      width: 7px;
      background-color: #ff0000;
      border-radius: 50%;
      display: inline-block;
    }
  }


  &-select {
    height: 32px;
    width: 100%;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--defaultFontSize);
    line-height: 18px;
    letter-spacing: 0.5px;

    margin: auto;

    & option {
    //  background-color: #222222;
    }
  }

  &-footer {
    &-button {
      margin-right: auto;
      margin-left: auto;
      width: 25%;

      background: linear-gradient(180deg, #FFD400 2.43%, #CA9318 100%);
      border-radius: 3px;

      font-size: var(--defaultFontSize);
      line-height: 18px;

      letter-spacing: 0.2px;

      color: #FFFFFF;
    }
  }
}
