
// @import "../../redesignVariables";

$titlePadding: 1vmin 5px 0.2vmin 15px;

$rowBackground: rgba(255,255,255,0.03);
$highlightColor: #FFC900;
$mobileSmSize: 574px;
.root-class-design20 {

  .zole-rules-page {
    padding-right: 10px;
    &-title {
      height: 45px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: var(--xxxLargeFontSize);
      line-height: 140%;
      color: #FFFFFF;
      margin-left: 50px;
    }

    & .page-title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: var(--xxxLargeFontSize);
      line-height: 140%;
      color: #FFFFFF;
      // margin-bottom: 49px;
    }

    &-mobile-header {
      display: none;
    }
    &-header {
      // flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      min-height: 300px;
      height: calc(100vh - 350px);

      @media only screen and (orientation: landscape) and (max-width: 1280px) {
        min-height: 100px;
      }

      &-tab {
        height: 100%;
        font-size: var(--headerFontSize);
      }

      &-link {
        color: $headerTextColour;
        opacity: $headerInactiveOpacity;
        margin: 0px 6px;
        padding: 6px 0px;
        padding-left: 6px;
        cursor: pointer;
        text-decoration: none;
        width: 100%;
        text-align: left;

        &:hover {
          color: $headerTextColour;
        }

        &:active {
          color: $headerTextColour;
        }

        &:focus {
          color: $headerTextColour;
        }

        &-active {
          border-left: 1px solid $headerActiveBorderColour;
          opacity: 1;
        }

        &-claim {

        }
      }

      &-links {
        margin-top: auto;
        margin-bottom: auto;
        text-align: right;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: var(--headerFontSize);
        line-height: 19px;
        color: #FFFFFF;
        text-transform: uppercase;
        display: inline-block;
      }

    }

    &-content {
      font-size: var(--headerFontSize);
      // flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      min-height: 300px;
      height: calc(100vh - 350px);

      @media only screen and (orientation: landscape) and (max-width: 1280px) {
        min-height: 100px;
      }

      @media only screen and (orientation: portrait) {
        height: calc(100vh - 430px);

      }

      &-tab {
        height: 100%;


        & .tab-pane {
          height: 100%;
          & .help-content {
            height: 100%;
          }
          & .menu-help-content {
            height: 100%;
          }
        }
      }
      & .mobile-help-content {
        display: none;
      }
    }
    @media only screen and (max-width: $mobileSmSize) {
      &-header {
        // height: calc(50% - 50px);
        width: 90%;
        margin-bottom: 50px;
        display: none;
      }

      &-content {
        // height: calc(50% - 50px);
        width: 100%;

        & .help-content {
          display: none;
        }
        & .mobile-help-content {
          display: flex;
          height: auto !important;
          font-size: var(--defaultFontSize);
        }
        & .menu-help-content {
          font-size: var(--defaultFontSize);
        }
      }

      &-title {
        margin-left: 0px;
      }

      &-mobile-header {
        display: flex;
        width: 100%;
        height: 49px;

        & .zole-rules-page-header-tab {
          width: 100%;

          & .landing-help-page-dropdown-list {
            width: 100%;
            top: 105px !important;
            height: 300px;
            min-height: 300px;
            overflow: scroll;
          }
          & .menu-rule-drop-down {
            top: 0px !important;
          }
          & .landing-version-history-dropdown-list {
            width: 100%;
            top: 0px !important;
          }
          & button {
            width: 100%;
            font-size: var(--defaultFontSize);
            color: #ffffff;
            opacity: 1;
          }

          & button::after {
            display: none;
          }
          & button::before {
            display: none;
          }
        }
        & button {
          width: 100%;
          font-size: var(--defaultFontSize);
          color: #ffffff;
          opacity: 1;
        }
      }
    }

    &-body {
      height: 100%;

      color: #FFF;
      font-size: var(--defaultFontSize);
      text-align: justify;
      margin-left: 5px;
      p {
        margin-bottom: 8px;
        font-size: var(--largeFontSize);
      }
      .second-level-bulletpoints {
        margin-bottom: 6px;
        margin-top: 0px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: var(--headerFontSize) !important;
        line-height: 140%;
      }
      @media only screen and (max-width: $mobileSmSize) {
        .second-level-bulletpoints {
          font-size: var(--defaultFontSize);
        }
      }
    }

    &-body.terms-of-use-body {
      text-align: left;
    }
  }
}

.root-class-design20-light {
  .zole-rules-page {
    @media only screen and (max-width: $mobileSmSize) {
      &-mobile-header {
        & .zole-rules-page-header-tab {
          & button {
            color: #ff8a1a;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobileSmSize) {
  .zole-rules-page-content-tab {
    .mobile-help-content {
      .zole-rules-page-content-wrapper.col {
        padding-left: 5px;
        padding-right: 0;
      }
    }
  }
  .zole-rules-page-content-version-history-wrapper {
    padding-left: 5px;
    padding-right: 0;
    .editor-readOnly {
      padding: 0;
      .editorClassName {
        padding: 0;
      }
    }
  }
}

.zole-rules-page-content-version-history-wrapper {
  span {
    color: white !important;
    background-color: unset !important;
  }
  a {
    span {
      color: unset !important;
    }
    color: rgb(255, 132, 0) !important;
    color: #FFC900 !important;

    text-decoration: underline !important;
  
    &:hover {
      color: rgb(255, 132, 0) !important;
      color: #FFC900 !important;

    }
  }
}

.contact-support-chat-body-scroll-area.zole-rules-page-body-scroll-area {
  height: 100%;
}

.game-help-modal .zole-rules-page-content-tab .contact-support-chat-body-scroll-area.zole-rules-page-body-scroll-area {
  height: 100%;
}

.zole-rules-page-content-tab .contact-support-chat-body-scroll-area.zole-rules-page-body-scroll-area {
  height: 815px;
}

.root-class-design20.root-class-design20-light {
  .zole-rules-page-header-link-active {
    border-left: 1px solid #D2110A;
  }
  .game-help-modal {
    .modal-body {
      background-color: rgba($color: #fff, $alpha: 0.95) !important;
      border-radius: 17px 17px 0 0 !important;
      .zole-rules-container {
        background-color: initial !important;
        .zole-rules-page-body-scroll-area {
          margin-left: -15px;
          border-left: 1px solid rgba($color: #000, $alpha: 0.1);
          padding-left: 15px;
        }
      }
    }
    .modal-footer {
      background-color: rgba($color: #fff, $alpha: 0.95) !important;
      margin-top: 0;
    }
  }
}

.zole-rules-page-content {
  .zole-rules-page-content-wrapper {
    font-size: var(--largeFontSize);
    padding-left: 37px;
  }
}

.root-class-design20-dark {
  .zole-rules-page-content-wrapper {
    table, td, tr, th {
      border: 1px solid rgba($color: #fff, $alpha: 0.2);
      text-align: center;
    }
  }
}

.root-class-design20-normal {
  .zole-rules-page-content-wrapper {
    table, td, tr, th {
      border: 1px solid rgba($color: #fff, $alpha: 0.2);
      text-align: center;
    }
  }
}

.root-class-design20-light {
  .zole-rules-page-content-wrapper {
    table, td, tr, th {
      border: 1px solid rgba($color: #fff, $alpha: 0.2);
      text-align: center;
    }
  }
}