$mobileLandScapeWidth: 1280px;

.root-class-design20 {
    @media screen and (orientation : portrait) {
        .layout-mobile-body-bonus-page {
            .layout-mobile-body-top {
                margin-top: 45px;

                .layout-subheader.layout-subheader-bonus-page {
                    text-align: center;
                    padding-top: 17px;
                    margin-bottom: 0;
                    display: block !important;

                    .layout-subheader-title {
                        padding: 0;
                        margin: 0;
                        font-size: var(--largeFontSize);
                        font-weight: 600;
                        line-height: 136%;
                        font-family: 'Open Sans';
                    }
                }
            }

            .layout-mobile-body-main {
                height: calc(100vh - 271px) !important;
                padding: 0 !important;
                margin: 11px 0 0 0 !important;

                .layout-subheader-title {
                    display: none;
                }

                .bonus-page-container {
                    height: 100%;
                    position: absolute;
                    top: 0;
                    align-items: self-start;

                    .bonus-page-container-wheel {
                        width: 100%;

                        .bonus-page-wheel-wrapper {
                            width: 40vh;
                            height: 100%;
                            margin-top: 0;

                            .bonus-page-wheel-bg {
                                width: 125%;
                                height: auto;
                                object-fit: contain;
                                max-width: unset;
                                max-height: unset;
                            }

                            .bonus-page-wheel {
                                width: 100%;
                                object-fit: contain;
                                height: auto;
                            }

                            .bonus-page-wheel-arrow {
                                width: 18%;
                                height: auto;
                                margin-top: 2%;
                                margin-left: 34%;
                            }
                        }

                        .bonus-page-container-content {
                            margin-top: -15px !important;
                            text-align: center;
                            padding-left: 20px;
                            padding-right: 20px;

                            .bonus-page-text {
                                flex-direction: column;

                                .mr-2 {
                                    margin: 0 !important;
                                    padding-bottom: 7px;
                                }

                                .bonus-page-money {
                                    display: inline-block;
                                    padding: 2px 8px;
                                    align-self: center;
                                    background: rgba(0, 0, 0, 0.3);
                                    border-radius: 88px;

                                    .bonus-page-money-text {
                                        margin-left: 7px;
                                    }
                                }
                            }

                            .bonus-page-complete-money {
                                display: inline-flex;
                                padding: 2px 8px;
                                border-radius: 88px;
                                align-items: center;
                                justify-content: center;
                                max-width: unset;
                                flex: unset;
                                width: auto;
                                background: rgba(0, 0, 0, 0.3);

                                .bonus-page-complete-money-text {
                                    margin-left: 7px;
                                }
                            }
                        }

                        .bonus-page-spin-button {
                            display: flex;
                            justify-content: center;
                            column-gap: 10px;

                            .layout-highlighted-button {
                                padding: 7px 15px;
                                white-space: nowrap;
                                margin: 0;
                                font-size: var(--defaultFontSize);
                                font-weight: 700;
                                text-transform: uppercase;
                                color: #000000;
                                line-height: 138%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .nav-link {
                                padding: 6px 15px;
                                white-space: nowrap;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &::before {
                                    margin-right: 13px;
                                }

                                &::after {
                                    margin-left: 13px;
                                }

                                font-size: var(--defaultFontSize);
                                font-weight: 700;
                                text-transform: uppercase;
                                color: #fff;
                                line-height: 138%;
                            }
                        }

                        .bonus-page-complete-button {
                            display: flex;
                            justify-content: center;
                            column-gap: 10px;

                            .layout-redHighlighted-button {
                                padding: 7px 15px;
                                white-space: nowrap;
                                margin: 0;
                                font-size: var(--defaultFontSize);
                                font-weight: 700;
                                text-transform: uppercase;
                                color: #fff;
                                line-height: 138%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .nav-link {
                                padding: 6px 15px;
                                white-space: nowrap;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &::before {
                                    margin-right: 13px;
                                }

                                &::after {
                                    margin-left: 13px;
                                }

                                font-size: var(--defaultFontSize);
                                font-weight: 700;
                                text-transform: uppercase;
                                color: #fff;
                                line-height: 138%;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        .bonus-page-modal {
            .modal-body {
                flex: none !important;
            }
        }
        .layout-mobile-body-bonus-page {
            .layout-mobile-body-main {
                padding-left: 12px !important;
                padding-top: 11px !important;

                .layout-subheader-title {
                    margin: 0;
                    padding: 0;
                }

                .bonus-page-container {
                    height: 100%;
                    position: absolute;
                    top: 20vh;
                    align-items: flex-start;

                    .bonus-page-container-wheel {
                        width: 100%;

                        .bonus-page-wheel-wrapper {
                            width: 85%;
                            height: 100%;
                            margin-top: 0;

                            .bonus-page-wheel-bg {
                                width: 65%;
                                height: auto;
                                object-fit: contain;
                                max-width: unset;
                                max-height: unset;
                            }

                            .bonus-page-wheel {
                                width: 50%;
                                object-fit: contain;
                                height: auto;
                            }

                            .bonus-page-wheel-arrow {
                                width: 9%;
                                height: auto;
                                margin-top: 1%;
                                margin-left: 17%;
                            }
                        }

                        .bonus-page-container-content {
                            margin-top: -15px !important;
                            text-align: center;
                            padding-left: 20px;
                            padding-right: 20px;

                            .bonus-page-text {
                                flex-direction: column;

                                .mr-2 {
                                    margin: 0 !important;
                                    padding-bottom: 7px;
                                }

                                .bonus-page-money {
                                    display: inline-block;
                                    padding: 2px 8px;
                                    align-self: center;
                                    background: rgba(0, 0, 0, 0.3);
                                    border-radius: 88px;

                                    .bonus-page-money-text {
                                        margin-left: 7px;
                                    }
                                }
                            }

                            .bonus-page-complete-money {
                                display: inline-flex;
                                padding: 2px 8px;
                                border-radius: 88px;
                                align-items: center;
                                justify-content: center;
                                max-width: unset;
                                flex: unset;
                                width: auto;
                                background: rgba(0, 0, 0, 0.3);

                                .bonus-page-complete-money-text {
                                    margin-left: 7px;
                                }
                            }
                        }

                        .bonus-page-spin-button {
                            display: flex;
                            justify-content: center;
                            column-gap: 10px;

                            .layout-highlighted-button {
                                padding: 7px 15px;
                                white-space: nowrap;
                                margin: 0;
                                font-size: var(--defaultFontSize);
                                font-weight: 700;
                                text-transform: uppercase;
                                color: #000000;
                                line-height: 138%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .nav-link {
                                padding: 6px 15px;
                                white-space: nowrap;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &::before {
                                    margin-right: 13px;
                                }

                                &::after {
                                    margin-left: 13px;
                                }

                                font-size: var(--defaultFontSize);
                                font-weight: 700;
                                text-transform: uppercase;
                                color: #fff;
                                line-height: 138%;
                            }
                        }

                        .bonus-page-complete-button {
                            display: flex;
                            justify-content: center;
                            column-gap: 10px;

                            .layout-redHighlighted-button {
                                padding: 7px 15px;
                                white-space: nowrap;
                                margin: 0;
                                font-size: var(--defaultFontSize);
                                font-weight: 700;
                                text-transform: uppercase;
                                color: #fff;
                                line-height: 138%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .nav-link {
                                padding: 6px 15px;
                                white-space: nowrap;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &::before {
                                    margin-right: 13px;
                                }

                                &::after {
                                    margin-left: 13px;
                                }

                                font-size: var(--defaultFontSize);
                                font-weight: 700;
                                text-transform: uppercase;
                                color: #fff;
                                line-height: 138%;
                            }
                        }
                    }
                }
            }
        }
    }

}

.root-class-design20-light {
    @media screen and (orientation : portrait) {
        .layout-mobile-body-bonus-page {
            .layout-mobile-body-main {
                .bonus-page-container {
                    .bonus-page-container-wheel {
                        .bonus-page-wheel-wrapper {
                            .bonus-page-wheel-bg {}

                            .bonus-page-wheel {}

                            .bonus-page-wheel-arrow {}
                        }

                        .bonus-page-container-content {

                            .bonus-page-text {

                                .mr-2 {}

                                .bonus-page-money {

                                    .bonus-page-money-text {
                                        color: #fff;
                                    }
                                }
                            }

                            .bonus-page-complete-money {
                                .bonus-page-complete-money-text {
                                    color: #fff;
                                }
                            }
                        }

                        .bonus-page-spin-button {
                            .layout-highlighted-button {
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4) !important;
                            }

                            .nav-link {
                                background: rgba(0, 0, 0, 0.4);
                                border: 1px solid rgba(255, 255, 255, 0.1);
                            }
                        }

                        .bonus-page-complete-button {
                            .nav-link {
                                background: rgba(0, 0, 0, 0.4);
                                border: 1px solid rgba(255, 255, 255, 0.1);
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        .bonus-page-modal {
            .modal-body {
                flex: none !important;
            }
        }
        .layout-mobile-body-bonus-page {
            .layout-mobile-body-main {
                .bonus-page-container {
                    .bonus-page-container-wheel {
                        .bonus-page-wheel-wrapper {
                            .bonus-page-wheel-bg {}

                            .bonus-page-wheel {}

                            .bonus-page-wheel-arrow {}
                        }

                        .bonus-page-container-content {

                            .bonus-page-text {

                                .mr-2 {}

                                .bonus-page-money {

                                    .bonus-page-money-text {
                                        color: #fff;
                                    }
                                }
                            }

                            .bonus-page-complete-money {
                                .bonus-page-complete-money-text {
                                    color: #fff;
                                }
                            }
                        }

                        .bonus-page-spin-button {
                            .layout-highlighted-button {
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4) !important;
                            }

                            .nav-link {
                                background: rgba(0, 0, 0, 0.4);
                                border: 1px solid rgba(255, 255, 255, 0.1);
                            }
                        }

                        .bonus-page-complete-button {
                            .nav-link {
                                background: rgba(0, 0, 0, 0.4);
                                border: 1px solid rgba(255, 255, 255, 0.1);
                            }
                        }
                    }
                }
            }
        }
    }
}
