// @import "../../redesignVariables";

// $defaultFontSize: 1.2vmin;

$rowHeight: $menuRowHeight;

.root-class-design20 {

  .top-search-popover {
    background-color: #000;
    color: #fff;
    border: 1px solid #fff;
    -webkit-box-shadow: 5px 5px 0px 0px #380000;
    -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 5px 0px 0px #380000;

    &-body {
      color: #fff;
    }
  }

  .top-search-popover-light {
    background-color: #fff !important;
    color: $lightTextColour !important;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: 5px 5px 0px 0px #868686 !important;
    -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75) !important;
    box-shadow: 5px 5px 0px 0px #868686 !important;

    & .top-search-popover-body {
      color: $lightTextColour !important;
    }
  }

  .top-table {
    &-search {
      width: 300px;
      transform: translate(-106px, 39px) !important;
    }
    background-color: $bodyTopBackgroundColour;
    // opacity: $bodyTopBackgroundOpacity;

    &-table {
      background-color: $bodyTopBackgroundColour;
      // opacity: $bodyTopBackgroundOpacity;
    }

    &-scrollarea {
      max-height: calc(100% - 40px);
      // height: auto!important;
      // max-height: 100%;

      &-body {
        height: auto;

        &-header.bg-black {
          background-color: rgba($color: #000, $alpha: 0.95);
        }

        &-header {
          white-space: wrap;
          word-wrap: break-word;
          overflow: hidden;
          // height: 40px;
          // background-color: rgba(0, 0, 0, 0.95);
          padding-left: 34px;
          padding-right: 34px;
          display: flex;
          flex-wrap: unset !important;

          & .menu-table-row-text {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            width: 6.66%;
            min-width: 60px;
            text-align: center;
          }
        }

        & .menu-table-row-inner-wrapper {

          &>div {

            &>.menu-table-row-text-last {
              border-right: 0 !important;
            }

            &>.menu-table-row-text {
              border-right: 1px solid rgba(255, 255, 255, 0.1);
              width: 6.66%;
              min-width: 60px;
              text-align: center;

              & .menu-table-row-text-positive {
                background: #009C10;
                border-radius: 44px;
                width: max-content;
                padding-left: 1.5vmin;
                padding-right: 1.5vmin;
              }

              & .menu-table-row-text-negative {
                background: #D2110A;
                border-radius: 44px;
                width: max-content;
                padding-left: 1.5vmin;
                padding-right: 1.5vmin;
              }
            }
          }
        }
      }
    }

    &-millionaires-link {
      padding: 0 8px;
      line-height: 38px;
      // opacity: $disabledLinkOpacity;
      // color: rgba(255, 255, 255, 0.5);
    }

    &-active-link {
      color: #FFC900;
      opacity: 1;
    }

    &-row {
      //  opacity: 0.03;
      //  transition: opacity 3s linear;
      display: flex;
      vertical-align: middle;

      height: 100%;
      align-items: center;
      //  min-height: 42px;

      &-button-wrapper {
        text-align: right;
      }

      &-text {
        vertical-align: middle;
        display: inline-block;
        text-overflow: ellipsis;

        height: $rowHeight;
        line-height: $rowHeight;
        position: relative;

        &-friend {
          position: absolute;
          right: 30px;
        }

        &-sm {
          width: 6.66%;
          // min-width: 60px;
        }

        &-slm {
          width: 8.66%;
          // min-width: 90px;
        }

        &-md {
          width: 10%;
          // min-width: 100px;
        }

        &-lgg {
          width: 16.66%;
          // min-width: 160px;
        }

        &-lsg {
          width: 12.66%;
          // min-width: 120px;
        }

        &-lmg {
          width: 15.66%;
          // min-width: 150px;
        }

        ;
      }

      &-player {
        vertical-align: middle;
        display: inline-block;

        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;

        min-height: 36px;

        &-block {
          // height: 2.4vmin;
          // width: 2.4vmin;
          // margin-top: 0.1vmin;
          // min-height: 28px;
          // min-width: 28px;
          width: 32px;
          height: 32px;
          margin-right: -2px;

          cursor: pointer;
        }

        &-friend {
          // height: 3.4vmin;
          // width: 3.4vmin;
          // margin-top: 0.1vmin;
          // min-height: 46px;
          // min-width: 46px;
          width: 50px;
          height: 50px;
          margin-right: -2px;

          cursor: pointer;

          &-disabled {
            opacity: 0.3;
          }
        }

        &-image {
          width: 36px;
          height: 36px;

          min-width: 36px;
          min-height: 36px;

          border-radius: 50%;

          &-default {
            padding: 2px;
          }

          &-frame {
            width: 36px;
            height: 36px;

            min-width: 36px;
            min-height: 36px;

            margin-right: 4px;
            vertical-align: middle;
            border-radius: 50%;
            //  background-color: #FFF;
            background-color: rgba(255, 255, 255, 0.2);
            //  opacity: 0.2;
            display: inline-block;
            position: relative;
          }
        }

      }

    }
  }


}

.root-class-design20.root-font-xLarge {
  .top-page-main-body-section {
    height: 100%;
    position: relative;

    .scrollArea-buttonUp {
      display: none;
    }

    .scrollArea-buttonDown {
      display: none;
    }

    .layout-body-main-table-header {
      padding-left: 10px;
      padding-right: 14px;
      padding-bottom: 11px;
      display: inline-block;
      min-width: 100%;

      &-wrapper {
        display: inline-flex;
        align-items: center;
        background-color: rgba(#ECECEC, 0.1);
        padding-top: 4px;
        padding-bottom: 4px;
        height: auto;

        .top-table-row-text-self {
          &-place {
            min-width: 80px;
            max-width: 80px;

            .layout-divider {
              margin-left: 16px;
            }
          }

          &-player {
            max-width: 240px;
            min-width: 200px;
          }

          &-level {
            max-width: 150px;
            min-width: 140px;

            .my-info-ratings-step-wrapper {
              justify-content: flex-start;
            }
          }

          &-rating {
            max-width: 150px;
            min-width: 73px !important;
          }
          &-balance {
            max-width: 150px;
            min-width: 105px;
          }

          &-points {
            max-width: 220px;
            min-width: 70px;
          }

          &-rounds {
            max-width: 220px;
            min-width: 112px;
          }

          &-bgwon {
            max-width: 180px;
            min-width: 95px;
          }

          &-tblswon {
            max-width: 200px;
            min-width: 110px;
          }

          &-sgwon {
            max-width: 250px;
            min-width: 140px;
          }

          &-achvcnt {
            min-width: 200px;
            max-width: 250px;
          }

          &-maxrndingame {
            min-width: 200px;
            max-width: 220px;
          }
        }

        &>div {
          flex-grow: 1;
          flex-basis: 0;
        }
      }

      .top-filter-icons-section {
        opacity: 1;
        width: 4%;

        button {
          font-size: var(--largeFontSize);
          opacity: 0.5;
          font-weight: 450;
          font-family: 'Futura PT';
          padding-right: 1px;
        }

        .rooms-table-filter-icon {
          opacity: 1;

          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .top-page-main-body-wrapper {
      overflow-x: auto;
      overflow-y: hidden;
      max-height: calc(100% - 90px);

      &::-webkit-scrollbar {
        height: 4px;
      }

      .top-table-scrollarea-body-header {
        padding: 0px 13px 14px 12px;
        overflow: unset;
        position: relative;

        .layout-body-main-table-header-orderby::after,
        .layout-body-main-table-header-orderby_asc_active::after,
        .layout-body-main-table-header-orderby_desc_active::after {
          top: 5px;
        }

        .layout-body-main-table-header {
          &-orderby {
            //white-space: nowrap;
            flex-grow: 1;
            flex-basis: 0;
          }

          &-place {
            min-width: 80px;
            max-width: 80px;
          }

          &-player {
            max-width: 240px;
            min-width: 200px;
          }

          &-level {
            max-width: 150px;
            min-width: 140px;
          }

          &-rating {
            max-width: 150px;
            min-width: 73px;
          }

          &-balance {
            max-width: 150px;
            min-width: 105px;
          }

          &-points {
            max-width: 220px;
            min-width: 70px;
          }

          &-rounds {
            max-width: 220px;
            min-width: 112px;
          }

          &-bgwon {
            max-width: 180px;
            min-width: 95px;
          }

          &-tblswon {
            max-width: 200px;
            min-width: 110px;
          }

          &-sgwon {
            max-width: 250px;
            min-width: 140px;
          }

          &-achvcnt {
            min-width: 200px;
            max-width: 250px;
          }

          &-maxrndingame {
            min-width: 200px;
            max-width: 220px;
          }
        }
      }

      .top-table-custom-scroll-body {
        max-height: calc(100% - 92px);
        overflow-y: hidden;
        overflow-x: hidden;
        display: inline-block;
        // margin-right: 4px;
        // width: calc(100% - 4px);
        //width: auto;
        position: unset;

        .scrollbar-container.vertical {
          top: 5.5vw;
          right: 4px;
        }

        &::-webkit-scrollbar {}

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        .menu-table-row-wrapper {
          padding-left: 10px;
          padding-right: 14px;
          padding-bottom: 1px;
          display: inline-flex;
        }

        .menu-table-row-inner-wrapper {
          .top-table-row {
            overflow: unset;
            position: relative;

            &-text {
              &-place {
                min-width: 80px;
                max-width: 80px;

                .layout-divider {
                  margin-left: 16px;
                }
              }

              &-player {
                max-width: 240px;
                min-width: 200px;
              }

              &-level {
                max-width: 150px;
                min-width: 140px;

                .my-info-ratings-step-wrapper {
                  justify-content: flex-start;
                }
              }

              &-rating {
                max-width: 150px;
                min-width: 73px;
              }

              &-balance {
                max-width: 150px;
                min-width: 105px;
              }

              &-points {
                max-width: 220px;
                min-width: 70px;
              }

              &-rounds {
                max-width: 220px;
                min-width: 112px;
              }

              &-bgwon {
                max-width: 180px;
                min-width: 95px;
              }

              &-tblswon {
                max-width: 200px;
                min-width: 110px;
              }

              &-sgwon {
                max-width: 250px;
                min-width: 140px;
              }

              &-achvcnt {
                min-width: 200px;
                max-width: 250px;
              }

              &-maxrndingame {
                min-width: 200px;
                max-width: 220px;
              }

              &-block {
                position: absolute;
                right: 3px;
                flex: 0;
              }

              &-right {
                position: absolute;
                right: 27px;
                top: -2px;
                flex: 0;
              }

              &-mobile-friend {
                position: absolute;
                right: 61px;
                top: -2px;
                flex: 0;
              }

            }

            &>div {
              flex-grow: 1;
              flex-basis: 0;
            }
          }


        }

      }
    }
  }
}

.root-class-design20.root-font-large {
  .top-page-main-body-section {
    height: 100%;
    position: relative;

    .scrollArea-buttonUp {
      display: none;
    }

    .scrollArea-buttonDown {
      display: none;
    }

    .layout-body-main-table-header {
      padding-left: 10px;
      padding-right: 14px;
      padding-bottom: 11px;
      display: inline-block;
      min-width: 100%;

      &-wrapper {
        display: inline-flex;
        align-items: center;
        background-color: rgba(#ECECEC, 0.1);
        padding-top: 4px;
        padding-bottom: 4px;
        height: auto;

        .top-table-row-text-self {
          &-place {
            min-width: 80px;
            max-width: 80px;

            .layout-divider {
              margin-left: 16px;
            }
          }

          &-player {
            max-width: 240px;
            min-width: 200px;
          }

          &-level {
            max-width: 120px;
            min-width: 100px;

            .my-info-ratings-step-wrapper {
              justify-content: flex-start;
            }
          }

          &-rating {
            max-width: 150px;
            min-width: 72px;
          }

          &-balance {
            max-width: 150px;
            min-width: 96px;
          }

          &-points {
            max-width: 220px;
            min-width: 62px;
          }

          &-rounds {
            max-width: 220px;
            min-width: 104px;
          }

          &-bgwon {
            max-width: 150px;
            min-width: 88px;
          }

          &-tblswon {
            max-width: 170px;
            min-width: 100px;
          }

          &-sgwon {
            max-width: 230px;
            min-width: 122px;
          }

          &-achvcnt {
            min-width: 182px;
            max-width: 220px;
          }

          &-maxrndingame {
            min-width: 180px;
            max-width: 220px;
          }
        }

        &>div {
          flex-grow: 1;
          flex-basis: 0;
        }
      }

      .top-filter-icons-section {
        opacity: 1;
        width: 4%;

        button {
          font-size: var(--largeFontSize);
          opacity: 0.5;
          font-weight: 450;
          font-family: 'Futura PT';
          padding-right: 1px;
        }

        .rooms-table-filter-icon {
          opacity: 1;

          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .top-page-main-body-wrapper {
      overflow-x: auto;
      overflow-y: hidden;
      max-height: calc(100% - 90px);

      &::-webkit-scrollbar {
        height: 4px;
      }

      .top-table-scrollarea-body-header {
        padding: 0px 13px 14px 12px;
        overflow: unset;
        position: relative;

        .layout-body-main-table-header-orderby::after,
        .layout-body-main-table-header-orderby_asc_active::after,
        .layout-body-main-table-header-orderby_desc_active::after {
          top: 5px;
        }

        .layout-body-main-table-header {
          &-orderby {
            //white-space: nowrap;
            flex-grow: 1;
            flex-basis: 0;
          }

          &-place {
            min-width: 80px;
            max-width: 80px;
          }

          &-player {
            max-width: 240px;
            min-width: 200px;
          }

          &-level {
            max-width: 120px;
            min-width: 100px;
          }

          &-rating {
            max-width: 150px;
            min-width: 72px;
          }
          &-balance {
            max-width: 150px;
            min-width: 96px;
          }

          &-points {
            max-width: 220px;
            min-width: 62px;
          }

          &-rounds {
            max-width: 220px;
            min-width: 104px;
          }

          &-bgwon {
            max-width: 150px;
            min-width: 88px;
          }

          &-tblswon {
            max-width: 170px;
            min-width: 100px;
          }

          &-sgwon {
            max-width: 230px;
            min-width: 122px;
          }

          &-achvcnt {
            min-width: 182px;
            max-width: 220px;
          }

          &-maxrndingame {
            min-width: 180px;
            max-width: 220px;
          }
        }
      }

      .top-table-custom-scroll-body {
        max-height: calc(100% - 92px);
        overflow-y: hidden;
        overflow-x: hidden;
        display: inline-block;
        // margin-right: 4px;
        // width: calc(100% - 4px);
        // width: auto;
        position: unset;

        .scrollbar-container.vertical {
          top: 5.5vw;
          right: 4px;
        }

        &::-webkit-scrollbar {}

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        .menu-table-row-wrapper {
          padding-left: 10px;
          padding-right: 14px;
          padding-bottom: 1px;
          display: inline-flex;
        }

        .menu-table-row-inner-wrapper {
          .top-table-row {
            overflow: unset;
            position: relative;

            &-text {
              &-place {
                min-width: 80px;
                max-width: 80px;

                .layout-divider {
                  margin-left: 16px;
                }
              }

              &-player {
                max-width: 240px;
                min-width: 200px;
              }

              &-level {
                max-width: 120px;
                min-width: 100px;

                .my-info-ratings-step-wrapper {
                  justify-content: flex-start;
                }
              }

              &-rating {
                max-width: 150px;
                min-width: 72px;
              }

              &-balance {
                max-width: 150px;
                min-width: 96px;
              }

              &-points {
                max-width: 220px;
                min-width: 62px;
              }

              &-rounds {
                max-width: 220px;
                min-width: 104px;
              }

              &-bgwon {
                max-width: 150px;
                min-width: 88px;
              }

              &-tblswon {
                max-width: 170px;
                min-width: 100px;
              }

              &-sgwon {
                max-width: 230px;
                min-width: 122px;
              }

              &-achvcnt {
                min-width: 182px;
                max-width: 220px;
              }

              &-maxrndingame {
                min-width: 180px;
                max-width: 220px;
              }

              &-block {
                position: absolute;
                right: 3px;
                flex: 0;
              }

              // &-friend {
              //   position: absolute;
              //   right: 27px;
              //   top: -2px;
              //   flex: 0;
              // }

              &-mobile-friend {
                position: absolute;
                right: 61px;
                top: -2px;
                flex: 0;
              }
            }

            &>div {
              flex-grow: 1;
              flex-basis: 0;
            }
          }


        }

      }
    }
  }
}

.root-class-design20.root-font-normal {
  .top-page-main-body-section {
    height: 100%;
    position: relative;

    .scrollArea-buttonUp {
      display: none;
    }

    .scrollArea-buttonDown {
      display: none;
    }

    .layout-body-main-table-header {
      padding-left: 10px;
      padding-right: 14px;
      padding-bottom: 11px;
      display: inline-block;
      min-width: 100%;

      &-wrapper {
        display: inline-flex;
        align-items: center;
        background-color: rgba(#ECECEC, 0.1);
        padding-top: 4px;
        padding-bottom: 4px;
        height: auto;

        .top-table-row-text-self {
          &-place {
            min-width: 80px;
            max-width: 80px;

            .layout-divider {
              margin-left: 16px;
            }
          }

          &-player {
            max-width: 240px;
            min-width: 200px;
          }

          &-level {
            max-width: 120px;
            min-width: 100px;

            .my-info-ratings-step-wrapper {
              justify-content: flex-start;
            }
          }

          &-rating {
            max-width: 150px;
            min-width: 62px;
          }

          &-balance {
            max-width: 150px;
            min-width: 84px;
          }

          &-points {
            max-width: 220px;
            min-width: 60px;
          }

          &-rounds {
            max-width: 220px;
            min-width: 97px;
          }

          &-bgwon {
            max-width: 130px;
            min-width: 75px;
          }

          &-tblswon {
            max-width: 160px;
            min-width: 85px;
          }

          &-sgwon {
            max-width: 220px;
            min-width: 110px;
          }

          &-achvcnt {
            min-width: 160px;
            max-width: 210px;
          }

          &-maxrndingame {
            min-width: 156px;
            max-width: 200px;
          }
        }

        &>div {
          flex-grow: 1;
          flex-basis: 0;
        }
      }

      .top-filter-icons-section {
        opacity: 1;
        width: 4%;

        button {
          font-size: var(--largeFontSize);
          opacity: 0.5;
          font-weight: 450;
          font-family: 'Futura PT';
          padding-right: 1px;
        }

        .rooms-table-filter-icon {
          opacity: 1;

          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .top-page-main-body-wrapper {
      overflow-x: auto;
      overflow-y: hidden;
      max-height: calc(100% - 90px);

      &::-webkit-scrollbar {
        height: 4px;
      }

      .top-table-scrollarea-body-header {
        padding: 0px 13px 14px 12px;
        overflow: unset;
        position: relative;

        .layout-body-main-table-header-orderby::after,
        .layout-body-main-table-header-orderby_asc_active::after,
        .layout-body-main-table-header-orderby_desc_active::after {
          top: 5px;
        }

        .layout-body-main-table-header {
          &-orderby {
            //white-space: nowrap;
            flex-grow: 1;
            flex-basis: 0;
          }

          &-place {
            min-width: 80px;
            max-width: 80px;
          }

          &-player {
            max-width: 240px;
            min-width: 200px;
          }

          &-level {
            max-width: 120px;
            min-width: 100px;
          }

          &-rating {
            max-width: 150px;
            min-width: 62px;
          }

          &-balance {
            max-width: 150px;
            min-width: 84px;
          }

          &-points {
            max-width: 220px;
            min-width: 60px;
          }

          &-rounds {
            max-width: 220px;
            min-width: 97px;
          }

          &-bgwon {
            max-width: 130px;
            min-width: 75px;
          }

          &-tblswon {
            max-width: 160px;
            min-width: 85px;
          }

          &-sgwon {
            max-width: 220px;
            min-width: 110px;
          }

          &-achvcnt {
            min-width: 160px;
            max-width: 210px;
          }

          &-maxrndingame {
            min-width: 156px;
            max-width: 200px;
          }
        }
      }

      .top-table-custom-scroll-body {
        max-height: calc(100% - 92px);
        overflow-y: hidden;
        overflow-x: hidden;
        display: inline-block;
        // margin-right: 4px;
        // width: calc(100% - 4px);
        // width: auto;
        position: unset;

        .scrollbar-container.vertical {
          top: 5.5vw;
          right: 4px;
        }

        &::-webkit-scrollbar {}

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        .menu-table-row-wrapper {
          padding-left: 10px;
          padding-right: 14px;
          padding-bottom: 1px;
          display: inline-flex;
        }

        .menu-table-row-inner-wrapper {
          .top-table-row {
            overflow: unset;
            position: relative;

            &-text {
              &-place {
                min-width: 80px;
                max-width: 80px;

                .layout-divider {
                  margin-left: 16px;
                }
              }

              &-player {
                max-width: 240px;
                min-width: 200px;
              }

              &-level {
                max-width: 120px;
                min-width: 100px;

                .my-info-ratings-step-wrapper {
                  justify-content: flex-start;
                }
              }

              &-rating {
                max-width: 150px;
                min-width: 62px;
              }

              &-balance {
                max-width: 150px;
                min-width: 84px;
              }

              &-points {
                max-width: 220px;
                min-width: 60px;
              }

              &-rounds {
                max-width: 220px;
                min-width: 97px;
              }

              &-bgwon {
                max-width: 130px;
                min-width: 75px;
              }

              &-tblswon {
                max-width: 160px;
                min-width: 85px;
              }

              &-sgwon {
                max-width: 220px;
                min-width: 110px;
              }

              &-achvcnt {
                min-width: 160px;
                max-width: 210px;
              }

              &-maxrndingame {
                min-width: 156px;
                max-width: 200px;
              }

              &-block {
                position: absolute;
                right: 3px;
                flex: 0;
              }

              // &-friend {
              //   position: absolute;
              //   right: 27px;
              //   top: -2px;
              //   flex: 0;
              // }

              &-mobile-friend {
                position: absolute;
                right: 61px;
                top: -2px;
                flex: 0;
              }
            }

            &>div {
              flex-grow: 1;
              flex-basis: 0;
            }
          }


        }

      }
    }
  }
}

.root-class-design20-dark {
  .top-table-scrollarea-body-header {
    // background-color: rgba($color: #000, $alpha: 0.95);
    color: rgba(255, 255, 255, 0.5);

    &>div {
      font-size: var(--largeFontSize);
      font-family: 'Futura PT';
      font-weight: 450;
    }
  }

  .top-table-custom-scroll-body {
    &::-webkit-scrollbar {
      background-color: #000;
    }
  }

  .layout-dark {
    .layout-body-content {
      background: rgba(0, 0, 0, 0.95);
      border-radius: 17px 17px 0 0;
    }
  }

  .layout-body-main-table-header-wrapper {
    .layout-body-main-table-header-lg {
      color: #fff;
    }
  }


}

.root-class-design20-normal {
  .top-table-custom-scroll-body {
    &::-webkit-scrollbar {
      background-color: rgb(37, 54, 120);
    }
  }

  .layout-normal {
    .layout-body-content {
      background: $normalBodyTopBackgroundColour;
      border-radius: 17px 17px 0 0;
    }
  }
}

.root-class-design20-light {
  .top-table-custom-scroll-body {
    &::-webkit-scrollbar {
      background-color: rgb(236, 236, 236);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  .layout-light {
    .layout-body-content {
      background: $lightBodyTopBackgroundColour;
      border-radius: 17px 17px 0 0;
    }
  }

  .top-page-main-body-section {
    .top-page-main-body-wrapper {
      .layout-body-main-table-header {
        .layout-body-main-table-header-wrapper {
          background-color: rgba(0,0,0,0.1) !important;
          .top-table-row-text-self-place {
            color: #0C0C0C;
          }

          .top-table-row-text-self-player {
            .top-table-row-text {
              color: #0C0C0C;
            }
          }

          .top-table-row-text-self-rating {
            .menu-table-row-player-icon-text {
              color: #0C0C0C !important;
            }
          }

          .top-table-row-text-self-balance {
            .menu-table-row-player-icon-text {
              color: #0C0C0C !important;
            }
          }

          & > div {
            color: #0C0C0C !important;
          }

        }
      }
    }
  }
}

.top-table-row-text-lg:last-of-type {
  width: 14%;
}

.root-class-design20.root-class-design20-light {
  .layout-body-main-table-header-wrapper {
    background-color: #a2a2a2;
  }
}

.root-class-design20-dark {
  .top-table-row-text-self-place {
    color: rgba($color: #fff, $alpha: 0.5);
  }
}