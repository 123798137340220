/*
$ratingNameColor: #FFF;
$ratingBgColor: linear-gradient(180deg, #0C0C0C 0%, #222222 100%);

$ratingLightColor: #FFF;
$ratingDarkColor: #000;

$ratingTitleBackground: #FFF;
$ratingTitleColor: #000; */

//$progressInactiveColor: #868686;
//$progressActiveColor: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);

/*
$achievementButtonColor: $approveButtonColor;
$achievementActiveBackground: $approveButtonBackground;

$achievementinactiveBackground: $declineButtonBackground;

*/

// @import "../../../redesignVariables";

.my-info-gifts {

  .my-info-gift {
    background-image: url('../../../../../images/redesign/my-info/my-info-gift-bg.png');
  }
  .my-info-gift-remove {
    border-radius: 50%;
  }
}

.root-class-design20-light {
  .my-info-gifts {
    .my-info-gift {
      background-image: url('../../../../../images/redesign/light-mode/my-info/my-info-gift-bg.png');
      // background-repeat: no-repeat;
    }
  }
}

.root-class-design20-dark {
  .my-info-gifts {
    .my-info-gift-remove {
      border: 2px solid #828282;
    }
  }
}

$mobileLandScapeWidth: 1280px;

.root-class-design20 {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-myinfo-page {

      .layout-mobile-body-main {
        height: calc(100vh - 370px) !important;
        padding: 0 !important;
        margin: 11px 0 0 0 !important;

        &-title {
          display: none;
        }
        .my-info {

          &-scrollarea {
            display: flex;
            flex-direction: column;
            max-height: calc(100% - 160px) !important;
          }

          &-ratings {
            margin-left: 1px;
            margin-right: 1px;
        
            position: relative;
            margin-top: 0px !important;

            background: $ratingBgColor;
            border: 1px solid #000000;
            box-sizing: border-box;
            box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.9);
            border-radius: 15px;
            
            &-title {
              position: relative;
              top: -5%;
              margin-top: 0px;
              margin-bottom: 10px;
              width: auto;
              background: $ratingTitleBackground;
              color: $ratingTitleColor;
              font-size: var(--xLargeFontSize);
              border-radius: 50px;
              border: 0;
              margin-left: auto;
              margin-right: auto;
              z-index: 500;
              padding: 7px 20px;
              line-height: 140%;
        
              & .my-info-ratings-title-text {
                position: relative;
        
                &:before {
                  position: absolute;
                  left: -70px;
                  top: -17px;
                  content: ' ';
                  background-image: url('../../../../../images/redesign/components/modal/modal_stars.png');
                  background-size: 65px 65px;
                  height: 65px;
                  width: 65px;
                }
        
                &:after {
                  position: absolute;
                  right: -70px;
                  top: -17px;
                  content: ' ';
                  background-image: url('../../../../../images/redesign/components/modal/modal_stars.png');
                  background-size: 65px 65px;
                  height: 65px;
                  width: 65px;
                  -webkit-transform: scaleX(-1);
                  transform: scaleX(-1);
                }
              }
            }
        
            &-body {
              position: relative;
        
              height: 80%;
              top: 0%;
              overflow-y: hidden;

              &::-webkit-scrollbar {
                height: 0px;
              }
            }
        
            &-steps {
              font-family: Open Sans;
              font-style: normal;
              font-weight: bold;
              font-size: var(--defaultFontSize);
              /* identical to box height, or 18px */
        
              text-transform: uppercase;
        
              /* Black */
        
              color: #000000;
        
              /* Inside Auto Layout */
        
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 0px 10px;
            }
        
            &-step {
              // border: 1px solid rgba(255, 255, 255, 0.7);
              text-align: center;
              padding-left: 6px;
              padding-right: 6px;
              line-height: 160%;
              font-size: var(--mSmallFontSize);
              font-weight: 700;
              font-style: normal;
              text-transform: uppercase;
              color: #fff;
        
              &-wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }
        
              &-weak {
                background: #FFFFFF;
                opacity: 0.3;
                border-radius: 4px;
                color: $ratingDarkColor;
              }
        
              &-beginner {
                background: #FFF001;
                opacity: 0.3;
                border-radius: 4px;
                color: $ratingDarkColor;
              }
        
              &-untrained {
                background: #019245;
                opacity: 0.3;
                border-radius: 4px;
                color: $ratingLightColor;
              }
        
              &-medium {
                background: #662B8D;
                opacity: 0.3;
                border-radius: 4px;
                color: $ratingLightColor;
              }
        
              &-good {
                background: #293991;
                opacity: 0.3;
                border-radius: 4px;
                color: $ratingLightColor;
              }
        
              &-great {
                background: #784A28;
                opacity: 0.3;
                border-radius: 4px;
                color: $ratingLightColor;
              }
        
              &-fan {
                background: #BE1D2C;
                opacity: 0.3;
                border-radius: 4px;
                color: $ratingLightColor;
              }
        
              &-pro {
                background: #000000;
                opacity: 0.3;
                border-radius: 4px;
                color: $ratingLightColor;
                border: unset;
              }
        
              &-divider {
                height: 5px;
                width: 2px;
                background: #FFFFFF;
                opacity: 0.2;
              }
        
              &-active {
                opacity: 1;
              }
        
              &-name {
                text-align: center;
                color: $ratingNameColor;
                opacity: 0.3;
              }
            }
        
            &-progressbar {
              flex-direction: column;
              justify-content: center;
              color: $progressInactiveColor;
              text-align: center;
              white-space: nowrap;
              background-color: $progressInactiveColor;
              transition: width .6s ease;
              //  background-color: #ffd34e!important;
              height: 4px;
        
              &-marker {
                width: 40px;
                height: 40px;
                position: absolute;
                margin-top: -20px;
                margin-left: -37px;
              }
        
              & .progress-bar {
                background: $progressActiveColor;
                height: 4px;
              }
            }
        
            &-icon {
              position: absolute;
              right: 0px;
              width: 56px;
              height: 56px;
              cursor: pointer;
            }
        
            &-description {
              font-size: var(--defaultFontSize);
              font-weight: 400;
              padding: 0px 20px;
            }
          }
        
        
          &-statistics {
            margin-left: 1px;
            margin-right: 1px;
        
            position: unset !important;
            min-height: auto !important;
            height: auto !important;

            &-info {
              display: grid;
              grid-auto-flow: row;
              width: 100%;
              &-section {
                width: 80%;
                padding-left: 15px;
                padding-right: 15px;
              }
            }
        
            &-icon {
              right: 0px;
              position: absolute;
              width: 56px;
              height: 56px;
              cursor: pointer;
            }
        
            &-data {
              font-size: var(--defaultFontSize);
              line-height: 160%;
              text-align: center;
              color: #FFC900;
            }
        
            &-title {
              height: 30px;
              font-family: 'Futura PT';
              font-style: normal;
              font-weight: 450;
              font-size: var(--mLargeFontSize);
              line-height: 23px;
            }
        
            &-progressbar {
              flex-direction: column;
              justify-content: center;
              color: $progressInactiveColor;
              text-align: center;
              white-space: nowrap;
              background-color: $progressInactiveColor;
              transition: width .6s ease;
              //  background-color: #ffd34e!important;
              height: 4px;
              // margin-bottom: 20px;
        
              & .progress-bar {
                background: $progressActiveColor;
                height: 4px;
              }
            }
        
            &-percent {
              font-family: 'Open Sans';
              font-weight: 400;
              font-size: var(--defaultFontSize);
        
              label {
                font-weight: 600;
              }
            }
        
          }
        
          &-gifts {
            margin-left: 1px;
            margin-right: 1px;
        
            position: unset !important;
            // min-height: 110px;
            // height: 33%;
            display: flex;
            flex-direction: column;
        
            &-title {
              height: 30px;
              font-size: var(--mLargeFontSize);
              line-height: 23px;
              font-family: 'Futura PT';
              font-weight: 450;
              color: #FFFFFF;
        
              @media (max-height: 1000px) {
                display: inline-block;
                vertical-align: top;
                margin-right: 50px;
              }
            }

            
            & .my-info-gift-wrapper {
              width: 15vmin !important;
              height: 15vmin !important;

              & .my-info-gift {
                background-size: 15vmin 15vmin;
                height: 15vmin;
                width: 15vmin;
                display: flex;
                justify-content: center;
                align-items: center;

                & .my-info-gift-image {
                  height: 11vmin;
                  width: 10vmin;
                }
              }
            }
        
            &-body {
              @media (max-height: 1000px) {
                display: inline-block;
              }
        
              &>.row {
                width: 100%;
              }
            }
        
            .my-info-gift {
              //  background-image: url('../../../../../images/redesign/my-info/my-info-gift-bg.png');
              background-size: 8vmin 8vmin;
              height: 8vmin;
              width: 8vmin;
        
              &-wrapper {
                position: relative;
                width: 8vmin;
                height: 8vmin;
                margin-right: 5px;
              }
        
              &-image {
                height: 6vmin;
                width: 6vmin;
                padding-top: 1vmin;
                margin-left: 1vmin;
              }
        
              &-remove {
                position: absolute;
                top: -0.7vmin;
                right: -0.7vmin;
                width: 2.7vmin;
                height: 2.7vmin;
                min-width: 24px;
                min-height: 24px;
                cursor: pointer;
              }
        
              &-collider {
                width: 86%;
                height: 86%;
                position: absolute;
                top: 7%;
                left: 7%;
                border-radius: 50%;
              }
        
            }
          }
        }
        
        .root-class-design20-dark {
          .my-info-statistics-percent {
            color: $darkDefaultTextColor;
        
            label {
              color: $normalTextColour;
            }
          }
        }
        
        .root-class-design20-light {
          .my-info-statistics-data {
            color: #FF8A1A !important;
          }
        }
        
        .root-class-design20-light {
          .my-info-gifts-title {
            color: #0C0C0C;
          }
        }

        .my-info-statistics {
          min-height: unset;
          height: auto;
        }
        
        .weekly-status-scrollbar {
          max-height: calc(100% - 60px) !important;
          height: calc(100% - 60px) !important;
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-myinfo-page {

      .layout-mobile-body-main {

        & .my-info-scrollarea {
          display: flex;
          flex-direction: column;
          max-height: calc(100% - 210px) !important;
          height: calc(100% - 210px) !important;
        }

        .weekly-status-scrollbar {
          max-height: calc(100% - 120px) !important;
          height: calc(100% - 120px) !important;
        }

        &-title {
          display: block !important;
          color: #FFC900;
          font-size: var(--largeFontSize);
          font-weight: 600;
        }

        & .my-info-statistics-info {
          display: grid;
          grid-auto-flow: row;
          width: 100%;
          grid-template-columns: 1fr 1fr;
          &-section {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
  
        .my-info-statistics {
          min-height: unset;
          height: auto;
        }

        // .my-info-ratings {
        //   height: 160px;
        // }

        .my-info-ratings-body {
          position: relative;
          height: 80%;
          top: 0%;
          //overflow-y: hidden;
          overflow-x: clip;

          &::-webkit-scrollbar {
            height: 0px;
          }
        }

        & .my-info-gifts {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: unset !important;
          height: unset !important;


          & .my-info-gift-wrapper {
            width: 13vmin !important;
            height: 13vmin !important;
  
            & .my-info-gift {
              background-size: 13vmin 13vmin;
              height: 13vmin;
              width: 13vmin;
              display: flex;
              justify-content: center;
              align-items: center;
  
              & .my-info-gift-image {
                height: 11vmin;
                width: 10vmin;
              }
            }
          }
        }
      }
    }
  }
}

.root-class-design20-light {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-myinfo-page {

      .layout-mobile-body-main {
        .my-info-gifts-title {
          color: #000;
        }

        .my-info-results-scrollarea-body::-webkit-scrollbar-thumb {
          background-color: #0C0C0C !important;
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-myinfo-page {

      &-title {
        display: block !important;
        color: #FF8A1A;
        font-size: var(--largeFontSize);
        font-weight: 600;
      }

      .layout-mobile-body-main {
        .my-info-gifts-title {
          color: #000;
        }
      }

      .my-info-results-scrollarea-body::-webkit-scrollbar-thumb {
        background-color: #0C0C0C !important;
      }
    }
  }
}

.my-info-results-scrollarea-body::-webkit-scrollbar-thumb {
  display: none;
}