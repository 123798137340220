
//$achievementButtonColor: #FFF;
//$achievementActiveBackground: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
//$achievementinactiveBackground: rgba(0, 0, 0, 0.4);

//@import "../../../redesignVariables";

$achievementButtonColor: $approveButtonColor;
$achievementActiveBackground: $approveButtonBackground;

$achievementinactiveBackground: $declineButtonBackground;

.my-info {

  &-achievements {
    height: 100%;
    margin-left: 15px;
    margin-right: 15px;

    &-left {
      height: 100%;
      padding: 0;
    }

    &-count {
      background-color: #FFC900;
      border-radius: 10px;
      padding: 0px 5px 1px;
      color: #000000;
      margin-bottom: 3px;
      word-break: break-word;
    }
    &-uncount {
      background: rgba(255, 255, 255, 0.7);
      border-radius: 10px;
      padding: 0px 5px 1px;
      //color: #868686;
      color: #333333;
      margin-bottom: 3px;
      word-break: break-word;
    }
    
    &-right {
      height: 100%;
      padding: 0;
      .my-info-achievements-column-div {
        width: 80%;
        margin: auto;
        img {
          // object-fit: cover;
          position: static;
          margin-bottom: 10px;
        }
      }
    }

    &-row {
      row-gap: 20px;
    }

    &-scrollarea {
    //  height: 335px;
      height: calc(100% - 10px);
      min-height: calc(100% - 10px);
      overflow-y: auto;
      overflow-x: hidden;

      &-content {
        padding: 15px;
      }
    }

    &-link {
      margin-left: 15px;
      margin-bottom: -1px;
      position: relative;
      &-text {
        color: $headerTextColour;
        opacity: $headerInactiveOpacity;
        margin: 0px 6px;
        padding: 6px 0px;
        padding-left: 10px;
        cursor: pointer;
        text-decoration: none;
        width: 100%;
        text-align: left;
        font-size: var(--largeFontSize) !important;

        &:hover {
          color: $headerTextColour;
        }

        &:active {
          color: $headerTextColour;
        }

        &:focus {
          color: $headerTextColour;
        }
      }
      &-claim::after{
        content: '';
        width: 10px;
        height: 10px;
        background-color: rgba(255, 0, 0, 1);
        position: absolute;
        border-radius: 50%;
        margin-top: 3px;
        margin-left: 5px;
      }

      &-active {
        border-left: 1px solid $headerActiveBorderColour;
        opacity: 1;
      }

      &-claim {

      }
    }

    &-collect-button {
      background: $achievementinactiveBackground;
      opacity: 0.3;
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      border-radius: 99px;
      width: 170px;

      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      margin-bottom: 20px;
      margin-top: 5px;

      color: $achievementButtonColor;

      &:hover {
        color: $achievementButtonColor;
        background: $achievementinactiveBackground;
      }

      &:active {
        color: $achievementButtonColor;
        background: $achievementinactiveBackground;
      }

      &:focus {
        color: $achievementButtonColor;
        background: $achievementinactiveBackground;
      }

      &-active {
        background: $achievementActiveBackground;
        border-radius: 99px;
        opacity: 1;

        &:hover {
          background: $achievementActiveBackground;
        }

        &:active {
          background: $achievementActiveBackground;
        }

        &:focus {
          background: $achievementActiveBackground;
        }
      }

      &-single {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        padding-left: 5px;
        padding-right: 5px;
      }

      &-text {
        display: inline-block;

      }

      &-coin {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-bottom: 3px;
        //margin-left: 4px;
      }
    }

    &-column {
      text-align: center;
      font-size: var(--defaultFontSize);

      &-div {
        min-height: 85px;
        // height: 85px;
        position: relative;
      }

      &-img {
        width: 100%;
        height: 85px;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        object-fit: contain;
      }
    }
  }
}


.root-class-design20-light {
  .my-info {
    &-achievements {
      &-uncount {
        background: #fff;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
      }
    }
  }
}
