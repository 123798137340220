

// @import "../../redesignVariables";

.root-class-design20 {

  .scrollArea-buttonUp {
    background: #000;
  }

  .scrollArea-buttonDown {
    background: #000;
  }

  .default-scrollbar {
    max-height: calc(100% - 10px);
    width: 100%;
  //  min-height: 70%;


    &-wrapper {
      max-height: 90%;
      display: flex;
      flex-direction: column;
    }

    .scrollbar-container.vertical {
      margin-right: -5px;

      &:hover {
        opacity: 1 !important;
        .scrollbar {
          background-color: rgba(255, 255, 255, 0.4) !important;
          margin-left: 0px;
        }
      }
    }
  }
}
