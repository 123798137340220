

.root-class-design20 {


  & .bonus-page {

    &-container {
      margin-top: calc(12.5vh - 112px);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-wheel {
      position: relative;
      width: 37.5vmin;
      height: 37.5vmin;

      &-wrapper {
        margin-left: auto;
        margin-right: auto;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 42.8vmin;
        height: 42.8vmin;
        margin-top: -11vh;
        pointer-events: none;
      }

      &-bg {
        position: absolute;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;

      }

      &-arrow {
        position: absolute;
        width: 7.37vmin; // 11/7 width/height ratio
        height: 4.69vmin;
        top: 50%;
        left: 50%; 
        transform: translate(-50%, -50%);
        margin-top: 0.7vmin;
        margin-left: 12.6vmin;
      }
    }

    &-text {
      text-align: center;
    }

    &-money {
      text-align: center;

      &-image {
        display: inline-block;
        width: 20px;
        height: 20px;
      }

      &-text {
        display: inline-block;
      }
    }

    &-spin-button {
      position: relative;
      width: 100%;
      margin-top: 5px;

      & .btn {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }


    &-warn {
      text-align: center;
      &-text {
        text-align: center;
        display: inline-block;
        margin-right: 3px;
      }

      & .spin-timer {
        display: inline-block;
        color: $highlightedTextColor;
      }
    }


    &-complete {

      &-wheel {
        margin-left: auto;
        margin-right: auto;
        position: relative;

      //  height: 65%;
      //  width: auto;

        width: 40%;
      //  height: auto;
        height: 100%;
        max-height: 100%;
      }

      &-text {
        text-align: center;
      }

      &-money {
        text-align: center;

        &-image {
          display: inline-block;
          width: 20px;
          height: 20px;
        }

        &-text {
          display: inline-block;
        }
      }

      &-button {
        text-align: center;

        & .btn {
          margin-left: auto;
          margin-right: auto;
        //  position: relative;
          display: block;
        }
      }
    }

  }
}

.root-class-design20 {
  .bonus-page-container {
    .layout-highlighted-button {
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: var(--largeFontSize);
      line-height: 1.33;
      text-transform: uppercase;
      padding: 9px 15px;
      box-shadow: none !important;
      border: none !important;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        // background-image: url('../../../../images/redesign/common/card-icon.svg');
        margin-right: 13px;
      }
      &::after {
        margin-left: 13px;
        // background-image: url('../../../../images/redesign/common/card-icon.svg');
      }
    }

    .layout-redHighlighted-button {
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: var(--largeFontSize);
      line-height: 1.33;
      text-transform: uppercase;
      padding: 9px 15px;
      box-shadow: none !important;
      border: none !important;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        background-image: url('../../../../images/redesign/common/card-icon.svg');
        margin-right: 13px;
      }
      &::after {
        margin-left: 13px;
        background-image: url('../../../../images/redesign/common/card-icon.svg');
      }
    }
  }
}

.layout-subheader-bonus-page {
  border-bottom: none !important;
  .layout-default-button {
    margin-right: 21px;
    margin-top: 9px;
  }
}
