.admin-chat-scroll {

  &-area {
    height: 550px;
  }

  &-content {

  }
}

.admin-chat {

  &-env {
    display: inline-block;
    float: left;
    width: auto;
    margin-left: 15px;
    margin-right: 5px;
    height: 30px;
    top: -5px;
    position: relative;
  }

  &-message {

    &-user {
      background-color: #060f0f;
      color: #676262;
      float: left;
      min-width: 10%;
      max-width: 50%;
      border-radius: 20px;
      margin-right: 15px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
      text-transform: none;
      text-align: left;

      & p {
        word-break: break-all;
      }
      
      & a {
        color: #fed75f !important;
        cursor: pointer;
      }
    }

    &-admin {
      background-color: #027b00;
      color: #0f2c0e;
      float: right;
      min-width: 10%;
      max-width: 50%;
      border-radius: 20px;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
      text-transform: none;
      text-align: left;
      
      & p {
        word-break: break-all;
      }

      & a {
        color: #0030f5 !important;
        cursor: pointer;
      }
    }
  }

  &-date {

    &-user {
      background-color: #060f0f;
      color: #676262;
      float: left;
      min-width: 10%;
      max-width: 50%;
      border-radius: 20px;
      margin-right: 15px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
      text-transform: none;
    }

    &-admin {
      background-color: #027b00;
      color: #0f2c0e;
      float: right;
      min-width: 10%;
      max-width: 50%;
      border-radius: 20px;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
      text-transform: none;
    }
  }
}
