// @import "../../redesignVariables";

$messagePadding: 0.8vmin 1.4vmin;

.root-class-design20 {
  .support-chat {
    bottom: 42px;
    left: 30px;
    width: 30%;
    position: absolute;
    background-color: #000;

    &-open {
      height: 50%;
      border-radius: 3px;
    }

    &-closed {
      height: 5%;
      background-image: linear-gradient(180deg, transparent, #383838);
      border-radius: 3px;
    }

    &-body {
      line-height: 24px;
      height: 400px;
      margin: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-color: #123d3d transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #123d3d;
        border-radius: 0px;
        border-left: 1px solid transparent;
        border-right: 3px solid transparent;
      }

      &-wrapper {
        padding: 0;
      }
    }

    &-footer {
      height: 10%;
      margin: 0;
      border: 1px solid #fff;
      margin-top: 3px;

      &-input {
        background-color: transparent;
        padding-left: 10px;
        padding-right: 10px;
        color: #fff;
        border: 0;
        resize: none;

        &:focus {
          background-color: transparent;
          color: #fff;
          border: 0;
        }

        &::placeholder {
          color: #fff;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #fff;
        }

        &::-ms-input-placeholder {
          color: #fff;
        }
      }

      &-button {
        width: 100%;
        color: #007b00;
        background-color: transparent;
        border: 0;
        padding: 0;
        cursor: default;

        &:hover {
          color: #007b00 !important;
          background-color: transparent !important;
          border: 0 !important;
          box-shadow: none !important;
        }

        &:active {
          color: #007b00 !important;
          background-color: transparent !important;
          border: 0 !important;
          box-shadow: none !important;
        }

        &:focus {
          color: #007b00 !important;
          background-color: transparent !important;
          border: 0 !important;
          box-shadow: none !important;
        }
      }
    }
  }

  .contact-support {

    &-chat {
      bottom: 42px;
      left: 30px;
      width: 30%;
      position: absolute;
      background-color: #000;

      &.modal-body {
        padding: 45px;
      }

      &-description {
        font-size: var(--defaultFontSize);
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      &-body {
        margin-top: 20px;
        overflow: hidden;

        &-wrapper {
          height: calc(100vh - 423px);
          &-scrollarea {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
          }
        }
      }

      &-footer {
        height: 49px;
        min-height: 45px;
        margin: 0;
        border: 0;
        margin-top: 3px;

        &-input {
          background-color: transparent;
          color: rgba(0, 0, 0, 0.5);
          border: 0;
          resize: none;
          width: 100%;
          height: 100%;
          padding: 12px;
          //  line-height: 100%;
          float: left;

          &-wrapper {
            width: 100%;
            height: 100%;
            background-color: #FFF;
            padding-left: 1.5vmin;
            padding-right: 0;
            border-radius: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            & form {
              width: 100%;
              height: 100%;
            }

            & textarea {
              // line-height: 3;
            }

            & button {
              width: 25%;
            }

            &-inputInlineMessage-danger {
              width: auto;
              height: auto;
              position: absolute;
              bottom: -8px;
              left: 15px;
              font-size: var(--smallFontSize);
              background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
              border-radius: 99px;
              padding: 1px 6px 2px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              color: #fff;
              line-height: normal;

              p {
                margin: 0;
              }
            }

            &-inputInlineMessage-error {
              width: auto;
              height: auto;
              position: absolute;
              bottom: -8px;
              left: 15px;
              font-size: var(--smallFontSize);
              background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
              border-radius: 99px;
              padding: 1px 6px 2px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              color: #fff;
              line-height: normal;

              p {
                margin: 0;
                font-size: var(--smallFontSize);
              }
            }

            &-inputInlineMessage-success {
              width: auto;
              height: auto;
              position: absolute;
              bottom: -8px;
              left: 15px;
              font-size: var(--smallFontSize);
              background: #009C10;
              border-radius: 99px;
              padding: 1px 6px 2px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              color: #fff;
              line-height: normal;

              p {
                margin: 0;
                font-size: var(--smallFontSize);
              }
            }
          }

          &-attach-icon {
            width: 30px;
            height: 19.65px;
            cursor: pointer;
          }

          &:focus {
            background-color: #transparent;
            color: rgba(0, 0, 0, 1);
            border: 0;
          }

          &::placeholder {
            color: rgba(0, 0, 0, 0.5);
            opacity: 1;
          }

          &:-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          &::-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        &-send {
          // width: calc(20% - 10px);
          color: #FFF !important;
          float: right;
          // background-color: rgba(0, 0, 0, 0.4);
          border: 0;
          background: rgba(0, 0, 0, 0.4) !important;
          border: 1px solid rgba(255, 255, 255, 0.1);
          padding: 0;
          cursor: default;
          box-shadow: none;
          // height: calc(100% - 10px);
          margin: 5px;
          width: 112px !important;
          height: 38px !important;
          //  position: absolute;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;

          &:hover {
            color: #FFF !important;
            background-color: rgba(0, 0, 0, 0.4);
            border: 0 !important;
            box-shadow: none !important;
          }

          &:active {
            color: #FFF !important;
            background-color: rgba(0, 0, 0, 0.4);
            border: 0 !important;
            box-shadow: none !important;
          }

          &:focus {
            color: #FFF !important;
            background-color: rgba(0, 0, 0, 0.4);
            border: 0 !important;
            box-shadow: none !important;
          }
        }

        .contact-support-chat-footer-input-wrapper {
          .btn {
            width: auto;
            min-width: auto;
            padding: 9px 15px;
            display: flex;
            margin-right: 4px;
            margin-left: 15px;

            &::before {
              margin-right: 0;
              margin-left: 13px;
              margin-bottom: 0px;
            }

            &::after {
              margin-right: 13px;
              margin-left: 0;
              margin-bottom: 0px;
            }
          }
        }
      }

      &-message {
        text-transform: none;

        & a {
          color: #fed75f;
        }

        &-row {
          padding-left: 10px;
          margin-right: 5px;
        }

        &-user {
          background-color: #FFF;
          color: #000;
          min-width: 15%;
          max-width: 100%;
          width: fit-content;
          border: 0px;
          border-radius: 30px 30px 30px 0px;
          padding: $messagePadding;
          font-size: var(--defaultFontSize);
          text-align: left;
          float: unset;
          display: flex;
          justify-content: center;
          align-items: center;

          & a {
            margin-left: 10px;
          }

          &-time {
            background-color: transparent;
            color: #868686;
            min-width: 10%;
            max-width: 50%;
            border: 0px;
            font-size: var(--smallFontSize);
            float: unset;
          }

          &-attach {
            display: flex;
            flex-direction: row;

            &-filename {
              margin-right: 5px;
              margin-bottom: 0px !important;
            }

            &-download {
              margin-bottom: 0px !important;
              cursor: pointer;
              margin-left: 10px;
            }

            &-open {
              margin-bottom: 0px !important;
              cursor: pointer;
            }
          }
        }

        &-other {
          background-color: #FFF;
          color: #D2110A;
          min-width: 15%;
          max-width: 85%;
          width: fit-content;
          border: 0px;
          border-radius: 30px 30px 0px 30px;
          padding: $messagePadding;
          font-size: var(--defaultFontSize);
          text-align: right;
          float: right;
          display: flex;
          justify-content: center;
          align-items: center;

          & a {
            margin-left: 10px;
          }

          &-time {
            background-color: transparent;
            color: #868686;
            min-width: 10%;
            max-width: 50%;
            border: 0px;
            font-size: var(--smallFontSize);
            float: right;
            text-align: right;
          }
        }
      }

    }

    &-button {
      height: 32px;
      width: 75%;
      margin-top: 10px;
      background: linear-gradient(180deg, #B4EC51 0%, #429321 100%);
      border-radius: 3px;

      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: var(--defaultFontSize);
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.2px;

      color: #FFFFFF;
      margin-left: auto;
      margin-right: auto;
      padding: 0;

      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

      &-dot {
        padding-left: 5px;
        height: 7px;
        width: 7px;
        background-color: #ff0000;
        border-radius: 50%;
        display: inline-block;
      }
    }

    &-footer {
      &-button {
        margin-right: auto;
        margin-left: auto;
        width: 25%;

        background: linear-gradient(180deg, #FFD400 2.43%, #CA9318 100%);
        border-radius: 3px;

        font-size: var(--defaultFontSize);
        line-height: 18px;

        letter-spacing: 0.2px;

        color: #FFFFFF;
      }
    }
  }
}

.root-class-design20-light {
  .contact-support-chat-footer {
    .contact-support-chat-footer-input-wrapper {
      .btn {
        // background: rgba(0, 0, 0, 0.4);
        // border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: none;
        // color: #fff;
        // font-size: var(--largeFontSize);
        // font-weight: 700;
        // line-height: 1.3;
        color: #0C0C0C;
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid rgba(0, 0, 0, 0.1);
    
        &:hover {
          background: rgba(0, 0, 0, 0.4);
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;
          border-radius: 99px;
    
          color: $menuRowPrimaryColour;
        }
      }
    }
  }
}