
// DEFAULT COLORS

$primaryTextColour: #FFF;
$secondaryTextColour: #000;
$highlightedTextColor: #FFC900;

// COLOURS
$headerTextColour: $primaryTextColour;
$headerActiveBorderColour: $highlightedTextColor;
$headerInactiveOpacity: 0.5;

$bodyTopBackgroundColour: rgba(0, 0, 0, 0.95);
$bodyTopBackgroundOpacity: 0.95;

$roomsTablePrimaryColour: $primaryTextColour;
$roomsTableSecondaryColour: $highlightedTextColor;


// $primaryTextColour: #FFF;


// FONTS
// $headerFontSize: 16px;
// $footerFontSize: 14px;

// $smallFontSize: 10px;
// $defaultFontSize: 12px;
// $largeFontSize: 14px;

// $bodyDefaultFontSize: 12px;

$disabledLinkOpacity: 0.5;

// ------ TABS ------ //

// MY INFO
$progressInactiveColor: rgba(#868686, 0.2);
$progressActiveColor: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);


// ------ MODALS ------ //

$modalTitleBackground: #FFF;
$modalTitleColor: #000;

$questionTitleBackground: #D2110A;
$questionTitleColor: #FFF;

$modalBodyBackground: #000;
$modalBodyColor: #FFF;

// ABOUT US




// ------ COMPONENTS ------ //

// MENU ROW
$menuRowInnergroundOpacity: 0.9;
$menuRowBackgroundOpacity: 0.95;
$menuRowPrimaryColour: #FFF;
$menuRowHeight: 3.6vmin;

// DEFAULT BUTTON
$buttonPrimaryColour: #FFF;
$buttonSecondaryColour: #000;
$buttonBackgroundColour: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);


// MODAL FOOTER BUTTON
$footerButtonBackground: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
$footerButtonColor: #000;

// APPROVE BUTTON
$approveButtonBackground: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
$approveButtonColor: #FFF;

// DECLINE BUTTON
$declineButtonBackground: rgba(0, 0, 0, 0.4);
$declineButtonColor: #FFF;


// SWITCH SELECT
$switchSelectTextColor: #FFFFFF;
$switchSelectBackground: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(64, 64, 64, 0.2) 100%);
$switchSelectBoxShadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);

$switchSelectActiveTextColor: #000;
$switchSelectActiveBackground: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
$switchSelectActiveBoxShadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);

// RATINGS
$ratingNameColor: #FFF;
$ratingBgColor: linear-gradient(180deg, #0C0C0C 0%, #222222 100%);

$ratingLightColor: #FFF;
$ratingDarkColor: #000;

$ratingTitleBackground: #FFF;
$ratingTitleColor: #000;

// CUSTOM DROPDOWN
$customDropdownBorderColor: rgba(255, 255, 255, 0.2);
$customDropdownTextColor: #FFF;
$customDropdownBackgroundColor: transparent;
$customDropdownMenuBackgroundColor: #000;


// Light mode design

$lightBackgroundImage: url('../../images/redesign/light-mode/layout/carpet.webp');
$lightBackgroundColor: #ECECEC;
$lightBackgroundSecondColor: #ffffff;
$lightBodyTopBackgroundColour: rgba(#ECECEC, 0.95);
$lightBodyTopBackgroundOpacity: 0.95;

$lightMenuRowInnergroundOpacity: 0.95;

$lightTextColour: #0C0C0C;
$lightSecondaryTextColour: #fff;
$lightHighlightedTextColor: #FF8A1A;

// COLOURS
$lightHeaderTextColour: $lightTextColour;
$lightHeaderActiveBorderColour: $lightHighlightedTextColor;
$lightHeaderInactiveOpacity: 0.5;

$lightRoomsTablePrimaryColour: $lightTextColour;

$lightLayoutDefaultButtonBackgroundColor: rgba(255, 255, 255, 0.4);

// Light mode design

$normalBackgroundImage: url('../../images/redesign/light-mode/layout/carpet.webp');
$normalBackgroundColor: #253678;
$normalBodyTopBackgroundColour: rgba(#253678, 0.95);
$normalBodyTopBackgroundOpacity: 0.95;

$normalMenuRowInnergroundOpacity: 0.95;

$normalTextColour: #fff;
$normalSecondaryTextColour: #0C0C0C;
$normalHighlightedTextColor: #FFC900;

// Dark mode design
$darkBodyTopBackgroundColour: rgba(#000, 0.95);
$darkBodyRowBackgroundColor: rgba(#000, 0.9);
$darkTableRowBackgroundColor: rgba(#fff, 0.03);
$darkDefaultTextColor: rgba(#fff, 0.5);

// COLOURS
$normalHeaderTextColour: $lightTextColour;
$normalHeaderActiveBorderColour: $lightHighlightedTextColor;
$normalHeaderInactiveOpacity: 0.5;

$normalRoomsTablePrimaryColour: $lightTextColour;

$normalLayoutDefaultButtonBackgroundColor: rgba(255, 255, 255, 0.4);

$headerFontSize: 16px;
$smallFontSize: 10px;
$defaultFontSize: 12px;
$largeFontSize: 14px;
$footerFontSize: 14px;
$bodyDefaultFontSize: 12px;

.root-font-normal {
  --headerFontSize: 16px;
  --footerFontSize: 14px;

  --xxSmallFontSize: 8px;
  --xSmallFontSize: 8px;
  --smallFontSize: 10px;
  --mSmallFontSize: 11px;
  --defaultFontSize: 12px;
  --largeFontSize: 14px;
  --xLargeFontSize: 20px;
  --mLargeFontSize: 18px;
  --xxLargeFontSize: 24px;
  --xxxLargeFontSize: 32px;
  --xxxxLargeFontSize: 62px;
  --bodyDefaultFontSize: 12px;
  --specialShapeSize: 26px;
}

.root-font-large {
  --headerFontSize: 18px;
    --footerFontSize: 16px;

    --xxSmallFontSize: 9px;
    --xSmallFontSize: 10px;
    --smallFontSize: 12px;
    --mSmallFontSize: 13px;
    --defaultFontSize: 14px;
    --largeFontSize: 16px;
    --xLargeFontSize: 22px;
    --mLargeFontSize: 20px;
    --xxLargeFontSize: 26px;
    --xxxLargeFontSize: 34px;
    --xxxxLargeFontSize: 64px;
    --bodyDefaultFontSize: 14px;
    --specialShapeSize: 28px;
}

.root-font-xLarge {
  --headerFontSize: 20px;
  --footerFontSize: 18px;

  --xxSmallFontSize: 10px;
  --xSmallFontSize: 12px;
  --smallFontSize: 14px;
  --mSmallFontSize: 15px;
  --defaultFontSize: 16px;
  --largeFontSize: 18px;
  --xLargeFontSize: 24px;
  --mLargeFontSize: 22px;
  --xxLargeFontSize: 28px;
  --xxxLargeFontSize: 36px;
  --xxxxLargeFontSize: 66px;
  --bodyDefaultFontSize: 16px;
  --specialShapeSize: 30px;
}

.popover-font-normal {
  --headerFontSize: 16px;
  --footerFontSize: 14px;

  --xSmallFontSize: 8px;
  --smallFontSize: 10px;
  --mSmallFontSize: 11px;
  --defaultFontSize: 12px;
  --largeFontSize: 14px;
  --xLargeFontSize: 20px;
  --mLargeFontSize: 18px;
  --xxLargeFontSize: 24px;
  --xxxLargeFontSize: 32px;
  --xxxxLargeFontSize: 62px;

  --bodyDefaultFontSize: 12px;

  --specialShapeSize: 26px;
}

.popover-font-large {
  --headerFontSize: 18px;
  --footerFontSize: 16px;

  --xSmallFontSize: 10px;
  --smallFontSize: 12px;
  --mSmallFontSize: 13px;
  --defaultFontSize: 14px;
  --largeFontSize: 16px;
  --xLargeFontSize: 22px;
  --mLargeFontSize: 20px;
  --xxLargeFontSize: 26px;
  --xxxLargeFontSize: 34px;
  --xxxxLargeFontSize: 64px;

  --bodyDefaultFontSize: 14px;

  --specialShapeSize: 28px;
}

.popover-font-xLarge {
  --headerFontSize: 20px;
  --footerFontSize: 18px;

  --xSmallFontSize: 12px;
  --smallFontSize: 14px;
  --mSmallFontSize: 15px;
  --defaultFontSize: 16px;
  --largeFontSize: 18px;
  --xLargeFontSize: 24px;
  --mLargeFontSize: 22px;
  --xxLargeFontSize: 28px;
  --xxxLargeFontSize: 36px;
  --xxxxLargeFontSize: 66px;

  --bodyDefaultFontSize: 16px;

  --specialShapeSize: 30px;
}
