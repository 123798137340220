

.root-class-design20 {

  .turn-timer-wrapper {
    height: fit-content!important;
    width: 10.2vmin!important;
    min-width: 120px!important;
    min-height: 120px!important;
    position: relative;
    pointer-events: none;

    & .player-info {
      position: absolute;
      width: 100%;
      height: 29vh;

      &-player {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-right: 0;

        &-background {
          width: 100%;
          height: 100%;
          position: relative;
        }

        &-image {
          background-size: cover;
          background-repeat: no-repeat;

          box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
          filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));

          position: absolute;
          top:      0;
          left:     0;
          bottom:   0;
          right:    0;

          margin: 10px;
          border-radius: 50%;

          @media screen and (max-height: 750px) {
            margin: 8px;
          }

          @media screen and (max-height: 650px) {
            margin: 6px;
          }

          @media screen and (max-height: 550px) {
            margin: 4px;
          }

          &-background {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url('../../../../../../images/redesign/player/UserFrame.png');
            background-size: cover;
            background-repeat: no-repeat;
            top: 0;
          }

          &-overlay {
            position: absolute;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            margin: 10px;
            left: -5px;
            top: -2px;
            background-image: url('../../../../../../images/redesign/player/UserOverlay.png');
            background-size: cover;
            background-repeat: no-repeat;


            @media screen and (max-height: 750px) {
              margin: 8px;
              left: -4px;
            }

            @media screen and (max-height: 650px) {
              margin: 6px;
              left: -3px;
            }

            @media screen and (max-height: 550px) {
              margin: 4px;
              left: -2px;
            }
          }

          &-wrapper {
            position: relative;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;

            &:before {
              content: "";
              display: block;
              padding-top: 100%; /* initial ratio of 1:1*/
            }
          }


        }

        &-name {
          display: inline-block;
          justify-content: center;
          align-items: center;
          padding: 7px 20px;

          /* Black */
          color: $buttonPrimaryColour;
          background: $buttonSecondaryColour;
          border-radius: 88px;

          @media screen and (max-height: 650px) {
            padding: 5px 16px;
          }

          @media screen and (max-height: 550px) {
            padding: 2px 10px;
          }

          &-wrapper {
            position: relative;
            text-align: center;
            font-size: var(--headerFontSize);
            line-height: $headerFontSize;
          }
        }

        &-info-wrapper {
          position: relative;
          text-align: center;
          font-size: var(--headerFontSize);
          margin-left: auto;
          margin-right: auto;
        }

        &-wrapper {
          position: relative;
          text-align: center;
          font-size: var(--headerFontSize);
          margin-left: auto;
          margin-right: auto;

          @media screen and (max-height: 650px) {
            font-size: var(--smallFontSize);
          }
        }

        &-balance {
          display: inline-block;
          position: relative;
          text-align: center;
          font-size: var(--defaultFontSize);

          color: $buttonPrimaryColour;
          padding-right: 3px;

          @media screen and (max-height: 650px) {
            font-size: var(--smallFontSize);
          }

          &-icon {
            display: inline-block;
            text-align: center;
            width: auto;
            height: $headerFontSize;
            padding-left: 3px;
            padding-right: 3px;

            @media screen and (max-height: 650px) {
              height: $smallFontSize;
            }
          }
        }

        &-rating {
          display: inline-block;
          position: relative;
          text-align: center;
          font-size: var(--defaultFontSize);

          color: $buttonPrimaryColour;

          @media screen and (max-height: 650px) {
            font-size: var(--smallFontSize);
          }

          &-icon {
            display: inline-block;
            text-align: center;
            width: auto;
            height: $headerFontSize;
            padding-left: 3px;
            padding-right: 3px;

            @media screen and (max-height: 650px) {
              height: $smallFontSize;
            }
          }
        }
      }
    }
  }
}
