$nameColor: #0C0C0C;
$bgColor: #FFF;
$commentColor: #868686;
$fontSize: 1.2vmin;

.root-class-design20 {
  .new-design-login {
    width: 100% !important;
    height: 100% !important;
    padding: 0px !important;

    & .row {
      margin-right: 0px !important;
      margin-left: 0px !important;
      padding: 0px !important
    }

    &-container {
      width: 100%;
      height: 100%;
      max-width: 100vw !important;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0px;
      position: relative;
      justify-self: center;
      align-items: center;
      backdrop-filter: blur(8px);

      &-body {
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

        &-header {
          width: 100%;
          height: 100px;

          &-links {
            margin-right: 10px;

            &-section {
              margin-right: 10px;

              & img {
                cursor: pointer;
              }

              & .divider {
                display: inline-block;
                content: ' ';
                background-image: url('../../../../images/redesign/common/karavs.svg');
                background-size: $sectionDividerSize $sectionDividerSize;
                background-repeat: no-repeat;
                background-position: center;
                width: $sectionDividerSize;
                margin-right: 3px;
                margin-left: 3px;
                height: $sectionDividerSize;
                vertical-align: top;
                margin-right: 20px;
              }

              & .language {
                z-index: 999;
                width: 20px;
                position: relative;
                display: inline-block;
                right: 15px;
                left: unset;
                top: 0px;
                cursor: pointer;

                &-image {
                  float: right;
                }

                &-label {
                  color: #fff;
                }

                &-label-active {
                  color: #FFC900;
                }

                & .dropdown {

                  & .dropdown-menu {
                    background-color: #000;
                    border: 0px;
                    border-radius: 17px 0px 17px 17px;
                    top: 0px !important;
                    left: -50px !important;
                    padding: 20px;
                    min-width: 11rem;

                    & .dropdown-item {
                      & div label {
                        font-size: var(--largeFontSize);
                        margin-right: 15px;
                      }
                    }

                    & .dropdown-item:hover {
                      background: unset !important;

                      & div label {
                        color: #FFC900;
                      }
                    }
                  }
                }
              }
            }

            & .login-fullscreen {
              display: inline;
            }

            & .login-return {
              display: inline;
            }

            // & .login-fullscreen {
            //   display: inline;
            // }
            @media only screen and (max-width: 580px) {
              & .login-fullscreen {
                display: none !important;
              }

              & .login-return {
                display: none !important;
              }
            }

          }
        }

        &-content {
          width: 100%;
          height: calc(100% - 100px);

          &-section {
            background: #000000;
            opacity: 0.95;
            box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
            border-radius: 17px;
            width: 513px;
            height: 576px;
            &-light {
              background: hsla(0,0%,100%,.95);
            }
            &-normal {
              background: rgba(20,34,85,.95);
            }
            &-dark {
              background: #000;
            }

            &-title {
              margin-top: -60px;
              position: relative;

              & .login-close {
                position: absolute;
                display: none;
                top: 15px;
                right: 0px;
                width: 34px;
                height: 34px;

                & img {
                  width: 100%;
                  height: 100%;
                }
              }

              @media only screen and (max-width: 580px) {
                & .login-close {
                  display: inline;
                }
              }

              & .login-logo {
                position: relative;

                & img {
                  width: 61px;
                  height: 94px;
                  position: relative;
                }
              }

              & .login-logo::before {

                position: absolute;
                left: -70px;
                top: 20px;
                content: ' ';
                background-image: url('../../../../images/redesign/components/modal/modal_stars.png');
                background-size: 80px 80px;
                height: 80px;
                width: 80px;
              }

              & .login-logo::after {
                position: absolute;
                right: -70px;
                top: 20px;
                content: ' ';
                background-image: url('../../../../images/redesign/components/modal/modal_stars.png');
                background-size: 80px 80px;
                height: 80px;
                width: 80px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
              }
            }

            &-form {
              width: 100%;
              height: calc(100% - 60px);
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              background: #000;
              border-radius: 17px;
              &-light {
                background: hsla(0,0%,100%,.95);
              }
              &-normal {
                background: rgba(20,34,85,.95);
              }
              &-dark {
                background: #000;
              }
              &-with {
                width: 75%;
                height: 20%;

                & .title {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--headerFontSize);
                  line-height: 160%;
                  color: #FFFFFF;
                  width: 100%;
                  height: 10%;
                }

                & .social {
                  width: 100%;
                  height: 90%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  flex-direction: row;

                  &-button {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 33.3%;
                    height: 30px;
                    background: rgba(0, 0, 0, 0.4);
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    border-radius: 99px;
                    margin-right: 10px;
                    cursor: pointer;

                    & img {
                      margin-right: 10px;
                    }

                    & label {
                      font-family: 'Open Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: var(--defaultFontSize);
                      line-height: 18px;
                      cursor: pointer;
                      text-align: center;
                      margin-bottom: 0px;
                      color: #FFFFFF;
                    }
                  }
                }
              }

              &-input {
                width: 75%;
                height: 56%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                &-light {
                  color: $lightTextColour;
                }
                &-normal {
                  color: $normalTextColour;
                }
                &-dark {
                  color: #ffffff;
                }
                & input:autofill {
                  background-color: #fff !important;
                }

                & input:-webkit-autofill {
                  background-color: #fff !important;
                }

                & .input-col {
                  padding: 0px;

                  &-message {
                    border-radius: 25px;
                  }
                }

                & .login-section {
                  padding: 0px;
                  width: 50%;
                }

                & .login-section-button {
                  width: 200px;
                  height: 47px;
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 700;
                  font-size: var(--headerFontSize);
                  line-height: 23px;
                  text-align: center;
                  text-transform: uppercase;

                  /* Black */
                  color: #000000;
                }

                @media only screen and (max-width: 580px) {
                  & .login-section-button {
                    width: 140px;
                  }
                }

                & .forgot-password-button {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--largeFontSize);
                  line-height: 160%;
                  text-align: right;
                  text-decoration-line: underline;
                  color: #FFC900;
                  cursor: pointer;
                  width: 50%;
                }
              }

              &-footer {
                width: 100%;
                height: 40%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                border-bottom-left-radius: 17px;
                border-bottom-right-radius: 17px;
                background: #1b1b1b;
                box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);

                &-light {
                  background: hsla(0,0%,100%,.95);
                }
                &-normal {
                  background: #1b1b1b;
                }
                &-dark {
                  background: #1b1b1b;
                }

                & .section {
                  width: 75%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;

                  & label {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                    color: #FFFFFF;
                  }

                  & .footer-section-button {
                    width: 220px;
                    height: 47px;
                    background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
                    border: 1px solid #0C0C0C;
                    border-radius: 99px;
                    position: relative;
                  }

                  & .footer-section-button::after {
                    position: absolute;
                    right: 23px;
                    top: 18px;
                    content: ' ';
                    background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
                    background-size: 8px 8px;
                    height: 8px;
                    width: 8px;
                  }

                  & .footer-section-button::before {
                    position: absolute;
                    left: 23px;
                    top: 18px;
                    content: ' ';
                    background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
                    background-size: 8px 8px;
                    height: 8px;
                    width: 8px;
                  }

                  & .reset-password-button {
                    width: 200px;
                    height: 47px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: var(--headerFontSize);
                    line-height: 23px;
                    text-align: center;
                    text-transform: uppercase;

                    /* Black */
                    color: #000000;
                  }
                }
              }

              // @media only screen and (max-width: 580px) {
              //   &-footer {
              //     // height: 25%;

              //   }
              // }
            }
          }
        }
      }

      &-sign {
        width: 100%;
        min-height: 1012px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &-bg {
          background-image: url('../../../../images/redesign/landing-view/new_bg_1.webp');
          width: 100%;
          min-height: 1012px;
          background-repeat: no-repeat;
          background-position: initial;
          background-size: cover;
          position: absolute;
          top: 0px;
          left: 0px;
        }

        &-dg {
          width: 100%;
          min-height: 261px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          bottom: 0px;
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          transform: matrix(1, 0, 0, -1, 0, 0);
        }

        &-body {
          position: absolute;
          top: 0px;
          width: 75%;
          min-height: 1012px;
          display: flex;
          justify-content: center;
          flex-direction: column;

          & .sign-row {
            width: 100%;
            margin-top: 220px;
            height: 60%;

            &-content {
              max-width: 500px;
              min-height: 400px;

              &-title {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: var(--xxxxLargeFontSize);
                line-height: 140%;
                color: #FFC900;
              }

              &-description {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: var(--xxLargeFontSize);
                line-height: 140%;
                color: #FFFFFF;
              }

              &-button {
                height: 47px;
                min-width: 172px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: var(--headerFontSize);
                line-height: 23px;
                text-transform: uppercase;
                color: #000000;
              }

              &-register-button {
                flex-direction: column;
                padding: 12px 18px;
                gap: 10px;

                width: 200px;
                height: 47px;

                background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
                /* Light Black */

                border: 1px solid #0C0C0C;
                border-radius: 99px;
                position: relative;

                & label {
                  width: 118px;
                  height: 23px;

                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 700;
                  font-size: var(--headerFontSize);
                  line-height: 23px;
                  text-align: center;
                  text-transform: uppercase;
                  color: #FFFFFF;
                  margin-bottom: 0px;
                }
              }

              &-register-button::after {
                position: absolute;
                right: 15px;
                content: ' ';
                background-image: url('../../../../images/icons/tree-svg.svg');
                background-size: 8px 8px;
                height: 8px;
                width: 8px;
              }

              &-register-button::before {
                position: absolute;
                left: 15px;
                content: ' ';
                background-image: url('../../../../images/icons/tree-svg.svg');
                background-size: 8px 8px;
                height: 8px;
                width: 8px;
              }
            }

            &-slide {
              min-height: 450px;
              max-width: 470px;
              margin-top: -50px;
              position: relative;

              & .splide__arrow--prev {
                left: -1em;
              }

              & .splide__arrow--next {
                right: -1em;
              }

              & .splide__pagination {
                background: rgba(0, 0, 0, 0.5);
                width: auto;
                padding: 5px;
                border-radius: 30px;

                & .is-active {
                  background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg') !important;
                }
              }

              & .splide__pagination__page {
                background-image: url('../../../../images/redesign/landing-view/slidePagination.svg') !important;
                width: 11px;
                height: 13px;
                background: transparent;
              }

              &-content {
                position: absolute;
                top: 50px;
                left: 50px;

                &-title {
                  max-width: 250px;
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 600;
                  font-size: var(--xxxxLargeFontSize);
                  line-height: 140%;
                  color: #000000;
                }

                &-description {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--headerFontSize);
                  line-height: 160%;
                  color: #0C0C0C;
                  max-width: 225px;
                }

                &-footer {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--smallFontSize);
                  line-height: 160%;
                  color: #0C0C0C;
                }
              }
            }

            &-banner {
              // position: absolute;
              // bottom: -100px;
              z-index: 9998;
              width: 100%;
              height: 40%;
              margin-top: 100px;

              &-slide {
                min-height: 370px;
                margin-top: -50px;
                position: relative;

                & .splide__arrow--prev {
                  left: -2em;
                }

                & .splide__arrow--next {
                  right: -2em;
                }

                & .splide__pagination {
                  background: rgba(0, 0, 0, 0.5);
                  width: auto;
                  padding: 5px;
                  border-radius: 30px;

                  & .is-active {
                    background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg') !important;
                  }
                }

                & .splide__pagination__page {
                  background-image: url('../../../../images/redesign/landing-view/slidePagination.svg') !important;
                  width: 11px;
                  height: 13px;
                  background: transparent;
                }

                &-content {
                  position: absolute;
                  top: 50px;
                  left: 50px;

                  &-title {
                    max-width: 227px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: var(--xxxLargeFontSize);
                    line-height: 140%;
                    color: #000000;
                  }

                  &-description {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                    color: #0C0C0C;
                    max-width: 175px;
                  }

                  &-footer {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--smallFontSize);
                    line-height: 160%;
                    color: #0C0C0C;
                    border-bottom-left-radius: 17px;
                    border-bottom-right-radius: 17px;
                    background: #868686;
                    opacity: 0.2;
                    box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);

                    & .section {
                      width: 75%;
                      height: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &-top {
        width: 100%;
        min-height: 980px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &-bg {
          background-image: url('../../../../images/redesign/landing-view/game_screen_bg.webp');
          width: 100%;
          min-height: 980px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          top: 0px;
          left: 0px;
        }

        &-dg {
          width: 100%;
          min-height: 373px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          top: 0px;
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        }

        &-bg-table {
          width: 100%;
          min-height: 850px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          top: 0px;
          background-image: url('../../../../images/redesign/landing-view/table_2.webp');
        }

        &-list {
          margin-top: 400px;
          z-index: 9999;
          position: relative;
          width: 75%;

          & .menu-table-row-filler-out {
            height: 20px;
            border-radius: 17px;
          }

          & .layout-body-background {
            border-radius: 17px;
          }

          & .top-table-row {
            height: 100%;
          }

          & .layout-body {
            box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);

            & .menu-table-row-wrapper {
              background-color: #0A0A0A;
            }

            &-top {
              & .layout-subheader {
                margin-left: 15px !important;
                margin-right: 15px !important;
                padding-top: 20px !important;
              }
            }

            &-main {
              & .menu-table-row-wrapper {
                padding-left: 15px !important;
                padding-right: 15px !important;
                padding-bottom: 0.5vmin !important;
              }
            }
          }

          & .layout-body::before {
            position: absolute;
            left: -200px;
            bottom: 0px;
            content: ' ';
            background-image: url('../../../../images/redesign/landing-view/landing_modal_stars.svg');
            background-size: 300px 300px;
            height: 300px;
            width: 300px;
          }

          & .layout-body::after {
            position: absolute;
            right: -200px;
            bottom: 0px;
            content: ' ';
            background-image: url('../../../../images/redesign/landing-view/landing_modal_stars.svg');
            background-size: 300px 300px;
            height: 300px;
            width: 300px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }

        &-footer {
          min-height: 250px;
          width: 100%;
          background-color: #0A0A0A;
          position: absolute;
          bottom: 0px;
        }

        &-footer::before {
          content: "";
          position: absolute;
          min-height: 261px;
          width: 100%;
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          top: -261px;
          transform: matrix(1, 0, 0, -1, 0, 0)
        }
      }

      &-blog {
        background-color: #0A0A0A;
        position: relative;
        width: 100%;

        &-list {
          width: 75%;
          margin-top: 100px;
          position: relative;

          &-title {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: var(--xxLargeFontSize);
            line-height: 140%;
            color: #FFFFFF;
            margin-bottom: 100px !important;
          }

          &-body {
            & .splide {
              min-height: 370px;
              margin-top: -50px;
              position: relative;

              & .splide__list {
                min-height: 600px;

                & .splide__slide {
                  position: relative;

                  & .splide-slide-content {
                    position: absolute;
                    max-width: 340px;
                    top: 280px;

                    &-title {
                      font-family: 'Open Sans';
                      font-style: normal;
                      font-weight: 600;
                      font-size: var(--xxLargeFontSize);
                      line-height: 140%;
                      color: #FFFFFF;
                    }

                    &-description {
                      font-family: 'Open Sans';
                      font-style: normal;
                      font-weight: 400;
                      font-size: var(--headerFontSize);
                      line-height: 160%;
                      color: #FFFFFF;
                    }

                    &-footer {
                      font-family: 'Open Sans';
                      font-style: normal;
                      font-weight: 400;
                      font-size: var(--smallFontSize);
                      line-height: 160%;
                      color: #0C0C0C;

                      & button {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 6px 15px;
                        gap: 10px;

                        width: 160px;
                        height: 30px;

                        background: rgba(0, 0, 0, 0.4);
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        border-radius: 99px;
                        position: relative;

                      }

                      & button::after {
                        position: absolute;
                        right: 10px;
                        content: ' ';
                        background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
                        background-size: 8px 8px;
                        height: 8px;
                        width: 8px;
                      }

                      & button::before {
                        position: absolute;
                        left: 10px;
                        content: ' ';
                        background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
                        background-size: 8px 8px;
                        height: 8px;
                        width: 8px;
                      }
                    }
                  }
                }
              }

              & .splide__arrow--prev {
                left: -2em;
              }

              & .splide__arrow--next {
                right: -2em;
              }

              & .splide__pagination {
                background: rgba(0, 0, 0, 0.5);
                width: auto;
                padding: 5px;
                border-radius: 30px;

                & .is-active {
                  background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg') !important;
                }
              }

              & .splide__pagination__page {
                background-image: url('../../../../images/redesign/landing-view/slidePagination.svg') !important;
                width: 11px;
                height: 13px;
                background: transparent;
              }

            }
          }


        }
      }

      &-mobile {
        // background-color: #0C0C0C;
        position: relative;
        width: 100%;
        background-image: url('../../../../images/redesign/landing-view/game_screen_bg.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &-bg {
          min-height: 600px;
          width: 90%;
          background-image: url('../../../../images/redesign/landing-view/table_1.webp');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          margin-top: 100px;
        }

        &-bd {
          background: linear-gradient(180deg, #0A0A0A 0%, rgba(0, 0, 0, 0) 100%);
          min-height: 155px;
          position: absolute;
          top: 0px;
          width: 100%;
        }

        &-list {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          height: 100%;
          backdrop-filter: blur(8px);
          flex-direction: column;

          &-body {
            width: 75%;
            height: 100%;

            &-row {
              width: 100%;
              height: 100%;

              &-content {
                max-width: 363px;

                & .title {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 600;
                  font-size: var(--xxxLargeFontSize);
                  line-height: 140%;
                  color: #FFC900;
                }

                & .description {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 600;
                  font-size: var(--xxLargeFontSize);
                  line-height: 140%;
                  color: #FFFFFF;
                }

                & .footer {
                  display: flex;
                  flex-direction: column;

                  & .header {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: var(--headerFontSize);
                    line-height: 22px;
                    color: #FFC900;
                  }

                  & .bottom {
                    display: flex;
                    flex-direction: row;

                    & img {
                      width: 120px;
                      height: 35px;
                    }
                  }
                }
              }

              // &-adv {
              //   width: 100%;
              //   background-image: url('../../../../images/redesign/landing-view/mobile_bg_1.webp');
              //   background-repeat: no-repeat;
              //   background-position: center;
              //   background-size: cover;
              //   min-height: 655px;
              // }
            }
          }
        }

        &-adv {
          width: 100%;
          height: 100%;

          & img {
            object-fit: cover;
            width: 80%;
          }
        }
      }

      &-footer {
        width: 100%;
        min-height: 400px;
        flex-direction: column;
        background-color: #000;
        height: 500px;
        z-index: 15;

        &-first {
          width: 100%;
          height: 40%;
          background: #000;
          z-index: 11;
          position: relative;
          flex-direction: column;

          & .zole-logo {
            width: 100%;
            height: 60%;
          }

          & .support {
            width: 100%;
            height: 40%;
            flex-direction: column;

            & .support-content {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: var(--smallFontSize);
              line-height: 160%;
              text-align: center;
              color: #FFFFFF;
              width: 100%;
              height: 10%;
            }

            & .support-by {
              flex-direction: row;

              & img {
                width: 120px;
                height: 34px;
              }
            }
          }
        }

        &-footer::before {
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          opacity: 0.7;
          -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
          transform: matrix(1, 0, 0, -1, 0, 0);
          min-height: 102px;
          width: 100%;
          content: ' ';
          top: -102px;
          position: absolute;
        }

        & .help-menu {
          width: 100%;
          height: 10%;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: var(--smallFontSize);
          line-height: 15px;
          text-decoration-line: underline;
        }

        & .help-develop {
          width: 100%;
          height: 20%;
          flex-direction: column;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: var(--smallFontSize);
          line-height: 160%;
          text-align: center;
          color: #FFFFFF;

          &-content {
            font-size: var(--defaultFontSize);
            line-height: 160%;
            text-align: center;
            color: #FFC900;
          }
        }

        & .help-manufacture {
          width: 100%;
          height: 30%;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: var(--smallFontSize);
          line-height: 160%;
          text-align: center;
          color: #868686;
        }
      }

      & .zole-rules-container {
        background-color: #0C0C0C;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      & .zole-rules-page {
        height: 75vh;
        max-height: 75vh;
        margin-top: 115px;
        width: 80%;

        &-title {
          height: 45px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: var(--xxxLargeFontSize);
          line-height: 140%;
          color: #FFFFFF;
          margin-left: 50px;
        }

        & .zole-rules-page-header {
          height: calc(100% - 53px);

          & .zole-rules-page-header-tab {
            & .contact-support-chat-body-scroll-area {
              width: 100%;
            }

            & .scrollArea-buttonDown {
              right: 19px !important;
            }

            & .scrollArea-buttonUp {
              right: 19px !important;
            }
          }
        }

        & .zole-rules-page-content {
          height: calc(100% - 53px);
          position: relative;
        }
      }

      @media only screen and (max-width: 574px) {
        & .zole-rules-page {
          height: 1200px;
          max-height: 1200px;
          width: 100% !important;

          & .zole-rules-page-header {
            height: calc(50% - 50px);
            width: 90%;
            margin-bottom: 50px;
          }

          & .zole-rules-page-content {
            height: calc(50% - 50px);
            width: 90%;
          }
        }
      }
    }

    &-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      width: 100%;
      max-width: 100%;
      position: relative;

      &-background-1 {
        width: 100%;
        height: 100%;
        background-image: url('../../../../images/redesign/landing-view/new_bg_1.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  & .forgot-password-section {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;

    &-content {
      width: 100%;
      flex-direction: column;

      &-result {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: var(--defaultFontSize);
        line-height: 160%;
        color: #FFFFFF;

        // margin-bottom: 18px;
        label {
          margin-bottom: 2px;

          a {
            text-decoration: underline;
            color: #FFC900;
          }
        }
      }

      &-input {
        & input[type=checkbox] {
          transform: scale(1.5);
        }
      }
    }
  }

  & .register-password-section {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;

    &-content {
      width: 100%;
      flex-direction: column;

      .form-group {
        margin-bottom: 8px;
        position: relative;
      }

      &-result {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: var(--headerFontSize);
        line-height: 160%;
        color: #FFFFFF;
      }

      & .rules-section {
        position: relative;
        width: 100%;

        & input[type=checkbox] {
          transform: scale(1.5);
          width: 5%;
          position: absolute;
          left: 25px;
          top: 0px;
        }
      }

      &-input {
        width: 100%;

        & .rules {
          padding-left: 10px;
          margin-left: 24px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: var(--headerFontSize);
          line-height: 160%;
          color: #FFFFFF;

          & .rules-button {
            color: #FFC900;
            margin-left: 5px;
          }
        }

        & .form-check-label {
          margin-top: 12px;
        }
      }

      &-input:last-child {
        min-height: auto;
      }
    }
  }
}

.tou-custom-checkbox:checked+.custom-control-label::before {
  background-color: rgba(0, 156, 16, 1);
}

.tou-custom-checkbox:checked+.custom-control-label::after {
  position: absolute;
  top: 5px;
  left: -21px;
  display: block;
  width: 19px;
  height: 20px;
  border-width: 4px 4px 0px 0px;
  height: 10px;
  content: "";
  background: 50% / 50% 50% no-repeat;
  border-color: white;
  border-style: solid;
  transform: rotate(134deg);
}
.tou-custom-checkbox-light:checked+.custom-control-label::after {
  border-color: black;
}

.tou-custom-checkbox+.custom-control-label::before {
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  top: 0;
}

@media (max-height: 900px) {
  .login-container-vertical-adjustment {
    margin-top: 7vh;
  }
}
@media (max-height: 750px) {
  .login-container-vertical-adjustment {
    margin-top: 13vh;
  }
}

.new-design-login-container-body-content {
  .new-design-login-container-body-content-section-form {
    .new-design-login-container-body-content-section-form-input {
      .common-form {
        .form-group {
          position: relative;

          .input-col-message {
            width: auto;
            height: 17px;
            position: absolute;
            top: 38px;
            left: 16px;
            font-size: var(--smallFontSize);
            background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
            border-radius: 99px;
            padding: 1px 6px 2px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: #fff;
            line-height: normal;
            border: none;
          }
        }
      }
    }
  }
}

.forgot-password-section {
  .forgot-password-section-content {
    .forgot-password-section-content-input {
      .common-form {
        .form-group {
          position: relative;

          .new-design-alert {
            width: auto;
            height: 17px;
            position: absolute;
            top: 38px;
            left: 16px;
            font-size: var(--smallFontSize);
            background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
            border-radius: 99px;
            padding: 1px 6px 2px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: #fff;
            line-height: normal;
            border: none;
          }
        }
      }
    }
  }
}