//@import "../../redesignVariables";

.root-class-design20 {

  .about-us-modal {
    font-size: var(--largeFontSize);
    margin-left: 4%;
    margin-right: 4%;

    &-text {
      color: $primaryTextColour;
      font-size: var(--defaultFontSize) !important;
    }

    &-link {
      color: $highlightedTextColor;

      & a {
        color: $highlightedTextColor;
      }
    }

    &-first-row {
      text-align: center;
      margin-bottom: 40px;
    }

    &-second-row {
      margin-bottom: 40px;

      &-column {
        display: inline-block;
        width: 50%;
        vertical-align: top;

        &-section {
          display: block;
          width: 100%;
          text-align: left;
          margin-bottom: 20px;
        }
      }

      &-section {
        display: inline-block;
        width: 33.3%;
        text-align: left;
      }
    }

    &-third-row {
      &-link {
        &-right {
          display: block;
          width: 112px;
          margin-left: 5px;
        }
        &-left {
          display: block;
          float: right;
          margin-right: 5px;
          width: 112px;
        }
      }

      &-img {
        width: 112px;
        height: auto;

        &-left {
          float: right;
        }
      }

      &-section {
        display: inline-block;
        width: 50%;
      }

    }
  }

}

.root-class-design20-light {
  .about-us-modal {
    &-link {
      color: #FF8A1A;

      & a {
        color: #FF8A1A;
      }
    }
  }
}