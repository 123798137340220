.layout-body-main-friends {
  &-no-friends {
    font-size: var(--mLargeFontSize);
  }
  .layout-body-main-scrollarea {
    .scrollarea-content {
      & .top-table-spinner-loading-section {
        img {
          width: 111px;
          height: 94px;
          margin-bottom: 28px;
        }
        font-size: var(--mLargeFontSize);
        font-weight: 400;
      }
    }
  }
}

.root-class-design20-dark {
  .layout-body-main-friends {
    .layout-body-main-scrollarea {
      .scrollarea-content {
        background-color: $darkBodyTopBackgroundColour;
      }
    }
  }
}

.root-class-design20-light {
  .layout-body-main-friends {
    .layout-body-main-scrollarea {
      .scrollarea-content {
        background-color: $lightBodyTopBackgroundColour;
      }
    }
  }
}
