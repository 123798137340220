$headerTextColour: #FFF;
$headerActiveBorderColour: #FFC900;

$onlineCountPrimaryColour: #FFFFFF;
$onlineCountSecondaryColour: #FFC900;

$bodyTopBackgroundColour: #000;
$bodyTopBackgroundOpacity: 0.95;

$sectionDividerSize: 0.7vmin;
$mobileLgSize: 1012px;
$mobileSmSize: 574px;
$mobileXsSize: 374px;

// @import "../../redesignVariables";

.root-class-design20 {

  .top-row {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0px;
    // z-index: 99999;

    .coin-img {
      display: none;
    }
    .coin-text {
      display: none;
    }
    .rating-img {
      display: none;
    }
    .rating-text {
      display: none;
    }

    @media screen and (min-width: 1920px) {
      position: absolute;
    }

    &-new {
      @media screen and (min-width: 1920px) {
        position: fixed;
      }
    }

    .logo {
      background-image: url('../../../../images/redesign/common/Zole logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 73px;
      height: 41px;
      display: block;
      margin-left: 13px;
      margin-top: 16px;

      &-wrapper {}
    }

    & .top-settings {
      float: right;
      position: absolute;
      top: 17px;
      right: 13px;

      &-tournament {
        top: 40px;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        column-gap: 11px;
      }

      &-section {
        // display: inline-block;
        // position: relative;
        // height: 100%;
        // vertical-align: middle;
        // font-size: var(--defaultFontSize);
        // line-height: 25px;

        & .sound-button {
          // width: auto;
          // height: 35px;
          // min-width: 1.4vmin;
          // cursor: pointer;
          // z-index: 100;
          // position: relative;
          // display: inline-block;
          // top: 7px;
          // left: -10px;
          display: block;
          position: unset;

          &-image {
            width: 22px;
            padding-left: 0px;
            height: auto;
            cursor: pointer;
          }
        }

        & .language {
          // z-index: 999;
          // width: 50px;
          // position: relative;
          // display: inline-block;
          // right: 0px;
          // left: unset;
          // top: 0px;
          width: unset;
          position: unset;
          display: block;

          &-select {
            .dropdown {
              min-width: auto !important;
            }

            button.dropdown-toggle {
              min-width: auto;
              padding: 0;
            }
          }

          &-image {
            float: right;
            width: 18px;
            height: 18px;
            border-radius: 55px;
          }

          &-label {
            color: #fff;
            cursor: pointer;
          }

          &-label-active {
            color: #FFC900;
          }

          & .dropdown {
            padding: 0;
            min-width: 35px;


            & .dropdown-menu {
              background-color: #000;
              border: 0px;
              border-radius: 17px 0px 17px 17px;
              top: 0px !important;
              left: -50px !important;
              padding: 20px;
              min-width: 11rem;

              & .dropdown-item {
                & div label {
                  font-size: var(--largeFontSize);
                  margin-right: 15px;
                }
              }

              & .dropdown-item:hover {
                background: unset !important;

                & div label {
                  color: #FFC900;
                }
              }
            }
          }
        }

        & .fullscreen-button-image {
          width: 20px;
          height: auto;
          min-width: auto;
          cursor: pointer;
        }

        & .user-settings-button {
          width: 25px;
          height: auto;
          min-width: 1.4vmin;
          cursor: pointer;
          margin: 0;
        }
      }

      &-section-old-design,
      &-section-logout {
        cursor: pointer;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        text-decoration: none;
        font-size: var(--defaultFontSize);
        line-height: 160%;
      }

      &-section-toggle-font {
        img {
          height: 17px;
          width: auto;
        }
      }

      &-divider {
        display: inline-block;
        content: ' ';
        background-image: url('../../../../images/redesign/common/karavs.svg');
        background-size: 7px 8px;
        background-repeat: no-repeat;
        background-position: center;
        width: 7px;
        height: 8px;
        vertical-align: top;
      }
    }

    & .top-settings-new {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 25px;
      flex-wrap: wrap;
      height: 100%;
      width: 70%;

      &-section {
        margin-right: 20px;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        font-size: var(--headerFontSize);
        font-weight: 600;
        font-style: normal;
        line-height: 22px;
        color: #fff;
        opacity: 0.5;
        padding-bottom: 8px;
        margin-bottom: -8px;

        &-active {
          border-bottom: 2px solid #FFC900;
          opacity: 1;
        }

        &-login-button {
          // width: 125px;
          // height: 38px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: var(--headerFontSize);
          line-height: 20px;
          text-align: center;
          text-transform: uppercase;
          margin-left: 6px;
          height: auto;
          padding: 9px 15px;
          min-width: auto;
          /* Black */

          color: #000000;

          &::before {
            margin-right: 13px;
          }

          &::after {
            margin-left: 13px;
          }
        }

        & .language {
          z-index: 999;
          width: 50px;
          position: relative;
          display: inline-block;
          right: 0px;
          left: unset;
          top: 0px;
          cursor: pointer;

          &-image {
            float: right;
          }

          &-label {
            color: #fff;
          }

          &-label-active {
            color: #FFC900;
          }

          & .dropdown {

            & .dropdown-menu {
              background-color: #000;
              border: 0px;
              border-radius: 17px 0px 17px 17px;
              top: 0px !important;
              left: -50px !important;
              padding: 20px;
              min-width: 11rem;

              & .dropdown-item {
                & div label {
                  font-size: var(--largeFontSize);
                  margin-right: 15px;
                }
              }

              & .dropdown-item:hover {
                background: unset !important;

                & div label {
                  color: #FFC900;
                }
              }
            }
          }
        }
      }

      &-divider {
        display: inline-block;
        content: ' ';
        background-image: url('../../../../images/redesign/common/karavs.svg');
        background-size: 8px 8px;
        background-repeat: no-repeat;
        background-position: center;
        //  height: 22px;
        height: $sectionDividerSize;
        height: 38px;
        width: 8px;
        margin-right: 11px;
        margin-left: 8px;
        vertical-align: middle;
      }
    }
  }

  @media screen and (max-width: $mobileSmSize) {
    .top-row.new-design-landing-mobile-header {
      position: absolute;
      width: auto;
      height: auto;
      top: 30px !important;
      left: 50%;
      transform: translateX(-50%);

      .logo {
        margin: 0;
        width: 98px;
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 992px) {
    .top-row.new-design-landing-mobile-header {
      position: absolute;
      width: auto;
      height: auto;
      top: 30px !important;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      display: flex;
    }
  }

  .online-stats {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0;
    padding: 6px 14px;
    pointer-events: none;
    top: 11px;
    background: #000;
    border-radius: 88px;
    z-index: 100000;

    &-text {
      color: rgba($color: $onlineCountPrimaryColour, $alpha: 0.5);
      font-size: var(--defaultFontSize);
      font-weight: 450;
      font-family: 'Futura PT';
      line-height: 125%;
      margin-right: 2px;
    }

    &-count {
      color: $onlineCountPrimaryColour;
      font-size: var(--largeFontSize);
      font-family: 'Futura PT';
      font-style: normal;
      line-height: 129%;
      font-weight: 450;
      text-transform: lowercase;
    }

    &-divider {
      content: ' ';
      background-size: 7px 8px;
      background-repeat: no-repeat;
      height: 8px;
      width: 7px;
      margin-right: 7px;
      margin-left: 7px;
      margin-bottom: 3px;
    }
  }

  & .top-row-new {
    height: 120px;
    top: 0px;
    background: linear-gradient(180deg, #000000 40%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;

    & .logo-wrapper {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 30%;
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 992px) {
    & .top-row-new {
      display: none !important;
    }
  }

  @media only screen and (max-width: $mobileSmSize) {
    & .top-row-new {
      display: none !important;
    }
  }
}