$mobileSmSize: 574px;
$mobileLandScapeWidth: 991px;

@media screen and (max-width: $mobileSmSize) {
    .root-class-design20 {
        .landing-mobile-versionHistory-header {
            display: none !important;
        }

        .new-design-landing-container {
            .zole-rules-container {
                .zole-rules-page {
                    .zole-rules-page-content {
                        margin-top: 32px;

                        .contact-support-chat-body-scroll-area {
                            margin-right: 0;

                            .row {
                                margin-left: 0;
                                margin-right: 0;

                                .zole-rules-page-content-wrapper {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .version-history-modal.modal-dialog {
            .modal-content {
                .modal-body {
                    background: #000 !important;
                    box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7) !important;

                    .zole-rules-page {
                        height: auto;
                        max-height: unset;

                        .zole-rules-page-body {
                            .contact-support-chat-body-scroll-area {
                                height: 57vh;

                                &::-webkit-scrollbar {
                                    background-color: rgba(255, 255, 255, 0.2);
                                }
                            }
                        }
                    }
                }

                .modal-footer {
                    background: #000 !important;
                    border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
                    margin-top: 0;
                }
            }
        }
    }
}

@media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .root-class-design20 {
        .landing-mobile-versionHistory-header {
            display: none !important;
        }

        .new-design-landing-container {
            .zole-rules-container {
                .zole-rules-page {
                    .zole-rules-page-content {
                        margin-top: 32px;

                        .contact-support-chat-body-scroll-area {
                            margin-right: 0;

                            .row {
                                margin-left: 0;
                                margin-right: 0;

                                .zole-rules-page-content-wrapper {
                                    padding-left: 0;

                                    .row {
                                        .editor-readOnly {
                                            padding: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .version-history-modal.modal-dialog {
            .modal-content {
                .modal-body {
                    background: #000 !important;
                    box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7) !important;

                    .zole-rules-page {
                        height: auto;
                        max-height: unset;

                        .zole-rules-page-body {
                            .contact-support-chat-body-scroll-area {
                                height: 45vh;

                                &::-webkit-scrollbar {
                                    background-color: rgba(255, 255, 255, 0.2);
                                }
                            }
                        }
                    }
                }

                .modal-footer {
                    background: #000 !important;
                    border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
                    margin-top: 0;
                }
            }
        }
    }
}
