

.root-class-design20 {

  .previous-round {
    position: fixed;
    top: 53.3vh;
    right: 34vw;

    &-student-room {
      position: fixed !important;
      top: 43.8vh !important;
      right: 25vw !important;
      left: unset !important;
    }

    @media screen and (min-width: 1500px) {
      &-student-room {
        position: fixed !important;
        top: 43.8vh !important;
        right: 28.5vw !important;
        left: unset !important;
      }
    }

    @media screen and (max-width: $mobileLandScapeWidth) {
      &-student-room {
        position: fixed !important;
        top: 42.3vh !important;
        right: 7.5vw !important;
        left: unset !important;
      }
    }

    &-image {
      width: 5vmin !important;
      max-width: unset !important;
      height: 5vmin !important;
      background-size: 5vmin 5vmin !important;
      background-image: url('../../../../../images/redesign/game/Last game cards.png');
      transition: 0.35s;
      cursor: pointer;
    }

    &-image-hover {
      width: 5vmin;
      height: 5vmin;
      background-size: 5vmin 5vmin;
      background-image: url('../../../../../images/redesign/game/Last game cards hover.png');
      transition: 0.35s;
      cursor: pointer;
    }

    &-player {


      &-photo {
        width: 3vmin;
        height: 3vmin;
        border-radius: 50%;

        &-light {
          border: 2px solid #aaaaaa;
        }

        &-normal {
          border: 1px solid rgba(0, 0, 0, 0.5);
        }

        &-dark {
          background-color: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(0, 0, 0, 0.5);
        }
      }

      &-name {
        font-size: var(--largeFontSize);
        color: $primaryTextColour;
        text-transform: none;
        text-align: left;
        cursor: default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-text {
      font-size: var(--defaultFontSize);
      color: $primaryTextColour;
      opacity: 0.5;
      display: inline-block;
    }

    &-value {
      font-size: var(--largeFontSize);
      color: $primaryTextColour;
      display: inline-block;
    }

    &-tricks-text {
      margin-top: 2vmin;
      font-size: var(--largeFontSize);
      color: $primaryTextColour;
      opacity: 0.5;
      //  display: inline-block;
    }

    &-cards {
      position: relative;
      width: 30%;
      height: 5.5vmin;
      margin-left: 0;
      display: block;
    }

    &-taken-cards {
      float: left;
      position: relative;
      width: 100%;
      height: 5.5vmin;
      margin: 0px;
      margin-bottom: 4px;
    }

    &-taken-card {
      width: 4vmin;
      height: 5.5vmin;
      background-color: transparent;
      border: none;
      transition: 0.3s ease;
      cursor: default;
      position: absolute;
      z-index: 9;
      background-repeat: no-repeat;
      background-size: cover;

      &-0 {
        left: 0px;
      }

      &-1 {
        left: 2vmin;
      }

      &-2 {
        left: 4vmin;
      }
    }

    &-burried-cards {
      float: left;
      position: relative;
      width: 2vmin;
      height: 5.5vmin;
      margin: 0;
      margin-bottom: 4px;
    }

    &-table-cards {
      position: relative;
      width: 10%;
      height: 5.5vmin;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    &-card {
      width: 4vmin;
      height: 5.5vmin;
      background-color: transparent;
      border: none;
      transition: 0.3s ease;
      cursor: default;
      position: absolute;
      z-index: 9;
      background-size: 4vmin 5.5vmin;
      background-position: top center;
      background-repeat: no-repeat;

      &-0 {
        left: 0;
      }

      &-1 {
        left: 1.5vmin;
      }

      &-2 {
        left: 3vmin;
      }

      &-3 {
        left: 4.5vmin;
      }

      &-4 {
        left: 6vmin;
      }

      &-5 {
        left: 7.5vmin;
      }

      &-6 {
        left: 9vmin;
      }

      &-7 {
        left: 10.5vmin;
      }

      &-♠︎-9 {
        background-image: url('../../../../../images/cards-white-fill/♠︎-9.png');
      }

      &-♠︎-10 {
        background-image: url('../../../../../images/cards-white-fill/♠︎-10.png');
      }

      &-♠︎-A {
        background-image: url('../../../../../images/cards-white-fill/♠︎-A.png');
      }

      &-♠︎-J {
        background-image: url('../../../../../images/cards-white-fill/♠︎-J.png');
      }

      &-♠︎-Q {
        background-image: url('../../../../../images/cards-white-fill/♠︎-Q.png');
      }

      &-♠︎-K {
        background-image: url('../../../../../images/cards-white-fill/♠︎-K.png');
      }

      &-♣︎-9 {
        background-image: url('../../../../../images/cards-white-fill/♣︎-9.png');
      }

      &-♣︎-10 {
        background-image: url('../../../../../images/cards-white-fill/♣︎-10.png');
      }

      &-♣︎-A {
        background-image: url('../../../../../images/cards-white-fill/♣︎-A.png');
      }

      &-♣︎-J {
        background-image: url('../../../../../images/cards-white-fill/♣︎-J.png');
      }

      &-♣︎-Q {
        background-image: url('../../../../../images/cards-white-fill/♣︎-Q.png');
      }

      &-♣︎-K {
        background-image: url('../../../../../images/cards-white-fill/♣︎-K.png');
      }

      &-♥-9 {
        background-image: url('../../../../../images/cards-white-fill/♥-9.png');
      }

      &-♥-10 {
        background-image: url('../../../../../images/cards-white-fill/♥-10.png');
      }

      &-♥-A {
        background-image: url('../../../../../images/cards-white-fill/♥-A.png');
      }

      &-♥-J {
        background-image: url('../../../../../images/cards-white-fill/♥-J.png');
      }

      &-♥-Q {
        background-image: url('../../../../../images/cards-white-fill/♥-Q.png');
      }

      &-♥-K {
        background-image: url('../../../../../images/cards-white-fill/♥-K.png');
      }

      &-♦︎-7 {
        background-image: url('../../../../../images/cards-white-fill/♦︎-7.png');
      }

      &-♦︎-8 {
        background-image: url('../../../../../images/cards-white-fill/♦︎-8.png');
      }

      &-♦︎-9 {
        background-image: url('../../../../../images/cards-white-fill/♦︎-9.png');
      }

      &-♦︎-10 {
        background-image: url('../../../../../images/cards-white-fill/♦︎-10.png');
      }

      &-♦︎-A {
        background-image: url('../../../../../images/cards-white-fill/♦︎-A.png');
      }

      &-♦︎-J {
        background-image: url('../../../../../images/cards-white-fill/♦︎-J.png');
      }

      &-♦︎-Q {
        background-image: url('../../../../../images/cards-white-fill/♦︎-Q.png');
      }

      &-♦︎-K {
        background-image: url('../../../../../images/cards-white-fill/♦︎-K.png');
      }
    }
  }
}
