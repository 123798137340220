/* The switch - the box around the slider */

//$defaultFontSize: 1.2vmin;
//$smallFontSize: 1.0vmin;

// @import "../../redesignVariables";

.root-class-design20 {

  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
    vertical-align: middle;
    margin: 0;
    margin-right: 10px;
  //  margin-bottom: 20px;

    &-text {
      width: auto;
      position: relative;
      // margin-left: 10px;
      margin-right: 10px;
      display: inline-block;
      font-size: var(--defaultFontSize);
      line-height: $defaultFontSize;

      &-small {
        font-size: var(--smallFontSize);
        line-height: $smallFontSize;
      }
    }

  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: #f3292c;
    background: rgba(0, 0, 0, 0.4);
  //  background: rgba(0, 156, 16, 0.2);
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid rgba(255, 255, 255, 0.1);

    &-img {

    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 29px;
    width: 29px;
    left: -1px;
    bottom: -6px;
    background: rgba(255, 255, 255, 0.5);
    -webkit-transition: .4s;
    transition: .4s;
    border: 2px solid #000000;
    background-color: #4d4d4d;
  }

  .slider:after {
    position: absolute;
    content: "";
    transform: translateY(-50%);
    top: 50%;
    background-image: url('../../../../images/redesign/components/slider/sliderX.svg');
    background-repeat: no-repeat;
    background-size: 8px 8px;
    transition: .4s;
    width: 8px;
    height: 8px;
    left: 9px;
  }

  input:checked + .slider {
  //  background-color: #009C10;
  //  opacity: 0.7;
    background: rgba(0, 156, 16, 0.2);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
    left: -3px;
    bottom: -4px;
    background-color: rgba(0, 156, 16, 1);
    border: 0;
    width: 25px;
    height: 25px;
  }

  input:checked + .slider:after {
    -webkit-transform: translate(16px, -50%);
    -ms-transform: translate(16px, -50%);
    transform: translate(16px, -50%);
    background-image: url('../../../../images/redesign/components/slider/sliderTick.svg');
    left: 7px;
    background-size: 15px 12px;
    width: 15px;
    height: 12px;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

}

.filter-body-section {
  .switch {
    &-text {
      margin-left: 5px;
      font-size: var(--headerFontSize);
      line-height: 160%;
      font-weight: 400;
    }
  }

  /* The slider */
  .slider {
    background: rgba(0, 0, 0, 0.4) !important;
  }

  input:checked + .slider {
    background: rgba(0, 156, 16, 0.2) !important;
  }
}

.user-settings-toggles {
  .slider {
    background: rgba(0, 156, 16, 0.2);
  }

  .slider:before {
    background-color: #009C10;
    opacity: 1;
    border: 0;
    width: 25px;
    height: 25px;
    left: -1px;
    bottom: -4px;
  }

  .slider:after {
    opacity: 1;
    background-image: url('../../../../images/redesign/components/slider/sliderTick.svg');
    left: 4px;
    background-size: 15px 12px;
    width: 15px;
    height: 12px;
  }
}
