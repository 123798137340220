// @import "../../redesignVariables";

.root-class-design20 {

  .about-us-modal {
    font-size: var(--largeFontSize);
  }

  .weekly-status-home-modal {
    .layout-body-main-table-header {
      margin-left: 16px;
      margin-right: 16px;
    }

    .default-modal-body {
      padding: 66px 0px 12px 0px;

      .weekly-status-container {
        .layout-body-main {
          padding-left: 26px;
          padding-right: 23px;

          .layout-body-main-table-header {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 15px;
            padding-right: 20px;

            .weekly-statistics-title {
              padding-left: 0;
              padding-right: 0;
            }
          }

          .layout-body-main-scrollarea {
            max-height: 500px !important;
            padding-right: 4px;

            .menu-table-row-wrapper {
              padding-left: 0;
              padding-right: 0;
              padding-bottom: 5px;

              .menu-table-row-inner-wrapper {
                box-shadow: unset !important;

                .weekly-statistics-name {
                  padding-left: 18px;
                }

                .weekly-statistics-trend {
                  padding-left: 15px;

                  .menu-table-row-weekly-low-trend {
                    margin: 0;
                  }

                  .menu-table-row-weekly-large-trend {
                    margin: 0;
                  }

                  .menu-table-row-weekly-neutral-trend {
                    margin: 0;
                  }
                }

                .row {
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }

}
.root-class-design20-light {
  .tournaments-join-private-password-modal {
    .room-password-digit {
      border-color: gray;
    }
  }
}

.root-class-design20-normal {
  .weekly-status-home-modal {
    .default-modal-body {
      .weekly-status-container {
        .layout-body-main {
          .layout-body-main-scrollarea {
            .menu-table-row-wrapper {
              .menu-table-row-inner-wrapper {
                background-color: rgba(236, 236, 236, 0.1);
              }
            }
          }
        }
      }
    }
  }
}

.root-class-design20-dark {
  .weekly-status-home-modal {
    .default-modal-body {
      .weekly-status-container {
        .layout-body-main {
          .layout-body-main-scrollarea {
            .menu-table-row-wrapper {
              .menu-table-row-inner-wrapper {
                background-color: rgba(255, 255, 255, 0.03);
              }
            }
          }
        }
      }
    }
  }
}