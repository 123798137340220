.allTournaments {

  &-table {


    &-col {
      text-align: center;

      &-button {
        padding: 5%;
        width: 90%;
      }
    }
  }
}

.tournaments {
  &-results {

    &-modal {
      max-width: 1420px;
    }

    &-header {
      font-size: var(--headerFontSize);
      text-align: center;
      border-bottom: 1px solid #fff;
    }

    &-body {

    }

    &-round {
      min-height: 30px;

      &-text {
        border-bottom: 1px solid #fff;
        font-size: var(--headerFontSize);
        text-align: center;
        font-weight: 700;
      }
    }

    &-col {

    }

    &-room {
      &-row {
        border-bottom: 1px solid #fff;
        min-height: 20px;
      }

      &-id {
        font-size: var(--defaultFontSize);
        text-align: center;
      //  font-weight: 500;
      }

      &-player {
        font-size: var(--defaultFontSize);
        text-align: center;
      //  font-weight: 500;

        &-disqualified {
          color: red;
        }

        &-name {
          font-size: var(--defaultFontSize);
          // font-size: 13px;
        }

        &-tourPoints {
          font-size: var(--mSmallFontSize);
        }

        &-roomPoints {
          font-size: var(--mSmallFontSize);
        }
      }
    }
  }
  &-join {
    &-message {
      
      // display: flex;
      // align-items: center;
      // justify-content: center;
      &-old {
        font-size: var(--titleFontSize);
        &-body {
          font-size: var(--largeFontSize);
        }
      }
    }
  }
}

.admin-modal {
  label {
    font-size: 16px;
    margin-bottom: 4px;
  }

  input {
    font-size: 16px;
  }
}

.tournaments-image-option {
  display: flex;
  flex-direction: row;
}
.sponsor-section {
  width: 50%;
  position: relative;
  & .event-banner-edit {
    position: absolute;
    top: 0px;
    right: 50px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-cancel {
    position: absolute;
    top: 0px;
    right: 20px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }

  & .event-banner-image {
    width: 86%;
    height: 150px;
    object-fit: contain;
    border-radius: 15px;
  }
}

.prize-section {
  width: 50%;
  position: relative;
  & .event-banner-edit {
    position: absolute;
    top: 0px;
    right: 50px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-cancel {
    position: absolute;
    top: 0px;
    right: 20px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-image {
    width: 86%;
    height: 150px;
    object-fit: contain;
    border-radius: 15px;
  }
}


.banner-section {
  width: 50%;
  position: relative;
  & .event-banner-edit {
    position: absolute;
    top: 0px;
    right: 50px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-cancel {
    position: absolute;
    top: 0px;
    right: 20px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-image {
    width: 86%;
    height: 150px;
    object-fit: contain;
    border-radius: 15px;
  }
}