.allUsers-table {

  &-col {
    text-align: center;

    &-env {
      width: 50px;
      max-width: 50px;
    }

    &-img {
      height: 28px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}


.user-history {
  &-table {
    max-width: 100%;
    table-layout: fixed;
    width: 100%;

    &-filter {
      font-size: 13px;

      &-text {
        font-size: 14px;
        text-align: right;
        padding-right: 0px;
      }
    }

    &-col {
      overflow: hidden;
      color: #fff;

      font-size: 12px;
      line-height: 15px;
      text-align: center;
    }

    &-row {
      height: 34px;
      max-width: 100%;
      overflow: hidden;
    }

    &-header {
      width: 100%;

      &-col {
        color: #FFF;
        border-bottom: 1px solid #fff;
        width: 15%;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
      }

      &-row {
        height: 34px;
        max-width: 100%;
        overflow: hidden;

        border-bottom: 1px solid #fff;

        font-weight: bold;
        font-size: 13px;
      }
    }
  }
}
