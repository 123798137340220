.emotions {
//  bottom: 100px;
//  top: 477px;
  bottom: 70px;
  left: 25px;
  width: 275px;
  position: absolute;
  background-color: #2c5955;
  border: 1px solid #fff;
  -webkit-box-shadow: 5px 5px 0px 0px #380000;
  -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 5px 0px 0px #380000;
//  border-radius: 10px;

  &-open {
  //  height: 20%;
  //  border-radius: 0;
  }

  &-closed {
    display: none;

    & .chat-header {
      height: 0%;
      border: 0;
    }
  }

  &-header {
     height: 15%;
     margin: 0;
     border-bottom: 1px solid #fff;

     &-closed {
       display: none;
     }

    &-name {
      font-size: var(--defaultFontSize);
      font-weight: 600;
      padding-right: 5px;
      padding-left: 8px;
      padding-top: 5px;
      color: #fff;
    }

    &-button {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 0;

      &-col {
        padding: 0;
      }

      &:hover {
        color: #fff!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:active {
        color: #fff!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:focus {
        color: #fff!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }
    }
  }

  &-body {
     height: 75%;
     margin: 0;
     margin-top: 5px;
     margin-bottom: 5px;

    &-open {
      display: block;
    }

    &-closed {
      display: none;
    }

     &-wrapper {
       padding: 0;
       height: 100%;
     }

     &-scroll {

       &-area {
         height: 100%;

       }

       &-content {
         margin-right: 7px;
       }
     }
  }

  &-emotion {
    width: 16%;
    height: 30%;
    padding: 1px;
    margin-top: 2px;

    &-image {
    //  width: auto;
    //  height: 100%;
      max-width: 100%;
      width: 100%;
      height: auto;
      margin: auto;
      cursor: pointer;

      &-handshake {
        width: 125%;
        height: auto;
        margin-top: 6px;
      }
    }
  }

  &-wrapper {
    padding-left: 25px;
    padding-right: 15px;
    height: 100%;
  }
}
