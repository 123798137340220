

$nameColor: #0C0C0C;
$bgColor: #FFF;
$commentColor: #868686;

.root-class-design20 {

  .default-tooltip {
  //  color: #fff;
  //  background-color: $bgColor;
  //  border-radius: 1.5vmin 1.5vmin 1.5vmin 0px;

  //  &-test {
  //    background-color: $bgColor;
  //  }

    & .tooltip-inner {
      background-color: $bgColor;
      border-radius: 1.5vmin 1.5vmin 1.5vmin 0px;
    }

    & .arrow {
      display: none;
    }

    &-from {
      color: $nameColor;
      text-align: center;
      font-size: var(--defaultFontSize);
    }

    &-comment {
      color: $nameColor;
      font-size: var(--defaultFontSize);
      text-align: justify;
    }
  }

}
