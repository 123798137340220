.new-game-logs {
  padding: 0px 30px;

  width: 100%;
  height: 100%;
  overflow: auto;

  &-empty-entries {
    font-size: var(--mLargeFontSize);
  }

  &-scrollarea {
    

    &-body {
      &-no-games {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }

  &-table {
    width: 100%;
    border-collapse: collapse;
    border-collapse: separate;
    border-spacing: 0 5px;

    &-header {
      & th {
        padding: 0px 7px;
      }
      & th:first-child {
        padding-left: 10px;
      }

      &-player {
        & img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          padding: 2px;
        }
      }

      th:nth-child(2) {
        font-family: 'Futura PT';
        font-style: normal;
        font-weight: 450;
        font-size: var(--largeFontSize);
        color: rgba($color: #fff, $alpha: 0.5)
      }
    }

    &-row {
      background-color: $darkTableRowBackgroundColor;

      & td {
        padding: 0px 7px;

        time {
          font-weight: 400;
          font-family: 'Open Sans';
          color: rgba($color: #fff, $alpha: 0.7);
        }
      }

      & td:first-child {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        padding-left: 10px;
      }

      & td:last-child {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        padding-left: 10px;
      }

      &-info {
        & img {
          width: 46px;
          height: 46px;
          cursor: pointer;
          float: right;
          //margin-right: 10px;
          margin-bottom: -6px;
        }
      }

      &-large-trend {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #009C10;
        padding: 4px 13px;
        border-radius: 44px;
        line-height: 20.8px;
      }

      &-low-trend {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #D2110A;
        padding: 4px 13px;
        border-radius: 44px;
        line-height: 20.8px;
      }

      &-neutral-trend {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffce5a;
        padding: 4px 13px;
        border-radius: 44px;
        line-height: 20.8px;

        color: black;
      }
    }
  }

  & .scrollArea-buttonUp {
    right: 30px !important;
  }

  & .scrollArea-buttonDown {
    right: 30px !important;
  }
}

.new-user-party-modal {
  width: 100%;
  height: 450px;
  font-size: var(--largeFontSize);

  & .scrollArea-buttonDown {
    right: 16px !important;
    bottom: 25px !important;
  }

  & .scrollArea-buttonUp {
    right: 16px !important;
    bottom: 26px !important;
  }
}

.root-class-design20-light {
  .new-game-logs {
    .new-game-logs-table {
      .new-game-logs-table-header {
        th:nth-child(2) {
          color: rgba($color: #0C0C0C, $alpha: 0.5);
        }
      }

      .new-game-logs-table-row {
        td {
          time {
            color: rgba($color: #0C0C0C, $alpha: 0.5);
          }
        }
      }
    }
  }
}