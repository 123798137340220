$mobileSmSize: 574px;
$mobileLandScapeWidth: 991px;

@media screen and (max-width: $mobileSmSize) {
    .root-class-design20 {
        .landing-mobile-contacts-header {
            display: none !important;
        }

        .new-design-landing-container {
            .contacts-container {
                background-color: #0C0C0C !important;
                width: 100%;
                min-width: auto;
                display: block !important;
                min-height: calc(100vh - 57px);

                .contacts-page {
                    margin-top: 0 !important;
                    margin-left: 0;
                    margin-right: 0;
                    width: 100%;
                    padding: 14px 20px 100px 20px;

                    &>div:first-of-type {
                        .contacts-page-title {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            font-family: 'Open Sans';
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 32.68px;
                            color: #fff;
                            margin-bottom: 0;
                        }
                    }

                    &>div:nth-of-type(2) {
                        .contacts-page-first-section {
                            padding-top: 22px;
                            margin-left: 0;
                            margin-right: 0;
                            margin-bottom: 0;

                            .contacts-page-first-section-item:first-of-type {
                                min-height: auto;
                                margin-bottom: 48px !important;
                                padding-left: 0 !important;
                                padding-right: 0;

                                .sub-title {
                                    padding-left: 0 !important;
                                    padding-right: 0 !important;
                                    margin-top: 0 !important;
                                    margin-bottom: 11px !important;
                                    font-family: 'Open Sans';
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 27.24px;
                                    color: #fff;
                                }

                                .contacts-page-first-section-item-common-form {
                                    min-width: auto;
                                    width: 100%;
                                    padding-left: 0 !important;
                                    padding-right: 0 !important;

                                    .inline-input-group {
                                        .form-group {
                                            margin-bottom: 8px;
                                            margin-right: 0 !important;

                                            &>div {
                                                padding-left: 12px !important;
                                                padding-right: 12px !important;
                                                padding-top: 13px !important;
                                                padding-bottom: 13px !important;

                                                .new-input-second-placeholder {
                                                    padding: 0;
                                                    padding-left: 10px !important;
                                                    font-family: 'Futura PT';
                                                    font-size: 18px;
                                                    font-style: normal;
                                                    font-weight: 450;
                                                    line-height: 23.08px;
                                                    color: rgba($color: #000, $alpha: 0.5);
                                                }

                                                .new-input-second-placeholder+.inputInlineMessage-danger {
                                                    padding: 1px 6px;
                                                    height: auto;
                                                    border-radius: 99px;
                                                    background: var(--red-gradient, linear-gradient(180deg, rgba(255, 0, 0, 0.80) 0%, rgba(132, 0, 0, 0.80) 100%)) !important;
                                                    left: 15px;

                                                    p {
                                                        color: #FFF;
                                                        text-align: center;
                                                        font-family: 'Futura PT';
                                                        font-size: 11px;
                                                        font-style: normal;
                                                        font-weight: 450;
                                                        line-height: 14.1px;
                                                        margin: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .comments-section {
                                        margin-bottom: 8px;

                                        textarea {
                                            font-family: 'Futura PT';
                                            font-size: 18px;
                                            font-style: normal;
                                            font-weight: 450;
                                            line-height: 23.08px;
                                            color: rgba($color: #000000, $alpha: 0.5);
                                            padding: 13px 26px 17px 26px;
                                        }

                                        textarea+.inputInlineMessage-danger {
                                            padding: 1px 6px;
                                            height: auto;
                                            border-radius: 99px;
                                            background: var(--red-gradient, linear-gradient(180deg, rgba(255, 0, 0, 0.80) 0%, rgba(132, 0, 0, 0.80) 100%)) !important;
                                            left: 27px;

                                            p {
                                                color: #FFF;
                                                text-align: center;
                                                font-family: 'Futura PT';
                                                font-size: 11px;
                                                font-style: normal;
                                                font-weight: 450;
                                                line-height: 14.1px;
                                                margin: 0;
                                            }
                                        }

                                        .limit-characters {
                                            color: #868686;
                                            font-family: 'Futura PT';
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 15.38px;
                                            right: 19px;
                                            margin-bottom: 0;
                                            bottom: 4px;
                                        }
                                    }

                                    .row {
                                        margin-left: 0;
                                        margin-right: 0;

                                        .send-message-button {
                                            button {
                                                color: #000 !important;
                                                font-family: 'Open Sans';
                                                font-size: 15px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 20.43px;
                                                text-transform: uppercase;
                                                padding: 9px 15px;
                                                border-radius: 99px;
                                                background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%) !important;
                                                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset !important;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                            }
                                        }
                                    }
                                }
                            }

                            .contacts-page-first-section-item:nth-of-type(2) {
                                min-height: auto;
                                padding-left: 0;
                                padding-right: 0;
                                margin-bottom: 0 !important;
                                padding-bottom: 0 !important;

                                .sub-title {
                                    font-family: 'Open Sans';
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 27.24px;
                                    color: #fff;
                                    margin-top: 0 !important;
                                    padding-left: 0 !important;
                                    padding-right: 0 !important;
                                }

                                .contacts-information {
                                    display: flex;
                                    align-items: center !important;
                                    column-gap: 11px;

                                    .contacts-information-icon {
                                        width: auto;
                                        margin-left: -4px;

                                        img {
                                            width: 50px;
                                            object-fit: contain;
                                        }
                                    }

                                    .contacts-information-content {
                                        position: static;
                                        color: #fff;
                                        font-family: 'Open Sans';
                                        font-size: 15px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 140%;
                                        margin-bottom: 5px;
                                        width: auto;
                                    }
                                }

                                .contacts-information:nth-of-type(3) {
                                    align-items: flex-start !important;

                                    .contacts-information-content {
                                        margin-bottom: 0;
                                        margin-top: 14px;

                                        .row {
                                            margin-left: 0;
                                            margin-right: 0;

                                            &>.col {
                                                font-family: 'Open Sans';
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 160%;
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .contact-message-sent-success {
                            padding: 126px 0 156px 0;

                            img {
                                width: 102px;
                                object-fit: contain;
                                height: auto;
                            }

                            .contact-message-sent-success-text {
                                margin-top: 28px;
                                color: #FFF;
                                font-family: 'Open Sans';
                                font-size: 15px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 160%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .root-class-design20 {
        .landing-mobile-contacts-header {
            display: none !important;
        }

        .new-design-landing-container {
            .contacts-container {
                background-color: #0C0C0C !important;
                width: calc(100vw - 57px);
                min-width: auto;
                display: block !important;
                min-height: 100vh;

                .contacts-page {
                    margin-top: 0 !important;
                    margin-left: 0;
                    margin-right: 0;
                    width: 100%;
                    padding: 14px 20px 100px 20px;

                    &>div:first-of-type {
                        .contacts-page-title {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            font-family: 'Open Sans';
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 32.68px;
                            color: #fff;
                            margin-bottom: 0;
                        }
                    }

                    &>div:nth-of-type(2) {
                        .contacts-page-first-section {
                            padding-top: 19px;
                            margin-left: 0;
                            margin-right: 0;
                            margin-bottom: 0;

                            .contacts-page-first-section-item:first-of-type {
                                min-height: auto;
                                margin-bottom: 26px !important;
                                padding-left: 0 !important;
                                padding-right: 0;

                                .sub-title {
                                    padding-left: 0 !important;
                                    padding-right: 0 !important;
                                    margin-top: 0 !important;
                                    margin-bottom: 11px !important;
                                    font-family: 'Open Sans';
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 27.24px;
                                    color: #fff;
                                }

                                .contacts-page-first-section-item-common-form {
                                    min-width: auto;
                                    width: 100%;
                                    padding-left: 0 !important;
                                    padding-right: 0 !important;

                                    .inline-input-group {
                                        width: 100%;
                                        justify-content: space-between;
                                        column-gap: 8px;

                                        .form-group {
                                            width: auto;
                                            margin-bottom: 8px;
                                            margin-right: 0 !important;

                                            &>div {
                                                padding-left: 12px !important;
                                                padding-right: 12px !important;
                                                padding-top: 13px !important;
                                                padding-bottom: 13px !important;

                                                .new-input-second-placeholder {
                                                    padding: 0;
                                                    padding-left: 10px !important;
                                                    font-family: 'Futura PT';
                                                    font-size: 18px;
                                                    font-style: normal;
                                                    font-weight: 450;
                                                    line-height: 23.08px;
                                                    color: rgba($color: #000, $alpha: 0.5);
                                                }

                                                .new-input-second-placeholder+.inputInlineMessage-danger {
                                                    padding: 1px 6px;
                                                    height: auto;
                                                    border-radius: 99px;
                                                    background: var(--red-gradient, linear-gradient(180deg, rgba(255, 0, 0, 0.80) 0%, rgba(132, 0, 0, 0.80) 100%)) !important;
                                                    left: 15px;

                                                    p {
                                                        color: #FFF;
                                                        text-align: center;
                                                        font-family: 'Futura PT';
                                                        font-size: 11px;
                                                        font-style: normal;
                                                        font-weight: 450;
                                                        line-height: 14.1px;
                                                        margin: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .comments-section {
                                        margin-bottom: 8px;
                                        width: 100%;

                                        textarea {
                                            font-family: 'Futura PT';
                                            font-size: 18px;
                                            font-style: normal;
                                            font-weight: 450;
                                            line-height: 23.08px;
                                            color: rgba($color: #000000, $alpha: 0.5);
                                            padding: 13px 26px 17px 26px;
                                        }

                                        textarea+.inputInlineMessage-danger {
                                            padding: 1px 6px;
                                            height: auto;
                                            border-radius: 99px;
                                            background: var(--red-gradient, linear-gradient(180deg, rgba(255, 0, 0, 0.80) 0%, rgba(132, 0, 0, 0.80) 100%)) !important;
                                            left: 27px;

                                            p {
                                                color: #FFF;
                                                text-align: center;
                                                font-family: 'Futura PT';
                                                font-size: 11px;
                                                font-style: normal;
                                                font-weight: 450;
                                                line-height: 14.1px;
                                                margin: 0;
                                            }
                                        }

                                        .limit-characters {
                                            color: #868686;
                                            font-family: 'Futura PT';
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 15.38px;
                                            right: 19px;
                                            margin-bottom: 0;
                                            bottom: 4px;
                                        }
                                    }

                                    .row {
                                        margin-left: 0;
                                        margin-right: 0;

                                        .send-message-button {
                                            button {
                                                color: #000 !important;
                                                font-family: 'Open Sans';
                                                font-size: 15px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 20.43px;
                                                text-transform: uppercase;
                                                padding: 9px 15px;
                                                border-radius: 99px;
                                                background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%) !important;
                                                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset !important;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                            }
                                        }
                                    }
                                }
                            }

                            .contacts-page-first-section-item:nth-of-type(2) {
                                min-height: auto;
                                padding-left: 0;
                                padding-right: 0;
                                margin-bottom: 0 !important;
                                padding-bottom: 0 !important;
                                width: 50%;
                                flex: 0 0 50%;
                                max-width: unset;

                                .sub-title {
                                    font-family: 'Open Sans';
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 27.24px;
                                    color: #fff;
                                    margin-top: 0 !important;
                                    padding-left: 0 !important;
                                    padding-right: 0 !important;
                                }

                                .contacts-information {
                                    display: flex;
                                    align-items: center !important;
                                    column-gap: 11px;

                                    .contacts-information-icon {
                                        width: auto;
                                        margin-left: -4px;

                                        img {
                                            width: 50px;
                                            object-fit: contain;
                                        }
                                    }

                                    .contacts-information-content {
                                        position: static;
                                        color: #fff;
                                        font-family: 'Open Sans';
                                        font-size: 15px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 140%;
                                        margin-bottom: 5px;
                                        width: auto;
                                    }
                                }

                                .contacts-information:nth-of-type(3) {
                                    display: none !important;
                                }
                            }

                            .contacts-information-third-section {
                                width: 50%;
                                flex: 0 0 50%;
                                max-width: unset;
                                display: flex !important;
                                margin-top: 36px;
                                padding: 0;

                                .contacts-information {
                                    display: flex;
                                    align-items: flex-start !important;
                                    column-gap: 11px;

                                    .contacts-information-icon {
                                        width: auto;

                                        img {
                                            width: 50px;
                                            object-fit: contain;
                                        }
                                    }

                                    .contacts-information-content {
                                        position: static;
                                        width: auto;

                                        .row {
                                            margin-left: 0;
                                            margin-right: 0;

                                            .col {
                                                color: #fff;
                                                font-family: 'Open Sans';
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 160%;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .contact-message-sent-success {
                            padding: 129px 0 156px 0;
                            width: 100%;
                            flex: 0 0 100%;
                            max-width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            img {
                                width: 102px;
                                object-fit: contain;
                                height: auto;
                                margin-bottom: 0;
                            }

                            .contact-message-sent-success-text {
                                margin-top: 28px;
                                color: #FFF;
                                font-family: 'Open Sans';
                                font-size: 15px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 160%;
                            }
                        }
                    }
                }
            }
        }
    }
}
