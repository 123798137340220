// .layout-body-main-friends {
//   .layout-body-main-scrollarea {
//     .scrollarea-content {
//       & .top-table-spinner-loading-section {
//         img {
//           width: 111px;
//           height: 94px;
//           margin-bottom: 28px;
//         }
//         font-size: var(--mLargeFontSize);
//         font-weight: 400;
//       }
//     }
//   }
// }

// .root-class-design20-dark {
//   .layout-body-main-friends {
//     .layout-body-main-scrollarea {
//       .scrollarea-content {
//         background-color: $darkBodyTopBackgroundColour;
//       }
//     }
//   }
// }

// .root-class-design20-light {
//   .layout-body-main-friends {
//     .layout-body-main-scrollarea {
//       .scrollarea-content {
//         background-color: $lightBodyTopBackgroundColour;
//       }
//     }
//   }
// }

$mobileLandScapeWidth: 1280px;

.root-class-design20 {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-friends-page {

      .layout-mobile-body-main {
        height: calc(100vh - 370px) !important;
        padding: 0 !important;
        margin: 11px 0 0 0 !important;

        &-title {
          display: none;
        }
        .friends-list {

          $rightSectionWidth: 70px;
          $midRightSectionWidth: 93px;
          $leftSectionWidth: 46px;

          overflow-x: auto;
          overflow-y: hidden;
          height: calc(100% - 50px);
          width: 100%;

          &-wrapper {
            min-width: 520px;
            max-width: 100%;
            height: 100%;
          }

          &-scrollarea {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            width: calc(100% - 4px);
            height: calc(100% - 50px) !important;
          }

          &-header {
            height: auto;
            // width: 100%;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: var(--defaultFontSize);

            .left-section {
              display: inline-block;
              width: $leftSectionWidth;
              height: 100%;
            }

            .middle-section {
              display: inline-block;
              width: calc(100% - #{$leftSectionWidth} - #{$midRightSectionWidth} - #{$rightSectionWidth});
              height: 100%;
              vertical-align: bottom;

              .item {
                display: inline-block;
                height: 100%;

                &-rating {
                  width: 25%;
                }
                &-balance {
                  width: 30%;
                }
                &-points {
                  width: 16%;
                }
                &-gamesPlayed {
                  width: 29%;
                }
              }
            }
            .middle-right-section {
              display: inline-block;
              width: $midRightSectionWidth;
              height: 100%;
              text-align: center;
              //word-break: break-all;
            }
            .right-section {
              display: inline-block;
              width: $rightSectionWidth;
              height: 100%;
              vertical-align: bottom;
              text-align: center;
            }
          }

          &-body {

            font-size: var(--smallFontSize);
            height: 100%;

            .left-section {
              padding-right: 12px;
              width: $leftSectionWidth;
              height: 100%;
              display: inline-block;

              .image-frame {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.2);
                display: flex;
                position: relative;

                &-light {
                  background-color: rgba(0, 0, 0, 0.6);
                }

                .profile-image {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
            }

            .middle-section {
              display: inline-block;
              width: calc(100% - #{$leftSectionWidth} - #{$midRightSectionWidth} - #{$rightSectionWidth});
              height: auto;

              .top-part {
                display: block;
                width: 100%;
                height: auto;

                .name-wrapper {
                  display: inline-block;
                  margin-right: 5px;
                }
                .rating-wrapper {
                  display: inline-block;
                }
              }
              .bottom-part {
                display: block;
                width: 100%;
                height: auto;

                .item {
                  display: inline-block;
                  height: 100%;

                  &-rating {
                    width: 25%;
                  }
                  &-balance {
                    width: 30%;
                  }
                  &-points {
                    width: 16%;
                  }
                  &-gamesPlayed {
                    width: 29%;
                  }

                  .icon {
                    display: inline-block;
                    padding-left: 2px;
                    padding-right: 2px;
                    width: auto;
                    height: 10px;
                    vertical-align: middle;

                    &-text {
                      display: inline-block;
                      text-align: left;
                    }
                  }
                }
              }
            }
            .middle-right-section {
              display: inline-block;
              width: $midRightSectionWidth;
              height: auto;
              vertical-align: bottom;

              .remove-friend-button {
                margin: 0px 21px;
              }
            }
            .right-section {
              display: inline-block;
              width: $rightSectionWidth;
              height: auto;
              vertical-align: bottom;

              .send-money-button {
                margin-right: auto;
                margin-left: auto;
                width: 35px;
                height: 35px;
                cursor: pointer;
              }
            }
          }


        }

        & .ignore-list-header {
          display: flex !important;
          margin-left: 51px;
          width: calc(100% - 51px - 35px);

          &>div {
            color: rgba($color: #FFF, $alpha: 0.5);
            font-family: 'Futura PT';
            font-size: 12px;
            font-style: normal;
            font-weight: 450;
            line-height: 15.38px;
          }

          &-column-ratings-md {
            width: calc(64/375 * 100vw) !important;
          }
          &-column-balance-md {
            width: calc(78/375 * 100vw) !important;
          }
          &-column-points-md {
            width: calc(68/375 * 100vw) !important;
          }
          &-column-games-md {
            width: calc(60/375 * 100vw) !important;
          }
          &-column-send-md {
            width: calc(65/375 * 100vw) !important;
          }
        }

        .menu-table-row-wrapper {
          padding: 0 !important;
          margin-bottom: 1px;

          .menu-table-row-inner-wrapper {
              padding: 4px 2px 4px 3px;
              box-shadow: none !important;

              .ignore-table-row {
                display: flex;
                vertical-align: middle;
                height: 100%;
                align-items: center;

                  .ignore-list-body-position {
                      min-width: calc(26/375 * 100vw);
                      width: calc(26/375 * 100vw);
                      color: #FFF;
                      font-family: 'Futura PT';
                      font-size: 11px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 14.1px;

                      .layout-divider {
                          display: none;
                      }
                  }

                  .ignore-list-body-player {
                      width: auto;
                      min-width: auto;

                      .ignore-table-row-player {
                          max-width: unset;
                          min-height: auto;

                          .ignore-table-row-player-image-frame {
                              margin-right: 5px !important;
                              width: 36px;
                              height: 36px;
                              min-width: auto;
                              min-height: auto;
                              border-radius: 50%;

                              img {
                                width: 36px;
                                height: 36px;
                                min-width: auto;
                                min-height: auto;
                                border-radius: 50%;
                              }
                          }

                          &>.ignore-table-row-text {
                              display: none;
                          }

                          .ignore-table-row-mobile-text-group {
                              width: auto;
                              background: grey;
                              display: flex;
                              flex-direction: column;

                              .ignore-table-row-mobile-first-line {
                                  display: flex;
                                  align-self: flex-start;
                                  align-items: center;
                                  column-gap: 8px;

                                  .ignore-table-row-text {
                                      color: #fff;
                                      font-family: 'Open Sans';
                                      font-size: 13px;
                                      font-weight: 400;
                                      line-height: 160%;
                                  }

                                  .my-info-ratings-step-wrapper {
                                      width: auto;
                                      height: auto;

                                      .my-info-ratings-step-active {
                                          color: #FFF;
                                          font-family: 'Open Sans';
                                          font-size: 12px;
                                          font-style: normal;
                                          font-weight: 700;
                                          line-height: 160%;
                                          text-transform: uppercase;
                                          padding: 0 6px;
                                          border-radius: 4px;
                                      }
                                  }
                              }

                              .ignore-table-row-mobile-second-line-landing {
                                  width: auto;
                                  display: flex;

                                  .menu-table-row-player-icon-wrapper-landing:first-of-type {
                                      width: calc(55/375 * 100vw);
                                      display: flex;
                                      align-items: center;

                                      img {
                                          width: 15px;
                                          object-fit: contain;
                                          height: auto;
                                          padding-right: 3px;
                                          padding-left: 0;
                                      }

                                      .menu-table-row-player-icon-text {
                                          font-family: 'Open Sans';
                                          font-size: 13px;
                                          font-style: normal;
                                          font-weight: 600;
                                          line-height: 160%;
                                          color: #fff;
                                          padding: 0;
                                      }
                                  }

                                  .menu-table-row-player-icon-wrapper-landing:nth-of-type(2) {
                                      width: calc(70/375 * 100vw);
                                      display: flex;
                                      align-items: center;

                                      img {
                                          width: 14.7px;
                                          object-fit: contain;
                                          height: auto;
                                          padding-left: 0;
                                          padding-right: 1.66px;
                                      }

                                      .menu-table-row-player-icon-text {
                                          font-family: 'Open Sans';
                                          font-size: 13px;
                                          font-style: normal;
                                          font-weight: 600;
                                          line-height: 160%;
                                          padding: 0;
                                          color: #fff;
                                      }
                                  }

                                  .ignore-list-body-column-ratings-md {
                                    width: 25%;
                                    font-family: 'Open Sans';
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 160%;
                                    color: #fff;
                                  }
                                  .ignore-list-body-column-balance-md {
                                    width: 25%;
                                    font-family: 'Open Sans';
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 160%;
                                    color: #fff;
                                  }

                                  .ignore-list-body-column-points-md {
                                      width: 25%;
                                      font-family: 'Open Sans';
                                      font-size: 13px;
                                      font-style: normal;
                                      font-weight: 600;
                                      line-height: 160%;
                                      color: #fff;
                                  }

                                  .ignore-list-body-column-games-md {
                                      width: 25%;
                                      font-family: 'Open Sans';
                                      font-size: 13px;
                                      font-style: normal;
                                      font-weight: 600;
                                      line-height: 160%;
                                      color: #fff;
                                  }

                              }
                          }
                      }
                  }
                  .ignore-list-body-column-send-md {
                    //width: calc(65/375 * 100vw);
                    width: 35px;
                    min-width: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &-button {
                      width: 38px;
                      height: 38px;
                      background-color: rgba(255, 255, 255, 0.2);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50%;
                      
                      & .menu-table-row-player-icon {
                        width: 36px;
                        height: 36px;

                      }
                    }
                }
              }
          }
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-friends-page {

      .layout-mobile-body-main {
        height: calc(100vh - 370px) !important;
        padding: 0 !important;

        &-title {
          display: block !important;
          color: #FFC900;
          font-size: var(--largeFontSize);
          font-weight: 600;
        }
        .friends-list {
          $rightSectionWidth: 70px;
          $midRightSectionWidth: 93px;
          $leftSectionWidth: 46px;

          overflow-x: auto;
          overflow-y: hidden;
          height: calc(100% - 63px);
          width: 100%;

          &-wrapper {
            min-width: 520px;
            max-width: 100%;
            height: 100%;
          }

          &-scrollarea {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            width: calc(100% - 4px);
            height: calc(100% - 50px) !important;
          }

          &-header {

            height: auto;
            // width: 100%;
            margin-left: 10px;
            margin-right: 18px;
            margin-bottom: 10px;
            font-size: var(--defaultFontSize);
            

            .left-section {
              display: inline-block;
              width: $leftSectionWidth;
              height: 100%;
            }

            .middle-section {
              display: inline-block;
              width: calc(100% - #{$leftSectionWidth} - #{$midRightSectionWidth} - #{$rightSectionWidth});
              height: 100%;
              vertical-align: bottom;

              .item {
                display: inline-block;
                height: 100%;

                &-rating {
                  width: 25%;
                }
                &-balance {
                  width: 30%;
                }
                &-points {
                  width: 16%;
                }
                &-gamesPlayed {
                  width: 29%;
                }
              }
            }
            .middle-right-section {
              display: inline-block;
              width: $midRightSectionWidth;
              height: 100%;
              vertical-align: bottom;
              text-align: center;
            }
            .right-section {
              display: inline-block;
              width: $rightSectionWidth;
              height: 100%;
              vertical-align: bottom;
              text-align: center;
            }
          }

          &-body {
            font-size: var(--smallFontSize);
            height: 100%;

            .left-section {
              padding-right: 12px;
              width: $leftSectionWidth;
              height: 100%;
              display: inline-block;

              .image-frame {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.2);
                display: flex;
                position: relative;

                &-light {
                  background-color: rgba(0, 0, 0, 0.6);
                }

                .profile-image {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
            }

            .middle-section {
              display: inline-block;
              width: calc(100% - #{$leftSectionWidth} - #{$midRightSectionWidth} - #{$rightSectionWidth});
              height: auto;

              .top-part {
                display: block;
                width: 100%;
                height: auto;

                .name-wrapper {
                  display: inline-block;
                  margin-right: 5px;
                }
                .rating-wrapper {
                  display: inline-block;
                }
              }
              .bottom-part {
                display: block;
                width: 100%;
                height: auto;

                .item {
                  display: inline-block;
                  height: 100%;

                  &-rating {
                    width: 25%;
                  }
                  &-balance {
                    width: 30%;
                  }
                  &-points {
                    width: 16%;
                  }
                  &-gamesPlayed {
                    width: 29%;
                  }

                  .icon {
                    display: inline-block;
                    padding-left: 2px;
                    padding-right: 2px;
                    width: auto;
                    height: 10px;
                    vertical-align: middle;

                    &-text {
                      display: inline-block;
                      text-align: left;
                    }
                  }
                }
              }
            }

            .middle-right-section {

              display: inline-block;
              width: $midRightSectionWidth;
              height: auto;
              vertical-align: bottom;
              text-align: center;

              .remove-friend-button {
                margin: 0px 21px;
              }
            }
            .right-section {
              display: inline-block;
              width: $rightSectionWidth;
              height: auto;
              vertical-align: bottom;

              .send-money-button {
                margin-right: auto;
                margin-left: auto;
                width: 35px;
                height: 35px;
                cursor: pointer;
              }
            }
          }
          .my-info-friends-scrollarea {
            max-height: calc(100% - 60px) !important;//
          }
        }

        & .ignore-list-header {
          display: flex !important;
          padding-left: calc(100vw * 40/375);

          &>div {
            color: rgba($color: #FFF, $alpha: 0.5);
            font-family: 'Futura PT';
            font-size: 12px;
            font-style: normal;
            font-weight: 450;
            line-height: 15.38px;
          }

          &-column-ratings-md {
            width: calc(40/375 * 100vw) !important;
          }
          &-column-balance-md {
            width: calc(40/375 * 100vw) !important;
          }
          &-column-points-md {
            width: calc(40/375 * 100vw) !important;
          }
          &-column-games-md {
            width: calc(60/375 * 100vw) !important;
          }
          &-column-send-md {
            width: calc(50/375 * 100vw) !important;
          }
        }

        .menu-table-row-wrapper {
          padding: 0 !important;
          margin-bottom: 1px;

          .menu-table-row-inner-wrapper {
              padding: 4px 2px 4px 3px;
              box-shadow: none !important;

              .ignore-table-row {
                display: flex;
                vertical-align: middle;
                height: 100%;
                align-items: center;

                  .ignore-list-body-position {
                      min-width: calc(26/375 * 100vw);
                      width: calc(26/375 * 100vw);
                      color: #FFF;
                      font-family: 'Futura PT';
                      font-size: 11px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 14.1px;

                      .layout-divider {
                          display: none;
                      }
                  }

                  .ignore-list-body-player {
                      width: auto;
                      min-width: auto;

                      .ignore-table-row-player {
                          max-width: unset;
                          min-height: auto;

                          .ignore-table-row-player-image-frame {
                              margin-right: 5px !important;
                              width: 36px;
                              height: 36px;
                              min-width: auto;
                              min-height: auto;
                              border-radius: 50%;

                              img {
                                width: 36px;
                                height: 36px;
                                min-width: auto;
                                min-height: auto;
                                border-radius: 50%;
                              }
                          }

                          &>.ignore-table-row-text {
                              display: none;
                          }

                          .ignore-table-row-mobile-text-group {
                              width: auto;
                              display: flex;
                              flex-direction: column;

                              .ignore-table-row-mobile-first-line {
                                  display: flex;
                                  align-self: flex-start;
                                  align-items: center;
                                  column-gap: 8px;

                                  .ignore-table-row-text {
                                      color: #fff;
                                      font-family: 'Open Sans';
                                      font-size: 13px;
                                      font-weight: 400;
                                      line-height: 160%;
                                  }

                                  .my-info-ratings-step-wrapper {
                                      width: auto;
                                      height: auto;

                                      .my-info-ratings-step-active {
                                          color: #FFF;
                                          font-family: 'Open Sans';
                                          font-size: 12px;
                                          font-style: normal;
                                          font-weight: 700;
                                          line-height: 160%;
                                          text-transform: uppercase;
                                          padding: 0 6px;
                                          border-radius: 4px;
                                      }
                                  }
                              }

                              .ignore-table-row-mobile-second-line-landing {
                                  width: auto;
                                  display: flex;

                                  .menu-table-row-player-icon-wrapper-landing:first-of-type {
                                      width: calc(40/375 * 100vw);
                                      display: flex;
                                      align-items: center;

                                      img {
                                          width: 15px;
                                          object-fit: contain;
                                          height: auto;
                                          padding-right: 3px;
                                          padding-left: 0;
                                      }

                                      .menu-table-row-player-icon-text {
                                          font-family: 'Open Sans';
                                          font-size: 13px;
                                          font-style: normal;
                                          font-weight: 600;
                                          line-height: 160%;
                                          color: #fff;
                                          padding: 0;
                                      }
                                  }

                                  .menu-table-row-player-icon-wrapper-landing:nth-of-type(2) {
                                      width: calc(40/375 * 100vw);
                                      display: flex;
                                      align-items: center;

                                      img {
                                          width: 14.7px;
                                          object-fit: contain;
                                          height: auto;
                                          padding-left: 0;
                                          padding-right: 1.66px;
                                      }

                                      .menu-table-row-player-icon-text {
                                          font-family: 'Open Sans';
                                          font-size: 13px;
                                          font-style: normal;
                                          font-weight: 600;
                                          line-height: 160%;
                                          padding: 0;
                                          color: #fff;
                                      }
                                  }

                                  .ignore-list-body-column-points-md {
                                      width: calc(40/375 * 100vw);
                                      font-family: 'Open Sans';
                                      font-size: 13px;
                                      font-style: normal;
                                      font-weight: 600;
                                      line-height: 160%;
                                      color: #fff;
                                  }

                                  .ignore-list-body-column-games-md {
                                      width: calc(65/375 * 100vw);
                                      font-family: 'Open Sans';
                                      font-size: 13px;
                                      font-style: normal;
                                      font-weight: 600;
                                      line-height: 160%;
                                      color: #fff;
                                  }
                              }
                          }
                      }
                  }
                  .ignore-list-body-column-send-md {
                    width: calc(55/375 * 100vw);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &-button {
                      width: 38px;
                      height: 38px;
                      background-color: rgba(255, 255, 255, 0.2);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50%;
                      
                      & .menu-table-row-player-icon {
                        width: 36px;
                        height: 36px;

                      }
                    }
                }
              }
          }
        }
      }
    }
  }
}

.root-class-design20-normal { 
  .layout-mobile-body-friends-page {

    .layout-mobile-body-main {
      &-title {
        color: #FFC900;
      }
    }
  }
}
.root-class-design20-light {
  .layout-mobile-body-friends-page {

    .layout-mobile-body-main {

      & .ignore-list-header {

        &>div {
          color: rgba($color: #000, $alpha: 0.5);
        }
      }

      .menu-table-row-wrapper {

        .menu-table-row-inner-wrapper {
           
            .ignore-table-row {

                .ignore-list-body-position {
                    color: #0C0C0C;
                }

                .ignore-list-body-player {
                   
                    .ignore-table-row-player {

                        .ignore-table-row-mobile-text-group {
                          
                            .ignore-table-row-mobile-first-line {

                                .ignore-table-row-text {
                                    color: #0C0C0C;
                                }

                                .my-info-ratings-step-wrapper {

                                    .my-info-ratings-step-active {
                                        color: #0C0C0C;
                                    }
                                }
                            }

                            .ignore-table-row-mobile-second-line-landing {

                                .menu-table-row-player-icon-wrapper-landing:first-of-type {
                                    .menu-table-row-player-icon-text {
                                        color: #0C0C0C;
                                    }
                                }

                                .menu-table-row-player-icon-wrapper-landing:nth-of-type(2) {
                                    .menu-table-row-player-icon-text {
                                        color: #0C0C0C;
                                    }
                                }

                                .ignore-list-body-column-date-md {
                                    color: #0C0C0C;
                                }
                            }
                        }
                    }
                }
                .ignore-list-body-column-unblock-md {
                  width: calc(35/375 * 100vw);
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
            }
        }
      }
    }
  }
  @media screen and (orientation : portrait) {
    .layout-mobile-body-friends-page {

      .layout-mobile-body-main {
        
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-friends-page {

      .layout-mobile-body-main {
        &-title {
          display: block !important;
          color: #FF8A1A;
          font-size: var(--largeFontSize);
          font-weight: 600;
        }
      }
    }
  }
}
