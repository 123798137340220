
$sendGiftButtonColor: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
$sendGiftBorder: 1px solid #0C0C0C;

.root-class-design20 {

  .send-gifts-scrollarea {
    margin-top: 10px;
    height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .send-gifts {

    &-confirmation {
      display: flex;
      align-items: center;
      text-align: center;
    }

    padding: 10px 25px;

    &-player {
      min-height: 7vmin;

      & .col {
        padding: 0;
      }

      & .switch {
        margin-bottom: 1.5vmin;
        margin-right: 10px;
      }

      &-name {
        font-size: var(--mSmallFontSize);
        display: inline-block;
        vertical-align: middle;
        line-height: 3vmin;
        margin-bottom: 1.5vmin;
        opacity: 0.7;
      }

      &-image {
        height: 3vmin;
        width: 3vmin;
        border-radius: 50%;
        margin-right: 10px;

        &-wrapper {
          display: inline-block;
          opacity: 0.7;
        }
      }

      &-everyone-image-wrapper {
        padding-right: 0px !important;
        opacity: 0.7;
        // & .send-gifts-player-image-wrapper {
        //   position: absolute;
        //   left: 0px;
        //   top
        // }
        & .send-gifts-player-image-wrapper:not(:first-child) {
          margin-left: -18px;
        }
      }

      &-checked {
        opacity: 1;
        color: #FFC900 !important;
      }
    }

    &-comment {
      background: #FFFFFF;
      color: #000000;
      height: 40px;
      width: 60%;
      margin-left: auto;
      margin-right: auto;

      border: 1px solid #868686;
      box-sizing: border-box;
      border-radius: 25px;

      &::placeholder {
        color: #868686;
      }

      &-length {
        position: absolute;
        z-index: 100;
        right: calc(20% + 30px);
        bottom: 0;
        color: #868686;
      }
    }
  }

  .gifts-gift {
    background-image: url('../../../../../images/redesign/my-info/my-info-gift-bg.png');
    background-size: 100% auto;
    width: 16%;
    height: 100px;
    margin: 2%;
  //  height: 8vmin;

    &-selected {
    //  background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
    //  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
    }

    &-wrapper {
      position: relative;
      width: 16%;
      height: auto;
    }

    &-image {
      height: 90%;
      width: 75%;
      max-height: 180px;
      padding-top: 12.5%;
      margin-left: 12.5%;
      bottom: unset;
    }

    &-select-button {
      width: 100%;
      margin: auto;
      position: absolute;
      bottom: 40px;
      right: 0;
      left: 0;

      height: 35px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: $primaryTextColour;

      background: $sendGiftButtonColor;
      border: $sendGiftBorder;
      box-sizing: border-box;
      border-radius: 99px;
      box-shadow: none;

      &:focus {
      //  background: $sendGiftButtonColor;
      //  border: 1px solid #0C0C0C;
      //  box-shadow: none;

        background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
      }

      &:active {
      //  background: $sendGiftButtonColor;
      //  border: 1px solid #0C0C0C;
      //  box-shadow: none;

        background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
      }

      &:hover {
      //  background: $sendGiftButtonColor;
      //  border: 1px solid #0C0C0C;
      //  box-shadow: none;

        background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
      }

      &-active {
        background: linear-gradient(180deg, #ff0000 0%, #ff8100 100%) !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
      }

      &-price {
        display: inline-block;
      }

      &-coin {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-left: 2px;
      }
    }



  }

  .gifts-gift-alt {
    width: 16% !important;
  }

  .send-gifts-list-mobile-tab {
    & .custom-dropdown {
      width: 100%;

      & button {
        width: 100%;
        margin: 12px 0px;
        opacity: 1;
      }

      & button::before {
        display: none;
      }
      & button::after {
        display: inline-block;
        content: ' ';
        background-image: unset !important;
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        margin-right: 6px;
        margin-left: 12px;
        vertical-align: middle;
        margin-bottom: 21px;
        top: 22px;
      }

      & .landing-help-page-dropdown-list {
        width: 100%;
      }
    }
  }
}
