


@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width:  $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top:  50%;
    left: 50%;
    width:  $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform:
          rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}


.root-class-design20 {

  .app-wrapper {
    min-height: unset;

    width: 99%!important;
    height: 100vh; //99%

    min-width: 65vw;
    //min-height: 710px;
    padding-right: 45px;
  //  max-width: 2160px;
    max-width: unset;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    padding: 0;
  }

  .three-player-table {
    width: 100%;
    height: 100vh;

    //  min-width: 450px;

    position: relative;
    margin: 0!important;

    & > .row {

      width: 75%;
      margin-left: 12.5%;
      height: 100%;
      position: relative;

      @media screen and (max-aspect-ratio: 16/2) {
        width: 30%;
        margin-left: 35%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/4) {
        width: 40%;
        margin-left: 30%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/5) {
        width: 42%;
        margin-left: 29%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/6) {
        width: 50%;
        margin-left: 25%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/7) {
        width: 54%;
        margin-left: 23%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/8) {
        width: 58%;
        margin-left: 21%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/10) {
        width: 65%;
        margin-left: 17%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/11) {
        width: 70%;
        margin-left: 15%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/12) {
        width: 80%;
        margin-left: 9.5%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/14) {
        width: 95%;
        margin-left: 2.5%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 1/1) {
        width: 100%;
        margin-left: 0;
        height: 100%;
      }

      @media all and (orientation: portrait) {
        width: 100%;
      }
    }



    .circle{
    //  position:relative;
    //  width:5%;padding-bottom:50%;
    //  margin-left:47.5%;
      -webkit-transform : rotate(280deg);
      -ms-transform : rotate(280deg);
      transform : rotate(340deg);
      }
      .circle div {
          position:absolute;
          top:0; left:0;
          width:100%; height:100%;
          -webkit-transform : rotate(20deg);
          -ms-transform : rotate(20deg);
          transform : rotate(20deg);
      }
      .circle:before, .circle div:before {
          content:'';
        //  position:absolute;
        //  top:0; left:0;
          width:100%; padding-bottom:100%;
          border-radius: 100%;
          border: 2px solid teal;
          background:gold;
      }
  }

  .four-player-table {
    width: 100%;
    height: 100vh;

    //  min-width: 450px;

    position: relative;
    margin: 0!important;

    & > .row {

      width: 75%;
      margin-left: 12.5%;
      height: 100%;
      position: relative;

      @media screen and (max-aspect-ratio: 16/2) {
        width: 30%;
        margin-left: 35%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/4) {
        width: 40%;
        margin-left: 30%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/5) {
        width: 42%;
        margin-left: 29%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/6) {
        width: 50%;
        margin-left: 25%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/7) {
        width: 54%;
        margin-left: 23%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/8) {
        width: 58%;
        margin-left: 21%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/10) {
        width: 65%;
        margin-left: 17%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/11) {
        width: 70%;
        margin-left: 15%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/12) {
        width: 80%;
        margin-left: 9.5%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 16/14) {
        width: 95%;
        margin-left: 2.5%;
        height: 100%;
      }

      @media screen and (max-aspect-ratio: 1/1) {
        width: 100%;
        margin-left: 0;
        height: 100%;
      }

      @media all and (orientation: portrait) {
        width: 100%;
      }
    }



    .circle{
    //  position:relative;
    //  width:5%;padding-bottom:50%;
    //  margin-left:47.5%;
      -webkit-transform : rotate(280deg);
      -ms-transform : rotate(280deg);
      transform : rotate(340deg);
      }
      .circle div {
          position:absolute;
          top:0; left:0;
          width:100%; height:100%;
          -webkit-transform : rotate(20deg);
          -ms-transform : rotate(20deg);
          transform : rotate(20deg);
      }
      .circle:before, .circle div:before {
          content:'';
        //  position:absolute;
        //  top:0; left:0;
          width:100%; padding-bottom:100%;
          border-radius: 100%;
          border: 2px solid teal;
          background:gold;
      }
  }

  .new-design-automation {
    z-index: 999999;
    width: 100%;
    height: 100%;
    background: rgba(130,130,130,0.8);
    position: absolute;
    &-section {
      position: relative;
      width: 70%;
      margin-right: auto;
      margin-left: auto;
      max-width: 300px;
      left: 0px;
      top: 40%;
      z-index: 999;
      color: #FFF;
      text-align: center;
      padding: 7px 15px 0px 15px;
      background: #000000;
      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
      border-radius: 17px;
      border: none;

    }

    &-mobile {
      //top: calc(100vh / 2 - 100px);
      left: 0px;
      width: 100vw;
      & .new-design-automation-section {
        width: 100vw;
        left: unset;
        margin: 0 auto;
      }
    }
  }
}
