$mobileLandScapeWidth: 1280px;

@media screen and (orientation : portrait) {
    .root-class-design20 {
        .landing-mobile-helpPage-header {
            display: none !important;
        }

        .new-design-landing-container {
            .zole-rules-container {
                background-color: #0C0C0C !important;
                min-height: calc(100vh - 57px);

                .zole-rules-page {
                    margin-top: 0;
                    min-height: auto;
                    margin-bottom: auto;
                    margin-left: 0;
                    margin-right: 0;
                    padding-top: 14px !important;
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                    padding-bottom: 100px !important;

                    &>div:first-of-type {
                        padding-left: 0;
                        padding-right: 0;

                        .zole-rules-page-title {
                            margin-left: 0;
                            height: auto;
                            color: #FFF;
                            font-family: 'Open Sans';
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 32.68px;
                            margin-bottom: 19px;
                        }
                    }

                    .zole-rules-page-mobile-header {
                        height: auto;
                        margin-left: 0;
                        padding-left: 0;
                        padding-right: 0;

                        .zole-rules-page-header-tab {
                            .custom-dropdown {
                                .custom-dropdown-toggle {
                                    border-radius: 25px;
                                    border: 1px solid rgba(255, 255, 255, 0.20) !important;
                                    padding: 11px 17px;
                                    color: #FFF;
                                    font-family: 'Open Sans';
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 16.34px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    &::after {
                                        content: "";
                                        display: inline-block;
                                        margin-top: 0;
                                        border-top: 5px solid;
                                        border-right: 6px solid transparent;
                                        border-left: 6px solid transparent;
                                        border-bottom: 0;
                                        background: unset;
                                        background-repeat: unset;
                                        background-position: unset;
                                        width: unset;
                                        height: unset;
                                        vertical-align: middle;
                                        background-size: unset;
                                        margin-left: 0;
                                        float: right;
                                    }
                                }

                                .custom-dropdown-menu {
                                    overflow-x: hidden;
                                    overflow-y: auto;

                                    &::-webkit-scrollbar {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }

                    &>div:nth-of-type(3) {
                        padding-left: 0;
                        padding-right: 0;

                        .zole-rules-page-sub-title {
                            padding-top: 16px;
                            margin-left: 0;
                            margin-bottom: 0;
                            color: #FFF;
                            font-family: 'Open Sans';
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 27.24px;
                        }
                    }

                    .zole-rules-page-content {
                        margin-top: 6px;
                        padding-right: 0 !important;
                        height: auto;
                        width: 100%;
                        padding-left: 0;
                        margin-bottom: 0;

                        .zole-rules-page-content-tab {
                            .tab-pane {
                                .mobile-help-content {
                                    &>.row {
                                        margin-left: 0;
                                        margin-right: 0;
                                        width: 100%;

                                        .zole-rules-page-content-wrapper {
                                            padding-left: 0;

                                            table,
                                            th,
                                            td {
                                                border: 1px solid rgba(255, 255, 255, 0.2);
                                                text-align: center;
                                            }

                                            table {
                                                width: calc(100vw - 40px);
                                                table-layout: fixed;

                                                th,
                                                td {
                                                    width: auto;
                                                    word-wrap: break-word;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .root-class-design20 {
        .landing-mobile-helpPage-header {
            display: none !important;
        }

        .new-design-landing-container {
            .zole-rules-container {
                background-color: #0C0C0C !important;
                min-height: 100vh;
                width: calc(100vw - 57px);
                display: block;

                .zole-rules-page {
                    width: 100%;
                    margin-top: 0;
                    min-height: auto;
                    margin-bottom: auto;
                    margin-left: 0;
                    margin-right: 0;
                    padding-top: 14px !important;
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                    padding-bottom: 100px !important;

                    &>div:first-of-type {
                        padding-left: 0;
                        padding-right: 0;

                        .zole-rules-page-title {
                            margin-left: 0;
                            height: auto;
                            color: #FFF;
                            font-family: 'Open Sans';
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 32.68px;
                            margin-bottom: 19px;
                        }
                    }

                    .zole-rules-page-header {
                        display: none;
                    }

                    .zole-rules-page-mobile-header {
                        height: auto;
                        margin-left: 0;
                        padding-left: 0;
                        padding-right: 0;
                        display: block;

                        .zole-rules-page-header-tab {
                            .custom-dropdown {
                                .custom-dropdown-toggle {
                                    border-radius: 25px;
                                    border: 1px solid rgba(255, 255, 255, 0.20) !important;
                                    padding: 11px 17px;
                                    color: #FFF;
                                    font-family: 'Open Sans';
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 16.34px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    width: 100%;
                                    opacity: 1;

                                    &::after {
                                        content: "";
                                        display: inline-block;
                                        margin-top: 0;
                                        border-top: 5px solid;
                                        border-right: 6px solid transparent;
                                        border-left: 6px solid transparent;
                                        border-bottom: 0;
                                        background: unset;
                                        background-repeat: unset;
                                        background-position: unset;
                                        width: unset;
                                        height: unset;
                                        vertical-align: middle;
                                        background-size: unset;
                                        margin-left: 0;
                                    }
                                }

                                .custom-dropdown-menu {
                                    overflow-x: hidden;
                                    overflow-y: auto;
                                    width: 100%;
                                    max-height: 200px;

                                    &::-webkit-scrollbar {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }

                    &>div:nth-of-type(3) {
                        padding-left: 0;
                        padding-right: 0;

                        .zole-rules-page-sub-title {
                            padding-top: 16px;
                            margin-left: 0;
                            margin-bottom: 0;
                            color: #FFF;
                            font-family: 'Open Sans';
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 27.24px;
                        }
                    }

                    .zole-rules-page-content {
                        margin-top: 6px;
                        padding-right: 0 !important;
                        height: auto;
                        width: 100%;
                        max-width: unset;
                        flex: unset;
                        padding-left: 0;
                        margin-bottom: 0;

                        .zole-rules-page-content-tab {
                            .tab-pane {
                                .help-content {
                                    display: none;
                                }

                                .mobile-help-content {
                                    display: block;

                                    &>.row {
                                        margin-left: 0;
                                        margin-right: 0;
                                        width: 100%;

                                        .zole-rules-page-content-wrapper {
                                            padding-left: 0;
                                            padding-right: 0;

                                            table,
                                            th,
                                            td {
                                                border: 1px solid rgba(255, 255, 255, 0.2);
                                                text-align: center;
                                            }

                                            table {
                                                width: calc(100vw - 103px);
                                                table-layout: fixed;

                                                th,
                                                td {
                                                    width: auto;
                                                    word-wrap: break-word;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
