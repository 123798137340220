$sectionDividerSize: 0.7vmin;
.lobby-background {
  // @include background-2x( '../../../images/Menu/zole_bg', 'png', 100%, 100%, top center, no-repeat );
  //  background-image: url('../../../../images/Menu/zole_bg.png');
  background-size: contain;
  background-position: top center;
  width: 100%;
  height: 100%;
  position: fixed;
  top:0px;
  left: 0px;
}

.content {
  &-left {
    margin-top: 27px;
    width: 285px;
    float: left;

    &-compact {
      margin-top: 13px;
    }
  }

  &-right {
    width: calc(100% - 310px);
    float: right;

    &-draugiem-iframe {
      position: fixed;
      width: calc(100% - 110px) !important;
      right: 50px;
    }
  }
}

.old-design-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: none;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 0px;
  }
}

.avoidwrap { display:inline-block; }

.app-wrapper-fullscreen {


  & .rules-page-content-tab {
  //  min-height: 80vh;
    height: 80vh;
    max-height: 80vh;
  }

  & .rules-page-header-tab {
  //  min-height: 80vh;
    height: 80vh;
    max-height: 80vh;
  }

//  overflow-y: scroll!important;
//  overflow-x: hidden!important;

  &-menu {
    min-width: 740px!important;
  }

  .content {
    height: 80vh;
    min-height: 600px;

    &-left {
      margin-top: 28px;
      width: 285px;
      float: left;

      @include lt-md {
        width: 200px;

        & .start-new-game {
          & .sng-choose-bet-label, .sng-choose-bet-label, .game-type-text {
            font-size: var(--defaultFontSize);
          }
        }

        & .online-stats {
          line-height: 5px;
          // white-space: normal;
        }
      }
    }

    &-right {
      width: calc(100% - 310px);
      float: right;

      @include lt-md {
        width: calc(100% - 215px);

        & .start-new-game {
          & .sng-choose-bet-label, .sng-choose-bet-label, .game-type-text {
            font-size: var(--defaultFontSize);
          }
        }

        & .online-stats {
          line-height: 15px;
          white-space: normal;
        }
      }
    }
  }

  .bottom-bar {
    position: absolute;
  //  height: 16vh;
    // height: 6vh;
    // margin-top: 10vh;
    width: 100%;
    bottom: 0px;
    left: 0px;

    &-rules-page {
      position: relative;
      height: 6vh;
      margin-top: 1vh;
    //  margin-top: 10vh;
      width: 100%;
    }

    &-links {
      position: absolute;
      bottom: 0px;
      width: 100%;
    }
  }

  .dropdown {
    button{
    //  min-width: 50px;
      min-width: 100%;
    }
  }

  .top-select {
    max-width: 100%;
    min-width: 110px;

    button:focus {
      background: #2e5a56;
      border: 1px solid #fff;
    }
    button:hover {
      color: #FDD55D;
      border: 1px solid #FDD55D;
    }
    .dropdown-menu {
      min-width: 300px;
    }
    &-ok {
      background: rgba(67, 130, 124, 0.7) !important;
      text-transform: uppercase;
    }

    &-sorting-param {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid white;
      float: right;
      display: inline-block;
      margin-right: 2px;
      margin-top: 8px;
    }

    &-radio {
      position: relative;
      float: right;
      //right: 5;
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid white;
      top: 5px;

      &-checked {
        border-radius: 50%;
        background: white;
        width: 6px;
        height: 6px;
        top: 2px;
        margin: auto;
        position: relative;
      }
    }
  }

  .menu-topTab {
    width: 20%;
    &-wrapper {
      @include lt-md {
        font-size: var(--mSmallFontSize);
      }

      @include md {
        font-size: var(--defaultFontSize);
      }
    }
  }

  .menu-top-tab {
    position: relative;
    .virtual-menu-top-tab {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
    }
  }

  .top-millionaires-button-active {
    color: #fff;
    background-color: #380000;
    border-color: #fff;
    box-shadow: none;
  }

  .main-tab-pane {
  //  max-height: 45vh;
  //  height: 45vh;

    .rooms-table {
      &-scrollarea {
        height: 100% !important;
        & > div {
          // height: 100%;
          .rooms-table-empty-entries-old {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
        }
      }

      &-header-text {
        @include lt-md {
          font-size: var(--defaultFontSize);
        }

        @include md {
          font-size: var(--headerFontSize);
          // font-size: 17px;
        }
      }

      & .dropdown-toggle {

        @include lt-sm {
          font-size: var(--smallFontSize);
          padding: 5px 3px;
        }

        @include lt-md {
          font-size: var(--mSmallFontSize);
        }

        @include md {
          font-size: var(--defaultFontSize);
          // font-size: 13px;
        }

      }

      &-select-type {
        margin-bottom: 2px;
        padding-right: 5px;
      }

      &-select-bet {
        margin-top: 2px;
        padding-left: 5px;
      }

      &-body {
        @include lt-md {
          font-size: var(--smallFontSize);
        }

        @include md {
          font-size: var(--mSmallFontSize);
        }
      }

      &-player-name {
        @include lt-md {
          font-size: var(--smallFontSize);
          max-width: 100%;
          margin-left: 0;
        }
      }

      &-player-lvl {
        @include lt-md {
          width: 16px;
          height: 16px;
          display: none;
        //  top: 4px;
        }

        &-text {
          @include lt-md {
            font-size: var(--smallFontSize);
            line-height: 14px;
          }
        }
      }

      & .gameType {
        @include lt-md {
          font-size: var(--smallFontSize);
          width: 22px;
          height: 18px;
        }

        &-text {
          @include lt-md {
            font-size: var(--smallFontSize);
            width: 22px;
            height: 18px;
            line-height: 18px;
          }
        }
      }

      & .join-room-button {
        @include lt-md {
          font-size: var(--defaultFontSize);
        }
      }

      & .filter-room-type-option {
        background: rgba(#380000, 0.7) !important;
      }

      & .filter-room-type-option-ok {
        background: rgba(#43827c, 0.7) !important;
        text-transform: uppercase;
      }
    }

    .top {
    //  max-height: 50vh;
    //  height: 50vh;
    //  min-height: 390px;

      // max-height: 70vh;
      // height: 70vh;
      // min-height: 60vh;
      height: 100%;

      &-scrollarea {
        max-height: 60vh;
        height: 52vh;
        min-height: 35vh;
      }

      &-header-text {
        @include xs {
          font-size: var(--smallFontSize);
        }

        @include sm {
          font-size: var(--defaultFontSize);
        }

        @include md {
          font-size: var(--largeFontSize);
        }
      }

      & .dropdown-toggle {
        @include xs {
          font-size: var(--smallFontSize);
        }

        @include sm {
          font-size: var(--defaultFontSize);
        }

        @include md {
          font-size: var(--largeFontSize);
        }
      }

      & .menu-topTab-wrapper {
        @include xs {
          font-size: var(--smallFontSize);
        }

        @include sm {
          font-size: var(--defaultFontSize);
        }

        @include md {
          font-size: var(--largeFontSize);
        }
      }
    }

    .my-info {
    //  max-height: 60vh;
    //  height: 60vh;
    //  min-height: 390px;

      // max-height: 70vh;
      // height: 70vh;
      // min-height: 60vh;

      &-scores {
        & .text, .textData {
          @include lt-md {
            font-size: var(--xSmallFontSize);
            padding-left: 5px;
            padding-right: 5px;
          }

          @include md {
            font-size: var(--smallFontSize);
          }
        }
      }

      &-tournaments {
        &-header {
          @include lt-md {
            font-size: var(--xSmallFontSize);
          }

          @include md {
            font-size: var(--smallFontSize);
          }
        }
      }

      & .levelProgress-text {
        @include lt-md {
          font-size: var(--defaultFontSize);

          &-yellow {
            font-size: var(--mSmallFontSize);
          }
        }
      }

      & .giftsHistory-header {
        @include lt-md {
          font-size: var(--defaultFontSize);
        }
      }

      &-header-text {
        @include xs {
          font-size: var(--smallFontSize);
        }

        @include sm {
          font-size: var(--defaultFontSize);
        }

        @include md {
          font-size: var(--largeFontSize);
        }
      }

      &-header-button {
        @include xs {
          font-size: var(--xSmallFontSize);
          // font-size: 7px;
          padding-left: 1px;
          padding-right: 1px;
        }

        @include sm {
          font-size: var(--xSmallFontSize);
          padding-left: 1px;
          padding-right: 1px;

          &::after {
            width: 6px;
            height: 6px;
            margin-left: 0px;
          }
        }

        @include md {
          font-size: var(--mSmallFontSize);
        }
      }
    }

    .tournaments {
      &-filter {
        font-size: var(--defaultFontSize);
      }
    //  max-height: 60vh;
    //  height: 60vh;
    //  min-height: 390px;

      // max-height: 70vh;
      // height: 70vh;
      // min-height: 50vh;

      &-scrollarea {
        // max-height: 60vh;
        // height: 50vh;
        // min-height: 40vh;

        &-body {
          height: 100%;
        }

        & > div {
          // height: 100%;
          .future-tournaments-empty-entries-old {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin-top: 30px;
          }
        }
        .tournaments-scrollarea-body-past {
          margin-right: 15px;
        }
        &-font-based-margin-xLarge {
          margin-top: 35px;
        }
      }

      &-header-text {
        @include xs {
          font-size: var(--smallFontSize);
        }

        @include sm {
          font-size: var(--defaultFontSize);
        }

        @include md {
          font-size: var(--largeFontSize);
        }
      }

      &-header-button {
        @include xs {
          font-size: var(--xSmallFontSize);
          padding-left: 2px;
          padding-right: 2px;
        }

        @include sm {
          font-size: var(--xSmallFontSize);
          // font-size: 9px;
          padding-left: 1px;
          padding-right: 1px;
        }

        @include md {
          font-size: var(--mSmallFontSize);
        }
      }
    }

   .top-scrollarea {
    //  max-height: 50vh;
    //  height: 50vh;
    //  min-height: 390px;
      // max-height: 60vh;
      // height: 52vh;
      // min-height: 50vh;
    }

    .top-scrollarea-body {
      height: auto;
    }

    .ignored-users-table {
      height: 60vh;
      min-height: 390px;
    }

    .weekly-statistics-table {
      height: 60vh;
      min-height: 390px;
    }

    .ach-table-scrollarea {
      height: 60vh;
      min-height: 390px;
    }

    .friends-table {
      height: calc(100vh - 440px);
      min-height: 360px;
      overflow: auto;
    }

    .buy-page {
      height: 60vh;
      min-height: 390px;
    }

    .bonus-page {
      height: 60vh;
      min-height: 390px;
    }

    .game-history-table {
      height: 60vh;
      // min-height: 360px;

      & .rs-table {
      //  height: 100%!important;
      }
    }

  //  .tournaments {
    //  height: 100%;
    //  min-height: 390px;
  //  }
  }


}

.app-wrapper {
  .main-tab-pane {
    .rooms-table {
      &-select-type,
      &-select-bet {
        & .dropdown {
          & .dropdown-toggle {
            max-width:100%;
            position: relative;

            & .item-wrapper {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: calc(100% - 12px);
            }
          }
        }
      }

      & .filter-room-type-menu {
        margin-right: 5px;
      }
    }
  }
}

.main-tab-pane {
  min-height: 400px;
}

.blocked-button {
  padding: 15px;

  &-wrapper {
    margin-top: 25px;

    background-color: #2c5955;
    border-radius: 25px;
    font-size: var(--largeFontSize);
    // font-size: 15px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    box-shadow: 5px 5px 0 0 #380000;
    cursor: pointer;
  }
  &-wrapper:hover {
    color: #FFD34E;
    border: 1px solid #FFD34E;
    border-radius: 25px;
  }
}

.sound-button {
//  position: absolute;

//  top: 90px;
//  right: -15px;

//  top: 60px;
//  right: 45px;
  z-index: 100;

  position: relative;
  display: inline-block;
  top: 7px;
  left: 38px;

  &-settings {
    &-normal {
      left: -5px;
      top: 0px;
    }
    &-large {
      left: -5px;
      top: 2px;
    }
    &-xLarge {
      left: -5px;
      top: 2px;
    }
  }

  &-inbox {
    right: 10px;
  //  top: 80px;
  }

  &-room {
    position: absolute;
    top: 60px;
    right: 15px;
    z-index: 100;
  }

  &-image {
    width: 28px;
    padding-left: 3px;
    cursor: pointer;
  }
}

.user-settings {

  &-header {
    font-size: var(--mLargeFontSize);
    margin-bottom: 5px;
  }

  &-wrapper {
    position: absolute;
  //  top: 120px;
    top: 15px;
    right: -20px;
    z-index: 999;

    &-game {
      top: 55px;
    }

    &-font-mode {
      margin-top: 10px;
      margin-left: -2px;
      height: 35px;
      width: 33px;
      object-fit: contain;
      z-index: 999;
      position: relative;
      display: block;
    }
  }

  &-space {
    height: 20px;
  }

  &-toggles {
    position: relative;
    // bottom: 26px;
  }

  &-button {
  //  position: absolute;
  //  top: 55px;
  //  right: -20px;
    position: relative;
    display: block;
    margin-top: 15px;

    width: 20px;
    min-width: 1.6vmin;
    // -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    // filter: invert(100%);
    cursor: pointer;
    z-index: 999;

    &-game {
      right: 0px;
    }
  }

  &-divider {
    width: 100%;
    padding-bottom: 6px;
    margin-bottom: 4px;
    border-bottom: 1px solid #FFF;
  }

  &-label {
    margin-left: 16px;
    font-size: var(--largeFontSize);
    &-margin {
      margin-top: 0.5rem;
    }
  }

  &-checkbox {
    position: relative;
    margin-left: 0;
    margin-top: 0 !important;
    vertical-align: middle;

  }
  &-list {
    padding-left: 0px !important;
  }

  &-play {
    font-size: var(--defaultFontSize) !important;
    vertical-align: middle;

    &-normal {
      padding-right: 0px;
    }

    &-large {
      padding-right: 30px;
    }

    &-xLarge {
      padding-right: 30px;
    }
  }
}

.room-ended-modal {
  &-message {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 25px;

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: var(--headerFontSize);
    }

    &-coins {
      margin-top: 5px;
      margin-bottom: 5px;

      &-coin {
        display: inline-block;
        width: 28px;
        height: 28px;
        margin-right: 6px;
      }

      &-label {
        display: inline-block;
        font-size: var(--largeFontSize);
        vertical-align: center;
      }
    }

    &-description {
      font-size: var(--defaultFontSize);
      font-style: normal;
      font-weight: 400;
    }
  }

  &-buy-options {
    margin-bottom: 25px;
  }

  &-money-pack {
    margin-top: 35px;
  }
}

.glitter-anim-image {
  position: absolute;
  z-index: 999;
  & div {
    cursor: default;
  }
}

.fullscreen-button {
//  position: absolute;
//  top: 95px;
//  right: -15px;

  position: relative;
  display: block;
  z-index: 950;
  margin-top: 10px;

  &-game {
    right: 0px;
  }

  &-image {
    width: 25px;
  //  height: 25px;
    height: auto;
    min-width: 1.6vmin;
    cursor: pointer;

    &-iframe {
      position: absolute;
      top: 3px;
      right: 51px;

      @media screen and (orientation : portrait) {
        display: none !important;
      }

      @media screen and (orientation : landscape) and (max-width: 1280px) {
        display: none !important;
      }
    }
  }
}

.language {
//  position: absolute;
//  top: 48px;
//  right: -20px;

//  top: 12px;
//  right: 40px;

  z-index: 999;
  width: 50px;

  position: relative;
  display: inline-block;
  // top: 4px;
  // left: 20px;
  top: 0px;
  left: -2px;

  &-settings {
    &-normal {
      top: -5px !important;
      left: -7px !important;
    }
    &-large {
      top: -4px !important;
      left: -7px !important;
    }
    &-xLarge {
      top: -3px !important;
      left: -7px !important;
    }
  }

  &-inbox {
    right: 0px;
    top: 40px;

    & .dropdown-toggle {
      padding-bottom: 0px;
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  & .dropdown {
    & button {
      border: 0!important;
      background: none!important;
    //  padding-left: 6px;
      min-width: 40px;

      &::after {
        background: none;
        width: 0px;
        height: 0px;
        margin: 0;
      }
    }

    & .dropdown-menu {
      min-width: 1rem;
      padding: 0;
      left: 6px!important;
      top: -10px!important;

      & .dropdown-item {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  &-image {
  //  width: 43px;
  //  height: 25px;
    display: inline-block;
  }

  &-select {
    padding: 0;
  }
}

.menu-myInfoTab {
  max-width: calc(100% / 7);
}

.menu-topTab {
  padding-left: 5px;
  padding-right: 5px;

  & .active {
    .menu-topTab-link {

      &:hover {
        color: #FFF;
        text-decoration: none;
      }

      &:focus {
        color: #FFF;
        text-decoration: none;
      }
    }
  }


  &-link {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    height: auto;
    font-size: var(--largeFontSize);
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding: 0;
    word-break: break-word;
    line-height: 15px;
    min-height: 36px;

    &:hover {
      color: #FFD34E;
      // border-color: #FFD34E;
      // background-color: #2c5955;
      text-decoration: none;
    }

    &:focus {
      color: #FFD34E;
      text-decoration: none;
    }

    &:active {
      color: #fff;
      //  background-color: #380000;
      //  -webkit-box-shadow: none;
      //  -moz-box-shadow: none;
      //  box-shadow: none;
    }


    &-notification::after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #C54D4D;
      position: absolute;
      border-radius: 50%;
      margin-top: 3px;
      margin-left: 5px;
    }
  }

  &-wrapper {
    background-color: #2c5955;
    border-radius: 15px;
    // height: 30px;
    font-size: var(--largeFontSize);
    // font-size: 15px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    -webkit-box-shadow: 5px 5px 0px 0px #380000;
    -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 0px 0px #380000;
    cursor: pointer;
    color: #FFF;
    padding: 3px 12px !important;

    &:hover {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #2c5955;
      text-decoration: none;
    }

    &:focus {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #2c5955;
      text-decoration: none;
    }

    &:active {
      color: #fff;
      background-color: #380000;
      border-color: #fff;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  & .active {
    color: #fff;
    background-color: #380000;
    border-color: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.rooms-table-section {
  max-width: 100%;
  height: calc(65vh + 65px);
}

.rooms-table {
  width: 100%;
  table-layout: fixed;
  padding: 15px 10px 15px 25px;
  //  background: linear-gradient(180deg, #000 5%, #000 10%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);
  border-radius: 15px;
  color: #fff;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  & .virtual-rooms-table {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 200px;
  }

  &-wrapper {
    position: relative;
    flex-grow: 1;
    display: flex;
    overflow: hidden;

    &-inner {
      position: relative;
      flex-grow: 1;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &-table {
    margin-right: 15px;
  }

  &-scrollarea {
    height: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;

    &-body {
      // min-height: 100%;
      // height: 100%;
    }
  }

  &-header {
    margin-top: 2px;
    height: 65px;

    &-image {
      display: inline-block;
      margin-right: 10px;
      width: 35px;
      //  height: 35px;
      height: auto;
      max-width: 20%;
    }

    &-text {
      text-transform: uppercase;
      font-size: 14px;
      color: #FFD34E;
      display: inline-block;
      font-style: normal;
      font-weight: bold;
      font-size: var(--xLargeFontSize);
      line-height: 28px;
      margin-top: 5px;
    }
  }

  &-footer {
    padding-top: 10px;
    font-size: var(--defaultFontSize);

    & .col {
      padding-left: 5px;
      padding-right: 5px;
    }

    & .minGames-wrapper {
      top: 5px;
      position: relative;
    }
  }

  &-select {
    border: 1px solid #fff;
    height: 25px;
    width: 90%;
    margin: auto;
    border-radius: 0;
    padding: 0;
    //font-size: 11px;
    border-radius: 30px;
    text-transform: uppercase;
    text-align: center;
    text-align-last: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
    font-style: normal;
    font-weight: 400;
    font-size: var(--mLargeFontSize);
    line-height: 26px;
    color: #fff;
    background: transparent;

    &:focus {
      background: transparent;
      color: #FFF;
    }

    & option {
      background-color: #2c5955;
      text-align: center;
      text-align-last: center;
      -ms-text-align-last: center;
      -moz-text-align-last: center;
    }

    &-type {
      padding-right: 0;
      font-size: 15px;

      & .dropdown-toggle {
        padding: 8px 4px;
        font-size: var(--largeFontSize);
        // font-size: 15px;
        text-align: center;
      }
    }

    &-bet {
      padding-left: 0;
      font-size: var(--largeFontSize);
      // font-size: 15px;

      & .dropdown-toggle {
        padding: 8px 4px;
        font-size: var(--largeFontSize);
        // font-size: 15px;
        text-align: center;
      }
    }
  }

  &-body {
    font-weight: bold;
    font-size: var(--defaultFontSize);
    text-align: left;
  }

  &-col {
    overflow: hidden;

    &-player {
      &-3 {
        display: inline-block;
        padding-left: 3px;
        padding-right: 3px;
        vertical-align: middle;
      }

      &-4 {
        display: inline-block;
        padding-left: 1px;
        padding-right: 1px;
        font-size: var(--mSmallFontSize);
        vertical-align: middle;
      }
    }
    /*  & .minGames {
    position: relative;

      &-text {
        width: 26px;
        height: 20px;

        position: absolute;
        top: 8px;
        text-align: center;

        margin: auto;

        font-style: normal;
        font-weight: 600;
        font-size: 13px;

        text-align: center;
        letter-spacing: 0.5px;

        color: #1E413D;
      }

      &-img {
        width: 26px;
        height: 26px;
      }
    } */

    & .fourPRoom {
      width: 20px;
      height: 24px;
      background: #C4C4C4;
      border-radius: 2px;

      &-text {
        width: 20px;
        height: 18px;
        margin: auto;
        font-style: normal;
        font-weight: 600;
        font-size: var(--largeFontSize);
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #1E413D;
      }
    }
  }

  &-row {
    height: 40px;
    max-width: 100%;
    overflow: hidden;
    transition: opacity 2s linear;
    opacity: 1;

    &-joined {
      border-bottom: 1px solid #FFD34E;
    }
  }

  &-player {
    font-size: var(--defaultFontSize);
    display: flex;
    align-items: center;

    &-name {
      display: inline-block;
      margin-left: 10px;
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 73px;
      // padding-top: 3px;
    }

    &-lvl {
      display: inline-block;
      background-color: #C54D4D;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align: center;
      position: relative;
      // top: -5px;

      &-text {
        margin: auto;
        font-style: normal;
        font-weight: bold;
        font-size: var(--defaultFontSize);
        line-height: 20px;
      }
    }

    &-waiting {
      position: relative;
    }
  }

  &-player-4 {
    font-size: var(--defaultFontSize);
    display: flex;
    align-items: center;

    &-name {
      display: inline-block;
      margin-left: 2px;
      font-size: var(--defaultFontSize);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 61px;
      // padding-top: 3px;
    }

    &-lvl {
      display: inline-block;
      background-color: #C54D4D;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align: center;
      position: relative;
      // top: -5px;

      &-text {
        margin: auto;
        font-style: normal;
        font-weight: bold;
        font-size: var(--mSmallFontSize);
        line-height: 20px;
      }
    }

    &-waiting {
      position: relative;
    }
  }

  & tbody tr:nth-of-type(even) {
    background-color: rgba(97,89,73, 0.3);
  }

  &-container {
    height: 500px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-track {
      background: linear-gradient(180deg, #B4EC51 21.76%, #429321 65.5%);
      border-radius: 3px;
      border-left: 7px solid #000;
      border-right: 7px solid #000;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #B4EC51 21.76%, #429321 65.5%);
      border-radius: 3px;
      border-left: 2px solid #000;
      border-right: 2px solid #000;
    }
  }
  /*  &-legend {

    &-text {
      font-size: 13px;
      color: #E4FFB9;
    }
  } */
}

.minGames {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &-text {
    width: 26px;
    height: 20px;
    position: absolute;
    top: 8px;
    text-align: center;
    margin: auto;
    font-style: normal;
    font-weight: 600;
    font-size: var(--defaultFontSize);
    letter-spacing: 0.5px;
    color: #1E413D;

    &-active-room {
      position: absolute;
      height: var(--defaultFontSize);
      margin: auto;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.5px;
      color: #1E413D;
    }
  }

  &-img {
    width: var(--specialShapeSize);
    height: var(--specialShapeSize);
  }
}


.rules-page {
  color: #fff;

  .help-button {
    background-color: transparent  !important;
    border: 0;
  //  margin-top: 5px;
    font-weight: 600;

    float: right;
    margin-right: 20px;
    margin-top: 13px;
  }

  .help-button:active, .help-button:focus, .help-button:hover {
    background-color: transparent !important;
    border: 0;
  }

  table {
    th {
      border: 1px solid #FFF;

    }

    td {
      border: 1px solid #FFF;

    }
  }

  &-desc-table {
    tr td {
      padding-bottom: 5px;
    }

    &-label{
      font-size: var(--xLargeFontSize);
      font-weight: 600;
      padding-left: 25px;
    }

    &-desc {
      padding-left: 25px;
    }
  }

  &-levels-table {
  //  border: 1px;
    width: 100%;

    tr td {
      padding-bottom: 5px;
    }

    td {
      border: 1px solid #fff;
      text-align: center;
    }

    &-header{
      font-size: var(--mLargeFontSize);
      font-weight: 600;
    }
  }

  &-body-table {
    tr th, tr td {
      border: 1px solid #fff;
      padding: 4px;
    }
  }

  &-header {
    margin-bottom: 20px;

    &-tab {
      height: 600px;
    }
  }

  &-text-yellow {
    color: #FFD34E;
  }

  &-text-header {
    color: #FFD34E;
    text-transform: uppercase;
    padding-bottom: 8px;
    display: inline-block;
  }

  &-content {
    color: #fff;

    > div {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      padding: 30px 30px;
    }

    &-wrapper {
    //  padding-left: 15px;
    //  padding-right: 25px;
      margin-right: 20px;
      text-align: justify;
      font-size: var(--largeFontSize);

      & table {
        font-size: var(--largeFontSize);
      }

      & th {
        text-align: center;
      }

      & td {
        text-align: center;
        padding: 5px;
      }

      & img {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-tab {
      height: 540px;
      max-height: 540px;
      overflow-y: auto;
      overflow-x: hidden;


      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #fff;
      }

      & .active {
        height: 100%;
      }
    }
  }

  &-tab {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;

    & .active {
      color: #fff;
      border-color: #fff;
      background-color: #380000;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    &-wrapper {
      background-color: #2c5955;
      border-radius: 15px;
    //  height: 30px;
      font-size: var(--defaultFontSize);
      text-align: left;
      text-transform: uppercase;
      line-height: 13px;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      cursor: pointer;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        border-color: #fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}


.rooms-table-body .join-room-button {
  line-height: 15px;
  margin:auto;
  background-color: #2c5955;
  border-radius: 15px;
  height: 27px;
  width: 120px;
  padding-left: 6px;
  font-size: var(--largeFontSize);
  // font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  -webkit-box-shadow: 3px 3px 0px 0px #380000;
  -moz-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 3px 3px 0px 0px #380000;

  pointer-events: auto;
  cursor: default;
  position: relative;

  &:hover {
    color: #FFD34E;
    border-color: #FFD34E;
    background-color: #2c5955;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #FFD34E;
    border-color: #FFD34E;
    background-color: #2c5955;
    text-decoration: none;
  }

  &:active {
    color: #fff;
    background-color: #380000;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &-alreadyJoined {
    padding-top: 5px;
    padding-left: 0;
    padding-right: 0;
  }

  &-privateKey {
    position: absolute;
    left: 8px;
    top: 4px;
  }
}

.rooms-table-body .join-room-button.disabled {
  cursor: not-allowed;
}


/*
.player-info {

  margin-top: 40px;
  margin-bottom: 0px;
  margin-left: 20px;

  & .player-profile {

    z-index: 10;

    & .player {
      &-avatar-frame {
      //  @include background-2x( '../../images/Player Picture Lobby', 'png', 80px, 80px, center center, no-repeat );
        width: 80px;
        height: 80px;
        position: absolute;
        top:-8px;
        left: 9px;
        z-index: 1;
      }

      &-avatar {
        width: 68px;
        height: 68px;
        border-radius: 35px;
        border: 0px;
        position: absolute;
        background-color: #847753;
        box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
        overflow: hidden;
        padding: 0;
        z-index: 2;
        img{
          width: 100%;
          height: 100%;
        }
      }

      &-name{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.228571px;

        color: #FFFFFF;

        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
      }
      &-balance{
        color: #E4FFB9;
        text-align: center;
      }
      &-type{
        text-align: center;
        margin-bottom: 5px;
      }
      &-level{
       border-radius:15px;
        padding: 0px;
        color: $white;
        text-align: center;
        width: 24px;
        height: 24px;
        position: relative;
        top:0px;
        left: 0px;
        margin-left: 10px;
        background: #242442;
        border: 2px solid #73bb73;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        margin-left: 10px;
        letter-spacing: 0.185714px;

        color: #dad18e;

        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

        &-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          margin-left: 10px;
          letter-spacing: 0.185714px;

          color: #FFFFFF;

          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
} */


.menu-player {
  &-info {
    padding-bottom: 10px;

    &-left {
      position: relative;
      display: flex;
      align-items: center;
    }
    &-right {
      padding-top: 15px;

      &-active-gift {
        position: absolute;
        right: -25px;
        top: 8px;
        width: 60px;
        height: 60px;
        object-fit: contain;
        z-index: 1;

        &-inbox {
          top: 28px;
          right: -5px;
        }

        &-remove {
          width: 10px;
          height: 10px;
          position: absolute;
          top: 0px;
          right: -31px;
          cursor: pointer;
          z-index: 2;

          &-inbox {
            top: 20px;
            right: -11px;
          }
        }
      }
    }
  }

  &-avatar {
    width: 68px;
    height: 68px;
    border-radius: 50px;
    border: 1px solid #fff;
  //  position: absolute;
    background-color: #847753;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
    padding: 0;
    z-index: 2;
    img{
      width: 100%;
      height: 100%;
    }

  }

  &-name{
    font-style: normal;
    font-weight: bold;
    font-size: var(--mLargeFontSize);
    line-height: 25px;
    text-align: center;

    color: #fff;
  }
  &-balance {
    color: #FDD55D;
    text-align: center;
    display: flex;
    z-index: 1;
    &-coin {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
    &-text {
      display: inline-block;
      font-size: var(--mLargeFontSize);

      &-orange {
        color: #F29100;
      }

      &-red {
        color: #C44C4D;
      }
    }
    &-popover {
      // width: 385px;
      // height: 225.96px;
      // margin-left: 20px;
      // background-color: #2C5956;
      // position: absolute;
      // left: -20px;
      // top: 10px;

      & .popover {
        width: 385px;
        height: 225.96px;
        max-width: 385px;
        left: 105px;
        border-radius: 18px;
      }

      & .popover .arrow {
        left: 18px;
      }

      & .popover-inner {
        width: 100%;
        height: 100%;
      }

      &-edit {
        & .popover {
          height: 160px;
        }
      }

      &-body {
        width: 100%;
        height: 100%;
        display: flex;
        color: #fff;
        flex-direction: column;
        padding: 20px;
        &-header {
          width: 100%;
          height: 30%;
          font-size: var(--mLargeFontSize);
          font-weight: 600;
        }
        &-main {
          width: 100%;
          height: 40%;
          font-size: var(--headerFontSize);
          font-weight: 400;
        }
        &-footer {
          width: 100%;
          height: 30%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          &-purchase-button {
            padding: 3px 10px !important;
            width: auto;
            height: 30px;
            max-width: auto;
            /* left: 160px; */
            /* top: 309px; */
            background: #F29100;
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 4px 4px 0px #380000;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }

          &-reset-button {
            padding: 3px 10px !important;
            width: auto;
            height: 30px;
            max-width: auto;
            background: #C44C4D;
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 4px 4px 0px #380000;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  &-type{
    text-align: center;
    margin-bottom: 5px;
  }

  &-level{
    display: inline-block;
    background-color: #C54D4D;
    font-size: var(--largeFontSize);
    line-height: 20px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 1px solid #fff;
    text-align: center;

    color: #fff;

    &-wrapper {
      position: absolute;
      bottom: 0;
      right: 25px;
    }

    &-text {
      padding-top: 2px;
      padding-left: 1px;
    }
  }

  &-edit {

    &-wrapper {
      position: absolute;
      top: -4px;
      right: 22px;
      z-index: 1;
    }

    &-image {
      display: inline-block;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    &-modal-image {
      display: inline-block;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}

.tabs {
  margin-top: 50px;
}

.online-stats {
  position: fixed;
  top: 0;
  left: 50%;
  width: auto;
  transform: translateX(-50%);
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: var(--defaultFontSize);
  line-height: 25px;
  color: #FFF;
  padding-right: 10px;
  // white-space: nowrap;

  > span {
    color: #FFD34F;
    font-weight: 600;
    text-transform: lowercase;
  }

  &-divider {
    display: inline-block;
    content: ' ';
    background-image: url('../../../../images/redesign/common/karavs.svg');
    background-size: $sectionDividerSize $sectionDividerSize;
    background-repeat: no-repeat;
    background-position: center;
  //  height: 22px;
  //  height: $sectionDividerSize;
    width: $sectionDividerSize;
    margin-right: 3px;
    margin-left: 3px;
  //  vertical-align: middle;

    height: 100%;
    vertical-align: top;
  }

}

.header-right {
  margin-top: 8px;


  & .daily-bonus-image {
    cursor: pointer;
    max-height: 80%;
  //  width: auto;
  }

  &-daily {
    padding-right: 5px;

    & .not-spined {
      background-color: #F29000;
    }

    & .active {
      color: #fff;
      border-color: #fff;
      background-color: #380000;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  &-buy {
    padding-left: 5px;

    & .active {
      color: #fff;
      border-color: #fff;
      background-color: #380000;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}

.buy-button {
  background-color: #c44c4c;
  border-radius: 15px;
  height: 45px;
  font-size: var(--smallFontSize);
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  -webkit-box-shadow: 5px 5px 0px 0px #380000;
  -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 5px 0px 0px #380000;
  cursor: pointer;

  .low-bal-anim-image {
    position: absolute;
    top: -2px;
    left: 1px;
  }

  &:hover {
    color: #FFD34E;
    border-color: #FFD34E;
    background-color: #c44c4c;
    text-decoration: none;
  }

  &:focus {
    color: #FFD34E;
    border-color: #FFD34E;
    background-color: #c44c4c;
    text-decoration: none;
  }

  &:active {
    color: #fff;
    background-color: #380000;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &-wrapper {
    height: 100%;
  }

  &-image {
    cursor: pointer;
    display: inline-block;
    float: left;
    width: 30%;
    padding-left: 4px;
    padding-top: 8px;
    max-height: 80%;
  }

  &-text {
    font-size: var(--defaultFontSize);
    // font-size: 13px;
    text-align: center;

    padding-top: 3px;
    padding-right: 14px;
    cursor: pointer;
    display: inline-block;
    float: right;
    width: 70%;
    padding-left: 2px;
  }
}

.daily-bonus {

  background-color: #c44c4c;
  border-radius: 15px;
  height: 45px;
  font-size: var(--smallFontSize);
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  -webkit-box-shadow: 5px 5px 0px 0px #380000;
  -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 5px 0px 0px #380000;
  cursor: pointer;

  &-history {
    &-empty-entries {
      font-size: var(--mLargeFontSize);
      text-align: center;
    }
  }

  &:hover {
    color: #FFD34E;
    border-color: #FFD34E;
    background-color: #c44c4c;
    text-decoration: none;
  }

  &:focus {
    color: #FFD34E;
    border-color: #FFD34E;
    background-color: #c44c4c;
    text-decoration: none;
  }

  &:active {
    color: #fff;
    background-color: #380000;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &-wrapper {
    height: 100%;
  }

  &-image {
    cursor: pointer;
    display: inline-block;
    float: left;
    width: 30%;
    padding-left: 4px;
    padding-top: 4px;
  }

  &-text {
    font-size: var(--defaultFontSize);
    // font-size: 13px;
    text-align: center;

    padding-top: 3px;
    cursor: pointer;
    display: inline-block;
    float: right;
    width: 70%;
  }
}


.btn-facebook {
  display: inline-block;
    background: #4267B2;
    color: #000;
    width: 220px;
    border-radius: 5px;
  //  border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;

    &-icon {
    //  background: url('../../assets/images/fbLogo.png') transparent 5px 50% no-repeat;
    //  @include background-2x( '../../images/fbLogo', 'png', 28px, 28px, center center, no-repeat );
      display: inline-block;
      vertical-align: middle;
      width: 28px;
      height: 28px;
    }

    &-text {
      display: inline-block;
      vertical-align: middle;
      padding-left: 21px;
      padding-right: 21px;
      font-size: var(--largeFontSize);
      font-weight: bold;
      color: #000;
    }

}

.playerCount {
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: var(--defaultFontSize);
  font-weight: bold;
  color: #e4ffb9;
}


.roomCount {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: var(--defaultFontSize);
  font-weight: bold;
  color: #e4ffb9;
}


.tournament-banner {
  height: 40px;
//  @include background-2x( '../../images/Lobby background app', 'png', 980px, 700px, top center, no-repeat );
//  background-size: contain;
  background-position: top center;
  margin-bottom: 5px;
  margin-top: 5px;

  &-left {
    height: 100%;
    padding: 0;

    &-logo {
      margin: 5%;
      margin-left: 10%;
      height: 90%;
      width: auto;
    }

    &-row {
      height: 100%;
    }

    &-col {
      height: 100%;
      padding-left: 15px;
    }

    &-col2 {
      height: 100%;
      color: #fff;
      margin-top: 6px;
      font-size: var(--mLargeFontSize);
    }
  }

  &-right {
    height: 100%;
    padding: 0;
  }

  &-button {
    height: 100%;
    width: 100%;
    background: #33a800;

    font-weight: bold;
    font-size: var(--headerFontSize);
    text-align: center;

    color: #fff;
    padding: 0;
  }
}

.main-tab-content {
  min-height: 300px;
  height: calc(100vh - 215px);
}

.root-font-xLarge:not(.root-is-app-frame) {
  .main-tab-content {
    // @media only screen and (max-width: 1206px) {
    //   height: calc(100vh - 251px) !important;
    // }

    // @media only screen and (max-width: 767px) {      
    //   height: calc(100vh - 265px) !important;
    // }

    // @media only screen and (max-width: 739px) {
    //   height: calc(100vh - 280px) !important;
    // }

    @media only screen and (max-width: 1090px) {
      height: calc(100vh - 251px) !important;
    }

    @media only screen and (max-width: 739px) {
      height: calc(100vh - 266px) !important;
    }
  }
}

.root-font-large:not(.root-is-app-frame) {
  .main-tab-content {
    @media only screen and (max-width: 1090px) {
      height: calc(100vh - 251px) !important;
    }

    @media only screen and (max-width: 739px) {
      height: calc(100vh - 266px) !important;
    }
  }
}

.root-font-normal:not(.root-is-app-frame) {
  .main-tab-content {
    @media only screen and (max-width: 975px) {
      height: calc(100vh - 251px) !important;
    }

    @media only screen and (max-width: 739px) {
      height: calc(100vh - 266px) !important;
    }
  }
}

.tab-content {
  //  height: 100%;

  .main-tab-pane {
    height: 100%;

    > .row {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
}

.root-is-app-frame:not(.app-wrapper-fullscreen) {
  .tab-content {
      //  height: 100%;
      .main-tab-pane {
        height: 100%;
    
        > .row {
          height: 100%;

          // > div {
          //   height: 460px;
          // }
        }
      }
    }
}

.app-wrapper-facebook:not(.app-wrapper-fullscreen) {
  .tab-content {
      //  height: 100%;
      .main-tab-pane {
        height: 100%;
    
        > .row {
          height: 100%;

          > div {
            height: calc(100% - 60px) !important;
          }
        }
      }
    }
}



.online-users {
  max-height: 400px;
  color: #fff;

//  background: linear-gradient(180deg, #CCCCCC 50%, #CCCCCC 5%);
  background: linear-gradient(180deg, rgba(50, 50, 50, 0.8) 0%, rgba(50, 50, 50, 0.1) 100%);
  border-radius: 20px 20px 0px 0px;
  // padding: 5px;
  padding: 10px 5px 5px 10px;
  padding-right: 0;
  padding-bottom: 0;

  &-legend {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.05) 100%);
    border-radius: 0px 0px 20px 20px;
    margin: 0;
    padding: 5px;

    &-item {
      font-size: var(--smallFontSize);
      height: 20px;
      color: #fff;
      padding-left: 2px;
      padding-right: 2px;
    //  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.05) 100%);
    }

    &-image {
      display: inline-block;
      padding-right: 4px;
    //  float: right;
    }

    &-text {
      display: inline-block;
    }
  }

  &-header {
    text-transform: uppercase;
    font-size: var(--mLargeFontSize);
    margin-top: 2px;
    text-align: center;
    padding-left: 10px;
    padding-right: 5px;
    color: #FFD34E;
    &-row {
      padding-left: 10px;
      padding-right: 15px;
      margin-bottom: 5px;
    }
  }

  &-select {
    border: 1px solid #fff;
    height: 25px;
    width: 100%;
    margin: auto;
    border-radius: 0;
    padding: 0;
    font-size: var(--mSmallFontSize);

    text-transform: uppercase;
    text-align: center;
    text-align-last:center;
    -ms-text-align-last: center;
   -moz-text-align-last: center;

    color: #fff;
    background: transparent;

    &:focus {
      background: transparent;
      color: #FFF;
    }

    & option {
      background-color: #2c5955;
      text-align: center;
      text-align-last:center;
      -ms-text-align-last: center;
     -moz-text-align-last: center;
    }

    &-type {
      padding-right: 0;
    }

    &-bet {
      padding-left: 0;
    }
  }

  &-caret {
    margin: auto;
    margin-top: 5px;

    &-up {
      padding-left: 0px;
      padding-right: 0px;
      cursor: pointer;
    }

    &-down {
      padding-left: 0px;
      padding-right: 5px;
      cursor: pointer;
    }
  }

  &-table {
  //  overflow-y: scroll;
  //  overflow-x: hidden;
  //  height: 350px;
  //  display: block;

    &-col {
      font-size: var(--smallFontSize);
      padding-right: 5px;

      &-name {
        font-size: var(--largeFontSize);
        // font-size: 15px;
        line-height: 15px;
        padding-left: 0px;

      }
      &-balance{
        color:#85928a;
        font-size: var(--defaultFontSize);
      }

      &-status {
        font-size: var(--smallFontSize);
        padding-left: 5px;
      }
    }

    &-image {
      display: inline-block;
      padding-right: 4px;
      margin-left: auto;
      margin-right: auto;
      float: right;
    }
  }

  &-avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #fff;
  //  position: absolute;
    background-color: #847753;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
    padding: 0;
    z-index: 2;
    margin-right: 20px;

    img{
      width: 100%;
      height: 100%;
    }
  }

  &-level{
    display: inline-block;
    background-color: #c44c4c;
    font-size: var(--smallFontSize);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    text-align: center;

    color: #fff;

    &-wrapper {
      position: absolute;
      bottom: 5px;
      left: 30px;
    }

    &-text {
      padding-top: 2px;
      padding-left: 1px;
    }
  }

  &-ReactTableContainer {
    padding: 0;
    margin: 0;
    display: block !important;
  }
}

.help-image {
  cursor: pointer;
}

.firstTimeModal {
  top: 0px!important;
}

.label {

  &-speed {
    width: 20px;
    margin-right: 1px;
  }

  &-pro {
    width: 18px;
    margin-top: 3px;
  }

  &-private {
    width: 25px;
  }
}


.holiday-image {
  width: 101px;
  height: 178px;
  position: absolute;
  top: 230px;
  left: 650px;

  &-wrapper {

  }
}

.new-version-modal {
  top: 50px !important;
  max-width: 800px !important;
  width: 800px !important;

  h2 {
    font-size: var(--xxLargeFontSize);
    // font-size: 23px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  p {
    font-size: var(--headerFontSize);
    text-align: left;
  }

  ul {
    li {
      font-size: var(--headerFontSize);
      text-align: left;
    }
  }

  &-scrollarea {
    overflow-y: auto;
    max-height: calc(100vh - 250px);
    min-height: 200px;
  }

  /*
  @media screen and (max-width: 1280px) and (orientation: landscape) {
    &-scrollarea {
      overflow-y: auto;
      max-height: calc(100vh - 250px);
    }
  } */
}

.logout-button {
//  position: absolute;
//  right: -20px;
//  top: 16px;

  position: relative;
  display: block;

  img {
  //  max-width: 25px;
  //  max-height: 25px;

    display: block;
    height: auto;
    width: 25px;
    min-width: 1.6vmin;
  }
}

.update-profile {
  font-size: var(--xLargeFontSize);

  &-body {
    & form {
      font-size: var(--mLargeFontSize);
      font-weight: 500;
    }
  }

  &-button {
    line-height: 15px;
    margin:auto;
    background-color: #2c5955;
    border-radius: 15px;
    height: 32px;
    width: 150px;
    padding-left: 6px;
    font-size: var(--largeFontSize);
    // font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    -webkit-box-shadow: 3px 3px 0px 0px #380000;
    -moz-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 3px 0px 0px #380000;

    &:hover {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #2c5955;
      text-decoration: none;
      cursor: pointer;
    }

    &:focus {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #2c5955;
      text-decoration: none;
    }

    &:active {
      color: #fff;
      background-color: #380000;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}

.achieve-unlock-modal {
  width: 100%;
  height: 370px;

  &-content {
    width: 100%;
    height: 50px;
    & .subheader {
      color: #ffc900;
      font-size: var(--xxLargeFontSize);
    }
    & p {
      font-size: var(--mLargeFontSize);
    }
  }

  &-scrollarea {
    width: 100%;
    height: 320px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    // justify-content: center;
    font-size: var(--defaultFontSize);

    &-section {
      flex: 0 0 auto;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      height: 320px;
      border-bottom: 5px solid rgba(#ffffff, 0.1);
    }

    & .scrollbar {
      background: rgba(#ffffff, 0.4) !important;
    }
    & .my-info-achievements-column {
      width: 255px;
      height: 100%;
      margin-top: -60px;
    }
    & .my-info-achievements-column-div {
      width: 100%;
      height: 250px;
      position: relative;
      overflow: hidden;

      & .achievement-img-bg {
        width: 75%;
        height: 70%;
        background: #65BEFF;
        opacity: 0.2;
        transform: rotate(-180deg);
        border-radius: 50%;
        position: absolute;
        top: 125px;
        left: 31px;
      }

      & img {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0px;
      }

      & .signle-achievement-img {
        width: 80%;
        height: 60%;
        position: absolute;
        bottom: -100px;
      }
    }
    & .my-info-achievements-column-div::before {
      position: absolute;
      left: -25px;
      bottom: 0px;
      content: ' ';
      background-image: url('../../../../images/redesign/landing-view/landing_modal_stars.svg');
      background-size: 200px 200px;
      height: 200px;
      width: 200px;
      z-index: 999;
    }

    & .my-info-achievements-column-div::after {
      position: absolute;
      right: -30px;
      bottom: 0px;
      content: ' ';
      background-image: url('../../../../images/redesign/landing-view/landing_modal_stars.svg');
      background-size: 200px 200px;
      height: 200px;
      width: 200px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }

    & .my-info-achievements-column-info {
      width: 100%;
      height: 70px;
    }
  }
}

.banned-users-list {
  font-size: var(--defaultFontSize) !important;
  .banned-user-empty-entries {
    height: 300px;
  }
}
