$mobileLandScapeWidth: 1280px;


.root-class-design20 {
  @media screen and (orientation : portrait) {

    .top-setting-menu-open-button {
      display: none;
    }

    .top-setting-menu-open-button-iframe {
      display: none;
    }

    .modal {
      .modal-dialog {
        top: 0;

        .modal-content {
          .modal-header {
            .modal-title {
              max-width: 70%;

              .default-modal-title {
                padding: 5px 8.5px;
                height: auto;
                line-height: 1.2;
              }
            }
          }

          .modal-body {
            padding-top: 40px;
            border-radius: 17px;

            .user-settings {
              margin-left: 15px;
              margin-right: 15px;
              max-height: calc(100vh - 285px);
              overflow-y: auto;
              overflow-x: hidden;

              .row {
                margin: 0;

                &>div.col-sm-6:first-of-type {
                  padding: 0;

                  .user-settings-row-wrapper {
                    padding-left: 0;

                    .user-settings-header {
                      line-height: 135%;
                      margin-bottom: 12px;
                      padding: 0;
                    }

                    .user-settings-row {
                      margin-bottom: 12px;
                      display: flex;
                      align-items: center;
                      column-gap: 5px;

                      .switch-text {}
                    }

                    .user-settings-divider {
                      width: 100%;
                      margin-left: 0;
                      max-width: none;
                    }
                  }
                }

                &>div.col-sm-6:last-of-type {
                  padding: 0;
                  margin-top: 25px;

                  .user-settings-header {
                    margin-bottom: 12px;
                    padding-left: 0;
                  }

                  .user-settings-row {
                    padding-left: 0;
                    margin-bottom: 12px;
                  }

                  .user-settings-space {
                    display: none;
                  }

                  .user-settings-toggles {
                    bottom: 0;
                    margin-top: 25px;

                    .user-settings-row {
                      .row {
                        .col-sm-12 {
                          padding-left: 0;

                          .user-settings-label {
                            margin-bottom: 12px;
                          }
                        }

                        .switch-text-wrapper {
                          .switch-text {
                            margin-left: 0;
                          }

                          .switch-text:last-of-type {
                            margin-left: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .modal-footer {}
          }

          .modal-footer {
            button {
              margin-left: 0;
              width: auto !important;
              min-height: auto !important;
            }
          }
        }
      }

      .top-setting-modal.modal-dialog {
        display: none;
      }

      .money-history-modal.modal-dialog {
        .modal-content {
          .modal-header {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
          }

          .modal-body {
            border-radius: 17px 17px 0 0 !important;
            border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

            .player-history-tabs {
              padding-bottom: 0;

              .col-sm-12 {
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
                padding: 0;
                display: flex;
                justify-content: center;
                column-gap: 15px;

                .player-history-tab-link {
                  margin-left: 0;

                  a {
                    margin: 0;
                    padding: 0;
                    font-size: var(--largeFontSize);
                    line-height: 136%;
                    padding-bottom: 5px;
                  }
                }
              }
            }

            .player-history-tabs+.row {
              .col-sm-12 {
                // overflow: auto hidden;
                padding-left: 5px;
                padding-right: 5px;

                &::-webkit-scrollbar {
                  height: 4px;
                }
              }
            }

            .player-history-table-header {
              display: flex;
              flex-wrap: nowrap;
              column-gap: 7px;
              height: auto;
              margin-bottom: 3px;
              overflow: visible;
              margin-left: 0;
              margin-right: 0;
              padding-left: 15px;
              padding-right: 5px;

              .player-history-table-header-col {
                padding: 0;
                color: rgba($color: #fff, $alpha: 0.5);
                font-size: 13px;
                max-width: unset;
                width: auto;
                flex: unset;
              }

              .player-history-table-header-col:nth-of-type(1) {
                width: 13%;
              }

              .player-history-table-header-col:nth-of-type(2) {
                width: 30%;
              }

              .player-history-table-header-col:nth-of-type(3) {
                width: 15%;
              }

              .player-history-table-header-col:nth-of-type(4) {
                width: 14%;
              }

              .player-history-table-header-col:nth-of-type(5) {
                width: 23%;
                text-align: right;
              }
            }

            .player-history-table-scrollarea {
              margin-top: 3px;
              overflow: visible auto;
              display: inline-block;
              width: 100%;

              .player-money-history-empty-entries {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
              }

              .player-history-table-row {
                flex-wrap: nowrap;
                padding-left: 18px;
                padding-right: 6.5px;
                margin: 0;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
                overflow: visible;
                column-gap: 4px;

                .player-history-table-col {
                  flex: unset;
                  width: auto;
                  max-width: unset;
                  padding-top: 6.5px;
                  padding-bottom: 6.5px;
                  padding-left: 0;
                  padding-right: 0;
                  font-size: 13px;

                  .player-history-table-col-positive {
                    padding: 6px 13px;
                    color: rgba($color: #fff, $alpha: 0.7);
                    float: right;
                  }

                  .player-history-table-col-negative {
                    padding: 6px 13px;
                    color: rgba($color: #fff, $alpha: 0.7);
                    float: right;
                  }
                }

                .player-history-table-col:nth-of-type(1) {
                  width: 13%;
                }

                .player-history-table-col:nth-of-type(2) {
                  width: 30%;
                }

                .player-history-table-col:nth-of-type(3) {
                  color: rgba($color: #fff, $alpha: 0.7);
                  width: 15%;
                }

                .player-history-table-col:nth-of-type(4) {
                  color: rgba($color: #fff, $alpha: 0.7);
                  width: 14%;
                }

                .player-history-table-col:nth-of-type(5) {
                  width: 23%;
                }
              }
            }
          }

          .modal-footer {
            margin-top: 0px !important;
          }
        }
      }

      .points-history-modal.modal-dialog {
        .modal-content {
          .modal-header {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
          }

          .modal-body {
            border-radius: 17px 17px 0 0 !important;
            border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

            .player-history-tabs {
              padding-bottom: 0;

              .col-sm-12 {
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
                padding: 0;
                display: flex;
                justify-content: center;
                column-gap: 15px;

                .player-history-tab-link {
                  margin-left: 0;

                  a {
                    margin: 0;
                    padding: 0;
                    font-size: var(--largeFontSize);
                    line-height: 136%;
                    padding-bottom: 5px;
                  }
                }
              }
            }

            &>.row>.col-sm-12 {
              // overflow: auto hidden;
              padding-left: 5px;
              padding-right: 5px;

              &::-webkit-scrollbar {
                height: 4px;
              }
            }

            .player-history-table-header {
              display: flex;
              flex-wrap: nowrap;
              column-gap: 7px;
              height: auto;
              margin-bottom: 3px;
              overflow: visible;
              margin-left: 0;
              margin-right: 0;
              padding-left: 15px;
              padding-right: 5px;

              .player-history-table-header-col {
                padding: 0;
                color: rgba($color: #fff, $alpha: 0.5);
                font-size: 13px;
                max-width: unset;
                width: auto;
                flex: unset;
              }

              .player-history-table-header-col:nth-of-type(1) {
                width: 13%;
              }

              .player-history-table-header-col:nth-of-type(2) {
                width: 30%;
              }

              .player-history-table-header-col:nth-of-type(3) {
                width: 15%;
              }

              .player-history-table-header-col:nth-of-type(4) {
                width: 14%;
              }

              .player-history-table-header-col:nth-of-type(5) {
                width: 23%;
                text-align: right;
              }
            }

            .player-history-table-scrollarea {
              margin-top: 3px;
              overflow: visible auto;
              display: inline-block;
              width: 100%;

              .player-points-history-empty-entries {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
              }

              .player-history-table-row {
                flex-wrap: nowrap;
                padding-left: 18px;
                padding-right: 6.5px;
                margin: 0;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
                height: auto;
                overflow: visible;
                column-gap: 12px;

                .player-history-table-col {
                  flex: unset;
                  width: auto;
                  max-width: unset;
                  padding-top: 6.5px;
                  padding-bottom: 6.5px;
                  padding-left: 0;
                  padding-right: 0;
                  font-size: 13px;

                  .player-history-table-col-positive {
                    padding: 6px 13px;
                    color: rgba($color: #fff, $alpha: 0.7);
                    float: right;
                  }

                  .player-history-table-col-negative {
                    padding: 6px 13px;
                    color: rgba($color: #fff, $alpha: 0.7);
                    float: right;
                  }
                }

                .player-history-table-col:nth-of-type(1) {
                  width: 13%;
                }

                .player-history-table-col:nth-of-type(2) {
                  width: 30%;
                }

                .player-history-table-col:nth-of-type(3) {
                  color: rgba($color: #fff, $alpha: 0.7);
                  width: 15%;
                }

                .player-history-table-col:nth-of-type(4) {
                  color: rgba($color: #fff, $alpha: 0.7);
                  width: 14%;
                }

                .player-history-table-col:nth-of-type(5) {
                  width: 23%;
                }
              }
            }
          }

          .modal-footer {
            margin-top: 0px !important;
          }
        }
      }

      .contact-admin-modal.modal-dialog {
        .modal-content {
          .modal-body {
            padding-top: 36px;
            border-radius: 17px 17px 0 0 !important;

            .contact-support-chat-description {
              margin-bottom: 20px;

              .col-12 {
                padding-left: 21px;
                padding-right: 54px;
              }
            }

            .contact-support-chat-body-wrapper {

              .contact-support-chat-body-scroll-area {
                overflow: hidden;

                .contact-support-chat-message-row {
                  .contact-support-chat-message {
                    .contact-support-chat-message-user {
                      padding: 11px 15px;
                      border-radius: 17px 17px 17px 0px;

                      p {
                        line-height: 1.6;
                      }
                    }

                    .contact-support-chat-message-other {
                      border-radius: 17px 17px 0px 17px;
                    }
                  }

                  .contact-support-chat-message-user-time {
                    margin-bottom: 20px;
                  }
                }

                .scrollbar-container {
                  width: 4px !important;

                  .scrollbar {
                    background: rgba($color: #fff, $alpha: 0.2) !important;
                    width: 4px !important;
                  }
                }
              }
            }

            .contact-support-chat-footer {
              margin-top: 11px;

              .contact-support-chat-footer-input-wrapper {
                .contact-support-chat-footer-input-attach-icon {
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }

      .contact-admin-modal.modal-dialog.desktop {
        display: none;
      }

      .edit-profile-modal.modal-dialog {
        .modal-body {
          padding-top: 58px;
          border-radius: 17px 17px 0 0 !important;
          padding-left: 6px;
          padding-right: 6px;

          .new-input-second-placeholder {
            padding-top: 0;
            padding-bottom: 0;
          }

          .update-profile-section {
            height: 60vh;
            overflow-y: auto;
            overflow-x: hidden;
            justify-content: flex-start;
            padding-left: 21px;
            padding-right: 21px;

            &-addEmail,
            &-changeEmail,
            &-confirmation {
              height: auto !important;
              min-height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &-changePass {
              min-height: 193px !important;
              height: 193px !important;
              overflow: hidden;
            }

            .update-profile-section-tabs {
              &>div {
                width: auto !important;
                height: auto !important;
                justify-content: center !important;
                column-gap: 19px;

                .new-update-profile-tab {
                  margin: 0;
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 600;
                  font-size: var(--defaultFontSize);
                  line-height: 1.5;

                  &>div {
                    margin-top: 5px;
                  }
                }
              }
            }

            .update-profile-section-tabs+.mt-4.mb-2 {
              margin-top: 0 !important;
              width: 100%;

              .switch-select {
                width: 100%;

                .switch-select-option {
                  width: 33.333%;
                }
              }
            }

            .profile {
              .profile-body-reg-date {
                margin-top: 0;
              }
            }

            .update-profile-section-add-email {
              width: 100%;

              .new-input-second-placeholder {
                padding-top: 0;
                padding-bottom: 0;
              }

              .add-email-button {
                min-width: auto;
                width: 100%;
              }

              .add-dob-section {
                .rw-widget-picker.rw-widget-container {
                  .rw-select {
                    //display: none;
                  }
                }

                .custom-birthday {

                  &>input,
                  &>span {
                    font-size: var(--largeFontSize);
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: 1;
                    width: 10px;
                  }

                  &>input.custom-birthday-day {
                    width: 36px;
                    padding: 0;
                  }

                  &>input.custom-birthday-month {
                    width: 36px;
                    padding: 0;
                  }

                  &>input.custom-birthday-year {
                    width: 47px;
                    padding: 0;
                  }
                }
              }

              .add-name-section {
                &>div:first-of-type {
                  width: auto !important;
                }
              }



              .add-surname-section {
                &>div:first-of-type {
                  width: auto !important;
                }
              }

              .add-email-section {
                column-gap: 8px;
              }

              .add-dob-section {}

              &>.d-flex {
                column-gap: 10px;
              }

              &>div {
                width: 100%;
              }
            }

            .change-email-password-section-wrapper {
              column-gap: 10px;
              width: 100%;

              .not-add-change-password-wrapper {
                flex-grow: 1;
                width: 50% !important;
              }

              .not-add-change-password-wrapper+.change-email-section {
                flex-grow: 1;
                width: 50%;
              }

              // .add-change-password-wrapper {
              //   flex-grow: 2;
              //   width: 66.6666%;
              // }

              .add-change-password-wrapper+.change-email-section {
                flex-grow: 1;
                width: 33.3333%;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .top-setting-menu-open-button {
      display: none;
    }

    .top-setting-menu-open-button-iframe {
      cursor: pointer;
      display: block !important;
      position: fixed;
      right: 5px;
      top: 35px;
    }

    .modal {
      .modal-dialog {
        top: 0;
        width: 72vw;
        //height: 100vh;

        .modal-content {
          .modal-header {
            position: static;

            .modal-title {
              position: relative;
              top: 25px;
            }

            .modal-close-img {
              background-color: rgba($color: #000000, $alpha: 0.7);
              background-image: url('../../../../images/redesign/mobile/icons/close-icon.svg');
              background-size: 15px 15px;
              background-repeat: no-repeat;
              border-radius: 50%;
              width: 22px;
              height: 22px;
              position: absolute;
              display: block;
              right: -44px;
              top: 44px;
              padding: 22px;
              transform: translateY(-50%);
              display: inline-flex;
              justify-content: center;
              align-items: center;

              &-in-app-frame {
                background-color: rgba($color: #000000, $alpha: 0.7);
                background-image: url('../../../../images/redesign/mobile/icons/close-icon.svg');
                background-size: 10px 10px !important;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 50%;
                width: 30px !important;
                height: 30px !important;
                position: absolute;
                display: block;
                right: -25px;
                top: 47px;
                cursor: pointer;
                //padding: 37px;
                transform: translateY(-50%);
                display: inline-flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          .modal-body {
            padding-top: 40px;
            border-radius: 17px;

            .user-settings {
              margin-left: 7px;
              margin-right: 7px;
              max-height: 45vh;
              overflow-y: auto;
              overflow-x: hidden;

              &-in-app-frame {
                max-height: calc(100vh - 350px) !important;
                margin: 0 !important;
                overflow-y: auto;
                overflow-x: hidden;
              }

              .row {
                margin: 0;

                &>div.col-sm-6:first-of-type {
                  padding: 0;
                  max-width: 100%;
                  flex: unset;

                  .user-settings-row-wrapper {
                    padding-left: 0;

                    .user-settings-header {
                      line-height: 135%;
                      margin-bottom: 12px;
                      padding: 0;
                    }

                    .user-settings-row {
                      margin-bottom: 12px;

                      .switch-text {
                        margin-left: 5px;
                      }
                    }

                    .user-settings-divider {
                      width: 100%;
                      margin-left: 0;
                      max-width: none;
                    }
                  }
                }

                &>div.col-sm-6:last-of-type {
                  padding: 0;
                  margin-top: 25px;
                  max-width: 100%;
                  flex: unset;

                  .user-settings-header {
                    margin-bottom: 12px;
                    padding-left: 0;
                  }

                  .user-settings-row {
                    padding-left: 0;
                    margin-bottom: 12px;
                  }

                  .user-settings-space {
                    display: none;
                  }

                  .user-settings-toggles {
                    bottom: 0;
                    margin-top: 25px;

                    .user-settings-row {
                      .row {
                        .col-sm-12 {
                          padding-left: 0;

                          .user-settings-label {
                            margin-bottom: 12px;
                          }
                        }

                        .switch-text-wrapper {
                          .switch-text {
                            margin-left: 0;
                          }

                          .switch-text:last-of-type {
                            margin-left: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .modal-footer {}
          }

          .modal-footer {
            margin-top: 0;
          }
        }
      }

      .money-history-modal.modal-dialog {
        .modal-content {
          .modal-header {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
          }

          .modal-body {
            border-radius: 17px 17px 0 0 !important;
            border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

            .player-history-tabs {
              padding-bottom: 0;

              .col-sm-12 {
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
                padding: 0;
                display: flex;
                justify-content: center;
                column-gap: 15px;

                .player-history-tab-link {
                  margin-left: 0;

                  a {
                    margin: 0;
                    padding: 0;
                    font-size: var(--largeFontSize);
                    line-height: 136%;
                    padding-bottom: 5px;
                  }
                }
              }
            }

            .player-history-table-header {
              display: flex;
              flex-wrap: nowrap;
              height: auto;
              margin-bottom: 3px;
              margin-left: 24px;
              margin-right: 14px;
              justify-content: space-between;

              .player-history-table-header-col {
                padding: 0;
                color: rgba($color: #fff, $alpha: 0.5);
                font-size: 14px;
                max-width: unset;
                width: auto;
                flex: unset;
              }

              .player-history-table-header-col:nth-of-type(1) {
                width: 13%;
              }

              .player-history-table-header-col:nth-of-type(2) {
                width: 30%;
              }

              .player-history-table-header-col:nth-of-type(3) {
                width: 15%;
              }

              .player-history-table-header-col:nth-of-type(4) {
                width: 14%;
              }

              .player-history-table-header-col:nth-of-type(5) {
                width: 23%;
                text-align: right;
              }
            }

            .player-history-table-scrollarea {
              margin-top: 3px;
              height: 22vh;
              width: 100%;

              .player-history-table-row {
                padding-left: 25px;
                padding-right: 5px;
                margin: 0;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;

                .player-history-table-col {
                  flex: unset;
                  width: auto;
                  max-width: unset;
                  padding-top: 6.5px;
                  padding-bottom: 6.5px;
                  padding-left: 0;
                  padding-right: 0;
                  font-size: 13px;

                  .player-history-table-col-positive {
                    padding: 6px 13px;
                    color: rgba($color: #fff, $alpha: 0.7);
                    float: right;
                  }

                  .player-history-table-col-negative {
                    padding: 6px 13px;
                    color: rgba($color: #fff, $alpha: 0.7);
                    float: right;
                  }
                }

                .player-history-table-col:nth-of-type(1) {
                  width: 13%;
                }

                .player-history-table-col:nth-of-type(2) {
                  width: 30%;
                }

                .player-history-table-col:nth-of-type(3) {
                  color: rgba($color: #fff, $alpha: 0.7);
                  width: 15%;
                }

                .player-history-table-col:nth-of-type(4) {
                  color: rgba($color: #fff, $alpha: 0.7);
                  width: 14%;
                }

                .player-history-table-col:nth-of-type(5) {
                  width: 23%;
                  float: right;
                }
              }
            }
          }

          .modal-footer {
            margin-top: 0px !important;
          }
        }
      }

      .points-history-modal.modal-dialog {
        .modal-content {
          .modal-header {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
          }

          .modal-body {
            border-radius: 17px 17px 0 0 !important;
            border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

            .player-history-tabs {
              padding-bottom: 0;

              .col-sm-12 {
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
                padding: 0;
                display: flex;
                justify-content: center;
                column-gap: 15px;

                .player-history-tab-link {
                  margin-left: 0;

                  a {
                    margin: 0;
                    padding: 0;
                    font-size: var(--largeFontSize);
                    line-height: 136%;
                    padding-bottom: 5px;
                  }
                }
              }
            }

            .player-history-table-header {
              display: flex;
              flex-wrap: nowrap;
              height: auto;
              margin-bottom: 3px;
              margin-left: 24px;
              margin-right: 14px;
              justify-content: space-between;

              .player-history-table-header-col {
                padding: 0;
                color: rgba($color: #fff, $alpha: 0.5);
                font-size: 13px;
                max-width: unset;
                width: auto;
                flex: unset;
              }

              .player-history-table-header-col:nth-of-type(1) {
                width: 13%;
              }

              .player-history-table-header-col:nth-of-type(2) {
                width: 30%;
              }

              .player-history-table-header-col:nth-of-type(3) {
                width: 15%;
              }

              .player-history-table-header-col:nth-of-type(4) {
                width: 14%;
              }

              .player-history-table-header-col:nth-of-type(5) {
                width: 23%;
                text-align: right;
              }
            }

            .player-history-table-scrollarea {
              margin-top: 3px;
              height: 22vh;

              .player-history-table-row {
                padding-left: 25px;
                padding-right: 14px;
                margin: 0;
                align-items: center;
                justify-content: space-between !important;
                margin-bottom: 5px;
                height: auto;

                .player-history-table-col {
                  flex: unset;
                  width: auto;
                  max-width: unset;
                  padding-top: 6.5px;
                  padding-bottom: 6.5px;
                  padding-left: 0;
                  padding-right: 0;
                  font-size: 13px;

                  .player-history-table-col-positive {
                    padding: 6px 13px;
                    color: rgba($color: #fff, $alpha: 0.7);
                    float: right;
                  }

                  .player-history-table-col-negative {
                    padding: 6px 13px;
                    color: rgba($color: #fff, $alpha: 0.7);
                    float: right;
                  }
                }

                .player-history-table-col:nth-of-type(1) {
                  width: 13%;
                }

                .player-history-table-col:nth-of-type(2) {
                  width: 30%;
                }

                .player-history-table-col:nth-of-type(3) {
                  color: rgba($color: #fff, $alpha: 0.7);
                  width: 15%;
                }

                .player-history-table-col:nth-of-type(4) {
                  color: rgba($color: #fff, $alpha: 0.7);
                  width: 14%;
                }

                .player-history-table-col:nth-of-type(5) {
                  width: 23%;
                }
              }
            }
          }

          .modal-footer {
            margin-top: 0px !important;
          }
        }
      }

      .contact-admin-modal.modal-dialog.desktop {
        display: none;
      }

      .contact-admin-modal.modal-dialog {
        .modal-content {
          .modal-body {
            padding-top: 20px;
            border-radius: 17px 17px 0 0 !important;

            .contact-support-chat-description {
              margin-bottom: 2px;

              .col-12 {
                padding-left: 20px;
                padding-right: 25px;
              }
            }

            .contact-support-chat-body-wrapper {
              height: calc(100vh - 240px) !important;

              .contact-support-chat-body-scroll-area {
                .contact-support-chat-message-row {
                  .contact-support-chat-message {
                    .contact-support-chat-message-user {
                      padding: 11px 15px;
                      border-radius: 17px 17px 17px 0px;

                      p {
                        line-height: 1.6;
                      }
                    }

                    .contact-support-chat-message-other {
                      border-radius: 17px 17px 0px 17px;
                    }
                  }

                  .contact-support-chat-message-user-time {
                    margin-bottom: 20px;
                  }
                }
              }
            }

            .contact-support-chat-footer {
              margin-top: 3px;

              .contact-support-chat-footer-input-wrapper {
                .contact-support-chat-footer-input-attach-icon {
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }

      .edit-profile-modal.modal-dialog {

        .modal-content {
          min-height: 270px !important;
        }
        .modal-header {
          .modal-close-img {
            top: 40px !important;
          }
        }
        .modal-body {
          // padding-top: 21px;
          border-radius: 17px 17px 0 0 !important;
          padding-left: 6px;
          padding-right: 6px;
          padding-bottom: 2px;

          .new-input-second-placeholder {
            padding-top: 0;
            padding-bottom: 0;
          }

          .update-profile-section {
            height: 50vh;
            overflow-y: auto;
            overflow-x: hidden;
            justify-content: flex-start;
            padding-left: 21px;
            padding-right: 21px;

            &-iframe {
              height: 72vh;
              max-height: 72vh;
            }

            &-addEmail,
            &-changeEmail,
            &-confirmation {
              height: auto !important;
              min-height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &-changePass {
              min-height: 193px !important;
              height: 193px !important;
              overflow: hidden;
            }

            .update-profile-section-tabs {
              &>div {
                width: auto !important;
                height: auto !important;
                justify-content: center !important;
                column-gap: 19px;

                .new-update-profile-tab {
                  margin: 0;
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 600;
                  font-size: var(--defaultFontSize);
                  line-height: 1.5;

                  &>div {
                    margin-top: 5px;
                  }
                }
              }
            }

            .update-profile-section-tabs+.mt-4.mb-2 {
              margin-top: 0 !important;
              width: 100%;

              .switch-select {
                width: 100%;

                .switch-select-option {
                  width: 33.333%;
                }
              }
            }

            .profile {
              .profile-body-reg-date {
                margin-top: 0;
              }
            }

            .update-profile-section-add-email {
              .new-input-second-placeholder {
                padding-top: 0;
                padding-bottom: 0;
              }

              .add-dob-section {
                .rw-widget-picker.rw-widget-container {
                  .rw-select {
                    //display: none;
                  }
                }

                .custom-birthday {

                  &>input,
                  &>span {
                    font-size: var(--largeFontSize);
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: 1;
                    width: 10px;
                  }

                  &>input.custom-birthday-day {
                    width: 36px;
                    padding: 0;
                  }

                  &>input.custom-birthday-month {
                    width: 36px;
                    padding: 0;
                  }

                  &>input.custom-birthday-year {
                    width: 47px;
                    padding: 0;
                  }
                }
              }

              .add-email-button {
                min-width: auto;
                width: 100%;
              }

              &>.d-flex {
                column-gap: 10px;
              }

              width: 100%;

              &>div {
                width: 100%;
              }

              .add-name-section {
                &>div:first-of-type {
                  width: auto !important;
                }
              }

              .add-surname-section {
                &>div:first-of-type {
                  width: auto !important;
                }
              }
            }

            .change-email-password-section-wrapper {
              column-gap: 10px;
              width: 100%;

              &>div {
                flex: 1;
                text-align: center;
              }
            }

          }
        }

        .modal-footer {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
    }
  }
}

.root-class-design20-light {
  @media screen and (orientation : portrait) {
    .modal {
      .user-settings-modal.modal-dialog {
        .modal-body+img.mobile-close {
          filter: invert(1);
        }
      }

      .money-history-modal.modal-dialog {
        .modal-content {
          .modal-body {
            border-bottom: none !important;

            .player-history-tabs {

              .col-sm-12 {
                border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                .player-history-tab-link {
                  a.player-history-tab-link-text {
                    opacity: 0.7;
                  }

                  a.player-history-tab-link-text-active {
                    opacity: 1;
                  }
                }
              }
            }

            .player-history-table-header {
              .player-history-table-header-col {
                color: rgba($color: #0C0C0C, $alpha: 0.5) !important;
              }
            }

            .player-history-table-scrollarea {
              .player-history-table-row {
                background-color: rgba($color: #000000, $alpha: 0.03);

                .player-history-table-col {

                  .player-history-table-col-positive {
                    color: rgba($color: #fff, $alpha: 0.7);
                  }

                  .player-history-table-col-negative {
                    color: rgba($color: #fff, $alpha: 0.7);
                  }
                }

                .player-history-table-col:nth-of-type(1) {}

                .player-history-table-col:nth-of-type(2) {}

                .player-history-table-col:nth-of-type(3) {
                  color: rgba($color: #0C0C0C, $alpha: 0.7) !important;
                }

                .player-history-table-col:nth-of-type(4) {
                  color: rgba($color: #0C0C0C, $alpha: 0.7) !important;
                }
              }
            }
          }

          .modal-footer {
            margin-top: 0px !important;
          }
        }
      }

      .points-history-modal.modal-dialog {
        .modal-content {
          .modal-body {
            border-bottom: none !important;

            .player-history-tabs {

              .col-sm-12 {
                border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                .player-history-tab-link {
                  a.player-history-tab-link-text {
                    opacity: 0.7;
                  }

                  a.player-history-tab-link-text-active {
                    opacity: 1;
                  }
                }
              }
            }

            .player-history-table-header {
              .player-history-table-header-col {
                color: rgba($color: #0C0C0C, $alpha: 0.5) !important;
              }
            }

            .player-history-table-scrollarea {
              .player-history-table-row {
                background-color: rgba($color: #000000, $alpha: 0.03);

                .player-history-table-col {

                  .player-history-table-col-positive {
                    color: rgba($color: #fff, $alpha: 0.7);
                  }

                  .player-history-table-col-negative {
                    color: rgba($color: #fff, $alpha: 0.7);
                  }
                }

                .player-history-table-col:nth-of-type(1) {}

                .player-history-table-col:nth-of-type(2) {}

                .player-history-table-col:nth-of-type(3) {
                  color: rgba($color: #0C0C0C, $alpha: 0.7) !important;
                }

                .player-history-table-col:nth-of-type(4) {
                  color: rgba($color: #0C0C0C, $alpha: 0.7) !important;
                }
              }
            }
          }

          .modal-footer {
            margin-top: 0px !important;
          }
        }
      }

      .contact-admin-modal.modal-dialog {
        .modal-content {
          .modal-body {
            .contact-support-chat-body-wrapper {
              .contact-support-chat-body-scroll-area {
                overflow: hidden;

                .contact-support-chat-message-row {
                  .contact-support-chat-message {
                    .contact-support-chat-message-user {
                      background: #ECECEC;
                    }

                    .contact-support-chat-message-other {
                      background: #ECECEC;
                    }
                  }
                }

                .scrollbar-container {
                  width: 4px;

                  .scrollbar {
                    width: 4px !important;
                    background: rgba($color: #000000, $alpha: 0.2) !important;
                  }
                }
              }
            }

            .contact-support-chat-footer {
              .contact-support-chat-footer-input-wrapper {
                border: 1px solid #868686;
                border-radius: 25px;
                background: #FFFFFF;

                .contact-support-chat-footer-input {
                  &::-webkit-scrollbar-thumb {
                    background-color: #fff !important;
                  }
                }
              }
            }
          }
        }
      }

      .edit-profile-modal.modal-dialog {
        .modal-footer+img {
          filter: invert(1);
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .modal {
      .modal-dialog {
        .modal-content {
          .modal-header {
            .modal-close-img-in-app-frame,
            .modal-close-img {
              filter: invert(1);
            }
          }
        }
      }

      .money-history-modal.modal-dialog {
        .modal-content {
          .modal-body {
            border-bottom: none !important;

            .player-history-tabs {

              .col-sm-12 {
                border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                .player-history-tab-link {
                  a.player-history-tab-link-text {
                    opacity: 0.7;
                  }

                  a.player-history-tab-link-text-active {
                    opacity: 1;
                  }
                }
              }
            }

            .player-history-table-header {
              .player-history-table-header-col {
                color: rgba($color: #0C0C0C, $alpha: 0.5) !important;
              }
            }

            .player-history-table-scrollarea {
              .player-history-table-row {
                background-color: rgba($color: #000000, $alpha: 0.03);

                .player-history-table-col {

                  .player-history-table-col-positive {
                    color: rgba($color: #fff, $alpha: 0.7);
                  }

                  .player-history-table-col-negative {
                    color: rgba($color: #fff, $alpha: 0.7);
                  }
                }

                .player-history-table-col:nth-of-type(1) {}

                .player-history-table-col:nth-of-type(2) {}

                .player-history-table-col:nth-of-type(3) {
                  color: rgba($color: #0C0C0C, $alpha: 0.7) !important;
                }

                .player-history-table-col:nth-of-type(4) {
                  color: rgba($color: #0C0C0C, $alpha: 0.7) !important;
                }
              }
            }
          }

          .modal-footer {
            margin-top: 0px !important;
          }

          .modal-footer+img {
            filter: invert(1);
          }
        }
      }

      .points-history-modal.modal-dialog {
        .modal-content {
          .modal-body {
            border-bottom: none !important;

            .player-history-tabs {

              .col-sm-12 {
                border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                .player-history-tab-link {
                  a.player-history-tab-link-text {
                    opacity: 0.7;
                  }

                  a.player-history-tab-link-text-active {
                    opacity: 1;
                  }
                }
              }
            }

            .player-history-table-header {
              .player-history-table-header-col {
                color: rgba($color: #0C0C0C, $alpha: 0.5) !important;
              }
            }

            .player-history-table-scrollarea {
              .player-history-table-row {
                background-color: rgba($color: #000000, $alpha: 0.03);

                .player-history-table-col {

                  .player-history-table-col-positive {
                    color: rgba($color: #fff, $alpha: 0.7);
                  }

                  .player-history-table-col-negative {
                    color: rgba($color: #fff, $alpha: 0.7);
                  }
                }

                .player-history-table-col:nth-of-type(1) {}

                .player-history-table-col:nth-of-type(2) {}

                .player-history-table-col:nth-of-type(3) {
                  color: rgba($color: #0C0C0C, $alpha: 0.7) !important;
                }

                .player-history-table-col:nth-of-type(4) {
                  color: rgba($color: #0C0C0C, $alpha: 0.7) !important;
                }
              }
            }
          }

          .modal-footer {
            margin-top: 0px !important;
          }

          .modal-footer+img {
            filter: invert(1);
          }
        }
      }

      .contact-admin-modal.modal-dialog {
        .modal-content {
          .modal-body {
            .contact-support-chat-body-wrapper {
              .contact-support-chat-body-scroll-area {
                .contact-support-chat-message-row {
                  .contact-support-chat-message {
                    .contact-support-chat-message-user {
                      background: #ECECEC;
                    }

                    .contact-support-chat-message-other {
                      background: #ECECEC;
                    }
                  }
                }
              }
            }

            .contact-support-chat-footer {
              .contact-support-chat-footer-input-wrapper {
                border: 1px solid #868686;
                border-radius: 25px;
                background: #FFFFFF;

                .contact-support-chat-footer-input {
                  &::-webkit-scrollbar-thumb {
                    background-color: #fff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.root-class-design20-normal {
  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .modal {
      .modal-dialog {
        .modal-content {
          .modal-header {
            .modal-close-img-in-app-frame,
            .modal-close-img {
              filter: invert(1);
            }
          }
        }
      }
    }
  }

  @media screen and (orientation : portrait) {
    .modal-content {
      .mobile-close {
        filter: invert(1);
      }
    }

    .modal-close-img {
      filter: unset;
    }
  }
}
