
/*
$rowInnergroundOpacity: 0.9;
$rowBackgroundOpacity: 0.95;

$rowPrimaryColour: #FFF;
$rowSecondaryColour: #FFC900;

$rowHeight: 3.6vmin; */

// @import "../../redesignVariables";
.root-class-design20 {

  .menu-table-row {

  //  background-color: $bodyTopBackgroundColour;
  //  opacity: $bodyTopBackgroundOpacity;

    display: block;
    flex: none;
    width: 100%;
    margin: 0;

    &-wrapper {
      overflow: hidden;
      width: 100%;
      // min-height: 42px;
      // min-height: 48px;
    //  height: 60px;
    //  padding: 10px;
      margin: 0;
    //  padding-bottom: 5px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 2px;

      // vertical-align: middle;

      // padding-bottom: 0.5vmin;
      // height: 3.6vmin;
      &-compact-height {
        min-height: unset !important;
        overflow: hidden;
        width: 100%;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 2px;
      }
    }

    &-inner-wrapper {
      // box-shadow: 0 0 0 500px rgba(0, 0, 0, $menuRowBackgroundOpacity);
      // background-color: rgba(0, 0, 0, $menuRowInnergroundOpacity);
      // background-color: $darkTableRowBackgroundColor;
      border-radius: 44px;
      padding-top: 4px;
      padding-bottom: 4px;
      // padding: 0;
      padding-left: 2px;
      padding-right: 3px;
      // height: 100%;

      // margin: auto;
      font-size: var(--defaultFontSize);
      // line-height: 3.6vmin;

      // &-noback {
      //   background-color: unset !important;
      // }
      & > .row {
        margin: 0;
        height: 100%;

        & > div {
          height: 100%;
          padding-left: 0px;
          padding-right: 2px;

        }
      }
    }

    &-filler {
      background-color: rgba(0, 0, 0, $menuRowBackgroundOpacity);
      height: 45px;
    }

    &-filler-out {
      background-color: rgba(0, 0, 0, $menuRowBackgroundOpacity);
      flex-grow: 1;
    }


    &-default-button {
      justify-content: center;
      align-items: center;
      display: inline-flex;

      box-sizing: border-box;
      border-radius: 99px;

      font-size: var(--largeFontSize);
      text-decoration: none;
      text-transform: uppercase;

      vertical-align: middle;
      margin-top: 3px;

      min-width: 94px;
      padding: 4px 3px;

      &:before {
        display: inline-block;
        content: ' ';
        background-image: url('../../../../images/redesign/common/karavs.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        margin-right: 7px;
      }

      &:after {
        display: inline-block;
        content: ' ';
        background-image: url('../../../../images/redesign/common/karavs.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        margin-left: 7px;
      }
    }

    &-text {
      vertical-align: middle;
      display: inline-block;
      text-overflow: ellipsis;

      // height: $menuRowHeight;
      // line-height: $menuRowHeight;
      position: relative;
    }

    &-player {
      vertical-align: middle;
      display: inline-block;

      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;

      height: $menuRowHeight;
      min-height: 36px;

      &-block {
        height: 3.4vmin;
        width: 3.4vmin;
        margin-top: 0.1vmin;
        min-height: 38px;
        min-width: 38px;
        margin-right: -2px;

        cursor: pointer;
      }

      &-text {
        vertical-align: middle;
        display: inline-block;
        text-overflow: ellipsis;

        height: $menuRowHeight;
        line-height: $menuRowHeight;
        position: relative;
      }
      &-blocked-text {
        &-normal {
          overflow: hidden;
          width: 158px;
        }
        &-large {
          overflow: hidden;
          width: 162px;
        }
        &-xLarge {
          overflow: hidden;
          width: 203px;
        }
      }

      &-image {
        width: 36px;
        height: 36px;

        min-width: 36px;
        min-height: 36px;

        border-radius: 50%;

        &-default {
          padding: 5px;
        }

        &-frame {
          width: 36px;
          height: 36px;

          min-width: 36px;
          min-height: 36px;

          margin: 0px;
          margin-right: 8px;
          vertical-align: middle;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.2);
          display: inline-block;
          position: relative;
        }
      }

      &-icon {
        display: inline-block;
        text-align: left;
        width: auto;
        height: 1.6vmin;
        padding-left: 3px;
        padding-right: 3px;

        &-wrapper {
          position: relative;
          text-align: left;
          // font-size: 1.6vmin;
          font-size: var(--headerFontSize);
          margin-left: auto;
          margin-right: auto;

          &-center {
            text-align: center;
          }
        }

        &-text {
          display: inline-block;
          position: relative;
          text-align: left;
          font-size: var(--defaultFontSize);
          color: #FFF;
          padding-right: 3px;
        }
      }
    }

    &-weekly-large-trend {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #009C10;
      padding: 4px 13px;
      line-height: 20.8px;
      border-radius: 44px;
      width: 50px;
      margin: 0 auto;
    }

    &-weekly-low-trend {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #D2110A;
      padding: 4px 13px;
      line-height: 20.8px;
      border-radius: 44px;
      width: 50px;
      margin: 0 auto;
    }

    &-weekly-neutral-trend {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffce5a;
      padding: 4px 13px;
      line-height: 20.8px;
      border-radius: 44px;
      width: 50px;
      margin: 0 auto;

      color: black;
    }
  }
}

.root-class-design20-light {
  .menu-table-row-default-button {
    color: #0C0C0C;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
      background: rgba(0, 0, 0, 0.4);
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      border-radius: 99px;

      color: $menuRowPrimaryColour;
    }
  }
}

.root-class-design20-normal {
  .menu-table-row-default-button {
    color: #FFF;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.root-class-design20-dark {
  .menu-table-row-default-button {
    color: #FFF;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}