$mobileLandScapeWidth: 1280px;

@media screen and (orientation : portrait) {
    .root-class-design20 {
        .landing-mobile-termsOfUse-header {
            display: none !important;
        }

        .new-design-landing-container {
            .terms-of-use-container {
                .zole-rules-page {
                    &>div:first-of-type {
                        .zole-rules-page-title {
                            margin-bottom: 17px;
                        }
                    }

                    .zole-rules-page-content {
                        margin-top: 0;

                        &>div {
                            &>p {
                                color: #fff;
                                font-family: 'Open Sans';
                                font-size: 15px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 160%;
                                margin-bottom: 6px;
                            }

                            &>p.second-level-bulletpoints {
                                b {
                                    font-family: 'Open Sans';
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 27.24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .root-class-design20 {
        .landing-mobile-termsOfUse-header {
            display: none !important;
        }

        .new-design-landing-container {
            .terms-of-use-container {
                min-height: 100vh;
                width: calc(100vw - 57px);
                display: block;

                .zole-rules-page {
                    margin-top: 0;
                    width: 100%;
                    width: 100%;
                    margin-top: 0;
                    min-height: auto;
                    margin-bottom: auto;
                    margin-left: 0;
                    margin-right: 0;
                    padding-top: 14px !important;
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                    padding-bottom: 100px !important;

                    &>div:first-of-type {
                        padding-left: 0;
                        padding-right: 0;

                        .zole-rules-page-title {
                            margin-bottom: 17px;
                            margin-left: 0;
                        }
                    }

                    .zole-rules-page-body {
                        padding-left: 0;
                        padding-right: 0;
                        margin-left: 0;
                    }

                    .zole-rules-page-content {
                        margin-top: 0;

                        &>div {
                            &>p {
                                color: #fff;
                                font-family: 'Open Sans';
                                font-size: 15px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 160%;
                                margin-bottom: 6px;
                            }

                            &>p.second-level-bulletpoints {
                                b {
                                    font-family: 'Open Sans';
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 27.24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
