// @import "../../redesignVariables";

$rowHeight: $menuRowHeight;

//$buttonPrimaryColour: #FFF;
//$buttonSecondaryColour: #000;
//$buttonBackgroundColour: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
$mobileLandScapeWidth: 1280px;

.root-class-design20 {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-power-rankings-page {

      .layout-mobile-body-main {
        height: calc(100vh - 370px) !important;
        padding: 0 !important;
        margin: 11px 0 0 0 !important;

        &-title {
          display: none;
        }
        .new-power-rankings {

          &-list-part-loading {
            height: 100%;
            width: 100%;
          }

          &-scrollarea {
            max-height: calc(100vh - 370px) !important;
            height: calc(100vh - 420px) !important;
          }

          &-header-tutorial {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            top: 8px;
            right: 18px;
            & img {
              width: 56px;
              height: 56px;
              margin-top: 2px;
              cursor: pointer;
              opacity: 1;
              float: right;
            }
          }
      
          &-header {
            height: 35px;
            margin-bottom: 10px;
            font-weight: 600;

            &-section {
              position: relative;
            }
      
            &-switch-section {
              display: flex;
              flex-direction: row;
              margin-left: 50px;
              margin-right: 5px;
              margin-bottom: 5px;
      
              & label.active {
                opacity: 1;
              }
      
              & label.disable {
                opacity: 0.5;
              }
      
              & label {
                font-weight: 400;
                margin-right: 5px;
                font-size: var(--headerFontSize);
                line-height: 160%;
              }

              .new-switch-wrapper > input[type="checkbox"]:disabled + .switch {
                background: transparent !important;
              }
            }
      
            &-tutorial {
              width: 56px;
              height: 56px;
              margin-top: 2px;
              cursor: pointer;
            }
          }
      
          &-body {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            word-break: break-word;
            padding: 0 30px 10px 30px;
            align-items: flex-end;
            row-gap: 30px;
            //padding-bottom: 100px;
      
            &-modal-content {
              font-size: var(--defaultFontSize);
            }
      
            & .player-info-player-background {
              width: 100% !important;
              & .player-info-player-name-wrapper {
                & .player-info-player-name {
                  font-size: var(--xLargeFontSize);
                  line-height: 140%;
                }
              }
            }
      
            & .player-info-player-image-wrapper {
              width: 100% !important;
            }
      
            &-item {
              width: 28%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              // margin-top: 20px;
              // height: 350px;
              &-second-row {
                width: 33% !important;
              }
              &-remaining-rows {
                width: 25% !important;
              }
      
              &-label {
                display: flex;
                justify-content: center;
                position: relative;
                &-key {
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  background: linear-gradient(180deg, #333333 0%, #121212 100%);
                  font-family: "Futura PT";
                  font-style: normal;
                  font-weight: 700;
                  font-size: var(--xLargeFontSize);
                  line-height: 26px;
                  text-align: center;
                  color: #fff;
                  position: absolute;
                  z-index: 20;
                  line-height: 25px;
                  vertical-align: middle;
                  word-break: normal !important;
                }
                &-key-top {
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  background: linear-gradient(180deg, #333333 0%, #121212 100%);
                  font-family: "Futura PT";
                  font-style: normal;
                  font-weight: 700;
                  font-size: var(--xLargeFontSize) !important;
                  line-height: 26px;
                  text-align: center;
                  color: #fff;
                  position: absolute;
                  z-index: 20;
                  line-height: 25px;
                  vertical-align: middle;
                  word-break: normal !important;
                }
                &-addition {
                  // width: 81px;
                  // height: 36px;
                  white-space: nowrap;
                  padding: 2px 11px;
                  border-radius: 88px;
                  background-color: #fff;
                  position: absolute;
                  bottom: -50px;
                  margin: 0;
                  z-index: 10;
                  color: #000;
                  font-size: var(--headerFontSize);
                  font-weight: 600;
                  text-align: center;
                  line-height: 140%;
                  vertical-align: middle;
                }
              }
      
              &-photo {
                position: relative;
                width: 100%;
                // width: 122px;
                // height: 122px;
                display: flex;
                justify-content: center;
                margin-top: 50px;
      
                & .player-info-player-image-wrapper {
                  max-width: 122px !important;
                }

                
                & .player-info-player-background {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .player-info-player-image-wrapper {
                    position: relative;
                    left: 0;
                    top: 0;
                    display: inline-flex;
                    justify-content: center;

                    .player-info-player-image {
                      background-size: contain;
                      background-repeat: no-repeat;
                      width: 51px;
                      height: 51px;
                      box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
                      -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
                      filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
                      border-radius: 50%;
                    }
                  }

                  .player-info-player-name-wrapper {
                    background: #000000;
                    border-radius: 88px;
                    padding: 1px 12px;
                    margin-top: 3px;

                    .player-info-player-name {
                      font-size: var(--defaultFontSize);
                      font-family: 'Open Sans';
                      font-style: normal;
                      font-weight: 600;
                      color: #FFFFFF;
                      // white-space: nowrap;
                      word-break: normal !important;
                      text-align: center !important;
                    }
                  }
                }
              }
      
              &-name {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 30px;
                font-size: var(--xLargeFontSize);
                font-weight: 600;
              }
      
              &-score {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                font-size: var(--largeFontSize);
                justify-content: center;
                &-zole-coins {
                  width: 50%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-evenly;
                  align-items: center;
      
                  & img {
                    width: 21px;
                  }
      
                  & label {
                  }
                }
      
                &-zole-points {
                  width: 50%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-evenly;
                  align-items: center;
      
                  & img {
                    width: 21px !important;
                  }
      
                  & label {
                  }
                }
              }
            }
            &-item-lg {
              width: 33% !important;
      
              & .new-power-rankings-body-item-label {
                height: 26px;
                &-key {
                  width: 26px;
                  height: 100%;
                  text-align: center;
                  color: #000000;
                  margin: 0;
                  font-size: var(--largeFontSize);
                  text-shadow: 1px 1px 0 #fff;
                  -webkit-text-stroke: 1px white;
                  vertical-align: middle;
                  font-weight: 700;
                  line-height: 26px;
                }
              }
      
              & .new-power-rankings-body-item-label-addition {
                white-space: nowrap;
                padding: 2px 11px;
                bottom: calc(-100%);
                margin: 0;
                font-size: var(--xLargeFontSize);
                line-height: 140%;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
              }
      
              & .new-power-rankings-body-item-photo {
                // width: 180px;
                width: 100%;
                // height: 180px;
                margin-top: 25px;
                & .player-info-player-image-wrapper {
                  max-width: 180px !important;
                }

                & .player-info-player-background {

                  .player-info-player-image-wrapper {

                    .player-info-player-image {
                      width: 68px;
                      height: 68px;
                    }
                  }

                  .player-info-player-name-wrapper {

                    .player-info-player-name {
                      word-break: normal !important;
                      text-align: center !important;
                    }
                  }
                }
              }
      
              & .new-power-rankings-body-item-label-addition:before {
                position: absolute;
                left: -50px;
                top: -20px;
                content: " ";
                background-image: url("../../../../images/redesign/components/modal/modal_stars.png");
                background-size: 65px 65px;
                height: 65px;
                width: 65px;
              }
      
              & .new-power-rankings-body-item-label-addition:after {
                position: absolute;
                right: -50px;
                top: -20px;
                content: " ";
                background-image: url("../../../../images/redesign/components/modal/modal_stars.png");
                background-size: 65px 65px;
                height: 65px;
                width: 65px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
              }
      
              & .new-power-rankings-body-item-score {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                font-size: var(--largeFontSize);
                justify-content: center;
              }
              & .new-power-rankings-body-item-score-zole-coins {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-coins img {
                width: 21px;
              }
      
              & .new-power-rankings-body-item-score-zole-points {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-points img {
                width: 21px;
              }
            }

            &-item-lg-no-stars {
              & .new-power-rankings-body-item-label-addition::before {
                display: none !important
              }
      
              & .new-power-rankings-body-item-label-addition::after {
                display: none !important
              }
            }
      
            &-item-md {
              width: 33% !important;
      
              & .new-power-rankings-body-item-label {
                height: 26px;
                &-key {
                  width: 26px;
                  height: 100%;
                  text-align: center;
                  color: #000000;
                  margin: 0;
                  font-size: var(--largeFontSize);
                  text-shadow: 1px 1px 0 #fff;
                  -webkit-text-stroke: 1px white;
                  vertical-align: middle;
                  line-height: 25px;
                }
              }
      
              & .new-power-rankings-body-item-label-addition {
                // width: 91px;
                // height: 42px;
                white-space: nowrap;
                padding: 2px 11px;
                bottom: calc(-100%);
                margin: 0;
                font-size: var(--xLargeFontSize);
                line-height: 140%;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
              }
      
              & .new-power-rankings-body-item-photo {
                // width: 142px;
                // height: 156px;
                margin-top: 25px;
                width: 100%;
                & .player-info-player-image-wrapper {
                  max-width: 156px !important;
                }

                & .player-info-player-background {

                  .player-info-player-image-wrapper {

                    .player-info-player-image {
                      width: 51px;
                      height: 51px;
                    }
                  }

                  .player-info-player-name-wrapper {

                    .player-info-player-name {
                      word-break: normal !important;
                      text-align: center !important;
                    }
                  }
                }
              }
      
              & .new-power-rankings-body-item-score {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                font-size: var(--largeFontSize);
                justify-content: center;
              }
              & .new-power-rankings-body-item-score-zole-coins {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-coins img {
                width: 21px;
              }
      
              & .new-power-rankings-body-item-score-zole-points {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-points img {
                width: 21px;
              }
            }
      
            &-item-sm {
              width: 28% !important;
      
              & .new-power-rankings-body-item-label {
                height: 26px;
                &-key {
                  width: 26px;
                  height: 100%;
                  text-align: center;
                  color: #000000;
                  margin: 0;
                  font-size: var(--largeFontSize);
                  text-shadow: 1px 1px 0 #fff;
                  -webkit-text-stroke: 1px white;
                  vertical-align: middle;
                  line-height: 25px;
                }
              }
      
              & .new-power-rankings-body-item-label-addition {
                // width: 91px;
                // height: 42px;
                white-space: nowrap;
                padding: 2px 11px;
                bottom: calc(-100%);
                margin: 0;
                font-size: var(--xLargeFontSize);
                line-height: 140%;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
              }
      
              & .new-power-rankings-body-item-photo {
                margin-top: 25px;
                width: 100%;
                & .player-info-player-image-wrapper {
                  max-width: 135px !important;
                }
                & .player-info-player-background {

                  .player-info-player-image-wrapper {

                    .player-info-player-image {
                      width: 47px;
                      height: 47px;
                    }
                  }

                  .player-info-player-name-wrapper {

                    .player-info-player-name {
                      word-break: normal !important;
                      text-align: center !important;
                    }
                  }
                }
              }
      
              & .new-power-rankings-body-item-score {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                font-size: var(--largeFontSize);
                justify-content: center;
              }
              & .new-power-rankings-body-item-score-zole-coins {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-coins img {
                width: 21px;
              }
      
              & .new-power-rankings-body-item-score-zole-points {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-points img {
                width: 21px;
              }
            }
          }
      
          &-modal {
            & .modal-content .modal-body {
              font-size: var(--largeFontSize);
            }
      
            &-close {
              width: 21px;
              height: 21px;
              color: #ffd34e;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {

    .layout-mobile-body-power-rankings-page {

      .layout-mobile-body-main {
        // height: calc(100vh - 330px) !important;
        // padding: 0 !important;
        // margin: 11px 0 0 0 !important;

        &-title {
          display: block !important;
        }
        .new-power-rankings {
          &-list-part-loading {
            height: calc(100vh - 127px);
            width: 100%;
          }

          &-scrollarea {
            max-height: calc(100vh - 127px) !important;
            height: calc(100vh - 127px) !important;
          }

          &-header-tutorial {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            top: 8px;
            right: 18px;
            & img {
              width: 56px;
              height: 56px;
              margin-top: 2px;
              cursor: pointer;
              opacity: 1;
              float: right;
            }
          }
      
          &-header {
            height: 35px;
            margin-bottom: 10px;
            font-weight: 600;

            &-section {
              position: relative;
            }
      
            &-switch-section {
              display: flex;
              flex-direction: row;
              margin-left: 50px;
              margin-right: 5px;
              margin-bottom: 5px;
      
              & label.active {
                opacity: 1;
              }
      
              & label.disable {
                opacity: 0.5;
              }
      
              & label {
                font-weight: 400;
                margin-right: 5px;
                font-size: var(--headerFontSize);
                line-height: 160%;
              }
              .new-switch-wrapper > input[type="checkbox"]:disabled + .switch {
                background: transparent !important;
              }
            }
      
            &-tutorial {
              width: 56px;
              height: 56px;
              margin-top: 2px;
              cursor: pointer;
            }
          }
      
          &-body {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            word-break: break-word;
            padding: 0 30px 10px 30px;
            align-items: flex-end;
            row-gap: 30px;
            //padding-bottom: 100px;
      
            &-modal-content {
              font-size: var(--defaultFontSize);
            }
      
            & .player-info-player-background {
              width: 100% !important;
              & .player-info-player-name-wrapper {
                & .player-info-player-name {
                  font-size: var(--xLargeFontSize);
                  line-height: 140%;
                }
              }
            }
      
            & .player-info-player-image-wrapper {
              width: 100% !important;
            }
      
            &-item {
              width: 28%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              // margin-top: 20px;
              // height: 350px;

              &-second-row {
                width: 33% !important;
              }
              &-remaining-rows {
                width: 25% !important;
              }
      
              &-label {
                display: flex;
                justify-content: center;
                position: relative;
                &-key {
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  background: linear-gradient(180deg, #333333 0%, #121212 100%);
                  font-family: "Futura PT";
                  font-style: normal;
                  font-weight: 700;
                  font-size: var(--xLargeFontSize);
                  line-height: 26px;
                  text-align: center;
                  color: #fff;
                  position: absolute;
                  z-index: 20;
                  line-height: 25px;
                  vertical-align: middle;
                  word-break: normal !important;
                }
                &-key-top {
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  background: linear-gradient(180deg, #333333 0%, #121212 100%);
                  font-family: "Futura PT";
                  font-style: normal;
                  font-weight: 700;
                  font-size: var(--xLargeFontSize) !important;
                  line-height: 26px;
                  text-align: center;
                  color: #fff;
                  position: absolute;
                  z-index: 20;
                  line-height: 25px;
                  vertical-align: middle;
                  word-break: normal !important;
                }
                &-addition {
                  // width: 81px;
                  // height: 36px;
                  white-space: nowrap;
                  padding: 2px 11px;
                  border-radius: 88px;
                  background-color: #fff;
                  position: absolute;
                  bottom: -50px;
                  margin: 0;
                  z-index: 10;
                  color: #000;
                  font-size: var(--headerFontSize);
                  font-weight: 600;
                  text-align: center;
                  line-height: 140%;
                  vertical-align: middle;
                }
              }
      
              &-photo {
                position: relative;
                width: 100%;
                // width: 122px;
                // height: 122px;
                display: flex;
                justify-content: center;
                margin-top: 50px;
      
                & .player-info-player-image-wrapper {
                  max-width: 122px !important;
                }

                
                & .player-info-player-background {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .player-info-player-image-wrapper {
                    position: relative;
                    left: 0;
                    top: 0;
                    display: inline-flex;
                    justify-content: center;

                    .player-info-player-image {
                      background-size: contain;
                      background-repeat: no-repeat;
                      width: 51px;
                      height: 51px;
                      box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
                      -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
                      filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
                      border-radius: 50%;
                    }
                  }

                  .player-info-player-name-wrapper {
                    background: #000000;
                    border-radius: 88px;
                    padding: 1px 12px;
                    margin-top: 3px;

                    .player-info-player-name {
                        font-size: var(--defaultFontSize);
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        color: #FFFFFF;
                        white-space: nowrap;
                    }
                  }
                }
              }
      
              &-name {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 30px;
                font-size: var(--xLargeFontSize);
                font-weight: 600;
              }
      
              &-score {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                font-size: var(--largeFontSize);
                justify-content: center;
                &-zole-coins {
                  width: 50%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-evenly;
                  align-items: center;
      
                  & img {
                    width: 21px;
                  }
      
                  & label {
                  }
                }
      
                &-zole-points {
                  width: 50%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-evenly;
                  align-items: center;
      
                  & img {
                    width: 21px !important;
                  }
      
                  & label {
                  }
                }
              }
            }
            &-item-lg {
              width: 33% !important;
      
              & .new-power-rankings-body-item-label {
                height: 26px;
                &-key {
                  width: 26px;
                  height: 100%;
                  text-align: center;
                  color: #000000;
                  margin: 0;
                  font-size: var(--largeFontSize);
                  text-shadow: 1px 1px 0 #fff;
                  -webkit-text-stroke: 1px white;
                  vertical-align: middle;
                  font-weight: 700;
                  line-height: 26px;
                }
              }
      
              & .new-power-rankings-body-item-label-addition {
                white-space: nowrap;
                padding: 2px 11px;
                bottom: calc(-100%);
                margin: 0;
                font-size: var(--xLargeFontSize);
                line-height: 140%;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
              }
      
              & .new-power-rankings-body-item-photo {
                // width: 180px;
                width: 100%;
                // height: 180px;
                margin-top: 25px;
                & .player-info-player-image-wrapper {
                  max-width: 180px !important;
                }

                & .player-info-player-background {

                  .player-info-player-image-wrapper {

                    .player-info-player-image {
                      width: 68px;
                      height: 68px;
                    }
                  }

                  .player-info-player-name-wrapper {

                    .player-info-player-name {
                      
                    }
                  }
                }
              }
      
              & .new-power-rankings-body-item-label-addition:before {
                position: absolute;
                left: -50px;
                top: -20px;
                content: " ";
                background-image: url("../../../../images/redesign/components/modal/modal_stars.png");
                background-size: 65px 65px;
                height: 65px;
                width: 65px;
              }
      
              & .new-power-rankings-body-item-label-addition:after {
                position: absolute;
                right: -50px;
                top: -20px;
                content: " ";
                background-image: url("../../../../images/redesign/components/modal/modal_stars.png");
                background-size: 65px 65px;
                height: 65px;
                width: 65px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
              }
      
              & .new-power-rankings-body-item-score {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                font-size: var(--largeFontSize);
                justify-content: center;
              }
              & .new-power-rankings-body-item-score-zole-coins {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-coins img {
                width: 21px;
              }
      
              & .new-power-rankings-body-item-score-zole-points {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-points img {
                width: 21px;
              }
            }
      
            &-item-lg-no-stars {
              & .new-power-rankings-body-item-label-addition::before {
                display: none !important
              }
      
              & .new-power-rankings-body-item-label-addition::after {
                display: none !important
              }
            }
            
            &-item-md {
              width: 33% !important;
      
              & .new-power-rankings-body-item-label {
                height: 26px;
                &-key {
                  width: 26px;
                  height: 100%;
                  text-align: center;
                  color: #000000;
                  margin: 0;
                  font-size: var(--largeFontSize);
                  text-shadow: 1px 1px 0 #fff;
                  -webkit-text-stroke: 1px white;
                  vertical-align: middle;
                  line-height: 25px;
                }
              }
      
              & .new-power-rankings-body-item-label-addition {
                // width: 91px;
                // height: 42px;
                white-space: nowrap;
                padding: 2px 11px;
                bottom: calc(-100%);
                margin: 0;
                font-size: var(--xLargeFontSize);
                line-height: 140%;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
              }
      
              & .new-power-rankings-body-item-photo {
                // width: 142px;
                // height: 156px;
                margin-top: 25px;
                width: 100%;
                & .player-info-player-image-wrapper {
                  max-width: 156px !important;
                }

                & .player-info-player-background {

                  .player-info-player-image-wrapper {

                    .player-info-player-image {
                      width: 51px;
                      height: 51px;
                    }
                  }

                  .player-info-player-name-wrapper {

                    .player-info-player-name {
                      
                    }
                  }
                }
              }
      
              & .new-power-rankings-body-item-score {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                font-size: var(--largeFontSize);
                justify-content: center;
              }
              & .new-power-rankings-body-item-score-zole-coins {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-coins img {
                width: 21px;
              }
      
              & .new-power-rankings-body-item-score-zole-points {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-points img {
                width: 21px;
              }
            }
      
            &-item-sm {
              width: 33% !important;
      
              & .new-power-rankings-body-item-label {
                height: 26px;
                &-key {
                  width: 26px;
                  height: 100%;
                  text-align: center;
                  color: #000000;
                  margin: 0;
                  font-size: var(--largeFontSize);
                  text-shadow: 1px 1px 0 #fff;
                  -webkit-text-stroke: 1px white;
                  vertical-align: middle;
                  line-height: 25px;
                }
              }
      
              & .new-power-rankings-body-item-label-addition {
                // width: 91px;
                // height: 42px;
                white-space: nowrap;
                padding: 2px 11px;
                bottom: calc(-100%);
                margin: 0;
                font-size: var(--xLargeFontSize);
                line-height: 140%;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
              }
      
              & .new-power-rankings-body-item-photo {
                margin-top: 25px;
                width: 100%;
                & .player-info-player-image-wrapper {
                  max-width: 135px !important;
                }
                & .player-info-player-background {

                  .player-info-player-image-wrapper {

                    .player-info-player-image {
                      width: 47px;
                      height: 47px;
                    }
                  }

                  .player-info-player-name-wrapper {

                    .player-info-player-name {
                      
                    }
                  }
                }
              }
      
              & .new-power-rankings-body-item-score {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                font-size: var(--largeFontSize);
                justify-content: center;
              }
              & .new-power-rankings-body-item-score-zole-coins {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-coins img {
                width: 21px;
              }
      
              & .new-power-rankings-body-item-score-zole-points {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
              }
              & .new-power-rankings-body-item-score-zole-points img {
                width: 21px;
              }
            }
          }
      
          &-modal {
            & .modal-content .modal-body {
              font-size: var(--largeFontSize);
            }
      
            &-close {
              width: 21px;
              height: 21px;
              color: #ffd34e;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.root-class-design20-light {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-power-rankings-page {

      .layout-mobile-body-main {

        .player-info-player-name-wrapper {
          background: #fff !important;
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-power-rankings-page {

      .layout-mobile-body-main {
        &-title {
          display: block !important;
          //color: #FF8A1A;
          font-size: var(--largeFontSize);
          font-weight: 600;
        }
        .player-info-player-name-wrapper {
          background: #fff !important;
        }
      }
    }
  }
}

.root-class-design20-normal {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-power-rankings-page {

      .layout-mobile-body-main {

        .player-info-player-name-wrapper {
          background: #253678 !important;
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-power-rankings-page {

      .layout-mobile-body-main {
        &-title {
          display: block !important;
          //color: #FF8A1A;
          font-size: var(--largeFontSize);
          font-weight: 600;
        }
        .player-info-player-name-wrapper {
          background: #253678 !important;
        }
      }
    }
  }
}

.root-class-design20 {
  
}
.root-font-large .new-switch-wrapper {
  margin-top: 5px;
}

.root-font-xLarge .new-switch-wrapper {
  margin-top: 8px;
}

.root-font-large .new-switch-wrapper > .switch > .switch-handle {
  top: -7px;
}

.root-font-xLarge .new-switch-wrapper > .switch > .switch-handle {
  top: -12px;
}

.new-power-rankings-main-section {
  .new-power-rankings-scrollarea {
    width: calc(100% - 4px);
  }
}

.my-info-ratings-step-wrapper {
  justify-content: center;
}