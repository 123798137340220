$mobileLandScapeWidth: 1280px;

.root-class-design20 {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-game-logs-page {

      .layout-mobile-body-main {
        height: calc(100vh - 370px) !important;
        padding: 0 !important;
        margin: 11px 0 0 0 !important;

        &-title {
          display: none;
        }
        .new-game-logs {
          padding: unset !important;
        
          width: 100%;
          height: 100%;
          overflow-x: auto !important;
          overflow-y: auto !important;

          &::-webkit-scrollbar {
            height: 4px;
          }

          &-scrollarea {
            // max-height: 60vh;
        
            &-body {
              &-no-games {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              }
            }
          }
          &-table-header-player-name {
            color: white;
            font-weight: 600 !important;
            font-family: Open Sans !important;
            line-height: 1 !important;
            font-size: var(--defaultFontSize) !important;
            padding-bottom: 10px !important;
            text-align: center;
          }
          &-table {
            width: 100%;
            border-collapse: collapse;
            border-collapse: separate;
            border-spacing: 0 5px;
            padding: 0 15px;
            
            &-header {
              &-player {
                & img {
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
                  padding: 2px;
                }
              }
        
              th:nth-child(2) {
                font-family: 'Futura PT';
                font-style: normal;
                font-weight: 450;
                font-size: var(--largeFontSize);
                color: rgba($color: #fff, $alpha: 0.5)
              }
            }
        
            &-row {
              background-color: $darkTableRowBackgroundColor;
        
              & td {
                padding: 0px 7px;
        
                time {
                  font-weight: 400;
                  font-family: 'Open Sans';
                  color: rgba($color: #fff, $alpha: 0.7);
                }
              }
        
              & td:first-child {
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
                padding-left: 10px;
              }
        
              & td:last-child {
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                padding-left: 10px;
              }
        
              &-info {
                & img {
                  width: 46px;
                  height: 46px;
                  cursor: pointer;
                  float: right;
                }
              }
        
              &-large-trend {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #009C10;
                padding: 4px 13px;
                border-radius: 44px;
                line-height: 20.8px;
              }
        
              &-low-trend {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #D2110A;
                padding: 4px 13px;
                border-radius: 44px;
                line-height: 20.8px;
              }
              &-neutral-trend {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ffce5a;
                padding: 4px 13px;
                border-radius: 44px;
                line-height: 20.8px;

                color: black;
              }
            }
          }
        
          & .scrollArea-buttonUp {
            right: 30px !important;
          }
        
          & .scrollArea-buttonDown {
            right: 30px !important;
          }
        }
        
        .new-user-party-modal {
          width: 100%;
          height: 450px;
          font-size: var(--largeFontSize);
        
          & .scrollArea-buttonDown {
            right: 16px !important;
            bottom: 25px !important;
          }
        
          & .scrollArea-buttonUp {
            right: 16px !important;
            bottom: 26px !important;
          }
        }
        
        .root-class-design20-light {
          
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-game-logs-page {

      .layout-mobile-body-main {

        &-title {
          display: block !important;
          color: #FFC900;
          font-size: var(--largeFontSize);
          font-weight: 600;
        }

        .my-info-statistics {
          min-height: unset;
          height: auto;
        }

        .my-info-ratings {
          height: 160px;
        }

        .my-info-ratings-body {
          position: relative;
          height: 80%;
          top: 0%;
          overflow-y: hidden;

          &::-webkit-scrollbar {
            height: 0px;
          }
        }

        .new-game-logs {
          padding: unset !important;
          overflow-x: auto !important;

          &-table-header-player-name {
            color: white;
            font-weight: 600 !important;
            font-family: Open Sans !important;
            line-height: 1 !important;
            font-size: var(--defaultFontSize) !important;
            padding-bottom: 10px !important;
            text-align: center;
          }

          &::-webkit-scrollbar {
            height: 4px;
          }
        }
      }
    }

    .new-user-party-modal {
      height: calc(100vh - 189px) !important;
    }
  }
}

.root-class-design20-light {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-game-logs-page {

      .layout-mobile-body-main {
        .new-game-logs {
          &-table-header-player-name {
            color: black !important;
          }
          .new-game-logs-table {
            .new-game-logs-table-header {
              th:nth-child(2) {
                color: rgba($color: #0C0C0C, $alpha: 0.5);
              }
            }
      
            .new-game-logs-table-row {
              td {
                time {
                  color: rgba($color: #0C0C0C, $alpha: 0.5);
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-game-logs-page {

      &-title {
        display: block !important;
        color: #FF8A1A;
        font-size: var(--largeFontSize);
        font-weight: 600;
      }

      .new-game-logs {
        &-table-header-player-name {
          color: black !important;
        }
        .new-game-logs-table {
          .new-game-logs-table-header {
            th:nth-child(2) {
              color: rgba($color: #0C0C0C, $alpha: 0.5);
            }
          }
    
          .new-game-logs-table-row {
            td {
              time {
                color: rgba($color: #0C0C0C, $alpha: 0.5);
              }
            }
          }
        }
      }
    }
  }
}
