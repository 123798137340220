.root-class-design20 {
  @media screen and (orientation : portrait) {
    .tournament-subheader-wrapper {
      &::-webkit-scrollbar {
        display: none;
      }

      padding-left: 3.5px;
      padding-right: 3.5px;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: auto hidden;
      column-gap: 15px;
      // width: calc(100% - 49px);

      &::-webkit-scrollbar {
        height: 2px;
      }

      .layout-subheader-link {
        margin: 0;

        .layout-subheader-link-text {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: var(--defaultFontSize);
          line-height: 136%;
          padding: 0 0 3px 0;
          margin: 0;
          white-space: nowrap;
          border-top: none;
          border-right: none;
          border-left: none;
        }
      }
    }

    .tournament-tutorial-button-wrapper-menu {
      width: 26px;
      height: 26px;
      overflow: hidden;

      .tournament-header-tutorial-menu {
        width: 100%;
        height: 100%;
        transform: scale(2.6);
        margin-top: 5px;
        cursor: pointer;
      }
    }

    .layout-mobile-body-tournaments-tab {
      .layout-mobile-body-top {
        .layout-subheader {
          display: none;
        }

        .layout-mobile-subheader {
          display: inline-flex !important;
          flex-direction: row;
          justify-content: space-between;
          height: 47px;
          background: #000000;
          border-radius: 8px;

          .tournament-subheader-wrapper {
            padding-left: 3.5px;
            padding-right: 3.5px;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto hidden;
            column-gap: 15px;
            // width: calc(100% - 49px);

            &::-webkit-scrollbar {
              height: 2px;
            }

            .layout-subheader-link {
              margin: 0;

              .layout-subheader-link-text {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: var(--defaultFontSize);
                line-height: 136%;
                padding: 0 0 3px 0;
                margin: 0;
                white-space: nowrap;
                border-top: none;
                border-right: none;
                border-left: none;
              }
            }
          }

          .tournament-tutorial-button-wrapper {
            img {
            }
          }

          .tournament-tutorial-button-wrapper-menu {
            width: 26px;
            height: 26px;
            overflow: hidden;

            .tournament-header-tutorial-menu {
              width: 100%;
              height: 100%;
              transform: scale(2.6);
              margin-top: 5px;
            }
          }
        }
      }

      .layout-mobile-body-main {
        .tournaments-filter {
          font-size: var(--smallFontSize);
        }

        .tournament-scrollarea {
          width: 100%;

          .tournaments-participate-content {
            margin-left: 20px;
            display: flex;
            padding: 0;
          }

          .menu-table-row-wrapper {
            padding: 0;

            .menu-table-row-inner-wrapper {
              padding: 0;

              .tournament-table-row {
                padding-left: 16px;
                padding-right: 9px;
                overflow: hidden;
                max-height: unset;

                .active-tournaments {
                  background: rgba($color: #FFFFFF, $alpha: 0.03);
                  border-radius: 12px;
                  margin-bottom: 1px;

                  .active-tournaments-info {
                    padding-top: 15px;

                    .active-tournaments-info-section {
                      padding-bottom: 3px;
                      margin-bottom: 0;

                      .active-tournaments-info-section-title {
                        padding: 0;

                        .active-tournaments-info-section-title-wrapper {
                          display: flex;
                          justify-content: space-between;
                          column-gap: 3px;
                          width: 100%;

                          img {
                            margin-left: 5px;
                            position: relative;
                            z-index: 10;
                          }

                          .active-tournaments-info-section-inner-wrapper {
                            display: flex;
                            flex-direction: column;
                            margin-left: -10px;
                            position: relative;
                            z-index: 100;
                            padding-right: 6px;
                            width: 100%;

                            .first-line {
                              display: flex;
                              justify-content: space-between;

                              .active-tournaments-info-section-title-left-title {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--defaultFontSize);
                                line-height: 160%;
                                color: #FFC900;
                              }

                              .active-tournaments-info-section-title-left-title-status {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--xSmallFontSize);
                                line-height: 160%;
                                text-transform: uppercase;
                                color: #000000;
                                background: #868686;
                                border-radius: 3px;
                                padding-left: 4px;
                                padding-right: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                align-self: center;
                              }

                              .registration-status {
                                background: #019245;
                                color: #FFFFFF;
                              }

                              .confirmed-status {
                                background: #FFFFFF;
                                color: #000000;
                              }

                              .pending-status {
                                background: #FFFFFF;
                                color: #000000;
                              }

                              .play-status {
                                color: #FFFFFF;
                                background: #D2110A;
                              }

                              .pause-status {
                                color: #FFFFFF;
                                background: #D2110A;
                              }
                            }

                            .second-line {
                              display: flex;
                              flex-wrap: wrap;
                              justify-content: flex-end;

                              .active-tournaments-info-section-title-left-private {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--defaultFontSize);
                                line-height: 160%;
                                color: #FFFFFF;
                                flex: auto;
                              }

                              .active-tournaments-info-section-time-rounds-wrapper {
                                .active-tournaments-info-section-time-rounds {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: rgba($color: #fff, $alpha: 0.5);
                                  margin-right: 2px;
                                }

                                .active-tournaments-info-section-time-roundsValue {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 600;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: #FFFFFF;
                                  margin-right: 0;
                                }
                              }

                              .active-tournaments-info-section-time-wrapper {
                                margin-left: 4px;

                                .active-tournaments-info-section-time-label {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: rgba($color: #FFFFFF, $alpha: 0.5);
                                  margin-right: 2px;
                                }

                                .active-tournaments-info-section-time-content {
                                  span {
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: var(--defaultFontSize);
                                    line-height: 160%;
                                    color: rgba($color: #FFF, $alpha: 0.7);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    .active-tournaments-info-button {
                      padding-top: 3px;
                      padding-bottom: 4px;
                      margin-bottom: 0;
                      column-gap: 2px;
                      // flex-direction: column;

                      button {
                        margin-top: 1px;
                        margin-bottom: 1px;
                        padding: 6px 2px;
                        background: rgba(0, 0, 0, 0.4);
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        border-radius: 99px;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 700;
                        //font-size: var(--defaultFontSize);
                        font-size: var(--xSmallFontSize);

                        line-height: 1.36;
                        text-align: center;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        min-width: unset !important;

                        &::after {
                          margin-left: 3px;
                        }

                        &::before {
                          margin-left: 3px;
                        }
                      }
                    }
                  }

                  .active-tournaments-sponsor.active-tournaments-sponsor-two {
                    border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
                    align-items: flex-end;

                    &::after {
                      content: unset;
                    }

                    .active-tournaments-sponsor-section {
                      margin-top: 12px;
                      flex: 1 1 0;

                      span {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: var(--defaultFontSize);
                        line-height: 160%;
                        text-align: center;
                        color: rgba($color: #FFF, $alpha: 0.5);
                      }

                      img {
                        width: calc(100% - 27px);
                      }
                    }

                    .active-tournaments-sponsor-section:first-of-type {
                      border-right: 1px solid rgba(255, 255, 255, 0.1);
                    }
                  }
                }
              }
            }
          }

          .future-tournaments-empty-entries {
            font-size: var(--mLargeFontSize);
          }
        }
      }
    }

    .modal {
      .modal-dialog {
        .modal-body {
          border-radius: 17px 17px 0 0 !important;
        }
      }

      .tournaments-info-modal.modal-dialog {
        .modal-body {
          padding-top: 37px;
          padding-left: 17px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .tournament-info-section {
            display: flex;
            flex-direction: column;
            padding: 0;
            overflow: hidden auto;
            justify-content: flex-start;
            min-height: 240px;
            max-height: calc(100vh - 280px);
            padding-right: 15px;

            .tournament-info-section-first {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              column-gap: 12px;

              .tournament-info-section-right-row {
                flex: 1;
                text-align: center;
                margin-bottom: 10px;

                img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }

            .tournament-info-section-main {
              margin-top: 0;

              .tournament-info-section-main-row {
                margin-right: 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                .tournament-info-section-main-row-content {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  line-height: 160%;
                  color: rgba($color: #FFFFFF, $alpha: 0.7);
                  flex: 1;
                }

                .tournament-info-section-main-row-value {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  line-height: 160%;
                  color: rgba($color: #FFFFFF, $alpha: 0.7);
                  flex: 1;
                }
              }
            }
          }
        }
      }

      .tournaments-tutorial-modal.modal-dialog {
        .modal-body {
          padding-top: 37px;
          padding-left: 26px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .row {
            margin: 0;

            .col {
              padding: 0;

              .tournaments-tutorial-table-scrollarea-new {
                padding-right: 15px;

                ol {
                  padding: 0;

                  & > p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                  }

                  & > p.title {
                    color: #FFC900;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                  }
                }
              }
            }
          }
        }
      }

      .tournament-top-modal {
        .modal-body {
          padding-top: 48px;
          padding-left: 7px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .tournament-scrollarea {
            overflow: hidden auto;
            //height: 55vh;
            height: auto;
            max-height: calc(100vh - 350px);

            .tournament-players {
              margin-left: 0;
              margin-right: 0;

              .tournament-players-player {
                padding: 0;
                height: auto;
                min-height: 120px;

                .tournament-player-info {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;

                  .tournament-player-info-background {
                  }

                  .tournament-player-info-player {
                    margin: 0;
                    width: 100%;
                    position: static;

                    .tournament-player-info-player-background {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;

                      .tournament-player-info-player-image-wrapper {
                        width: 100%;
                        min-width: auto;
                        max-width: unset;
                        display: flex;
                        justify-content: center;
                        position: relative;

                        &::before {
                          content: unset;
                        }

                        .tournament-player-info-player-image-background {
                          //display: none;
                        }

                        .tournament-player-info-player-image {
                          margin: 0px;
                          width: 62px;
                          height: 62px;
                          position: static;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 62px;
                          height: 62px;
                          margin-left: -5px;
                        }

                        .player-info-help-icon {
                          display: block !important;
                          position: absolute;
                          width: 90px;
                          height: 90px;
                          left: -15px;
                          top: -40px;
                        }

                        .player-info-player-image-emotion {
                          display: block !important;
                          position: absolute;
                          right: 0;
                          top: 8px;
                          width: 51px;
                          height: 51px;
                          z-index: 100;
                        }

                        .player-info-player-mark {
                          display: block !important;
                          position: absolute;
                          background-color: #662B8D;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 700;
                          font-size: var(--xSmallFontSize);
                          line-height: 160%;
                          text-transform: uppercase;
                          color: #FFFFFF;
                          padding-left: 3px;
                          padding-right: 3px;
                          border-radius: 2px;
                          left: 50%;
                          top: 40px;
                          z-index: 10;
                          transform: translateX(-50%);
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                        top: 7px;

                        .tournament-player-info-player-name {
                          padding: 0;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 600;
                          font-size: var(--defaultFontSize);
                          line-height: 120%;
                          text-align: center;
                          color: #FFFFFF;
                        }
                      }

                      .tournament-player-info-player-wrapper {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .tournament-leaderboard-modal {
        .modal-body {
          padding-left: 21px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;
          padding-top: 20px !important;
          padding-bottom: 2px !important;

          .tournament-leaderboard-top3-scroll-box {
            padding-right: unset;
            height: unset;
            overflow: hidden hidden;
            padding-top: 16px;

            .tournament-leaderboard-top3 {
              height: 100px;

              .col {
                padding-left: 0;
                padding-right: 0;

                .tournament-player-info {
                  display: inline-flex;
                  flex-direction: column;
                  align-items: center;

                  .tournament-player-info-player-glow-1 {
                    width: 200px;
                    height: 200px;
                    background-size: 200px 200px;
                    top: -32px;
                    left: -47px;
                    position: absolute;
                  }

                  .tournament-player-info-userlevel-badge-1 {
                    top: -16px;
                    z-index: 1000;
                  }

                  .tournament-player-info-userlevel-badge-2 {
                    top: -16px;
                    z-index: 1000;
                  }

                  .tournament-player-info-userlevel-badge-3 {
                    top: -16px;
                    z-index: 1000;
                  }

                  .tournament-player-info-userlevel-badge {
                    background: linear-gradient(180deg, #333333 0%, #121212 100%);
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
                    font-family: 'Futura PT';
                    font-style: normal;
                    font-weight: 700;
                    font-size: var(--largeFontSize);
                    line-height: 136%;
                    text-align: center;
                    color: #000000;
                    width: 30px;
                    height: 30px;
                    text-shadow: 1px 1px 0 #fff;
                    -webkit-text-stroke: 1px white;
                  }

                  .tournament-player-info-background-2 {
                    height: 52px;
                  }

                  .tournament-player-info-background-1 {
                    height: 81px;
                  }

                  .tournament-player-info-background-3 {
                    height: 31px;
                  }

                  .tournament-player-info-player {
                    margin: 0;
                    bottom: 10%;

                    .tournament-player-info-player-background {
                      display: flex;
                      flex-direction: column;
                      align-items: center;

                      .tournament-player-info-player-image-wrapper {
                        position: relative;
                        width: auto;
                        max-width: unset;
                        min-width: auto;
                        margin: unset;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &::before {
                          content: unset;
                        }

                        .tournament-player-info-player-image-background {
                          //display: none;
                        }

                        .tournament-player-info-player-image {
                          background-size: 100% 100% !important;
                          width: 62px;
                          height: 62px;
                          position: static;
                          margin: 0;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 62px;
                          height: 62px;
                          margin-left: -5px;
                        }

                        .tournament-player-info-player-image-emotions {
                          top: -25px;
                          left: -25px;
                          width: 51px;
                          height: 51px;
                          background-size: 100% 100%;
                        }

                        .tournament-player-info-player-image-level {
                          width: auto;
                          top: unset;
                          bottom: -6px;
                          z-index: 100;

                          .tournament-player-info-ratings-step-wrapper {

                            .tournament-player-info-ratings-step {
                              font-family: 'Open Sans';
                              font-style: normal;
                              font-weight: 700;
                              font-size: var(--xSmallFontSize);
                              line-height: 160%;
                              text-transform: uppercase;
                              color: #000;
                              background: #FFFFFF;
                              padding-left: 3px;
                              padding-right: 3px;
                            }
                          }
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                        top: 3px;
                        max-width: 142%;

                        .tournament-player-info-player-name {
                          padding: 1px 12px;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 600;
                          font-size: var(--defaultFontSize);
                          line-height: 160%;
                          color: #FFFFFF;
                          background: #000000;
                        }
                      }

                      .tournament-player-info-player-wrapper {
                        display: none;
                      }
                    }
                  }

                  .tournament-player-info-player-1 {
                    bottom: 12%;

                    .tournament-player-info-player-background {
                      .tournament-player-info-player-image-wrapper {
                        .tournament-player-info-player-image {
                          width: 62px;
                          height: 62px;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 62px;
                          height: 62px;
                        }

                        .tournament-player-info-player-image-emotions {
                          top: -20px;
                          left: -25px;
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                      }
                    }
                  }

                  .tournament-player-info-player-3 {
                    bottom: 8%;

                    .tournament-player-info-player-background {
                      .tournament-player-info-player-image-wrapper {
                        .tournament-player-info-player-image {
                          width: 68px;
                          height: 68px;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 68px;
                          height: 68px;
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                      }
                    }
                  }
                }
              }
            }

            .tournament-leaderboard-table-wrapper {
              width: 100%;
              overflow-x: auto;

              .col-12 {
                padding: 0;
                height: auto;
                overflow: auto hidden;
                max-width: unset;
                flex: unset;
                height: unset;
                min-height: unset;
                min-width: 486px;

                &::-webkit-scrollbar {
                  height: 4px;
                }

                .tournament-table-header {
                  margin-top: 23px !important;
                  margin-bottom: 9px !important;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  height: auto !important;

                  & > div {
                    white-space: nowrap;
                    padding: 0;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--smallFontSize);
                    line-height: 100%;
                    color: rgba($color: #fff, $alpha: 0.5);
                  }

                  & > div:nth-of-type(1) {
                    min-width: 65px;
                  }

                  & > div:nth-of-type(2) {
                    min-width: 180px;
                    text-align: center;
                  }

                  & > div:nth-of-type(3) {
                    min-width: 100px;
                    text-align: center;
                  }

                  & > div:nth-of-type(4) {
                    min-width: 100px;
                    text-align: center;
                  }

                  & > div:nth-of-type(5) {
                    min-width: 165px;
                    text-align: center;
                  }
                }

                .tournament-leaderboard {
                  overflow-y: auto;
                  // width: auto;
                  height: unset;
                  display: inline-block;
                  max-height: calc(100vh - 460px);

                  .menu-table-row-wrapper {
                    padding-bottom: 1px;

                    .menu-table-row-inner-wrapper {
                      padding-left: 0;

                      .row {
                        display: flex;
                        flex-wrap: nowrap;

                        & > div {
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 400;
                          font-size: var(--defaultFontSize);
                          line-height: 160%;
                          color: #FFFFFF;
                        }

                        & > div:nth-of-type(1) {
                          min-width: 65px;
                          font-family: 'Futura PT';
                          font-style: normal;
                          font-weight: 450;
                          font-size: var(--smallFontSize);
                          line-height: 1.27;
                          color: rgba($color: #fff, $alpha: 0.5);
                          padding-left: 5px;

                          &::before {
                            content: unset;
                          }
                        }



                        & > div:nth-of-type(2) {
                          min-width: 180px;
                          text-align: center;
                        }

                        & > div:nth-of-type(3) {
                          min-width: 100px;
                          text-align: center;
                        }

                        & > div:nth-of-type(4) {
                          min-width: 100px;
                          text-align: center;
                        }

                        & > div:nth-of-type(5) {
                          min-width: 165px;
                          text-align: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-tournaments-tab {
      .layout-mobile-body-top {
        .layout-subheader {
          display: none;
        }

        .layout-mobile-subheader {
          display: inline-flex !important;
          flex-direction: row;
          justify-content: space-between;
          height: 47px;
          background: #000000;
          border-radius: 8px;

          .tournament-subheader-wrapper {
            padding-left: 3.5px;
            padding-right: 3.5px;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto hidden;
            column-gap: 15px;
            width: calc(100% - 49px);

            &::-webkit-scrollbar {
              height: 2px;
            }

            .layout-subheader-link {
              margin: 0;

              .layout-subheader-link-text {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: var(--defaultFontSize);
                line-height: 136%;
                padding: 0 0 3px 0;
                margin: 0;
                white-space: nowrap;
                border-top: none;
                border-right: none;
                border-left: none;
              }
            }
          }

          .tournament-tutorial-button-wrapper {
            img {
            }
          }
        }
      }

      .layout-mobile-body-main {
        .tournaments-filter {
          font-size: var(--smallFontSize);
        }
        .tournament-scrollarea {
          width: 100%;

          .tournaments-participate-content {
            margin-left: 20px;
            display: flex;
            padding: 0;
          }

          .menu-table-row-wrapper {
            padding: 0;

            .menu-table-row-inner-wrapper {
              padding: 0;

              .tournament-table-row {
                padding-left: 0;
                padding-right: 0;
                overflow: hidden;
                max-height: unset;

                .active-tournaments {
                  background: rgba($color: #FFFFFF, $alpha: 0.03);
                  border-radius: 12px;
                  margin-bottom: 1px;

                  .active-tournaments-info {
                    padding-top: 15px;

                    .active-tournaments-info-section {
                      padding-bottom: 3px;
                      margin-bottom: 0;

                      .active-tournaments-info-section-title {
                        padding: 0;

                        .active-tournaments-info-section-title-wrapper {
                          display: flex;
                          justify-content: space-between;
                          column-gap: 3px;
                          width: 100%;

                          img {
                            overflow: hidden;
                            margin-left: 5px;
                            position: relative;
                            z-index: 10;
                          }

                          .active-tournaments-info-section-inner-wrapper {
                            display: flex;
                            flex-direction: column;
                            margin-left: -10px;
                            position: relative;
                            z-index: 100;
                            padding-right: 6px;
                            width: calc(100% - 30px);

                            .first-line {
                              display: flex;
                              justify-content: space-between;

                              .active-tournaments-info-section-title-left-title {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--defaultFontSize);
                                line-height: 160%;
                                color: #FFC900;
                              }

                              .active-tournaments-info-section-title-left-title-status {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--xSmallFontSize);
                                line-height: 160%;
                                text-transform: uppercase;
                                color: #000000;
                                background: #868686;
                                border-radius: 3px;
                                padding-left: 4px;
                                padding-right: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                align-self: center;
                              }

                              .registration-status {
                                background: #019245;
                                color: #FFFFFF;
                              }

                              .confirmed-status {
                                background: #FFFFFF;
                                color: #000000;
                              }

                              .pending-status {
                                background: #FFFFFF;
                                color: #000000;
                              }

                              .play-status {
                                color: #FFFFFF;
                                background: #D2110A;
                              }

                              .pause-status {
                                color: #FFFFFF;
                                background: #D2110A;
                              }
                            }

                            .second-line {
                              display: flex;
                              flex-wrap: wrap;
                              justify-content: flex-end;

                              .active-tournaments-info-section-title-left-private {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--defaultFontSize);
                                line-height: 160%;
                                color: #FFFFFF;
                                flex: auto;
                              }

                              .active-tournaments-info-section-time-rounds-wrapper {
                                .active-tournaments-info-section-time-rounds {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: rgba($color: #fff, $alpha: 0.5);
                                  margin-right: 2px;
                                }

                                .active-tournaments-info-section-time-roundsValue {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 600;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: #FFFFFF;
                                  margin-right: 0;
                                }
                              }

                              .active-tournaments-info-section-time-wrapper {
                                margin-left: 4px;

                                .active-tournaments-info-section-time-label {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: rgba($color: #FFFFFF, $alpha: 0.5);
                                  margin-right: 2px;
                                }

                                .active-tournaments-info-section-time-content {
                                  span {
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: var(--defaultFontSize);
                                    line-height: 160%;
                                    color: rgba($color: #FFF, $alpha: 0.7);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    .active-tournaments-info-button {
                      padding-top: 3px;
                      padding-bottom: 4px;
                      margin-bottom: 0;
                      column-gap: 2px;

                      button {
                        padding: 6px 15px;
                        background: rgba(0, 0, 0, 0.4);
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        border-radius: 99px;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: var(--defaultFontSize);
                        line-height: 1.36;
                        text-align: center;
                        text-transform: uppercase;
                        color: #FFFFFF;

                        &::after {
                          margin-left: 13px;
                        }

                        &::before {
                          margin-left: 13px;
                        }
                      }
                    }
                  }

                  .active-tournaments-sponsor.active-tournaments-sponsor-two {
                    border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
                    align-items: flex-end;

                    &::after {
                      content: unset;
                    }

                    .active-tournaments-sponsor-section {
                      margin-top: 12px;
                      flex: 1 1 0;

                      span {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: var(--defaultFontSize);
                        line-height: 160%;
                        text-align: center;
                        color: rgba($color: #FFF, $alpha: 0.5);
                      }

                      img {
                        width: calc(100% - 27px);
                      }
                    }

                    .active-tournaments-sponsor-section:first-of-type {
                      border-right: 1px solid rgba(255, 255, 255, 0.1);
                    }
                  }
                }
              }
            }
          }

          .future-tournaments-empty-entries {
            font-size: var(--mLargeFontSize);
          }
        }
      }
    }

    .modal {
      .modal-dialog {
        .modal-body {
          border-radius: 17px 17px 0 0 !important;
        }
      }

      .tournaments-info-modal.modal-dialog {
        .modal-body {
          padding-top: 37px;
          padding-left: 17px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .tournament-info-section {
            display: flex;
            flex-direction: column;
            padding: 0;
            overflow: hidden auto;
            justify-content: flex-start;
            min-height: calc(100vh - 222px);
            max-height: calc(100vh - 350px);
            padding-right: 15px;

            .tournament-info-section-first {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              column-gap: 12px;

              .tournament-info-section-right-row {
                flex: 1;
                text-align: center;
                margin-bottom: 10px;

                img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }

            .tournament-info-section-main {
              margin-top: 0;

              .tournament-info-section-main-row {
                margin-right: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                .tournament-info-section-main-row-content {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  line-height: 160%;
                  color: rgba($color: #FFFFFF, $alpha: 0.7);
                  flex: 1;
                }

                .tournament-info-section-main-row-value {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  line-height: 160%;
                  color: rgba($color: #FFFFFF, $alpha: 0.7);
                  flex: 1;
                }
              }
            }
          }
        }
      }

      .tournaments-tutorial-modal.modal-dialog {
        .modal-body {
          padding-top: 37px;
          padding-left: 26px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .row {
            margin: 0;

            .col {
              padding: 0;

              .tournaments-tutorial-table-scrollarea-new {
                padding-right: 15px;
                min-height: calc(100vh - 210px);
                height: calc(100vh - 350px) !important;

                ol {
                  padding: 0;

                  & > p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                  }

                  & > p.title {
                    color: #FFC900;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                  }
                }
              }
            }
          }
        }
      }

      .tournament-top-modal {
        .modal-body {
          padding-top: 30px !important;
          padding-bottom: 2px !important;

          padding-left: 7px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;
          flex: unset;

          .tournament-scrollarea {
            overflow: hidden auto;
            //height: 48vh;
            height: auto;
            max-height: calc(100vh - 275px);

            .tournament-players {
              margin-left: 0;
              margin-right: 0;

              .tournament-players-player {
                padding: 0;
                height: auto;
                min-height: 120px;

                .tournament-player-info {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;



                  .tournament-player-info-background {
                  }

                  .tournament-player-info-player {
                    margin: 0;
                    width: 100%;
                    position: static;

                    .tournament-player-info-player-background {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;

                      .tournament-player-info-player-image-wrapper {
                        width: 100%;
                        min-width: auto;
                        max-width: unset;
                        display: flex;
                        justify-content: center;
                        position: relative;

                        &::before {
                          content: unset;
                        }

                        .tournament-player-info-player-image-background {
                          //display: none;
                        }

                        .tournament-player-info-player-image {
                          margin: 0px;
                          width: 80px;
                          height: 80px;
                          position: static;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 80px;
                          height: 80px;
                          margin-left: -5px;
                        }

                        .player-info-help-icon {
                          display: block !important;
                          position: absolute;
                          width: 90px;
                          height: 90px;
                          left: -15px;
                          top: -40px;
                        }

                        .player-info-player-image-emotion {
                          display: block !important;
                          position: absolute;
                          right: 0;
                          top: 8px;
                          width: 51px;
                          height: 51px;
                          z-index: 100;
                        }

                        .player-info-player-mark {
                          display: block !important;
                          position: absolute;
                          background-color: #662B8D;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 700;
                          font-size: var(--xSmallFontSize);
                          line-height: 160%;
                          text-transform: uppercase;
                          color: #FFFFFF;
                          padding-left: 3px;
                          padding-right: 3px;
                          border-radius: 2px;
                          left: 50%;
                          top: 40px;
                          z-index: 10;
                          transform: translateX(-50%);
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                        top: 7px;

                        .tournament-player-info-player-name {
                          padding: 0;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 600;
                          font-size: var(--defaultFontSize);
                          line-height: 120%;
                          text-align: center;
                          color: #FFFFFF;
                        }
                      }

                      .tournament-player-info-player-wrapper {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }
}

.root-is-app-frame {
  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .modal {
      .tournament-leaderboard-modal {
        max-width: 100vw;
      }
    }
  }
}
.root-is-not-app-frame {
  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .modal {
      .tournament-leaderboard-modal {
        .modal-body {
          padding-left: 21px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          padding-top: 20px !important;
          padding-bottom: 1px !important;


          // .tournament-leaderboard-top3-scroll-box-in-app-frame {
          //   height: 65vh !important;
          // }

          .tournament-leaderboard-top3-scroll-box {
            padding-right: 7.5px;
            height: unset;
            overflow: hidden hidden;
            padding-top: 2px;

            .tournament-leaderboard-top3 {
              display: none;
              height: 80px;

              .col {
                padding-left: 0;
                padding-right: 0;

                .tournament-player-info {
                  display: inline-flex;
                  flex-direction: column;
                  align-items: center;

                  .tournament-player-info-player-glow-1 {
                    width: 200px;
                    height: 200px;
                    background-size: 200px 200px;
                    top: -28px;
                    left: -26px;
                  }

                  .tournament-player-info-userlevel-badge-1 {
                    left: calc(50% - 40px);
                    top: 8px;
                    z-index: 1000;
                  }

                  .tournament-player-info-userlevel-badge-2 {
                    left: calc(50% - 40px);
                    top: 8px;
                    z-index: 1000;
                  }

                  .tournament-player-info-userlevel-badge-3 {
                    left: calc(50% - 40px);
                    top: 8px;
                    z-index: 1000;
                  }

                  .tournament-player-info-userlevel-badge {
                    background: linear-gradient(180deg, #333333 0%, #121212 100%);
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
                    font-family: 'Futura PT';
                    font-style: normal;
                    font-weight: 700;
                    font-size: var(--largeFontSize);
                    line-height: 136%;
                    text-align: center;
                    color: #000000;
                    width: 30px;
                    height: 30px;
                    text-shadow: 1px 1px 0 #fff;
                    -webkit-text-stroke: 1px white;
                  }

                  .tournament-player-info-background-2 {
                    height: 31px;
                  }

                  .tournament-player-info-background-1 {
                    height: 41px;
                  }

                  .tournament-player-info-background-3 {
                    height: 21px;
                  }

                  .tournament-player-info-player {
                    margin: 0;
                    bottom: 10%;

                    .tournament-player-info-player-background {
                      display: flex;
                      flex-direction: column;
                      align-items: center;

                      .tournament-player-info-player-image-wrapper {
                        position: relative;
                        width: auto;
                        max-width: unset;
                        min-width: auto;
                        margin: unset;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &::before {
                          content: unset;
                        }

                        .tournament-player-info-player-image-background {
                          //display: none;
                        }

                        .tournament-player-info-player-image {
                          background-size: 100% 100% !important;
                          width: 44px;
                          height: 44px;
                          position: static;
                          margin: 0;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 44px;
                          height: 44px;
                          margin-left: -5px;
                        }

                        .tournament-player-info-player-image-emotions {
                          top: -25px;
                          left: -25px;
                          width: 51px;
                          height: 51px;
                          background-size: 100% 100%;
                        }

                        .tournament-player-info-player-image-level {
                          width: auto;
                          top: unset;
                          bottom: -6px;
                          z-index: 100;

                          .tournament-player-info-ratings-step-wrapper {

                            .tournament-player-info-ratings-step {
                              font-family: 'Open Sans';
                              font-style: normal;
                              font-weight: 700;
                              font-size: var(--xSmallFontSize);
                              line-height: 160%;
                              text-transform: uppercase;
                              color: #000;
                              background: #FFFFFF;
                              padding-left: 3px;
                              padding-right: 3px;
                            }
                          }
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                        top: 3px;

                        .tournament-player-info-player-name {
                          padding: 1px 12px;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 600;
                          font-size: var(--defaultFontSize);
                          line-height: 160%;
                          color: #FFFFFF;
                          background: #000000;
                        }
                      }

                      .tournament-player-info-player-wrapper {
                        display: none;
                      }
                    }
                  }

                  .tournament-player-info-player-1 {
                    bottom: 12%;

                    .tournament-player-info-player-background {
                      .tournament-player-info-player-image-wrapper {
                        .tournament-player-info-player-image {
                          width: 44px;
                          height: 44px;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 44px;
                          height: 44px;
                        }

                        .tournament-player-info-player-image-emotions {
                          top: -20px;
                          left: -25px;
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                      }
                    }
                  }

                  .tournament-player-info-player-3 {
                    bottom: 8%;

                    .tournament-player-info-player-background {
                      .tournament-player-info-player-image-wrapper {
                        .tournament-player-info-player-image {
                          width: 44px;
                          height: 44px;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 44px;
                          height: 44px;
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                      }
                    }
                  }
                }
              }
            }

            .tournament-leaderboard-table-wrapper {
              width: 100%;
              overflow-x: auto;

              .col-12 {
                padding: 0;
                height: auto;
                overflow: auto hidden;
                max-width: unset;
                flex: unset;
                height: auto;
                min-width: 486px;
                min-height: unset;
                height: calc(100vh - 190px);
                display: flex;
                flex-direction: column;

                &::-webkit-scrollbar {
                  height: 4px;
                }

                .tournament-table-header {
                  margin-top: 23px !important;
                  margin-bottom: 9px !important;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  height: auto !important;

                  & > div {
                    white-space: nowrap;
                    padding: 0;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--smallFontSize);
                    line-height: 100%;
                    color: rgba($color: #fff, $alpha: 0.5);
                  }

                  & > div:nth-of-type(1) {
                    min-width: 65px;
                  }

                  & > div:nth-of-type(2) {
                    min-width: 180px;
                    text-align: center;
                  }

                  & > div:nth-of-type(3) {
                    min-width: 100px;
                    text-align: center;
                  }

                  & > div:nth-of-type(4) {
                    min-width: 100px;
                    text-align: center;
                  }

                  & > div:nth-of-type(5) {
                    min-width: 165px;
                    text-align: center;
                  }
                }

                .tournament-leaderboard {
                  overflow-y: auto;
                  // width: auto;
                  height: auto;
                  max-height: unset;
                  display: inline-block;
                  flex: 1;

                  .menu-table-row-wrapper {
                    padding-bottom: 1px;

                    .menu-table-row-inner-wrapper {
                      padding-left: 0;

                      .row {
                        display: flex;
                        flex-wrap: nowrap;

                        & > div {
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 400;
                          font-size: var(--defaultFontSize);
                          line-height: 160%;
                          color: #FFFFFF;
                        }

                        & > div:nth-of-type(1) {
                          min-width: 65px;
                          font-family: 'Futura PT';
                          font-style: normal;
                          font-weight: 450;
                          font-size: var(--smallFontSize);
                          line-height: 1.27;
                          color: rgba($color: #fff, $alpha: 0.5);

                          &::before {
                            content: unset;
                          }
                        }



                        & > div:nth-of-type(2) {
                          min-width: 180px;
                          text-align: center;
                        }

                        & > div:nth-of-type(3) {
                          min-width: 100px;
                          text-align: center;
                        }

                        & > div:nth-of-type(4) {
                          min-width: 100px;
                          text-align: center;
                        }

                        & > div:nth-of-type(5) {
                          min-width: 165px;
                          text-align: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .modal-footer {
          padding-bottom: 1px !important;
          padding-top: 1px !important;
        }
      }
    }
  }
}

.root-class-design20-normal {}

.root-class-design20-light {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-tournaments-tab {
      .layout-mobile-body-top {
        .layout-subheader {
          display: none;
        }

        .layout-mobile-subheader {
          display: inline-flex !important;
          flex-direction: row;
          justify-content: space-between;
          height: 47px;
          background: #000000;
          border-radius: 8px;

          .tournament-subheader-wrapper {
            padding-left: 3.5px;
            padding-right: 3.5px;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto hidden;
            column-gap: 15px;
            // width: calc(100% - 49px);

            &::-webkit-scrollbar {
              height: 2px;
            }

            .layout-subheader-link {
              margin: 0;

              .layout-subheader-link-text {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: var(--defaultFontSize);
                line-height: 136%;
                padding: 0 0 3px 0;
                margin: 0;
                white-space: nowrap;
                border-top: none;
                border-right: none;
                border-left: none;
              }
            }
          }

          .tournament-tutorial-button-wrapper {
            img {
            }
          }

          .tournament-tutorial-button-wrapper-menu {
            width: 26px;
            height: 26px;
            overflow: hidden;

            .tournament-header-tutorial-menu {
              width: 100%;
              height: 100%;
              transform: scale(1.2);
              margin-top: 0px;
            }
          }
        }
      }

      .layout-mobile-body-main {
        .tournament-scrollarea {
          width: 100%;

          .tournaments-participate-content {
            margin-left: 20px;
            display: flex;
            padding: 0;
          }

          .menu-table-row-wrapper {
            padding: 0;

            .menu-table-row-inner-wrapper {
              padding: 0;

              .tournament-table-row {
                padding-left: 16px;
                padding-right: 9px;
                overflow: hidden;
                max-height: unset;

                .active-tournaments {
                  background: rgba($color: #FFFFFF, $alpha: 0.03);
                  border-radius: 12px;
                  margin-bottom: 1px;

                  .active-tournaments-info {
                    padding-top: 15px;

                    .active-tournaments-info-section {
                      padding-bottom: 3px;
                      margin-bottom: 0;

                      .active-tournaments-info-section-title {
                        padding: 0;

                        .active-tournaments-info-section-title-wrapper {
                          display: flex;
                          justify-content: space-between;
                          column-gap: 3px;
                          width: 100%;

                          img {
                            margin-left: 5px;
                            position: relative;
                            z-index: 10;
                          }

                          .active-tournaments-info-section-inner-wrapper {
                            display: flex;
                            flex-direction: column;
                            margin-left: -10px;
                            position: relative;
                            z-index: 100;
                            padding-right: 6px;
                            width: 100%;

                            .first-line {
                              display: flex;
                              justify-content: space-between;

                              .active-tournaments-info-section-title-left-title {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--defaultFontSize);
                                line-height: 160%;
                                color: #FFC900;
                              }

                              .active-tournaments-info-section-title-left-title-status {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--xSmallFontSize);
                                line-height: 160%;
                                text-transform: uppercase;
                                color: #000000;
                                background: #fff;
                                border-radius: 3px;
                                padding-left: 4px;
                                padding-right: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                align-self: center;
                              }

                              .registration-status {
                                background: #019245;
                                color: #FFFFFF;
                              }

                              .confirmed-status {
                                background: #FFFFFF;
                                color: #000000;
                              }

                              .pending-status {
                                background: #FFFFFF;
                                color: #000000;
                              }

                              .play-status {
                                color: #FFFFFF;
                                background: #D2110A;
                              }

                              .pause-status {
                                color: #FFFFFF;
                                background: #D2110A;
                              }
                            }

                            .second-line {
                              display: flex;
                              flex-wrap: wrap;
                              justify-content: flex-end;

                              .active-tournaments-info-section-title-left-private {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--defaultFontSize);
                                line-height: 160%;
                                color: #0C0C0C;
                                flex: auto;
                              }

                              .active-tournaments-info-section-time-rounds-wrapper {
                                .active-tournaments-info-section-time-rounds {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: #0C0C0C;
                                  margin-right: 2px;
                                }

                                .active-tournaments-info-section-time-roundsValue {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 600;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: #0C0C0C;
                                  margin-right: 0;
                                }
                              }

                              .active-tournaments-info-section-time-wrapper {
                                margin-left: 4px;

                                .active-tournaments-info-section-time-label {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: #0C0C0C;
                                  margin-right: 2px;
                                }

                                .active-tournaments-info-section-time-content {
                                  span {
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: var(--defaultFontSize);
                                    line-height: 160%;
                                    color: #0C0C0C;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    .active-tournaments-info-button {
                      padding-top: 3px;
                      padding-bottom: 4px;
                      margin-bottom: 0;
                      column-gap: 2px;

                      button {
                        padding: 6px 15px;
                        background: rgba(255, 255, 255, 0.4);
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 99px;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: var(--defaultFontSize);
                        line-height: 1.36;
                        text-align: center;
                        text-transform: uppercase;
                        color: #0C0C0C;

                        &::after {
                          margin-left: 13px;
                        }

                        &::before {
                          margin-left: 13px;
                        }
                      }
                    }
                  }

                  .active-tournaments-sponsor.active-tournaments-sponsor-two {
                    border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
                    align-items: flex-end;

                    &::after {
                      content: unset;
                    }

                    .active-tournaments-sponsor-section {
                      margin-top: 12px;
                      flex: 1 1 0;

                      span {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: var(--defaultFontSize);
                        line-height: 160%;
                        text-align: center;
                        color: #0C0C0C;
                      }

                      img {
                        width: calc(100% - 27px);
                      }
                    }

                    .active-tournaments-sponsor-section:first-of-type {
                      border-right: 1px solid rgba(255, 255, 255, 0.1);
                    }
                  }
                }
              }
            }
          }

          .future-tournaments-empty-entries {
            font-size: var(--mLargeFontSize);
          }
        }
      }
    }

    .modal {
      .modal-dialog {
        .modal-body {
          border-radius: 17px 17px 0 0 !important;
        }

        .modal-footer + img {
          filter: invert(1);
        }
      }

      .tournaments-info-modal.modal-dialog {
        .modal-body {
          padding-top: 37px;
          padding-left: 17px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .tournament-info-section {
            display: flex;
            flex-direction: column;
            padding: 0;
            overflow: hidden auto;
            justify-content: flex-start;
            min-height: 240px;
            max-height: calc(100vh - 280px);
            padding-right: 15px;

            .tournament-info-section-first {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              column-gap: 12px;

              .tournament-info-section-right-row {
                flex: 1;
                text-align: center;
                margin-bottom: 10px;

                img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }

            .tournament-info-section-main {
              margin-top: 0;

              .tournament-info-section-main-row {
                margin-right: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                .tournament-info-section-main-row-content {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  line-height: 160%;
                  color: #000;
                  flex: 1;
                }

                .tournament-info-section-main-row-value {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  color: #000;
                  line-height: 160%;
                  flex: 1;
                }
              }
            }
          }
        }
      }

      .tournaments-tutorial-modal.modal-dialog {
        .modal-body {
          padding-top: 37px;
          padding-left: 26px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .row {
            margin: 0;

            .col {
              padding: 0;

              .tournaments-tutorial-table-scrollarea-new {
                padding-right: 15px;

                ol {
                  padding: 0;

                  & > p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                  }

                  & > p.title {
                    color: #FFC900;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                  }
                }
              }
            }
          }
        }
      }

      .tournament-top-modal {
        .modal-body {
          padding-top: 48px;
          padding-left: 7px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .tournament-scrollarea {
            overflow: hidden auto;
            //height: 55vh;
            height: auto;
            max-height: calc(100vh - 350px);

            .tournament-players {
              margin-left: 0;
              margin-right: 0;

              .tournament-players-player {
                padding: 0;
                height: auto;
                min-height: 120px;

                .tournament-player-info {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;

                  .tournament-player-info-background {
                  }

                  .tournament-player-info-player {
                    margin: 0;
                    width: 100%;
                    position: static;

                    .tournament-player-info-player-background {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;

                      .tournament-player-info-player-image-wrapper {
                        width: 100%;
                        min-width: auto;
                        max-width: unset;
                        display: flex;
                        justify-content: center;
                        position: relative;

                        &::before {
                          content: unset;
                        }

                        .tournament-player-info-player-image-background {
                          //display: none;
                        }

                        .tournament-player-info-player-image {
                          margin: 0px;
                          width: 80px;
                          height: 80px;
                          position: static;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 80px;
                          height: 80px;
                          margin-left: -5px;
                        }

                        .player-info-help-icon {
                          display: block !important;
                          position: absolute;
                          width: 50px;
                          height: 50px;
                          left: 0px;
                          top: -23px;
                        }

                        .player-info-player-image-emotion {
                          display: block !important;
                          position: absolute;
                          right: 0;
                          top: 8px;
                          width: 51px;
                          height: 51px;
                          z-index: 100;
                        }

                        .player-info-player-mark {
                          display: block !important;
                          position: absolute;
                          background-color: #662B8D;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 700;
                          font-size: var(--xSmallFontSize);
                          line-height: 160%;
                          text-transform: uppercase;
                          color: #FFFFFF;
                          padding-left: 3px;
                          padding-right: 3px;
                          border-radius: 2px;
                          left: 50%;
                          top: 40px;
                          z-index: 10;
                          transform: translateX(-50%);
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                        top: 7px;

                        .tournament-player-info-player-name {
                          padding: 0;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 600;
                          font-size: var(--defaultFontSize);
                          line-height: 120%;
                          text-align: center;
                          color: #FFFFFF;
                        }
                      }

                      .tournament-player-info-player-wrapper {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
//"temp"
      // .tournament-leaderboard-modal {
      //   .modal-body {
      //     padding-left: 21px;
      //     padding-right: 7px;
      //     border-radius: 17px 17px 0 0 !important;

      //     .tournament-leaderboard-top3-scroll-box {
      //       padding-right: 7.5px;
      //       height: 58vh;
      //       overflow: hidden hidden;
      //       padding-top: 16px;

      //       .tournament-leaderboard-top3 {
      //         height: 160px;

      //         .col {
      //           padding-left: 0;
      //           padding-right: 0;

      //           .tournament-player-info {
      //             display: inline-flex;
      //             flex-direction: column;
      //             align-items: center;

      //             .tournament-player-info-player-glow-1 {
      //               width: 200px;
      //               height: 200px;
      //               background-size: 200px 200px;
      //               top: -32px;
      //               left: -47px;
      //               position: absolute;
      //             }

      //             .tournament-player-info-userlevel-badge {
      //               background: linear-gradient(180deg, #CDC8C8 0%, #F2F0F0 100%);
      //               box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      //               font-family: 'Futura PT';
      //               font-style: normal;
      //               font-weight: 700;
      //               font-size: var(--largeFontSize);
      //               line-height: 136%;
      //               text-align: center;
      //               color: #000000;
      //               width: 30px;
      //               height: 30px;
      //               text-shadow: 1px 1px 0 #fff;
      //               -webkit-text-stroke: 1px white;
      //             }

      //             .tournament-player-info-background-2 {
      //               height: 52px;
      //             }

      //             .tournament-player-info-background-1 {
      //               height: 81px;
      //             }

      //             .tournament-player-info-background-3 {
      //               height: 31px;
      //             }

      //             .tournament-player-info-player {
      //               margin: 0;
      //               bottom: 10%;

      //               .tournament-player-info-player-background {
      //                 display: flex;
      //                 flex-direction: column;
      //                 align-items: center;

      //                 .tournament-player-info-player-image-wrapper {
      //                   position: relative;
      //                   width: auto;
      //                   max-width: unset;
      //                   min-width: auto;
      //                   margin: unset;
      //                   display: flex;
      //                   flex-direction: column;
      //                   align-items: center;

      //                   &::before {
      //                     content: unset;
      //                   }

      //                   .tournament-player-info-player-image-background {
      //                     //display: none;
      //                   }

      //                   .tournament-player-info-player-image {
      //                     background-size: 100% 100% !important;
      //                     width: 80px;
      //                     height: 80px;
      //                     position: static;
      //                     margin: 0;
      //                   }

      //                   .tournament-player-info-player-image-overlay {
      //                     width: 80px;
      //                     height: 80px;
      //                     margin-left: -5px;
      //                   }

      //                   .tournament-player-info-player-image-emotions {
      //                     top: -25px;
      //                     left: -25px;
      //                     width: 51px;
      //                     height: 51px;
      //                     background-size: 100% 100%;
      //                   }

      //                   .tournament-player-info-player-image-level {
      //                     width: auto;
      //                     top: unset;
      //                     bottom: -6px;
      //                     z-index: 100;

      //                     .tournament-player-info-ratings-step-wrapper {

      //                       .tournament-player-info-ratings-step {
      //                         font-family: 'Open Sans';
      //                         font-style: normal;
      //                         font-weight: 700;
      //                         font-size: var(--xSmallFontSize);
      //                         line-height: 160%;
      //                         text-transform: uppercase;
      //                         color: #000;
      //                         background: #FFFFFF;
      //                         padding-left: 3px;
      //                         padding-right: 3px;
      //                       }
      //                     }
      //                   }
      //                 }

      //                 .tournament-player-info-player-name-wrapper {
      //                   top: 3px;

      //                   .tournament-player-info-player-name {
      //                     padding: 1px 12px;
      //                     font-family: 'Open Sans';
      //                     font-style: normal;
      //                     font-weight: 600;
      //                     font-size: var(--defaultFontSize);
      //                     line-height: 160%;
      //                     color: #FFFFFF;
      //                     background: #000000;
      //                   }
      //                 }

      //                 .tournament-player-info-player-wrapper {
      //                   display: none;
      //                 }
      //               }
      //             }

      //             .tournament-player-info-player-1 {
      //               bottom: 12%;

                  // .tournament-player-info-player-1 {
                  //   bottom: 12%;

                  //   .tournament-player-info-player-background {
                  //     .tournament-player-info-player-image-wrapper {
                  //       .tournament-player-info-player-image {
                  //         width: 100px;
                  //         height: 100px;
                  //       }

                        // .tournament-player-info-player-image-overlay {
                        //   width: 100px;
                        //   height: 100px;
                        // }

      //                   .tournament-player-info-player-image-emotions {
      //                     top: -20px;
      //                     left: -25px;
      //                   }
      //                 }

      //                 .tournament-player-info-player-name-wrapper {
      //                 }
      //               }
      //             }

      //             .tournament-player-info-player-3 {
      //               bottom: 8%;

      //               .tournament-player-info-player-background {
      //                 .tournament-player-info-player-image-wrapper {
      //                   .tournament-player-info-player-image {
      //                     width: 68px;
      //                     height: 68px;
      //                   }

      //                   .tournament-player-info-player-image-overlay {
      //                     width: 68px;
      //                     height: 68px;
      //                   }
      //                 }

      //                 .tournament-player-info-player-name-wrapper {
      //                 }
      //               }
      //             }
      //           }
      //         }
      //       }

      //       .tournament-leaderboard-table-wrapper {
      //         width: 100%;
      //         overflow-x: auto;

      //         .col-12 {
      //           padding: 0;
      //           height: auto;
      //           overflow: auto hidden;
      //           max-width: unset;
      //           flex: unset;
      //           height: calc(65vh - 255px);
      //           min-width: 486px;
      //           background: grey;

      //           &::-webkit-scrollbar {
      //             height: 4px;
      //           }

      //           .tournament-table-header {
      //             margin-top: 23px !important;
      //             margin-bottom: 9px !important;
      //             display: flex;
      //             flex-direction: row;
      //             flex-wrap: nowrap;
      //             height: auto !important;

      //             & > div {
      //               white-space: nowrap;
      //               padding: 0;
      //               font-family: 'Open Sans';
      //               font-style: normal;
      //               font-weight: 400;
      //               font-size: var(--smallFontSize);
      //               line-height: 100%;
      //               color: rgba($color: #000, $alpha: 0.5);
      //             }

      //             & > div:nth-of-type(1) {
      //               min-width: 65px;
      //             }

      //             & > div:nth-of-type(2) {
      //               min-width: 180px;
      //               text-align: center;
      //             }

      //             & > div:nth-of-type(3) {
      //               min-width: 100px;
      //               text-align: center;
      //             }

      //             & > div:nth-of-type(4) {
      //               min-width: 100px;
      //               text-align: center;
      //             }

      //             & > div:nth-of-type(5) {
      //               min-width: 165px;
      //               text-align: center;
      //             }
      //           }

      //           .tournament-leaderboard {
      //             overflow-y: auto;
      //             // width: auto;
      //             height: auto;
      //             display: inline-block;
      //             max-height: calc(100% - 61px);

      //             .menu-table-row-wrapper {
      //               padding-bottom: 1px;

      //               .menu-table-row-inner-wrapper {
      //                 padding-left: 0;

      //                 .row {
      //                   display: flex;
      //                   flex-wrap: nowrap;

      //                   & > div {
      //                     font-family: 'Open Sans';
      //                     font-style: normal;
      //                     font-weight: 400;
      //                     font-size: var(--defaultFontSize);
      //                     line-height: 160%;
      //                     color: #000;
      //                   }

      //                   & > div:nth-of-type(1) {
      //                     min-width: 65px;
      //                     font-family: 'Futura PT';
      //                     font-style: normal;
      //                     font-weight: 450;
      //                     font-size: var(--smallFontSize);
      //                     line-height: 1.27;
      //                     color: #000;

      //                     &::before {
      //                       content: unset;
      //                     }
      //                   }



      //                   & > div:nth-of-type(2) {
      //                     min-width: 180px;
      //                     text-align: center;
      //                   }

      //                   & > div:nth-of-type(3) {
      //                     min-width: 100px;
      //                     text-align: center;
      //                   }

      //                   & > div:nth-of-type(4) {
      //                     min-width: 100px;
      //                     text-align: center;
      //                   }

      //                   & > div:nth-of-type(5) {
      //                     min-width: 165px;
      //                     text-align: center;
      //                   }
      //                 }
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-tournaments-tab {
      .layout-mobile-body-top {
        .layout-subheader {
          display: none;
        }

        .layout-mobile-subheader {
          display: inline-flex !important;
          flex-direction: row;
          justify-content: space-between;
          height: 47px;
          background: #000000;
          border-radius: 8px;

          .tournament-subheader-wrapper {
            padding-left: 3.5px;
            padding-right: 3.5px;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto hidden;
            column-gap: 15px;
            width: calc(100% - 49px);

            &::-webkit-scrollbar {
              height: 2px;
            }

            .layout-subheader-link {
              margin: 0;

              .layout-subheader-link-text {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: var(--defaultFontSize);
                line-height: 136%;
                padding: 0 0 3px 0;
                margin: 0;
                white-space: nowrap;
                border-top: none;
                border-right: none;
                border-left: none;
              }
            }
          }

          .tournament-tutorial-button-wrapper {
            img {
            }
          }
        }
      }

      .layout-mobile-body-main {
        .tournament-scrollarea {
          width: 100%;

          .tournaments-participate-content {
            margin-left: 20px;
            display: flex;
            padding: 0;
          }

          .menu-table-row-wrapper {
            padding: 0;

            .menu-table-row-inner-wrapper {
              padding: 0;

              .tournament-table-row {
                padding-left: 16px;
                padding-right: 9px;
                overflow: hidden;
                max-height: unset;

                .active-tournaments {
                  background: rgba($color: #FFFFFF, $alpha: 0.03);
                  border-radius: 12px;
                  margin-bottom: 1px;

                  .active-tournaments-info {
                    padding-top: 15px;

                    .active-tournaments-info-section {
                      padding-bottom: 3px;
                      margin-bottom: 0;

                      .active-tournaments-info-section-title {
                        padding: 0;

                        .active-tournaments-info-section-title-wrapper {
                          display: flex;
                          justify-content: space-between;
                          column-gap: 3px;
                          width: 100%;

                          img {
                            margin-left: 5px;
                            position: relative;
                            z-index: 10;
                          }

                          .active-tournaments-info-section-inner-wrapper {
                            display: flex;
                            flex-direction: column;
                            margin-left: -10px;
                            position: relative;
                            z-index: 100;
                            padding-right: 6px;

                            .first-line {
                              display: flex;
                              justify-content: space-between;

                              .active-tournaments-info-section-title-left-title {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--defaultFontSize);
                                line-height: 160%;
                                color: #FFC900;
                              }

                              .active-tournaments-info-section-title-left-title-status {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--xSmallFontSize);
                                line-height: 160%;
                                text-transform: uppercase;
                                color: #000000;
                                background: #fff;
                                border-radius: 3px;
                                padding-left: 4px;
                                padding-right: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                align-self: center;
                              }

                              .registration-status {
                                background: #019245;
                                color: #FFFFFF;
                              }

                              .confirmed-status {
                                background: #FFFFFF;
                                color: #000000;
                              }

                              .pending-status {
                                background: #FFFFFF;
                                color: #000000;
                              }

                              .play-status {
                                color: #FFFFFF;
                                background: #D2110A;
                              }

                              .pause-status {
                                color: #FFFFFF;
                                background: #D2110A;
                              }
                            }

                            .second-line {
                              display: flex;
                              flex-wrap: wrap;
                              justify-content: flex-end;

                              .active-tournaments-info-section-title-left-private {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: var(--defaultFontSize);
                                line-height: 160%;
                                color: #0C0C0C;
                                flex: auto;
                              }

                              .active-tournaments-info-section-time-rounds-wrapper {
                                .active-tournaments-info-section-time-rounds {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: #0C0C0C;
                                  margin-right: 2px;
                                }

                                .active-tournaments-info-section-time-roundsValue {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 600;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: #0C0C0C;
                                  margin-right: 0;
                                }
                              }

                              .active-tournaments-info-section-time-wrapper {
                                margin-left: 4px;

                                .active-tournaments-info-section-time-label {
                                  font-family: 'Open Sans';
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: var(--defaultFontSize);
                                  line-height: 160%;
                                  color: #0C0C0C;
                                  margin-right: 2px;
                                }

                                .active-tournaments-info-section-time-content {
                                  span {
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: var(--defaultFontSize);
                                    line-height: 160%;
                                    color: #0C0C0C;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    .active-tournaments-info-button {
                      padding-top: 3px;
                      padding-bottom: 4px;
                      margin-bottom: 0;
                      column-gap: 2px;

                      button {
                        padding: 6px 15px;
                        background: rgba(255, 255, 255, 0.4);
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 99px;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: var(--defaultFontSize);
                        line-height: 1.36;
                        text-align: center;
                        text-transform: uppercase;
                        color: #0C0C0C;

                        &::after {
                          margin-left: 13px;
                        }

                        &::before {
                          margin-left: 13px;
                        }
                      }
                    }
                  }

                  .active-tournaments-sponsor.active-tournaments-sponsor-two {
                    border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
                    align-items: flex-end;

                    &::after {
                      content: unset;
                    }

                    .active-tournaments-sponsor-section {
                      margin-top: 12px;
                      flex: 1 1 0;

                      span {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: var(--defaultFontSize);
                        line-height: 160%;
                        text-align: center;
                        color: #0C0C0C;
                      }

                      img {
                        width: calc(100% - 27px);
                      }
                    }

                    .active-tournaments-sponsor-section:first-of-type {
                      border-right: 1px solid rgba(255, 255, 255, 0.1);
                    }
                  }
                }
              }
            }
          }

          .future-tournaments-empty-entries {
            font-size: var(--mLargeFontSize);
          }
        }
      }
    }

    .modal {
      .modal-dialog {
        .modal-body {
          border-radius: 17px 17px 0 0 !important;
        }

        .modal-footer + img {
          filter: invert(1);
        }
      }

      .tournaments-info-modal.modal-dialog {
        .modal-body {
          padding-top: 37px;
          padding-left: 17px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .tournament-info-section {
            display: flex;
            flex-direction: column;
            padding: 0;
            overflow: hidden auto;
            justify-content: flex-start;
            min-height: calc(100vh - 222px);
            max-height: calc(100vh - 350px);
            padding-right: 15px;

            .tournament-info-section-first {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              column-gap: 12px;

              .tournament-info-section-right-row {
                flex: 1;
                text-align: center;
                margin-bottom: 10px;

                img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }

            .tournament-info-section-main {
              margin-top: 0;

              .tournament-info-section-main-row {
                margin-right: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                .tournament-info-section-main-row-content {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  line-height: 160%;
                  color: #000;
                  flex: 1;
                }

                .tournament-info-section-main-row-value {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  color: #000;
                  line-height: 160%;
                  flex: 1;
                }
              }
            }
          }
        }
      }

      .tournaments-tutorial-modal.modal-dialog {
        .modal-body {
          padding-top: 37px;
          padding-left: 26px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;

          .row {
            margin: 0;

            .col {
              padding: 0;

              .tournaments-tutorial-table-scrollarea-new {
                padding-right: 15px;

                ol {
                  padding: 0;

                  & > p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                  }

                  & > p.title {
                    color: #FFC900;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                  }
                }
              }
            }
          }
        }
      }

      .tournament-top-modal {
        .modal-body {
          padding-top: 30px !important;
          padding-bottom: 2px !important;
          padding-left: 7px;
          padding-right: 7px;
          border-radius: 17px 17px 0 0 !important;
          flex: unset;

          .tournament-scrollarea {
            overflow: hidden auto;
            //height: 55vh;
            height: auto;
            max-height: calc(100vh - 275px);

            .tournament-players {
              margin-left: 0;
              margin-right: 0;

              .tournament-players-player {
                padding: 0;
                height: auto;
                min-height: 120px;

                .tournament-player-info {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;

                  .tournament-player-info-background {
                  }

                  .tournament-player-info-player {
                    margin: 0;
                    width: 100%;
                    position: static;

                    .tournament-player-info-player-background {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;

                      .tournament-player-info-player-image-wrapper {
                        width: 100%;
                        min-width: auto;
                        max-width: unset;
                        display: flex;
                        justify-content: center;
                        position: relative;

                        &::before {
                          content: unset;
                        }

                        .tournament-player-info-player-image-background {
                          //display: none;
                        }

                        .tournament-player-info-player-image {
                          margin: 0px;
                          width: 80px;
                          height: 80px;
                          position: static;
                        }

                        .tournament-player-info-player-image-overlay {
                          width: 80px;
                          height: 80px;
                          margin-left: -5px;
                        }

                        .player-info-help-icon {
                          display: block !important;
                          position: absolute;
                          width: 50px;
                          height: 50px;
                          left: 0px;
                          top: -23px;
                        }

                        .player-info-player-image-emotion {
                          display: block !important;
                          position: absolute;
                          right: 0;
                          top: 8px;
                          width: 51px;
                          height: 51px;
                          z-index: 100;
                        }

                        .player-info-player-mark {
                          display: block !important;
                          position: absolute;
                          background-color: #662B8D;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 700;
                          font-size: var(--xSmallFontSize);
                          line-height: 160%;
                          text-transform: uppercase;
                          color: #FFFFFF;
                          padding-left: 3px;
                          padding-right: 3px;
                          border-radius: 2px;
                          left: 50%;
                          top: 40px;
                          z-index: 10;
                          transform: translateX(-50%);
                        }
                      }

                      .tournament-player-info-player-name-wrapper {
                        top: 7px;

                        .tournament-player-info-player-name {
                          padding: 0;
                          font-family: 'Open Sans';
                          font-style: normal;
                          font-weight: 600;
                          font-size: var(--defaultFontSize);
                          line-height: 120%;
                          text-align: center;
                          color: #FFFFFF;
                        }
                      }

                      .tournament-player-info-player-wrapper {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      //"temp"
      // .tournament-leaderboard-modal {
      //   .modal-body {
      //     padding-left: 21px;
      //     padding-right: 7px;
      //     border-radius: 17px 17px 0 0 !important;

      //     .tournament-leaderboard-top3-scroll-box {
      //       padding-right: 7.5px;
      //       height: 58vh;
      //       overflow: hidden hidden;
      //       padding-top: 16px;

      //       .tournament-leaderboard-top3 {
      //         height: 160px;

      //         .col {
      //           padding-left: 0;
      //           padding-right: 0;

      //           .tournament-player-info {
      //             display: inline-flex;
      //             flex-direction: column;
      //             align-items: center;

      //             .tournament-player-info-player-glow-1 {
      //               width: 200px;
      //               height: 200px;
      //               background-size: 200px 200px;
      //               top: -32px;
      //               left: -47px;
      //               position: absolute;
      //             }

      //             .tournament-player-info-userlevel-badge {
      //               background: linear-gradient(180deg, #CDC8C8 0%, #F2F0F0 100%);
      //               box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      //               font-family: 'Futura PT';
      //               font-style: normal;
      //               font-weight: 700;
      //               font-size: var(--largeFontSize);
      //               line-height: 136%;
      //               text-align: center;
      //               color: #000000;
      //               width: 30px;
      //               height: 30px;
      //               text-shadow: 1px 1px 0 #fff;
      //               -webkit-text-stroke: 1px white;
      //             }

      //             .tournament-player-info-background-2 {
      //               height: 52px;
      //             }

      //             .tournament-player-info-background-1 {
      //               height: 81px;
      //             }

      //             .tournament-player-info-background-3 {
      //               height: 31px;
      //             }

      //             .tournament-player-info-player {
      //               margin: 0;
      //               bottom: 10%;

      //               .tournament-player-info-player-background {
      //                 display: flex;
      //                 flex-direction: column;
      //                 align-items: center;

      //                 .tournament-player-info-player-image-wrapper {
      //                   position: relative;
      //                   width: auto;
      //                   max-width: unset;
      //                   min-width: auto;
      //                   margin: unset;
      //                   display: flex;
      //                   flex-direction: column;
      //                   align-items: center;

      //                   &::before {
      //                     content: unset;
      //                   }

      //                   .tournament-player-info-player-image-background {
      //                     //display: none;
      //                   }

      //                   .tournament-player-info-player-image {
      //                     background-size: 100% 100% !important;
      //                     width: 80px;
      //                     height: 80px;
      //                     position: static;
      //                     margin: 0;
      //                   }

      //                   .tournament-player-info-player-image-overlay {
      //                     width: 80px;
      //                     height: 80px;
      //                     margin-left: -5px;
      //                   }

      //                   .tournament-player-info-player-image-emotions {
      //                     top: -25px;
      //                     left: -25px;
      //                     width: 51px;
      //                     height: 51px;
      //                     background-size: 100% 100%;
      //                   }

      //                   .tournament-player-info-player-image-level {
      //                     width: auto;
      //                     top: unset;
      //                     bottom: -6px;
      //                     z-index: 100;

      //                     .tournament-player-info-ratings-step-wrapper {

      //                       .tournament-player-info-ratings-step {
      //                         font-family: 'Open Sans';
      //                         font-style: normal;
      //                         font-weight: 700;
      //                         font-size: var(--xSmallFontSize);
      //                         line-height: 160%;
      //                         text-transform: uppercase;
      //                         color: #000;
      //                         background: #FFFFFF;
      //                         padding-left: 3px;
      //                         padding-right: 3px;
      //                       }
      //                     }
      //                   }
      //                 }

      //                 .tournament-player-info-player-name-wrapper {
      //                   top: 3px;

      //                   .tournament-player-info-player-name {
      //                     padding: 1px 12px;
      //                     font-family: 'Open Sans';
      //                     font-style: normal;
      //                     font-weight: 600;
      //                     font-size: var(--defaultFontSize);
      //                     line-height: 160%;
      //                     color: #FFFFFF;
      //                     background: #000000;
      //                   }
      //                 }

      //                 .tournament-player-info-player-wrapper {
      //                   display: none;
      //                 }
      //               }
      //             }

      //             .tournament-player-info-player-1 {
      //               bottom: 12%;

      //               .tournament-player-info-player-background {
      //                 .tournament-player-info-player-image-wrapper {
      //                   .tournament-player-info-player-image {
      //                     width: 100px;
      //                     height: 100px;
      //                   }

      //                   .tournament-player-info-player-image-overlay {
      //                     width: 100px;
      //                     height: 100px;
      //                   }

      //                   .tournament-player-info-player-image-emotions {
      //                     top: -20px;
      //                     left: -25px;
      //                   }
      //                 }

      //                 .tournament-player-info-player-name-wrapper {
      //                 }
      //               }
      //             }

      //             .tournament-player-info-player-3 {
      //               bottom: 8%;

      //               .tournament-player-info-player-background {
      //                 .tournament-player-info-player-image-wrapper {
      //                   .tournament-player-info-player-image {
      //                     width: 68px;
      //                     height: 68px;
      //                   }

      //                   .tournament-player-info-player-image-overlay {
      //                     width: 68px;
      //                     height: 68px;
      //                   }
      //                 }

      //                 .tournament-player-info-player-name-wrapper {
      //                 }
      //               }
      //             }
      //           }
      //         }
      //       }

      //       .tournament-leaderboard-table-wrapper {
      //         width: 100%;
      //         overflow-x: auto;

      //         .col-12 {
      //           padding: 0;
      //           height: auto;
      //           overflow: auto hidden;
      //           max-width: unset;
      //           flex: unset;
      //           height: calc(65vh - 192px);
      //           min-width: 486px;

      //           &::-webkit-scrollbar {
      //             height: 4px;
      //           }

      //           .tournament-table-header {
      //             margin-top: 23px !important;
      //             margin-bottom: 9px !important;
      //             display: flex;
      //             flex-direction: row;
      //             flex-wrap: nowrap;
      //             height: auto !important;

      //             & > div {
      //               white-space: nowrap;
      //               padding: 0;
      //               font-family: 'Open Sans';
      //               font-style: normal;
      //               font-weight: 400;
      //               font-size: var(--smallFontSize);
      //               line-height: 100%;
      //               color: rgba($color: #000, $alpha: 0.5);
      //             }

      //             & > div:nth-of-type(1) {
      //               min-width: 65px;
      //             }

      //             & > div:nth-of-type(2) {
      //               min-width: 180px;
      //               text-align: center;
      //             }

      //             & > div:nth-of-type(3) {
      //               min-width: 100px;
      //               text-align: center;
      //             }

      //             & > div:nth-of-type(4) {
      //               min-width: 100px;
      //               text-align: center;
      //             }

      //             & > div:nth-of-type(5) {
      //               min-width: 165px;
      //               text-align: center;
      //             }
      //           }

      //           .tournament-leaderboard {
      //             overflow-y: auto;
      //             // width: auto;
      //             height: auto;
      //             display: inline-block;
      //             max-height: calc(100% - 61px);

      //             .menu-table-row-wrapper {
      //               padding-bottom: 1px;

      //               .menu-table-row-inner-wrapper {
      //                 padding-left: 0;

      //                 .row {
      //                   display: flex;
      //                   flex-wrap: nowrap;

      //                   & > div {
      //                     font-family: 'Open Sans';
      //                     font-style: normal;
      //                     font-weight: 400;
      //                     font-size: var(--defaultFontSize);
      //                     line-height: 160%;
      //                     color: #000;
      //                   }

      //                   & > div:nth-of-type(1) {
      //                     min-width: 65px;
      //                     font-family: 'Futura PT';
      //                     font-style: normal;
      //                     font-weight: 450;
      //                     font-size: var(--smallFontSize);
      //                     line-height: 1.27;
      //                     color: #000;

      //                     &::before {
      //                       content: unset;
      //                     }
      //                   }



      //                   & > div:nth-of-type(2) {
      //                     min-width: 180px;
      //                     text-align: center;
      //                   }

      //                   & > div:nth-of-type(3) {
      //                     min-width: 100px;
      //                     text-align: center;
      //                   }

      //                   & > div:nth-of-type(4) {
      //                     min-width: 100px;
      //                     text-align: center;
      //                   }

      //                   & > div:nth-of-type(5) {
      //                     min-width: 165px;
      //                     text-align: center;
      //                   }
      //                 }
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
