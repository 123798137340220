


// @import "../../redesignVariables";

$fontSize: $largeFontSize;

$paddingSize: $smallFontSize;

.switch-select {
  height: 38px;

  &-option {
    display: inline-block;
  //  display: flex;
  //  flex-direction: row;
  //  align-items: flex-start;
    padding: 9px $paddingSize;
    height: 100%;

    background: $switchSelectBackground;
    box-shadow: $switchSelectBoxShadow;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: var(--largeFontSize);
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    /* White */

    &-name {
      color: $switchSelectTextColor;
      opacity: 0.5;
    }

    &-first {
      border-radius: 55px 0 0 55px;
    }

    &-last {
      border-radius: 0 55px 55px 0;
    }

    &-active {
      background: $switchSelectActiveBackground;
      box-shadow: $switchSelectActiveBoxShadow;

      & .switch-select-option-name {
        color: $switchSelectActiveTextColor;
        opacity: 1;
      }
    }
  }

  &-option:first-child {
    border-radius: 55px 0 0 55px;
  }
}

.root-class-design20-normal  {
  .switch-select-option {
    background-color: #131933;
  }
}

.root-class-design20-light {
  .user-settings-row {
    .switch-text-wrapper {
      .switch-text.active {
        color: #000 !important;
      }
      .switch-text.disable {
        color: rgba($color: #000000, $alpha: 0.5) !important;
      }
    } 
  } 
}