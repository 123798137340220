.money-history {

  &-tabs {
    padding-bottom: 10px;
  }

  &-tab {
    padding-left: 5px;
    padding-right: 5px;

    &-wrapper {
      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      cursor: pointer;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        border-color: #fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    & .active {
      color: #fff;
      background-color: #380000;
      border-color: #fff;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }


  &-table {
    max-width: 100%;
    table-layout: fixed;
    width: 100%;

    &-scrollarea {
      margin-top: 10px;
      height: 300px;
    }

    &-body {
    //  max-height: 300px;
    //  overflow-y: scroll;
    //  overflow-x: hidden;
    }

    &-col {
      overflow: hidden;
      color: #fff;

      //  border: 1px solid #fff;

      font-size: var(--defaultFontSize);
      line-height: 15px;
      text-align: center;
    }

    &-row {
      height: 34px;
      max-width: 100%;
      overflow: hidden;

      &-user {
        height: 34px;
        max-width: 100%;
        overflow: hidden;
        background: rgba(247, 180, 90, 0.3);
      }
    }

    &-header {
      width: 100%;
      max-width: 100%;
      overflow: hidden;

      border-bottom: 1px solid #fff;

      font-weight: bold;
      font-size: var(--largeFontSize);

      &-col {
        color: #FFF;
      //  border-bottom: 1px solid #fff;
        width: 15%;

        font-weight: 450;
        font-family: 'Futura PT';
        font-size: var(--largeFontSize);
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        line-height: 1.28;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.money-history-table-body {
  // height: 100%;
}

.money-history-empty-entries,
.player-points-history-empty-entries-old {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1rem;
}
