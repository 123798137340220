.top {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);
  border-radius: 15px;
  color: #fff;
//  padding: 10px 5px 5px 10px;
  padding: 15px 10px 15px 25px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-leaderboard-modal {
    &-section {
      margin-bottom: 15px;
      &-title {
        font-weight: 700;
        font-size: var(--largeFontSize);
      }

      &-description {
        opacity: 0.8;
        &-first-msg {
          font-weight: 700;
        }
      }
      input {
        margin-right: 5px;
      }
      &-sorting-wrapper {
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        margin-bottom: 15px;
      }
      &-sorting-label {
        margin-right: 13px;
        margin-bottom: 1px !important;
        margin-top: 1px;
      }

      &-toggle-wrapper {
        display: flex;
        vertical-align: middle;
        align-items: center;
        margin-bottom: 8px;
      }

      &-toggle-label {
        margin-bottom: 1px !important;
        margin-top: 1px;
        user-select: none;
      }
    }
  }

  &-iframe-joyride-players-target {
    display: block;
    position: absolute;
    width: 198px;
    //height: 512px;
    //height: calc(100vh - 220px);
    left: 0px;
    top: 150px;
    bottom: 5px;
    pointer-events: none;

    @media screen and (orientation : portrait) {
      height: calc(100vh - 510px);
      width: 99%;
      top: 108px;
      bottom: unset;
      opacity: 0;
    }
  }

  &-scrollarea {
    height: 300px;
    max-height: 300px;

    &-body {

    }
  }

  &-search {
    padding-top: 10px;
  }
  &-list-part {
    flex: 1;
    position: relative;
    overflow: hidden;
    & .virtual-top-list-part {
      position: absolute;
      height: 550px;
      width: 100%;
      left: 0px;
      top: 0px;
    }
  }
  
  &-tabs {
  //  margin-top: 20px;

    margin-bottom: 10px;

    &-header {
      height: 35px;

      &-image {
        display: inline-block;
        margin-right: 10px;
        width: 28px;
        height: auto;
        max-width: 20%;
      }

      &-text {
        text-transform: uppercase;
        font-size: var(--headerFontSize);
        font-weight: 600;
        color: #fff;
        margin-top: 5px;
        display: inline-block;
      }
    }
  }

  &-select {

    width: 100%;
  //  margin-right: 3%;
  //  float: right;

    text-transform: uppercase;
  //  text-align: center;
  //  text-align-last:center;
  //  -ms-text-align-last: center;
  //  -moz-text-align-last: center;

    & button {
    //  min-width: 180px;
    }

    & .dropdown-toggle {
      padding: 5px 8px;
      font-size: var(--largeFontSize);
      // font-size: 15px;
    }
  }

  &-table-wrapper {
    overflow: auto;
    height: 100%;

    &::-webkit-scrollbar-corner {
      background: rgba(0,0,0,0) !important;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background: white;
    }
  }
  &-table-wrapper thead th {
    position: sticky !important;
    top: 0;
    background: rgb(38, 78, 74) !important;
    z-index: 1;
 }
  &-table {
    max-width: 98%;
    table-layout: fixed;
    width: 100%;

    &-spinner-loading-section {
      width: 100%;
      height: 100%;
      min-height: 350px;
    }

    &-col {
      overflow: hidden;
      color: #fff;

    //  border: 1px solid #fff;

      font-size: var(--mSmallFontSize);
      line-height: 15px;
      text-align: center;

      &-no-border {
        border-bottom: none !important;
      }
    }

    &-row {
      height: 34px;
      max-width: 100%;
      overflow: hidden;

      &-var-height {
        max-width: 100%;
        overflow: hidden;
      }

      &-user {
        height: 34px;
        max-width: 100%;
        overflow: hidden;
        background: rgba(247, 180, 90, 0.3);
      }
    }

  & .col-player {
    text-align: center;
    width: 200px;
  }

  & .col-lvl {
    position: relative;

    &-lvl {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #c44c4c;
      font-size: var(--smallFontSize);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid #fff;
      text-align: center;

      color: #fff;
    }

      &-text {
      }
  }

  & .col-index {
    width: 10%;
  }

  &-header {
    width: 100%;

    &-col {
      color: #FFF;
    //  border: 1px solid #fff;
      border-bottom: 1px solid #fff;
      width: 100px;

      font-weight: bold;
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      text-align: center;

      &-no-border {
        border: none !important;
      }
    }

    &-row {
      height: 34px;
      max-width: 100%;
      overflow: hidden;

      border-bottom: 1px solid #fff;

      font-weight: bold;
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      &-no-border {
        border: none !important;
        height: 0px !important;
      }

      & .col-index {
        width: 100px;
      }

      & .col-player {
        width: 200px;
      }
    }

    &-coin {
      width: 20px;
      height: 20px;
      margin: auto;
    }
  }

//  & tbody tr:nth-of-type(even) {
  //  background-color: rgba(97,89,73, 0.3);
//  }


  /*
  &-container {
    height: 500px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-track {
      background: linear-gradient(180deg, #B4EC51 21.76%, #429321 65.5%);
      border-radius: 3px;
      border-left: 7px solid #000;
      border-right: 7px solid #000;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #B4EC51 21.76%, #429321 65.5%);
      border-radius: 3px;
    //  background: black;
      border-left: 2px solid #000;
      border-right: 2px solid #000;
    }
  }

  &-legend {

    &-text {
      font-size: 13px;
    }
  }
  */
  }
}
