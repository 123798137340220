
$achievementButtonColor: $approveButtonColor;
$achievementActiveBackground: $approveButtonBackground;

$achievementinactiveBackground: $declineButtonBackground;

.my-info {

  &-results {
  //  height: 100%;

    &-scrollarea {
      height: auto!important;
      max-height: 100%;

      & .min-width-100 {
        min-width: 110px !important;
      }

      & .min-width-90 {
        min-width: 100px !important;
      }

      & .line-height-14 {
        line-height: 1.4vmin;
      }

      &-body {
        height: auto;
        max-height: calc(100% - 65px) !important;
        &-header {
          white-space: nowrap;
          overflow: hidden;
          height: 40px;
          background-color: rgba(0, 0, 0, 0.95);
          padding-left: 15px;
          padding-right: 15px;
          font-family: 'Open Sans';
          font-size: var(--defaultFontSize);
          font-weight: 400;
          color: $darkDefaultTextColor;
          & .menu-table-row-text {
            // border-right: 1px solid rgba(255,255,255,0.1);
            width: 6.66%;
            min-width: 60px;
            text-align: center;
          }

          & .menu-table-row-text-breakspaces {
            white-space: break-spaces;
          }

        }

        & .menu-table-row-wrapper {
          padding-bottom: 1px;
          & .menu-table-row-inner-wrapper {
            & > div {

              & > .menu-table-row-text-last {
                border-right: 0!important;
              }

              & > .menu-table-row-text {
                border-right: 1px solid rgba(255,255,255,0.1);
                width: 6.66%;
                min-width: 60px;
                text-align: center;

                & .menu-table-row-text-positive {
                  background: #009C10;
                  border-radius: 44px;
                  width: max-content;
                  padding-left: 1.5vmin;
                  padding-right: 1.5vmin;
                }

                & .menu-table-row-text-negative {
                  background: #D2110A;
                  border-radius: 44px;
                  width: max-content;
                  padding-left: 1.5vmin;
                  padding-right: 1.5vmin;
                }
              }

              & .md-size {
                width: 9.66%;
              }
            }
          }
        }

      }
    }

    &-right {
      height: 100%;
      padding: 0;
    }

  }
}

.my-info-body-main {
  position: relative;
  .scrollArea-buttonUp,
  .scrollArea-buttonDown {
    display: none;
  }
}

.my-info-results-scrollarea {
  overflow-y: hidden;
  overflow-x: auto;
  height: calc(100% - 2px) !important;
  padding-bottom: 4px;

  .scrollArea-buttonUp,
  .scrollArea-buttonDown {
    display: none;
  }

  &::-webkit-scrollbar {
    height: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba($color: #fff, $alpha: 0.2);
  }

  .my-info-results-scrollarea-body {
    overflow-y: hidden;
    overflow-x: hidden;
    height: calc(100% - 60px);
    width: calc(100% - 4px);
    position: unset;

    .scrollbar-container.vertical {
      top: 69px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba($color: #fff, $alpha: 0.2);
      &:hover {
        background-color:rgba(255, 255, 255, 0.4);
      }
    }
  }
}

.root-class-design20-dark {
  .my-info-results-scrollarea,
  .my-info-results-scrollarea-body {
    &::-webkit-scrollbar {
      background-color: #000;
    }
  }
}

.root-class-design20-normal {
  .my-info-results-scrollarea,
  .my-info-results-scrollarea-body {
    &::-webkit-scrollbar {
      background-color: rgb(37, 54, 120)
    }
  }
}

.root-class-design20-light {
  .my-info-results-scrollarea,
  .my-info-results-scrollarea-body {
    &::-webkit-scrollbar {
      background-color: rgb(236, 236, 236);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.95) !important;
    }
  }
  .my-info-results-scrollarea-body-header {
    color: rgba($color: #0C0C0C, $alpha: 0.5);
  }
}

.root-font-normal {
  .my-info-results-scrollarea {
    .my-info-results-scrollarea-body {
      .scrollbar-container.vertical {
        top: 69px;      
      }
    } 
  }
}

.root-font-large {
  .my-info-results-scrollarea {
    .my-info-results-scrollarea-body {
      .scrollbar-container.vertical {
        top: 69px;      
      }
    } 
  }
}

.root-font-xLarge {
  .my-info-results-scrollarea {
    .my-info-results-scrollarea-body {
      .scrollbar-container.vertical {
        top: 69px;      
      }
    } 
  }
}