$nameColor: #0C0C0C;
$bgColor: #FFF;
$commentColor: #868686;
$fontSize: 1.2vmin;
$mobileSmSize: 575px;

.root-class-design20 {
  .contacts-container {
    width: 100%;
    height: auto;
    min-height: 50vh;
    background-color: #0C0C0C;
    min-width: 375px;

    & .contacts-page {
      width: 80%;
      margin-top: 150px;
      &-title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        //font-size: var(--xxxLargeFontSize);
        font-size: 24px;
        line-height: 140%;
        color: #FFFFFF;
        margin-bottom: 74px;
      }

      @media only screen and (max-width: $mobileSmSize) {
        &-title {
          margin-bottom: 10px;
        }

        &-first-section {
          justify-content: center;
        }
      }



      &-first-section {
        width: 100%;
        height: auto;

        &-item {
          width: 100%;

          & .sub-title {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: var(--xxLargeFontSize);
            line-height: 140%;
            color: #FFFFFF;
          }

          &-common-form {
            width: 100%;
            min-width: 350px;
            display: inline-block;

            & .three-part-section {
              width: 30%;
              margin-bottom: 9px;
            }

            & .comments-section {
              position: relative;
              width: 91%;
              // height: 156px;

              & textarea {
                width: 100%;
                height: 100%;
                background: #FFFFFF;
                border: 1px solid #868686;
                border-radius: 25px;
              }
              & .limit-characters {
                position: absolute;
                right: 20px;
                bottom: -8px;
              }

              & .inputInlineMessage-danger {
                width: auto;
                height:  17px;
                position: absolute;
                bottom: -8px;
                left: 15px;
                font-size: var(--smallFontSize);
                background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
                border-radius: 99px;
                padding: 1px 6px 2px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                color: #fff;
                line-height: normal;
                top: unset;
              }
            }

            & .send-message-button {
              & button {
                width: auto;
                height: 38px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: var(--largeFontSize);
                line-height: 20px;
                text-align: center;
                text-transform: uppercase;
              }
            }
            
            @media only screen and (max-width: $mobileSmSize) {
              & .send-message-button {
                & button {
                  padding: 9px 15px;
                  width: auto;
                  height: auto;
                  line-height: 136%;
                  &::before {
                    margin-right: 13px;
                  }
                  &::after {
                    margin-left: 13px;
                  }
                }
              }
            }

          }

          & .contacts-information {
            width: 100%;
            position: relative;
            &-icon {
              width: 20%;
            }
            
            &-content {
              font-size: var(--largeFontSize);
              width: 80%;
              position: absolute;
              left: 70px;
              top: 12px;
              color: white;
            }

          }
        }
      }


    }

    @media only screen and (max-width: $mobileSmSize) {
      & .contacts-page {
        margin-top: 20px !important;
        width: 100%;

        &-first-section {
          &-item {
            min-height: 420px;
            margin-bottom: 34px !important;
            & .sub-title {
              font-size: var(--xLargeFontSize);
              margin-top: 14px !important;
              margin-bottom: 11px !important;
              padding-left: 20px !important;
              padding-right: 20px !important;
            }

            &-common-form {
              min-width: 350px;
              padding-left: 20px !important;
              padding-right: 20px !important;
            }
          }

          .contact-message-sent-success {
            padding-top: 148px;
            padding-bottom: 156px;
            padding-left: 0;
            text-align: center;
            img.contact-message-sent-success-image {
              margin: 0 auto;
            }
            .contact-message-sent-success-text {
              margin-top: 28px;
              font-family: 'Open Sans';
              font-weight: 400;
              font-size: var(--largeFontSize);
              line-height: 160%;
              color: #fff;
            }
          }
          
        }

        &-title {
          padding-left: 20px !important;
          padding-right: 20px !important;
          font-size: var(--xxLargeFontSize);
        }

        & .inline-input-group {
          flex-direction: column !important;
        }

        & .three-part-section {
          width: 100%;
        }

        & .comments-section {
          width: 100%;
        }
      }
    }

    @media only screen and (max-width: $mobileSmSize) {
      & .blog-article-section {
        min-height: 3050px;
        & .blog-image {
          height: 239px !important;
        }
        & .blog-article-back {
          display: none;
        }
      }
    }
  }
}

.contacts-page-first-section {
  margin-bottom: 105px;
  .contact-message-sent-success {
    .contact-message-sent-success-image {
      margin-bottom: 28px;
    }
    .contact-message-sent-success-text {
      color: #fff;
    }
  }
}