
// @import "../../redesignVariables";

// $defaultFontSize: 1.2vmin;

$rowHeight: $menuRowHeight;

//$buttonPrimaryColour: #FFF;
//$buttonSecondaryColour: #000;
//$buttonBackgroundColour: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);

.root-class-design20 {

  .tournament {

    &-top {
      &-menu-row-inner-wrapper-dark {
        &-first {
          background-color: rgba(255,255,255,0.2) !important;
        }
        background-color: rgba(255,255,255,0.03) !important;
      }
      &-menu-row-inner-wrapper-normal {
        &-first {
          background-color: rgba(255,255,255,0.2) !important;
        }
        background-color: rgba(255,255,255,0.03) !important;
      }
      &-menu-row-inner-wrapper-light {
        &-first {
          background-color: rgba(0,0,0,0.2) !important;
        }
        background-color: rgba(0,0,0,0.03) !important;
      }
      &-row {
        margin-left: 10px;
        margin-right: 10px;
        // overflow: hidden scroll;
      }
      &-row-scroll {
        margin-left: 10px;
        margin-right: 10px;
      }
      &-header-col {
        text-align: center;
        font-weight: 700;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      &-player-col {
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;

        &-light {
          background: rgba(0,0,0,0.1);
        }
        &-normal {
          background: rgba(255,255,255,0.2);
        }
        &-dark {
          background: rgba(255,255,255,0.2);
        }
      }
      &-col {
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

  //  &-scrollarea {
  //    max-height: 50vh;
  //    height: 50vh;
  //  }

    &-leaderboard {

      &-top3 {
        height: 281px;
        margin: 0;

        & .tournament-player-info {
          position: absolute;
          width: 100%;
          height: 100%;

          &-background {
            width: 100%;
            height: 100%;

            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center bottom;

            &-1 {
              background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
              width: 100%;
              height: 140px;
              position: absolute;
              bottom: 0;
            }

            &-2 {
              background: linear-gradient(180deg, #898989 0%, #DEDEDE 100%);
              width: 100%;
              height: 120px;
              position: absolute;
              bottom: 0;
            }

            &-3 {
              background: linear-gradient(180deg, #882900 0%, #DA8231 100%);
              width: 100%;
              height: 100px;
              position: absolute;
              bottom: 0;
            }

          /*  &-shadow {
              position: absolute;
              top: 0;


              background-image: url('../../../../images/redesign/player-shadow.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 100%;
            //  margin-left: 25%;
            //  margin-right: 25%;
              height: 100%;
              pointer-events: none
            }

            &-shadow2 {
              position: absolute;
              top: -20%;


              background-image: url('../../../../images/redesign/player-shadow2.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 130%;
              margin-left: -10%;
              height: 100%;
              pointer-events: none
            }  */
          }

          &-player {
            position: absolute;
            bottom: 5%;
            width: 70%;
            margin-left: 15%;
            margin-right: 15%;

            &-1 {
              bottom: 7%;
              z-index: 100;
            }

            &-2 {
              bottom: 4%;
            }

            &-3 {
              bottom: 1%;
            }

          //  display: none;

            &-background {
              position: relative;
            }

            &-position {
              &-1 {

              }

              &-2 {

              }

              &-3 {

              }
            }

            &-image {
            //  position: relative;

              background-size: cover;
              background-repeat: no-repeat;
            //  width: 100%;
            //  height: 100%;

              box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
              filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));

              position: absolute;
              top:      0;
              left:     0;
              bottom:   0;
              right:    0;

              margin: 12px;
              border-radius: 50%;

              &-background {
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url('../../../../images/redesign/player/UserFrame.png');
                background-size: cover;
                background-repeat: no-repeat;
                top: 0;
              }

              &-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url('../../../../images/redesign/player/UserOverlay.png');
                background-size: cover;
                background-repeat: no-repeat;
                top: 0;
              }

              &-wrapper {
                position: relative;
                width: 50%;
                min-width: 140px;
                max-width: 18vmin;
                margin-left: auto;
                margin-right: auto;

                &:before {
                  content: "";
                  display: block;
                  padding-top: 100%; /* initial ratio of 1:1*/
                }
              }


            }

            &-name {
              display: inline-block;
              justify-content: center;
              align-items: center;
              padding: 7px 20px;

              /* Black */
              color: $buttonPrimaryColour;
              background: $buttonSecondaryColour;
              border-radius: 88px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;

              &-wrapper {
                position: relative;
                text-align: center;
                font-size: var(--headerFontSize);
              }
            }

            &-info-wrapper {
              position: relative;
              text-align: center;
              font-size: var(--headerFontSize);
            }

            &-wrapper {
              position: relative;
              text-align: center;
              font-size: var(--headerFontSize);
              margin-left: auto;
              margin-right: auto;

            //  display: inline-block;
            //  justify-content: center;
            //  align-items: center;
              padding: 2px 10px;
              color: #FFF;
              background: rgba(0, 0, 0, 0.3);
              border-radius: 88px;
            }

            &-balance {
              display: inline-block;
              position: relative;
              text-align: center;
              font-size: var(--defaultFontSize);
            //  float: left;

              color: $buttonPrimaryColour;
              padding-right: 3px;
            //  width: 6vmin;
            //  background: $buttonSecondaryColour;

              &-icon {
                display: inline-block;
                text-align: center;
                width: auto;
                height: $headerFontSize;
                padding-left: 3px;
                padding-right: 3px;
              //  float: left;
              }
            }

            &-rating {
              display: inline-block;
              position: relative;
              text-align: center;
              font-size: var(--defaultFontSize);
            //  float: left;

              color: $buttonPrimaryColour;
            //  width: 6vmin;
            //  background: $buttonSecondaryColour;
              &-icon {
                display: inline-block;
                text-align: center;
                width: auto;
                height: $headerFontSize;
                padding-left: 3px;
                padding-right: 3px;
              //  float: left;
              }
            }

          /*  &-balance {
              display: inline-block;
              position: relative;
              text-align: center;
              font-size: var(--defaultFontSize);

              color: $buttonPrimaryColour;
              padding-right: 3px;
            //  background: $buttonSecondaryColour;

              &-icon {
                display: inline-block;
                width: auto;
                height: $headerFontSize;
              }
            }

            &-rating {
              display: inline-block;
              position: relative;
              text-align: center;
              font-size: var(--defaultFontSize);

              color: $buttonPrimaryColour;

              &-icon {
                display: inline-block;
                width: auto;
                height: $headerFontSize;
              }
            }  */
          }
        }
      }


      &-table {
        font-size: var(--defaultFontSize);
        width: 100%;

        &-wrapper {
          margin: 0;

          & > div {
            // max-height: calc(30vh + 19px);
            min-height: 190px;
            @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
              min-height: auto;
            }
          }
        }

        &-header {
        //  border-bottom: 1px solid #fff;
          text-align: center;
        }

        &-row {
          height: 34px;
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          overflow: hidden;

          background-color: rgba(255, 255, 255, 0.05);
          border-radius: 100px;
          margin-bottom: 2px;

          &-user {
            height: 34px;
            max-width: 100%;
            overflow: hidden;
            background: rgba(247, 180, 90, 0.3);
          }
        }

        &-col {
          text-align: center;
        }

      }
    }


  }

}
