
// @import "../../redesignVariables";

.root-class-design20 {

  .feedback-new-section {
    position: fixed;
    left: 10px;
    top: unset !important;
    width: 494px;
    height: auto;
    bottom: 10px;
    z-index: 1101;

    @media screen and (orientation : portrait) and (max-width: 1280px) {
      width: auto;
      left: 3px;
      right: 42px;
    }

    & .modal-body {
      padding-left: unset !important;
      padding-right: unset !important;
    }

    &-complete-feedback-body {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    &-complete-feedback-footer {
      width: 100%;
      height: 151px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 10px;
    }

    &-feedback-body {
      width: 100%;
      height: 280px;
      text-align: left;
      padding: 0px !important;
      color: #fff;
      border-bottom: 1px solid #868686;

      &-rating-stars {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & label {
          font-size: var(--headerFontSize);
        }

        & .rating-stars-section {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        & .rating-stars-point-section {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 50px;

          & span {
            font-size: var(--xxLargeFontSize);
          }

          @media screen and (orientation : portrait) and (max-width: 1280px) {
            justify-content: center;
            margin-left: 0;
          }
        }

        & .rating-stars-point-label {
          justify-content: center;
          align-items: center;
          display: flex;
          margin-top: 10px;
        }
      }
      &-rating-comments-section {
        padding: 20px;
      }

      &-rating-comments {
        position: relative;
        left: 0px;
        top: 0px;
        height: 82px;
        margin-top: 30px;
        background: #fff;
        border: 1px solid #868686;
        box-sizing: border-box;
        border-radius: 25px;

        & textarea {
          width: 100%;
          height: 80%;
          /* border: 1px solid #868686; */
          box-sizing: border-box;
          border-radius: 25px;
          border: none;
        }

        & label {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: var(--smallFontSize);
          line-height: 160%;
          text-align: right;
          color: #868686;
          position: absolute;
          right: 15px;
        }

        &-limited-characters-error {
          color: #a81211;
        }

        &-inputInlineMessage-danger {
          width: auto;
          height:  17px;
          position: absolute;
          bottom: -8px;
          left: 15px;
          font-size: var(--smallFontSize);
          background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
          border-radius: 99px;
          padding: 1px 6px 2px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          color: #fff;
          line-height: normal;
        }

        &-inputInlineMessage-success {
          width: auto;
          height:  17px;
          position: absolute;
          top: 26px;
          font-size: var(--smallFontSize);
          background: #009C10;
          border-radius: 99px;
          padding: 1px 6px 2px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          color: #fff;
          line-height: normal;
        }
      }
    }

    &-feedback-footer {
      width: 100%;
      display: block;
      height: 71px;
      padding: 12px 12px 20px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      & .btn {
        justify-content: center;
        align-items: center;
        display: inline-block;
        vertical-align: middle;

        height: 36px;

        background: $footerButtonBackground;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4),
          0px 4px 20px rgba(0, 0, 0, 0.25),
          inset 0px 1px 0px rgba(255, 255, 255, 0.4);
        border-radius: 99px;

        color: $footerButtonColor;

        font-family: Open Sans;
        font-style: normal;
        font-weight: 900;
        font-size: var(--headerFontSize);
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;

        padding: 3px 8px;

        min-width: 15%;

        &:before {
          display: inline-block;
          content: ' ';
          background-image: url('../../../../images/redesign/common/karavs.svg');
          background-size: 7px 7px;
          height: 7px;
          width: 7px;
          margin-right: 12px;
          margin-left: 6px;
          vertical-align: middle;
        }

        &:after {
          display: inline-block;
          content: ' ';
          background-image: url('../../../../images/redesign/common/karavs.svg');
          background-size: 7px 7px;
          height: 7px;
          width: 7px;
          margin-right: 6px;
          margin-left: 12px;
          vertical-align: middle;
        }
      }

      &-submit-button {
        height: 47px !important;

        /* Inside auto layout */

        flex: none !important;
        order: 0 !important;
        flex-grow: 0 !important;
        margin: 0px 7px !important;
        & .openPage {
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 0px !important;
        }
      }

      &-close-button {
        height: 47px !important;

        background: rgba(0, 0, 0, 0.4) !important;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
        box-sizing: border-box !important;
        border-radius: 99px !important;
        color: #fff !important;
        /* Inside auto layout */

        flex: none !important;
        order: 1 !important;
        flex-grow: 0 !important;
        margin: 0px 7px !important;
        & .openPage {
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 0px !important;
        }
      }
    }
  }
  .survey-modal-body-section {
    min-height: 302px;
    height: auto;

    @media screen and (orientation : landscape) and (max-width: 1280px) {
      min-height: unset;
      overflow-y: auto;
      max-height: calc(100vh - 167px);
      width: calc(100% - 5px);
    }
  }
}


.root-class-design20-light {
  .feedback-new-section {
    &-feedback-body {
      color: black;

      // &-rating-stars {

      // }
      &-rating-comments-section {
        .form-group {
          .switch-text {
            color: black;
          }
        }
      }
    }
  }
}