.root-class-design20 {

  .emotions {
    background: transparent;
    border: 0;
    box-shadow: none;
    width: 308px;
    height: 228px;
    bottom: 8.3vmin;
    width: 29vmin;
    z-index: 9999;

    &-section {
      height: 100% !important;
      width: 100% !important;
    }
  }

  .room-chat {
    bottom: 50px;
    left: 20px;
    width: 24%;
    position: fixed;
    background-color: transparent;

    &-close-button,
    &-open-button {

      padding: 0;

      &-icon {
        //  width: 30px;
        //  height: 30px;

        width: 30px;
        height: 30px;

        min-width: 20px;
        min-height: 20px;
        margin-top: -2px;
        // margin-left: 10px;
        //  min-height: 1.5vmin;

        background-image: url('../../../../../images/redesign/game/open.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center bottom;
      }
    }

    &-header {
      // height: 3vmin;
      // position: absolute;
      // top: -4.5vmin;
      // left: 15px;
      padding-left: 40px;
      margin-bottom: 5px;

      .room-chat-close-button,
      .room-chat-open-button {
        padding-right: 10px;
      }
    }

    &-open {
      //  height: 40%;
      min-height: 4vmin;
      max-height: 40%;
      height: fit-content;
      border-radius: 3px;

      // bottom: calc(5% + 0px);
      margin-bottom: 9px;

      & .room-chat-body {
        overflow-y: auto;
        // height: 100%;
        display: flex;
        flex-direction: column-reverse;
        
        border-radius: 19px 19px 0px 0px;

        &-light {
          background: rgba(255,255,255,0.5);
        }
        &-normal {
          background: rgba(255,255,255,0.5);
        }
        &-dark {
          background: rgba(0,0,0,0.7);
        }

        & .chat-body-new-scroll-area {
          height: 340px;
        }
      }
    }

    &-closed {
      height: 0;
      bottom: 101px;//82px;
      background-image: linear-gradient(180deg, transparent, #383838);
      border-radius: 3px;

      & .room-chat-body {
        height: 0;
      }
    }

    &-bar {
      position: fixed;
      bottom: 5px;
      left: 20px;
      width: 30%;
      height: 5%;
      min-height: 40px;

      & .room-chat-bar-section {
        display: flex;
        justify-content: space-between;

        .room-chat-bar-emotions-button {
          width: 30%;
          display: flex;
          justify-content: flex-end;
        }
      }

      & .row {
        height: 100%;
      }

      &-input {
        background-color: transparent;
        padding-left: 10px;
        padding-right: 10px;
        color: #fff;
        font-size: var(--defaultFontSize);
        border: 0;
        resize: none;
        height: 100%;

        &:focus {
          background-color: transparent;
          color: #fff;
          border: 0;
        }

        &::placeholder {
          color: #fff;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #fff;
        }

        &::-ms-input-placeholder {
          color: #fff;
        }
      }

      &-button {
        &-emotions {
          width: auto;
          height: 100%;
          margin-right: 7px;
          padding-top: 5px;
          padding-bottom: 5px;
          // position: absolute;
          cursor: pointer;

          &-col {
            padding: 0;
            max-height: 100%;
          }
        }

        &-support {
          margin-right: 10px;
          cursor: pointer;
          border: 1px solid rgba(#ffffff, 0.2);
          border-radius: 50%;
          padding: 8px;

          img {
            object-fit: contain;
            width: 18px;
            height: 18px;
          }
        }

        &-send {
          //  width: 100%;
          //  height: 100%;
          border-radius: 50px;
          height: calc(100% - 8px);
          margin-right: 4px;
          margin-top: 4px;
          margin-bottom: 4px;
          text-transform: uppercase;
          float: right;
          font-size: var(--defaultFontSize);
          background: rgba(255, 255, 255, 0.1);

          &-col {
            padding-left: 10px;
            padding-right: 0;
          }
        }
      }

      &-left {
        width: 80%;
        height: 100%;
        float: left;
        border-radius: 0px 55px 55px 55px;
        background: rgba(0, 0, 0, 0.8);
        color: #FFFFFF;

      }

      &-right {
        width: 20%;
        height: 100%;
        float: right;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-message {
      
      &-col {
        padding-left: 20px;
        // padding-bottom: 3px;
        display: flex;
        flex-direction: row;
        position: relative;
      }

      &-game {
        background: rgba(255, 255, 255, 0.8);
        color: #0C0C0C;
        min-width: 15%;
        max-width: 85%;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        border: 0px;
        border-radius: 55px 55px 55px 0px;
        padding: 0.8vmin 1.4vmin;
        font-size: var(--defaultFontSize);
        text-align: left;
        margin-left: 10px;
        float: unset;
      }

      &-user {
        background: rgba(0, 0, 0, 0.8);
        color: #FFFFFF;
        min-width: 15%;
        max-width: 85%;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        border: 0px;
        border-radius: 55px 0px 55px 55px;
        padding: 0.8vmin 1.4vmin;
        font-size: var(--defaultFontSize);
        text-align: right;
        float: right;

        & p {
          color: $highlightedTextColor;
        }
      }

      &-other {
        background: rgba(255, 255, 255, 0.8);
        color: #0C0C0C;
        min-width: 15%;
        max-width: 85%;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        border: 0px;
        border-radius: 55px 55px 55px 0px;
        padding: 0.8vmin 1.4vmin;
        font-size: var(--defaultFontSize);
        text-align: left;
        float: unset;
        margin-left: 5px;
        position: relative;

        display: flex;
        align-items: center;

        &-dark {
          background: black;
          color: rgba(255, 255, 255, 0.8);

        }

        &-image-frame {
          width: calc(3.2vmin + 2px);
          height: calc(3.2vmin + 2px);
          display: inline-block;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.2);
          margin-right: 10px;
          border: 2px solid rgba(0,0,0,0.4);

          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        &-image {
          border-radius: 50px;
          width: 3.2vmin;
          height: 3.2vmin;
          // margin-left: 0px;
          // margin-right: 10px;
          //  margin-top: 5px;
          display: inline-block;
        }

        &-text {
          display: inline-block;

          span {
            margin-right: 5px;
          }
        }

        & p {
          color: $highlightedTextColor;
        }

        &-report {
          position: absolute;
          right: -36px;
          top: 5px;
          cursor: pointer;
          & img {
            width: 32px;
            height: 32px;
          }
        }
        & .report-message-popover {
          position: absolute;
          top: 33px;
          right: -4px;
          border-radius: 17px 0px 17px 17px;
          background: #000;
          padding: 3px 5px;
          font-size: var(--defaultFontSize);
          cursor: pointer;
          z-index: 999;
          color: #fff;
        }
      }

    }

    &-body {
      line-height: 24px;
      // height: 100%;
      margin: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-color: rgba(0, 0, 0, 0.7) transparent;
      scrollbar-width: thin;
      direction: rtl;

      padding-left: 5px;


      //   & > div {
      //  direction:ltr;
      //   }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        max-height: 50px;
        min-height: 40px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0px;
        border-left: 1px solid transparent;
        border-right: 3px solid transparent;
      }

      &-wrapper {
        padding: 0;
        direction: ltr;
      }
    }

    &-footer {
      height: 10%;
      margin: 0;
      border: 1px solid #fff;
      margin-top: 3px;

      &-input {
        background-color: transparent;
        padding-left: 10px;
        padding-right: 10px;
        color: #fff;
        border: 0;
        resize: none;

        &:focus {
          background-color: transparent;
          color: #fff;
          border: 0;
        }

        &::placeholder {
          color: #fff;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #fff;
        }

        &::-ms-input-placeholder {
          color: #fff;
        }
      }

      &-button {
        width: 100%;
        color: #007b00;
        background-color: transparent;
        border: 0;
        padding: 0;
        cursor: default;

        &:hover {
          color: #007b00 !important;
          background-color: transparent !important;
          border: 0 !important;
          box-shadow: none !important;
        }

        &:active {
          color: #007b00 !important;
          background-color: transparent !important;
          border: 0 !important;
          box-shadow: none !important;
        }

        &:focus {
          color: #007b00 !important;
          background-color: transparent !important;
          border: 0 !important;
          box-shadow: none !important;
        }
      }
    }
  }
}

.room-chat-counter {
  font-size: var(--smallFontSize);
  align-self: end;
}

.room-chat-counter.overcounter {
  color: #D2110A;
}

.root-class-design20-normal {
  .room-chat-bar-button-support {
    background-color: initial;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.root-class-design20-light {
  .room-chat-bar-button-support {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.root-class-design20.root-class-design20-light {
  .room-chat-message-other {
    p {
      color: #FF8A1A;
    }
  }
}

.root-class-design20.root-class-design20-normal {
  .room-chat-message-other {
    p {
      color: rgba(0,0,0,0.8);
      text-shadow: 1px 1px 4px #FFC900;
    }
  }
}



.score-table-toggle-open-new {
  // bottom: 42vh !important;
  //bottom: 435px !important;

  position: fixed !important;
  bottom: calc(165px + 24vh) !important;
  right: 15px !important;
  .room-chat-open-button-icon {
    width: 30px;
    height: 30px;
    min-width: 20px;
    min-height: 20px;
    background-image: url('../../../../../images/redesign/game/close.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    cursor: pointer;
  }
}

.score-table-toggle-closed-new {
  bottom: 74px !important;

  .room-chat-close-button-icon {
    width: 30px;
    height: 30px;
    min-width: 20px;
    min-height: 20px;
    background-image: url('../../../../../images/redesign/game/open.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    cursor: pointer;
  }
}

.root-class-design20-dark {
  .score-table-toggle-open-new {
    .room-chat-open-button-icon {
      background-image: url('../../../../../images/redesign/game/close.svg');
    }
  }

  .score-table-toggle-closed-new {
    .room-chat-close-button-icon {
      background-image: url('../../../../../images/redesign/game/open.svg');
    }
  }

  .room-chat-close-button {
    .room-chat-close-button-icon {
      background-image: url('../../../../../images/redesign/game/close.svg');
    }
  }

  .room-chat-open-button {
    .room-chat-open-button-icon {
      background-image: url('../../../../../images/redesign/game/open.svg');
    }
  }
}

.root-class-design20-normal,
.root-class-design20-light {
  .score-table-toggle-open-new {
    .room-chat-open-button-icon {
      background-image: url('../../../../../images/redesign/light-mode/game/close.svg');
    }
  }

  .score-table-toggle-closed-new {
    .room-chat-close-button-icon {
      background-image: url('../../../../../images/redesign/light-mode/game/open.svg') !important;
    }
  }

  .room-chat-close-button {
    .room-chat-close-button-icon {
      background-image: url('../../../../../images/redesign/light-mode/game/close.svg');
    }
  }

  .room-chat-open-button {
    .room-chat-open-button-icon {
      background-image: url('../../../../../images/redesign/light-mode/game/open.svg');
    }
  }
}
