// @import "../../redesignVariables";

$titlePadding: 1vmin 5px 0.2vmin 0;
$rowHeight: $menuRowHeight;

$passwordBackground: linear-gradient(180deg, #181818 0%, #000000 100%);
$passwordText: #FFF;

.root-class-design20 {

  .new-game {

    &-title {
      font-size: var(--mLargeFontSize);
      padding: $titlePadding;
      font-family: 'Futura PT';
      font-style: normal;
      font-weight: 450;
      line-height: 128%;
    }
  }

  .room-code-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto !important;
  }

  .rooms-table {
    background-color: $bodyTopBackgroundColour;
    opacity: $bodyTopBackgroundOpacity;

    &-empty-entries {
      font-size: var(--mLargeFontSize);
    }

    &-info-modal {
      padding: 15px;

      &-block {
        display: flex;
        align-items: baseline;
        width: 100%;
        margin-top: 10px;
      }

      &-text {
        color: $roomsTablePrimaryColour;
        font-size: var(--defaultFontSize);
        display: inline-block;
      }

      &-highlighted {
        color: $roomsTableSecondaryColour;
      }

      &-icon {
        height: 25px;
        width: 25px;
        margin-right: 5px;
        display: inline-block;
      }
    }

    &-filter-button {
      line-height: 38px;
      padding: 0 8px;
      // opacity: $disabledLinkOpacity;
      text-decoration: underline;
    }

    &-filter-icon {
      height: $defaultFontSize;
      padding: 0;
      opacity: 1;
      text-decoration: none;
      position: relative;
      vertical-align: middle;
      top: 40%;

      &-wrapper {
        height: 100%;
        position: relative;
        vertical-align: middle;
        margin-right: -5px;
      }
    }

    &-header-tools {
      align-items: center;
    }

    &-header-tutorial {
      width: 56px;
      height: 56px;
      margin-top: 2px;
      cursor: pointer;
      opacity: $disabledLinkOpacity;
    }

    &-table {
      background-color: $bodyTopBackgroundColour;
      opacity: $bodyTopBackgroundOpacity;
    }

    &-row {
      opacity: 0.03;
      transition: opacity 3s linear;
      vertical-align: middle;
      color: $roomsTablePrimaryColour;

      &-light {
        color: $lightRoomsTablePrimaryColour;

        & .rooms-table-row-player-image-frame {
          background: linear-gradient(180deg, #FFFFFF 0%, #ECECEC 100%);
        }

        & .rooms-table-row-player-image-default {
          border: 2px solid #aaaaaa;
        }

        & .rooms-table-row-bet {
          opacity: 0.8;
        }
      }

      height: $rowHeight;
      //  min-height: 42px;

      &-button-wrapper {
        text-align: right;
      }

      &-speed {
        display: inline-block;
        //  width: 16px;
        width: 21px;
        min-width: 30px;
        margin-right: 2px;
        vertical-align: middle;

        &-img {
          width: 100%;
          min-width: 10px;
        }

        &-img-regular {
          width: 60%;
          min-width: 10px;
          // width: 7px;
          // height: 100%;
          // min-width: 7px;
          margin-left: 20%;
          // margin-right: 3px;
        }
      }

      &-type-wrapper {
        display: inline-block;
        vertical-align: middle;

        & .rooms-table-row-bet {
          //  font-size: 14px;
          font-size: var(--defaultFontSize);
          line-height: 160%;
        }

        & .rooms-table-row-type {
          color: $roomsTableSecondaryColour;
          //  font-size: 10px;
          font-size: var(--smallFontSize);
          line-height: 160%;
        }
      }

      &-pro {
        display: inline-block;
        vertical-align: middle;
        margin-right: 2px;
        float: right;
        position: absolute;
        right: 5vmin;

        &-wrapper {
          vertical-align: middle;
          //  height: 2vmin;
          width: auto;
          position: relative;
          display: inline-block;
        }

        &-img {
          //  width: 20px;
          vertical-align: middle;
          width: 3vmin;
          min-width: 18px;
          height: auto;
          position: relative;
          display: inline-block;
        }
      }

      &-student {
        display: inline-block;
        vertical-align: middle;
        margin-right: 2px;
        float: right;
        position: absolute;
        right: 8vmin;

        &-wrapper {
          vertical-align: middle;
          //  height: 2vmin;
          width: auto;
          position: relative;
          display: inline-block;
        }

        &-img {
          //  width: 20px;
          vertical-align: middle;
          width: 3vmin;
          min-width: 18px;
          height: auto;
          position: relative;
          display: inline-block;
          // top: -4px;
        }
      }

      &-minGames {
        display: inline-block;
        vertical-align: middle;
        margin-right: 2px;
        float: right;
        position: absolute;
        right: 0vmin;

        &-wrapper {
          vertical-align: middle;
          //  height: 2vmin;
          width: auto;
          position: relative;
          display: inline-block;
        }

        &-img {
          vertical-align: middle;
          width: 5vmin;
          min-width: 28px;
          height: auto;
          position: relative;
          display: inline-block;
        }

        &-bell-img {
          vertical-align: middle;
          // width: 4vmin;
          // min-width: 11px;
          width: 45px;
          height: auto;
          position: relative;
          display: inline-block;

          &-legend {
            width: 32px;
          }
        }

        &-bell-count {
          position: absolute;
          background: rgba(210, 17, 10, 1);
          color: white;
          //width: 16px;
          height: 21px;
          top: 0px;
          left: 20px;
          padding: 0px 4px 0px 4px;
          border-radius: 17px 17px 17px 0px;
          gap: 10px;

          &-legend {
            left: 14px;
            padding: 0px 2px 0px 2px;
          }
        }

        &-number {
          position: absolute;
          top: 0.5vmin;
          right: 1.6vmin;
          font-size: var(--smallFontSize);
          line-height: 0.9vmin;
        }
      }

      &-player {
        vertical-align: middle;
        display: inline-block;
        white-space: nowrap;
        // overflow: hidden;
        max-width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        //  overflow: hidden;
        //  height: 40px;
        //        height: $rowHeight;
        //        min-height: 40px;
        //  &-wrapper {
        //    height: 100%;
        //  }

        &-image {
          //  width: 30px;
          //  height: 30px;
          // width: $rowHeight * 0.75;
          // height: $rowHeight * 0.75;
          // min-width: 27px;
          // min-height: 27px;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          object-fit: contain;

          &-default {
            padding: 5px;
          }

          &-frame {
            //  width: 30px;
            //  height: 30px;
            // width: $rowHeight * 0.75;
            // height: $rowHeight * 0.75;

            min-width: 36px;
            min-height: 36px;
            // margin: 4px;
            margin-right: 8px;
            // vertical-align: middle;
            border-radius: 50%;
            //  background-color: #FFF;
            background-color: rgba(255, 255, 255, 0.2);
            //  opacity: 0.2;
            display: inline-block;
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.5);
            // display: flex;
            // justify-content: center;
            // align-items: center;
          }
        }

        &-name {
          vertical-align: middle;
          display: inline-block;
          text-overflow: ellipsis;
          //  height: 40px;
          //  line-height: 40px;

          height: $rowHeight;
          //  line-height: $rowHeight;
          line-height: $rowHeight;
          position: relative;
          //  min-height: 25px;
        }

        &-waiting {
          vertical-align: middle;
          display: inline-block;
          font-weight: 400;
          opacity: 0.7;
          //  height: 40px;
          //  line-
          //  height: $rowHeight;
          //  line-height: $rowHeight;
          //  line-height: $rowHeight;
          //  min-height: 25px;
        }
      }

      &-password {
        &-text {
          text-align: center;
          font-size: var(--smallFontSize);
          line-height: $smallFontSize;
          color: $highlightedTextColor;
        }

        &-digit {
          font-size: var(--smallFontSize);
          line-height: 16px;
          display: inline-block;
          text-align: center;
          font-weight: 700;
          width: 16px;
          height: 16px;
          background: $passwordBackground;
          color: $passwordText;
          border-radius: 2px;
          margin-left: 1px;
          margin-right: 1px;
        }
      }
    }
  }

  .rooms-table-filter-modal {
    .modal-header {
      .modal-title {
        .default-modal-title {
          padding: 7px 20px;
          font-weight: 600;
          font-size: var(--xLargeFontSize);
          line-height: 110%;
        }
      }
    }

    .modal-body {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;

      .switch-select {
        &-option {
          padding: 9px 15px;
        }
      }

      .switch-select-label {
        font-family: 'Futura PT';
        font-weight: 450;
        font-size: var(--mLargeFontSize);
        line-height: 160%;
        margin-bottom: 8px;
        margin-top: 12px;
        margin-left: 61px;
      }

      .switch-toggle-column-wrapper {
        display: flex;
        align-items: center;
        margin-left: 59px;

        &-in-app-frame {
          flex-direction: unset !important;
        }
      }

      .switch-toggle-column-wrapper-round {
        margin-bottom: 51px;
      }
    }

    .modal-footer {
      margin-top: 66px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.rooms-table-row-button-wrapper .btn.disabled.layout-default-button {
  cursor: not-allowed;
  pointer-events: auto;
}

.new-rooms-table-section {
  .rooms-table-layout-body {
    .layout-subheader {
      border-bottom: none;
    }
  }
}

.rooms-table-joined-row {
  background-color: rgba($color: #D2110A, $alpha: 0.5) !important;
}

.root-class-design20-light {
  .rooms-table-row-type-wrapper {
    .rooms-table-row-type {
      color: #FF8A1A !important;

      &-joined {
        color: black !important;
      }
    }
  }

  .rooms-table-info-modal {
    .rooms-table-info-modal-highlighted {
      color: #FF8A1A !important;
    }
  }
}
