.tournaments {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);
  border-radius: 15px;
  color: #fff;
  padding: 10px 5px 5px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &-empty-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
  }
  &-scrollarea {
    // height: 300px;
    // max-height: 300px;
    flex: 1;

    &-body {
      height: 100%;
    }
  }

  &-results-scrollarea {
    min-height: 250px;
    max-height: calc(100vh - 400px);
    flex: 1;

    &-body {
      height: 100%;
    }
  }

  &-join {
    &-message {
      display: inline-block;
      font-size: var(--defaultFontSize);
      width: 100%;

      &-image {
        display: inline-block;
        margin-left: 4px;
        width: 18px;
        height: 18px;
      }
    }
  }

  &-tutorial-table {
    max-width: 100%;
    table-layout: fixed;
    width: 100%;

    &-scrollarea {
      margin-top: 10px;
      min-height: 250px;
      height: calc(100vh - 380px) !important;
      padding-right: 4px;
    }

    &-col {
      overflow: hidden;
      color: #fff;
      font-size: var(--mSmallFontSize);
      line-height: 15px;
      text-align: center;
    }

    &-row {
      height: 34px;
      max-width: 100%;
      overflow: hidden;

      &-user {
        height: 34px;
        max-width: 100%;
        overflow: hidden;
        background: rgba(247, 180, 90, 0.3);
      }
    }

    &-header {
      width: 100%;
      height: 24px;
      max-width: 100%;
      overflow: hidden;
      border-bottom: 1px solid #fff;
      font-weight: bold;
      font-size: var(--defaultFontSize);

      &-col {
        color: #FFF;
        //  border-bottom: 1px solid #fff;
        width: 15%;
        font-weight: bold;
        font-size: var(--defaultFontSize);
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
      }
    }

    &-body {
      font-size: var(--defaultFontSize);
    }
  }

  &-leaderboard {
    font-size: var(--headerFontSize);
    width: 100%;

    &-table {
      &-header {
        //  border-bottom: 1px solid #fff;
        text-align: center;
        font-size: var(--largeFontSize);

        &-name {
          text-align: start;
          font-size: var(--largeFontSize);
        }

        &-pos {
          text-align: center;
          font-size: var(--largeFontSize);
          min-width: 60px;
        }
      }

      &-row {
        height: 34px;
        max-width: 100%;
        overflow: hidden;
        font-size: var(--defaultFontSize);

        &-user {
          height: 34px;
          max-width: 100%;
          overflow: hidden;
          background: rgba(247, 180, 90, 0.3);
        }
      }

      &-col {
        text-align: center;

        &-name {
          text-align: start;
        }

        &-pos {
          text-align: center;
          min-width: 60px;
        }
      }
    }

    &-user-image {
      width: 25px;
      height: 25px;
      margin-right: 5px;

      &-wrapper {
        display: inline-block;
      }
    }
  }

  &-user-image {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: 1px solid #fff;
    background-color: #847753;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
    padding: 0;
    z-index: 2;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-header {
    // height: 45px;
    margin-bottom: 10px;
    font-weight: 600;

    &-image {
      display: inline-block;
      margin-right: 10px;
    }

    &-text {
      text-transform: uppercase;
      font-size: var(--largeFontSize);
      color: #fff;
      margin-top: 5px;
      display: inline-block;
    }

    &-button {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      background-color: #2c5955;
      border-radius: 15px;
      height: 100%;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &.active {
        color: #fff;
        background-color: #380000;
        border-color: #fff;
        box-shadow: none;

        &:hover {
          color: #fff;
          background-color: #380000;
          border-color: #fff;
          box-shadow: none;
        }
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        // color: #FFD34E;
        // border-color: #FFD34E;
        // background-color: #2c5955;
        text-decoration: none;
      }
    }

    &-tutorial {
      width: 32px;
      height: 32px;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  &-table {
    width: 100%;

    &-header {
      font-weight: bold;
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      text-align: center;
      border-bottom: 1px solid #fff;

      &-col {
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 5px;
        text-transform: capitalize;
      }
    }

    &-body {
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      text-align: center;
    }

    &-button {
      //  width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      white-space: nowrap;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      &-col {
        //  width: 45%;
        display: inline-block;
        //  margin: auto;
        margin-left: 2.5%;
        margin-right: 2.5%;
      }
    }

    &-waiting {
    }

    &-status {
      &-text {
        display: inline-block;
      }

      &-spinner {
        display: inline-block;
        margin-left: 2px;
        width: 17px;
        height: 17px;
      }
    }
  }


  &-modal {

    & .modal-content .modal-body {
      font-size: var(--largeFontSize);
    }

    &-close {
      width: 21px;
      height: 21px;
      color: #FFD34E;
      cursor: pointer;
    }
  }

  & .active-tournaments {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    &-info {
      display: flex;
      flex-direction: column;

      &-section {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin-bottom: 10px;
        position: relative;

        &-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 7px 17px 5px 17px;

          &-left {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            &-img {
              width: 15px;
              margin-right: 10px;
              cursor: pointer;
            }

            &-title {
              font-weight: 600;
              font-size: var(--largeFontSize);
              line-height: 160%;
              color: #FFC900;
              margin-right: 10px;
            }

            &-private {
              font-weight: 600;
              font-size: var(--defaultFontSize);
              line-height: 160%;
              color: #FFFFFF;
            }
          }

          &-right {
            & div {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 0px 6px 1px;
              grid-gap: 10px;
              gap: 10px;
              background: #D2110A;
              border-radius: 4px;
              font-weight: 600;
              font-size: var(--smallFontSize);
              line-height: 160%;
            }
          }
        }

        &-time {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0px 17px 5px 17px;

          &-rounds {
            font-weight: 400;
            font-size: var(--defaultFontSize);
            color: #FFFFFF;
            opacity: 0.5;
            margin-right: 5px;
          }

          &-roundsValue {
            font-weight: 600;
            font-size: var(--defaultFontSize);
            color: #FFFFFF;
            margin-right: 5px;
          }

          &-label {
            font-weight: 400;
            font-size: var(--defaultFontSize);
            color: #FFFFFF;
            opacity: 0.5;
            margin-right: 5px;
          }

          &-content {
            font-weight: 400;
            font-size: var(--defaultFontSize);
            color: #FFFFFF;
            opacity: 0.7;
          }
        }
      }

      &-section::after {
        height: 23px;
        width: 100%;
        content: ' ';
        opacity: 0.2;
        position: absolute;
        bottom: -23px;
        z-index: 1;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, #000 100%);
        position: absolute;
        bottom: -23px;
      }


      &-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        z-index: 2;

        & .notification-footer-button {
          margin: unset !important;
          height: 40px;
        }
      }
    }

    &-sponsor {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      position: relative;

      &-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 12px;
        margin-bottom: 12px;

        & span {
          margin-bottom: 4px;
          font-size: var(--largeFontSize);
        }

        & img {
          width: 60%;
          border-radius: 6px;
        }
      }

      &-two::after {
        content: ' ';
        width: 1px;
        height: 100%;
        position: absolute;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
    }

    & .plan-tournaments-info-section-title-right {
      & div {
        background: #019245;
        color: #fff;
      }


      &-confirmed {
        & div {
          background: #FFFFFF;
          color: #000;
        }
      }
    }

    & .history-tournaments-info-section-title-right {
      & div {
        background: #868686;
        color: #000000;
      }
    }
  }

  & .active-tournaments-first {
    margin-top: 10px;
  }
}

.layout-mobile-body-main-title {
  display: block;
  color: #FFC900;
  font-size: var(--largeFontSize);
  font-weight: 600;
}

.tournament-icon {
  display: inline-block;
  padding-left: 2px;
}

.menu-topTab-tournament {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tournament-info-section {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 40px;
  padding-right: 30px;
  max-height: calc(100vh - 350px);
  min-height: 250px;
  overflow-y: auto;

  &-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 20px;
      padding: 10px 20px 10px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      font-weight: 400;
      font-size: var(--largeFontSize);
      line-height: 160%;
      color: #FFFFFF;
      opacity: 1;

      &-image-container {
        display: inline-flex;
        width: 50%;
        flex-direction: column;
        text-align: center;

        img {
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
        }
      }
    }
    &-row:last-child {
      border-bottom: none;
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    &-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      & span {
        font-weight: 600;
        font-size: var(--largeFontSize);
        line-height: 140%;
        color: #ECECEC;
        margin-bottom: 3px;
      }
      & img {
        border: 1px solid #FFFFFF;
        box-shadow: 4px 4px 0px #380000;
        border-radius: 12px;
      }
    }
  }
}


.tournaments-scrollarea-body.tournaments-scrollarea-body-past {
  height: auto !important;
}
