
.app-wrapper-fullscreen {

  & .bonus-page {

  & .triangle-wrapper {
    position: absolute;
    top: 39%;
    right: -10px;
    width: 40px;
    max-width: 100%;
    height: auto;
  }

  &-block {
    &-wheel {

      .wheel-bg {
      //  position: absolute;
        position: relative;
        width: 256px;
      //  max-width: 90%;
        max-width: 100%;
        margin-left: auto;
      //  margin-right: auto;
      }

      .wheel {
        width: 256px;
        margin-top: 4px;
      //  max-width: 90%;
        max-width: 100%;
      //  margin-left: auto;
      //  margin-right: auto;
      }

      .wheel-wrapper {
        width: 256px;
        margin-top: 4px;
      //  max-width: 90%;
        max-width: 100%;
        margin-left: auto;
      //  margin-right: auto;
      }

        .triangle-wrapper {
          position: absolute;
          top: 39%;
          right: 10px;
          width: 40px;
          max-width: 10%;
          height: auto;
        }
      }

      &-complete {
        &-wheel {
          .wheel-bg {
            position: relative;
            width: 256px;
            max-width: 100%;
            margin-left: auto;
          }

          .wheel {
            width: 256px;
            margin-top: 4px;
          //  max-width: 90%;
            max-width: 100%;
            margin-left: auto;
          }
        }
      }
    }
  }
}


.bonus-page {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);
  border-radius: 15px;
  color: #fff;
  padding: 10px 5px 5px 10px;
  height: 390px;

  &-no-entries {
    padding-top: 40px;
    height: 200px;
    font-size: 1rem;
  }

  &-history {

    &-link {

      &:hover {
        color: #FFD34E !important;
      }
    }

    &-wrapper {
      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;
      margin-right: 25px;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      cursor: pointer;
      float: right;

      &:hover {
        color: #FFD34E !important;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        border-color: #fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    & .active {
      color: #fff;
      background-color: #380000;
      border-color: #fff;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  &-header {
    height: 35px;

    &-image {
      display: inline-block;
      margin-right: 10px;
    }

    &-text {
      text-transform: uppercase;
      font-size: var(--largeFontSize);
      color: #fff;
      font-weight: 600;
      margin-top: 5px;
      display: inline-block;
    }

    &-button {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  &-block {
    height: 100%;
    width: 100%;

    &-warn {
      width: 45%;
      float: right;
      margin-bottom: 10px;
      position: relative;
      margin-top: -5px;
      line-height: 15px;


      > div {
        text-align: left;
        font-size: var(--defaultFontSize);
      }

      .spin-timer {
        display: inline-block;
        font-weight: bold;
        font-size: var(--headerFontSize);
        color: #fff;
        vertical-align: sub;
        padding-left: 5px;
      }
    }
    //  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.75, rgba(252, 255, 182, 0.6)), color-stop(0.25, rgba(252, 255, 182, 0.1)));
    //  padding: 1px;

    &-container {
      height: 100%;
    }

    &-background {
      //  @include background-2x( '../../images/BonusPage/Daily bonus rec bckgr desktop', 'png', 980px, 700px, top center, no-repeat );
      //  background-size: cover;
      //  background-position: top center;
      //border-bottom: 1px solid rgba(252, 255, 182, 0.6);
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }

    &-left {
      //  padding-left: 30px;
      position: relative;
      top: 40%;
      padding-right: 15px;

      &-text {
        margin-bottom: 6px;
        margin-top: 30px;
        font-size: var(--defaultFontSize);
        // font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #fff;
      }

      &-image {
        margin-top: 15px;

        & .coin-stack {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-money {
        //  font-size: 44px;
        //  line-height: 54px;
        //  text-align: left;
        //  float: left;
        //  padding: 0;
        //  color: #FFD34E;

        text-align: center;

        &-image {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-bottom: 10px;
          margin-right: 4px;
        }

        &-text {
          font-size: var(--xxxLargeFontSize);
          // font-size: 38px;
          line-height: 54px;
          text-align: left;
          font-weight: 600;
          padding: 0;
          color: #FFD34E;
          display: inline-block;
        }
      }

      &-moneySign {
        font-size: var(--xxLargeFontSize);
        line-height: 54px;
        float: right;
        padding: 0;
        text-align: right;
        color: #FFD34E;
      }

      &-everyDay {
        margin-top: -25px;
        font-weight: bold;
        font-size: var(--largeFontSize);
        line-height: 14px;
        text-align: center;
        color: #fff;
      }
    }


    &-wheel {

      .wheel-bg {
        position: absolute;
        width: 256px;
        max-width: 90%;
        //  max-width: 100%;
        //  margin-left: auto;
        //  margin-right: auto;
      }

      .wheel {
        position: relative;
        width: 256px;
        margin-top: 4px;
        //  max-width: 90%;
        max-width: 98%;
        //  max-width: 100%;
        //  margin-left: auto;
        //  margin-right: auto;
      }

      .wheel-wrapper {
        width: 256px;
        margin-top: 4px;
        //  max-width: 90%;
        max-width: 90%;
        //  margin-left: auto;
        //  margin-right: auto;
      }

      .triangle-wrapper {
        position: absolute;
        top: 39%;
        right: 10px;
        width: 40px;
        max-width: 10%;
        height: auto;
      }

      .triangle {
        position: relative;
        background-color: #c44c4c;
        text-align: left;
        z-index: 100;
        position: absolute;
        left: 1px;
        top: 1px;
      }

      .triangle:before,
      .triangle:after {
        content: '';
        position: absolute;
        background-color: inherit;
      }

      .triangle,
      .triangle:before,
      .triangle:after {
        width: 19px;
        height: 19px;
        border-top-right-radius: 30%;
      }

      .triangle {
        transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
      }

      .triangle:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
      }

      .triangle:after {
        transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
      }

      .triangle2 {
        position: absolute;
        background-color: #380000;
        text-align: left;
        top: 4px;
        left: -7px;
      }

      .triangle2:before,
      .triangle2:after {
        content: '';
        position: absolute;
        background-color: inherit;
      }

      .triangle2,
      .triangle2:before,
      .triangle2:after {
        width: 21px;
        height: 21px;
        border-top-right-radius: 30%;
      }

      .triangle2 {
        transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
      }

      .triangle2:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
      }

      .triangle2:after {
        transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
      }

      .triangle3 {
        position: absolute;
        background-color: #fff;
        text-align: left;
        z-index: 50;
      }

      .triangle3:before,
      .triangle3:after {
        content: '';
        position: absolute;
        background-color: inherit;
      }

      .triangle3,
      .triangle3:before,
      .triangle3:after {
        width: 21px;
        height: 21px;
        border-top-right-radius: 30%;
      }

      .triangle3 {
        transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
      }

      .triangle3:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
      }

      .triangle3:after {
        transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
      }
    }

    &-button {
      padding-left: 5px;

      & .spin-button {
        width: 90%;
        max-width: 220px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        background-color: #c44c4c;
        border-radius: 15px;
        height: 30px;
        font-size: var(--defaultFontSize);
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 3px solid #2E5A56;

        &:hover {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #c44c4c;
          text-decoration: none;
        }

        &:disabled {
          color: #fff;
          border-color: #2E5A56;
          text-decoration: none;
        }

        &:focus {
          color: #FFD34E;
          border-color: #FFD34E;
          background-color: #c44c4c;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }

      & .spin-button-container {
        position: relative;
        top: 43%;

        & .triangle-wrapper {
          position: absolute;
          top: 39%;
          right: 10px;
          width: 40px;
          max-width: 10%;
          height: auto;
        }
      }

      & .spin-timer {
        font-weight: bold;
        font-size: var(--mLargeFontSize);
        //  line-height: 18px;
        color: #fff;
      }
    }


    &-complete {
      &-text {
        margin-top: 15px;
        font-size: var(--largeFontSize);
        text-align: left;
        color: #fff;
      }

      &-money {
        &-image {
          display: inline-block;
          width: 50px;
          height: 50px;
          //  margin-top: 8px;
          margin-bottom: 10px;
          margin-right: 4px;
        }

        &-text {
          font-size: var(--xxxLargeFontSize);
          // font-size: 44px;
          line-height: 54px;
          text-align: left;
          //  float: left;
          padding: 0;
          color: #FFD34E;
          display: inline-block;
        }
      }

      &-moneySign {
        font-size: var(--xxLargeFontSize);
        line-height: 54px;
        float: right;
        padding: 0;
        text-align: right;
        color: #FFD34E;
      }

      &-everyDay {
        margin-top: -25px;
        font-weight: bold;
        font-size: var(--mLargeFontSize);
        //  line-height: 19px;
        text-align: center;
        color: #fff;
      }

      &-wheel {
        .wheel-bg {
          position: absolute;
          width: 256px;
        }

        .wheel {
          width: 256px;
          margin-top: 4px;
        }

        .triangle-wrapper {
          position: absolute;
          top: 45%;
          right: 10px;
          width: 40px;
          max-width: 10%;
          height: auto;
        }
      }

      &-button {

        & .accept-button {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          background-color: #c44c4c;
          border-radius: 15px;
          height: 30px;
          font-size: var(--defaultFontSize);
          text-align: center;
          text-transform: uppercase;
          color: #fff;
          border: 1px solid #fff;
          -webkit-box-shadow: 5px 5px 0px 0px #380000;
          -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
          box-shadow: 5px 5px 0px 0px #380000;

          &:hover {
            color: #FFD34E;
            border-color: #FFD34E;
            background-color: #c44c4c;
            text-decoration: none;
          }

          &:focus {
            color: #FFD34E;
            border-color: #FFD34E;
            background-color: #c44c4c;
            text-decoration: none;
          }

          &:active {
            color: #fff;
            background-color: #380000;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
          }
        }

        & .accept-button-container {
          position: relative;
          top: 43%;
        }
      }
    }
  }
}
