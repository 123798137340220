.rules-page {
  background-image: url('../../../images/Menu/zole_bg.webp');

  &-header {

    &-logo {
      width: 192px;
      height: 39px;
      float: left;
      position: relative;

      &-back {
        position: absolute;
        left: 85px;
        top: 40px;
        cursor: pointer;
      }
    }

    &-links {
      margin-top: auto;
      margin-bottom: auto;
      text-align: right;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: var(--headerFontSize);
      // font-size: 17px;
      line-height: 19px;
      color: #FFFFFF;
      text-transform: uppercase;
      display: inline-block;
    }
  }

  &-body {
    color: #FFF;
    font-size: var(--largeFontSize);
    text-align: justify;
  }

  &-scroll-area {
    height: 100%;
    max-height: 85vh;
  }

  &-scroll-content {
    margin-right: 15px !important;
  }

  &-tab {
    &-link {
      line-height: 1.2rem;
    }
  }
}




.terms-of-use-scroll {
  &-area {
    height: 100%;
    max-height: 90vh;
    margin-right: 2px;

    & .scrollbar-container {
    }
  }

  &-content {
    padding-right: 15px!important;
  }
}
