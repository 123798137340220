.root-class-design20 {

  .score-table {
    position: fixed;
    right: 15px;
    bottom: 15px;
    border: 0;

    bottom: 45px;
    top: unset;
    max-width: 25%;
    width: 25%;

    @media screen and (max-width: 750px) {
      width: 22%;
      max-width: 22%;
    }

    &-toggle {
      &-o {
        bottom: 39vh;
        top: unset;
        position: fixed;
        right: 15px;
      }

      &-c {
        bottom: 15px;
        top: unset;
        position: fixed;
        right: 15px;
      }
      @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        &-o,
        &-c {
          display: none;
        }
      }
      @media screen and (orientation : portrait) {
        &-o,
        &-c {
          display: none;
        }
      }

    }

    &-top {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 17px 17px 17px 0px;
      margin-bottom: 5px;
    }

    &-names {
      width: 100%;

      & thead {
        width: 100%;
      }

      &-name {
        color: $highlightedTextColor;
        width: 23%;
        overflow: hidden;
        text-align: left;
        font-size: var(--defaultFontSize);
        background: transparent;
        text-align: center;
      }
    }

    &-id-header {
      font-size: var(--defaultFontSize);
    }

    &-pules-header {
      font-size: var(--defaultFontSize);
    }

    &-table {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      height: 24vh;

      &-wrapper {}

      &-content {

        & table {
          width: 100%;
        }
      }
    }

    &-points {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      height: 2vmin;

      &-col {
        font-size: var(--defaultFontSize);

        &-id {
          font-size: var(--defaultFontSize);
        }

        &-pules {
          font-size: var(--defaultFontSize);
        }
      }
    }

    &-bottom {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 0px 17px 17px 17px;

      border-top: 1px solid rgba(255, 255, 255, 0.2);
      //height: 2vmin;

      &-col {
        font-size: var(--defaultFontSize);

        &-id {
          font-size: var(--defaultFontSize);
        }

        &-pules {
          font-size: var(--defaultFontSize);
        }
      }
    }

    &-sum {}
  }

}

.root-class-design20-normal {
  .score-table-names {
    .score-table-pules-header {
      .score-table-pules-header-player {
        color: rgba(12, 12, 12, 0.7) !important;
      }
    }
  }

  .score-table-table-wrapper {
    .score-table-row {
      .score-table-pules-col {
        .strikeout {
          color: rgba(12, 12, 12, 0.5) !important;

          &::after {
            border-bottom: 2px solid rgba(12, 12, 12, 0.5) !important;
          }
        }
      }
    }
  }

  .end-result-table-body {
    .end-result-table-row {
      .end-result-table-col {
        .strikeout {
          color: #fff;

          &::after {
            border-bottom: 2px solid #fff !important;
          }
        }
      }
    }
  }
}

.root-class-design20-light {
  .score-table-table-wrapper {
    .score-table-row {
      .score-table-pules-col {
        &>div {
          color: #0c0c0c !important;
        }

        &>span.strikeout {
          color: #0c0c0c !important;

          &::after {
            border-bottom: 2px solid rgba($color: #0c0c0c, $alpha: 1.0) !important;
          }
        }
      }
    }
  }

  .end-result-table-body {
    .end-result-table-row {
      .end-result-table-col {
        .strikeout {
          color: #0C0C0C;

          &::after {
            border-bottom: 2px solid #0C0C0C !important;
          }
        }
      }
    }
  }
}

.root-class-design20-dark {
  .score-table-table-wrapper {
    .score-table-row {
      .score-table-pules-col {
        .strikeout {
          color: #fff !important;

          &::after {
            border-bottom: 2px solid #fff !important;
          }
        }
      }
    }
  }

  .end-result-table-body {
    .end-result-table-row {
      .end-result-table-col {
        .strikeout {
          color: #fff;

          &::after {
            border-bottom: 2px solid #fff !important;
          }
        }
      }
    }
  }
}