
//$achievementButtonColor: #FFF;
//$achievementActiveBackground: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
//$achievementinactiveBackground: rgba(0, 0, 0, 0.4);

//@import "../../../redesignVariables";

$achievementButtonColor: $approveButtonColor;
$achievementActiveBackground: $approveButtonBackground;

$achievementinactiveBackground: $declineButtonBackground;

$mobileLandScapeWidth: 1280px;

.root-class-design20 {
  @media screen and (orientation : portrait) {
    .my-info-achievements-column-mobile {
      min-width: 20% !important;
      max-width: 20% !important;
    }
    .layout-mobile-body-achievements-page {

      .layout-mobile-body-main {
        height: calc(100vh - 370px) !important;
        padding: 0 !important;
        margin: 11px 0 0 0 !important;

        &-title {
          display: none;
        }
        .my-info {

          &-achievements {
            height: 96%;
            margin-left: 15px;
            margin-right: 15px;

            &-scrollarea {
              max-height: calc(100% - 370px) !important;
              height: calc(100% - 370px) !important;
            }

            &-header {
              height: auto;
              margin-left: 0;
              padding-left: 0;
              padding-right: 0;
              display: block;
              margin-top: 15px;
              margin-bottom: 15px;

              &-tab {
                height: 100%;
                font-size: var(--headerFontSize);
                
                & .custom-dropdown {
                  .active-item {
                    color: #FFC900;
                  }

                  & .custom-dropdown-menu {
                    overflow-x: hidden;
                    overflow-y: auto;
                    width: 100%;
                    max-height: 300px;
                  }
                  & .custom-dropdown-toggle {
                    border-radius: 25px;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    padding: 11px 17px;
                    color: #FFF;
                    font-size: var(--defaultFontSize);
                    font-style: normal;
                    font-weight: 400;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    opacity: 1;
                    display: block;
                  }

                  & .custom-dropdown-toggle:first-letter {
                    text-transform: uppercase;
                  }

                  & .custom-dropdown-toggle::after {
                    border-top: 0.5em solid;
                    border-right: 0.5em solid transparent;
                    border-bottom: 0;
                    border-left: 0.5em solid transparent;
                    margin-top: -15px;
                  }

                  & button span {
                    display: block;
                  }
                  
                  & button::first-letter {
                    text-transform: uppercase;
                  }

                  & button span::first-letter {
                    text-transform: uppercase;
                  }

                }
              }
            }
        
            &-left {
              height: 100%;
              padding: 0;
            }
        
            &-count {
              background-color: #FFC900;
              border-radius: 99px;
              padding: 0px 5px 1px;
              color: #000000;
              margin-bottom: 3px;
              display: block;
            }
            &-uncount {
              background: rgba(255, 255, 255, 0.7);
              border-radius: 99px;
              padding: 0px 5px 1px;
              //color: #868686;
              color: #333333;
              margin-bottom: 3px;
              display: block;
            }
            
            &-right {
              height: 100%;
              padding: 0;
              .my-info-achievements-column-div {
                width: 80%;
                margin: auto;
                img {
                  // object-fit: cover;
                  position: static;
                  margin-bottom: 10px;
                }
              }
            }
        
            &-row {
              row-gap: 20px;
            }
        
            &-scrollarea {
            //  height: 335px;
              height: calc(100% - 50px);
              min-height: calc(100% - 50px);
              overflow-y: auto;
              overflow-x: hidden;
        
              &-content {
                padding: 15px;
              }
            }
        
            &-link {
              margin-left: 15px;
              margin-bottom: -1px;
              position: relative;
              &-text {
                color: $headerTextColour;
                opacity: $headerInactiveOpacity;
                margin: 0px 6px;
                padding: 6px 0px;
                padding-left: 10px;
                cursor: pointer;
                text-decoration: none;
                width: 100%;
                text-align: left;
                font-size: var(--largeFontSize) !important;
        
                &:hover {
                  color: $headerTextColour;
                }
        
                &:active {
                  color: $headerTextColour;
                }
        
                &:focus {
                  color: $headerTextColour;
                }
              }
              &-claim::after{
                content: '';
                width: 10px;
                height: 10px;
                background-color: rgba(255, 0, 0, 1);
                position: absolute;
                border-radius: 50%;
                margin-top: 3px;
                margin-left: 5px;
              }
        
              &-active {
                border-left: 1px solid $headerActiveBorderColour;
                opacity: 1;
              }
        
              &-claim {
        
              }
            }
        
            &-collect-button {
              background: $achievementinactiveBackground;
              opacity: 0.3;
              border: 1px solid rgba(255, 255, 255, 0.1);
              box-sizing: border-box;
              border-radius: 99px;
              width: 170px;
        
              height: 30px;
              font-size: var(--defaultFontSize);
              text-align: center;
              margin-bottom: 20px;
              margin-top: 5px;
        
              color: $achievementButtonColor;
        
              &:hover {
                color: $achievementButtonColor;
                background: $achievementinactiveBackground;
              }
        
              &:active {
                color: $achievementButtonColor;
                background: $achievementinactiveBackground;
              }
        
              &:focus {
                color: $achievementButtonColor;
                background: $achievementinactiveBackground;
              }
        
              &-active {
                background: $achievementActiveBackground;
                border-radius: 99px;
                opacity: 1;
        
                &:hover {
                  background: $achievementActiveBackground;
                }
        
                &:active {
                  background: $achievementActiveBackground;
                }
        
                &:focus {
                  background: $achievementActiveBackground;
                }
              }
        
              &-single {
                width: 100%;
                margin-left: 0%;
                margin-right: 0%;
                padding-left: 5px;
                padding-right: 5px;
              }
        
              &-text {
                display: inline-block;
        
              }
        
              &-coin {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-bottom: 3px;
                margin-left: 4px;
              }
            }
        
            &-column {
              text-align: center;
        
              &-div {
                min-height: 85px;
                // height: 85px;
                position: relative;
              }
        
              &-img {
                width: 100%;
                height: 85px;
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .my-info-achievements-column-mobile {
      min-width: 20% !important;
      max-width: 20% !important;
    }
    .layout-mobile-body-achievements-page {

      .layout-mobile-body-main {

        &-title {
          display: block !important;
          color: #FFC900;
          font-size: var(--largeFontSize);
          font-weight: 600;
        }

        .my-info {

          &-achievements {
            height: 96%;

            &-right {
              height: calc(100% - 100px);
            }

            &-header {
              height: auto;
              margin-left: 0;
              padding-left: 0;
              padding-right: 0;
              display: block;
              margin-top: 15px;
              margin-bottom: 15px;
  
              &-tab {
                height: 100%;
                font-size: var(--headerFontSize);
                
                & .custom-dropdown {
                  .active-item {
                    color: #FFC900;
                  }

                  & .custom-dropdown-menu {
                    overflow-x: hidden;
                    overflow-y: auto;
                    width: 100%;
                    max-height: 254px;
                  }
                  & .custom-dropdown-toggle {
                    border-radius: 25px;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    padding: 11px 17px;
                    color: #FFF;
                    font-size: var(--defaultFontSize);
                    font-style: normal;
                    font-weight: 400;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    opacity: 1;
                    display: block;
                  }

                  & .custom-dropdown-toggle::after {
                    border-top: 0.5em solid;
                    border-right: 0.5em solid transparent;
                    border-bottom: 0;
                    border-left: 0.5em solid transparent;
                    margin-top: -15px;
                  }

                  & button::first-letter {
                    text-transform: uppercase;
                  }

                  & button span {
                    display: block;
                  }
                  & button span::first-letter {
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.root-class-design20-normal {
  .layout-mobile-body-achievements-page {

    .layout-mobile-body-main {
      .my-info {

        &-achievements {
          &-header {
            &-tab {
              
              & .custom-dropdown {
                & .custom-dropdown-menu {
                  background-color: rgba(20, 34, 85, 0.95) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.root-class-design20-light {
  @media screen and (orientation : portrait) {
    .layout-mobile-body-achievements-page {

      .layout-mobile-body-main {
        .my-info {

          &-achievements {
            &-header {
              &-tab {
                
                & .custom-dropdown {
                  .active-item {
                    color: #FFC900 !important;
                    text-shadow: 1px 1px 1px black;
                  }
                  
                  & .custom-dropdown-menu {
                    background-color: rgba(236, 236, 236, 0.95) !important;
                    color: #0C0C0C !important;
                    border: 1px solid rgba(0, 0, 0, 0.25) !important;

                    & button {
                      color: #0C0C0C;
                    }

                    & .dropdown-item:hover {
                      background: rgba(0, 0, 0, 0.4) !important;
                    }
                  }
                  & .custom-dropdown-toggle {
                    border: 1px solid #868686 !important;
                    color: #0C0C0C !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile-body-achievements-page {

      &-title {
        display: block !important;
        color: #FF8A1A;
        font-size: var(--largeFontSize);
        font-weight: 600;
      }

      .layout-mobile-body-main {
        & .custom-dropdown {
          .active-item {
            color: #FFC900 !important;
            text-shadow: 1px 1px 1px black;
          }
          & .custom-dropdown-menu {
            background-color: rgba(236, 236, 236, 0.95) !important;
            color: #0C0C0C !important;
            border: 1px solid rgba(0, 0, 0, 0.25) !important;

            & button {
              color: #0C0C0C;
            }

            & .dropdown-item:hover {
              background: rgba(0, 0, 0, 0.4) !important;
            }
          }
          & .custom-dropdown-toggle {
            border: 1px solid #868686 !important;
            color: #0C0C0C !important;
          }
        }
      }
    }
  }
}
