
// @import "../../redesignVariables";

// $bodyFontSize: 1.2vmin;
$bodyFontSize: 12px;
// $headerFontSize: 1.6vmin;
$headerFontSize: 16px;

$titlePadding: 1vmin 5px 0.2vmin 15px;

$rowHeight: 3.6vmin;

$rowBackground: rgba(255,255,255,0.03);
$highlightColor: #FFC900;

.root-class-design20 {

  .player-history {

    &-tabs {
      padding-bottom: 10px;
    }

    &-tab {
      padding-left: 5px;
      padding-right: 5px;

      text-align: left;

      margin: 0px;
      margin-bottom: 8px;
      padding-top: 20px;

      position: relative;

      &-link {
        display: inline-block;
        margin-left: 15px;
        margin-bottom: -1px;
        font-size: var(--headerFontSize);

        &-text {
          color: $headerTextColour;
          opacity: $headerInactiveOpacity;
          margin: 0px 6px;
          padding: 6px 0px;
          cursor: pointer;
          text-decoration: none;

          &-active {
            opacity: 1 !important;
            border-bottom: 1px solid $headerActiveBorderColour;

            &-light {
              opacity: 1 !important;
              border-bottom: 3px solid #d2110a !important;
              color: black !important;
            }
            &-normal {
              opacity: 1 !important;
              border-bottom: 3px solid rgb(255, 201, 0) !important;
              color: white !important;
            }
            &-dark {
              opacity: 1 !important;
              border-bottom: 3px solid rgb(255, 201, 0) !important;
              color: white !important;
            }
          }
        }

        &:hover {
          color: $headerTextColour;
        }

        &:active {
          color: $headerTextColour;
        }

        &:focus {
          color: $headerTextColour;
        }
      }

      & .active {
        color: #fff;
        background-color: #380000;
        border-color: #fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }


    &-table {
      max-width: 100%;
      table-layout: fixed;
      width: 100%;

      &-scrollarea {
        margin-top: 10px;
        min-height: 75px;
        height: calc(100vh - 383px);
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        & .player-points-history-empty-entries,
        & .player-money-history-empty-entries {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          font-size: var(--mLargeFontSize);
        }
      }

      &-header {
      //  width: 100%;
        height: 24px;
        max-width: 100%;
        overflow: hidden;
        margin-left: 15px;
        margin-right: 15px;

        font-weight: bold;
        font-size: var(--defaultFontSize);

        &-col {
          color: #FFF;
          width: 15%;

          font-weight: bold;
          font-size: var(--largeFontSize);
          padding-left: 5px;
          padding-right: 5px;
          text-align: left;

          &-last {
            text-align: right;
          }
        }
      }

      &-body {
      //  max-height: 300px;
      //  overflow-y: scroll;
      //  overflow-x: hidden;
      }

      &-col {
        overflow: hidden;
        color: #fff;

        font-size: var(--defaultFontSize);
        line-height: 3.5vmin;
        text-align: left;
        vertical-align: middle;
        padding-left: 5px;
        padding-right: 5px;

        &-in-app-frame {
          line-height: unset !important;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }

        //white-space: nowrap;
        text-overflow: ellipsis;

        &-highlight {
          color: $highlightColor;
        }

        &-last {
          text-align: right;
        }

        &-positive {
          background: #009C10;
          border-radius: 44px;
          width: max-content;
          padding-left: 1.5vmin;
          padding-right: 1.5vmin;
        }

        &-negative {
          background: #D2110A;
          border-radius: 44px;
          width: max-content;
          padding-left: 1.5vmin;
          padding-right: 1.5vmin;
        }

        &-neutral {
          background: #ffce5a;
          border-radius: 44px;
          width: max-content;
          padding-left: 1.5vmin;
          padding-right: 1.5vmin;
          color: black;
        }
      }

      &-row {
        //height: 4vmin;
        max-width: 100%;
        overflow: hidden;

        background: $rowBackground;
        border-radius: 44px;
        margin-bottom: 0.5vmin;

        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 0.25vmin;
        padding-top: 0.25vmin;

        &-in-app-frame {
          padding-bottom: 0 !important;
          padding-top: 0 !important;
        }

        &-user {
          height: 34px;
          max-width: 100%;
          overflow: hidden;
          background: rgba(247, 180, 90, 0.3);
        }
      }
    }
  }

  @media screen and (orientation : portrait) {
    .wrapper-column {
      padding-left: 12px;
      padding-right: 12px;
    }
    .player-history {
      &-table-header {
        margin-left: 0px;
        margin-right: 0px;
      }

      &-table-row {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .blocked-players-modal {
      .modal-content {
        .modal-header {
          .modal-close-img {
            display: none !important;
          }
        }
      }

    }
    .banned-user-history-table-body {
      height: calc(100vh - 270px) !important;
    }
  }
}

.banned-user-history-table-body {
  & > div {
    min-height: 45px;
    align-items: center;
    img.player-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: inline-block;
      margin-left: 5px;
      margin-right: 8px;
      object-fit: cover;
    }
    img.player-avatar.player-avatar-default {
      background-color: rgba(255, 255, 255, 0.2);
      padding: 5px;
    }
  }
}