
.notification {
  top: 175px;

  font-style: normal;
  font-weight: bold;

  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

  text-align: center;

  & .modal-content {
  //  background-color: transparent;
  //  box-shadow: none;
  //  border: 0;
    border-radius: 0;

    background-color: #2c5955;
    box-shadow: 3px 3px 0px 0px #380000;
    border: 1px solid #fff;
  }

  & .modal-header {
  //  border: 1px solid #fff;
  //  border-bottom: 0;
    border-radius: 0;
  }

  &-link {
    color: #FFD34E;
  }

  &-header {
    font-size: var(--headerFontSize);
    line-height: 24px;
    border: 0;

    color: #fff;
  //  background-color: #2c5955;
  //  box-shadow: 3px 3px 0px 0px #380000;
  //  border: 1px solid #fff;
  //  border-bottom: 0;
  //  text-transform: uppercase;

    & .modal-title {
      width: 100%;
      text-align: center;
      font-size: var(--headerFontSize);
    }

    &-description {
      font-size: var(--defaultFontSize);
      text-align: left;
      margin-bottom: 2px;
    }

    &-tab {

      &-link {
        border: 0;
        padding: 0.25rem, 1rem;

        color: #fff;
      //  background: linear-gradient(180deg, rgba(255, 176, 70, 0.65) 0%, rgba(94, 70, 45, 0.65) 100%), #000000;

        opacity: 0.7;

        &.active {
          opacity: 1;
          color: #fff;
        }
      }
    }

    &-close {
      width: 21px;
      height: 21px;
      color: #fed65f;
      cursor: pointer;
      z-index: 999;
    }
  }

  &-body {
    font-size: var(--largeFontSize);

    color: #fff;
  //  background-color: #2c5955;
  //  box-shadow: 3px 3px 0px 0px #380000;
  //  border: 1px solid #fff;
  //  border-top: 0;
  //  text-transform: uppercase;
    &-new {
      font-size: var(--mLargeFontSize);
    }
  }

  &-footer {
    border: 0;
    // width: 100%;
    display: block;

  //  background-color: transparent;
  //  box-shadow: none;
  //  text-transform: uppercase;

    &-button {
      width: 30%;
      margin:auto;
      margin-left: 10px;
      margin-right: 10px;
      background-color: #2c5955;
      border-radius: 20px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      padding-top: 3px;
      
      &:hover {
        color: #fed65f;
        border-color: #fed65f;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #fed65f;
        border-color: #fed65f;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  &-cards {
    padding-top: 10px;

    &-text {
      position: relative;
      top: -20px;
      padding-right: 5px;
      display: inline-block;
      text-align: center;
    }

    &-card {
      width: 37px;
      height: 55px;
      background-color: transparent;
    //  float: left;
      margin-right: 4px;
      border: none;
      transition: none !important;

      background-size: 37px 55px;
      background-position: top center;
      background-repeat: no-repeat;
      display: inline-block;
    }

    &-card-♠︎-9{background-image: url('../../../images/cards-white-fill/♠︎-9.png');}
    &-card-♠︎-10{ background-image: url('../../../images/cards-white-fill/♠︎-10.png');}
    &-card-♠︎-A{ background-image: url('../../../images/cards-white-fill/♠︎-A.png');}
    &-card-♠︎-J{ background-image: url('../../../images/cards-white-fill/♠︎-J.png');}
    &-card-♠︎-Q{ background-image: url('../../../images/cards-white-fill/♠︎-Q.png');}
    &-card-♠︎-K{ background-image: url('../../../images/cards-white-fill/♠︎-K.png');}

    &-card-♣︎-9{ background-image: url('../../../images/cards-white-fill/♣︎-9.png');}
    &-card-♣︎-10{ background-image: url('../../../images/cards-white-fill/♣︎-10.png');}
    &-card-♣︎-A{ background-image: url('../../../images/cards-white-fill/♣︎-A.png');}
    &-card-♣︎-J{ background-image: url('../../../images/cards-white-fill/♣︎-J.png');}
    &-card-♣︎-Q{ background-image: url('../../../images/cards-white-fill/♣︎-Q.png');}
    &-card-♣︎-K{ background-image: url('../../../images/cards-white-fill/♣︎-K.png');}

    &-card-♥-9{ background-image: url('../../../images/cards-white-fill/♥-9.png');}
    &-card-♥-10{ background-image: url('../../../images/cards-white-fill/♥-10.png');}
    &-card-♥-A{ background-image: url('../../../images/cards-white-fill/♥-A.png');}
    &-card-♥-J{ background-image: url('../../../images/cards-white-fill/♥-J.png');}
    &-card-♥-Q{ background-image: url('../../../images/cards-white-fill/♥-Q.png');}
    &-card-♥-K{ background-image: url('../../../images/cards-white-fill/♥-K.png');}

    &-card-♦︎-7{ background-image: url('../../../images/cards-white-fill/♦︎-7.png');}
    &-card-♦︎-8{ background-image: url('../../../images/cards-white-fill/♦︎-8.png');}
    &-card-♦︎-9{ background-image: url('../../../images/cards-white-fill/♦︎-9.png');}
    &-card-♦︎-10{ background-image: url('../../../images/cards-white-fill/♦︎-10.png');}
    &-card-♦︎-A{ background-image: url('../../../images/cards-white-fill/♦︎-A.png');}
    &-card-♦︎-J{ background-image: url('../../../images/cards-white-fill/♦︎-J.png');}
    &-card-♦︎-Q{ background-image: url('../../../images/cards-white-fill/♦︎-Q.png');}
    &-card-♦︎-K{ background-image: url('../../../images/cards-white-fill/♦︎-K.png');}
  }

  &-cards-new-section {
    position: relative;
    min-height: 165px;

    &-first-card {
      transform: rotate(-7.74deg);
      position: absolute;
      left: calc(100% /2 - 33px);
      width: 77px;
      height: 105px;
      background-size: 77px 105px;
    }

    &-second-card {
      transform: rotate(2.26deg);
      position: absolute;
      width: 77px;
      height: 105px;
      background-size: 77px 105px;
    }
  }

  &-points-section {
    &-title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: #FFC900;
    }
    &-points {
      font-family: 'Futura PT';
      font-style: normal;
      font-weight: 600;
      font-size: 92px;
      line-height: 118px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
    }
    &-bottom {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: #FFFFFF;
    }
  }

  &-winners {
    width: 100%;
    min-height: 300px;
    position: relative;
    & .player-info-wrapper-section{
      width: auto;//80%
      height: 100%;
      position: relative;

      & .player-info-wrapper-loser {
        margin-left: 18px;
        margin-right: 18px;
      }

      & .player-info-wrapper {
        min-width: 136px !important;
        min-height: 136px !important;
        width: 136px !important;
        height: 136px !important;
        position: relative;
        pointer-events: none;
  
        & .player-info-player-image-background {
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: url('../../../images/redesign/player/UserFrame.png');
          background-size: cover;
          background-repeat: no-repeat;
          top: 0;
          z-index: 100;
        }
  
        & .player-info-player-image-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: url('../../../images/redesign/player/UserOverlay.png');
          background-size: cover;
          background-repeat: no-repeat;
          top: 10px;
          left: 0;
          z-index: 100;
        }
  
        & .player-info-player-image {
          background-size: cover;
          background-repeat: no-repeat;
          box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
          filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: 12px;
          border-radius: 50%;
          z-index: 100;
        }

        & .player-info-player-image-sm-gift {
          position: absolute;
          width: 167px;
          height: 161px;
          left: -15px;
          top: -5px;
          background-image: url('../../../images/redesign/components/modal/smFlower.png');
          background-size: cover;
          background-repeat: no-repeat;
          z-index: 101;
        }
  
        & .player-info-player-image-lg-gift {
          position: absolute;
          width: 220px;
          height: 213px;
          left: -19px;
          top: -5px;
          background-image: url('../../../images/redesign/components/modal/lgFlower.png');
          background-size: cover;
          background-repeat: no-repeat;
          z-index: 101;
        }

        & .player-info-player-image-sm-crossout {
          position: absolute;
          width: 167px;
          height: 167px;
          left: -15px;
          top: -15px;
          background-image: url('../../../images/redesign/components/modal/crossout.svg');
          background-size: cover;
          background-repeat: no-repeat;
          z-index: 101;
        }

        & .player-info-player-image-lg-crossout {
          position: absolute;
          width: 220px;
          height: 220px;
          left: -19px;
          top: -20px;
          background-image: url('../../../images/redesign/components/modal/crossout.svg');
          background-size: cover;
          background-repeat: no-repeat;
          z-index: 101;
        }
      }
      & .player-info-wrapper-large {
        min-width: 180px !important;
        min-height: 180px !important;
        width: 180px !important;
        height: 180px !important;
        position: relative;
        pointer-events: none;
      }
      & .player-info-wrapper-loser-large {
        margin-left: 22px;
        margin-right: 22px;
      }
    }
    & .player-info-wrapper-container::before {
      position: absolute;
      left: -220px;
      top: -115px;
      content: ' ';
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png');
      background-size: 301px 301px;
      height: 301px;
      width: 301px;
    }
    & .player-info-wrapper-container::after {
      position: absolute;
      right: -220px;
      top: -115px;
      content: ' ';
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png');
      background-size: 301px 301px;
      height: 301px;
      width: 301px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      z-index: 90;
    }
    & .player-info-wrapper-container-3p::before {
      position: absolute;
      left: -170px;
      top: -115px;
      content: ' ';
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png');
      background-size: 251px 251px;
      height: 251px;
      width: 251px;

      @media only screen and (max-width: 830px) {
        background-size: 20vw 20vw;
        height: 20vw;
        width: 20vw;
        top: -54px;
        left: -45px;
      }
    }
    & .player-info-wrapper-container-3p::after {
      position: absolute;
      right: -170px;
      top: -115px;
      content: ' ';
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png');
      background-size: 251px 251px;
      height: 251px;
      width: 251px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      z-index: 90;
      @media only screen and (max-width: 830px) {
        background-size: 20vw 20vw;
        height: 20vw;
        width: 20vw;
        top: -54px;
        right: -45px;
      }
    }

    & .player-info-wrapper-container-3p-large::before {
      position: absolute !important;
      left: -82px !important;
      top: -120px !important;
      content: ' ' !important;
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png') !important;
      background-size: 261px 261px !important;
      height: 261px !important;
      width: 261px !important;

      @media only screen and (max-width: 800px) {
        background-size: 20vw 20vw !important;
        height: 20vw !important;
        width: 20vw !important;
        top: -62px !important;
        left: -72px !important;
      }
    }
    & .player-info-wrapper-container-3p-large::after {
      position: absolute !important;
      right: -82px !important;
      top: -120px !important;
      content: ' ' !important;
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png') !important;
      background-size: 261px 261px !important;
      height: 261px !important;
      width: 261px !important;
      -webkit-transform: scaleX(-1) !important;
      transform: scaleX(-1) !important;
      z-index: 90 !important;
      @media only screen and (max-width: 800px) {
        background-size: 20vw 20vw !important;
        height: 20vw !important;
        width: 20vw !important;
        top: -62px !important;
        right: -72px !important;
      }
    }

    & .player-info-wrapper-container-4p::before {
      position: absolute;
      left: -137px;
      top: -115px;
      content: ' ';
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png');
      background-size: 251px 251px;
      height: 251px;
      width: 251px;

      @media only screen and (max-width: 700px) {
        background-size: 20vw 20vw;
        height: 20vw;
        width: 20vw;
        top: -54px;
        left: -95px;
      }
    }
    & .player-info-wrapper-container-4p::after {
      position: absolute;
      right: -137px;
      top: -115px;
      content: ' ';
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png');
      background-size: 251px 251px;
      height: 251px;
      width: 251px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      z-index: 90;
      @media only screen and (max-width: 700px) {
        background-size: 20vw 20vw;
        height: 20vw;
        width: 20vw;
        top: -54px;
        right: -95px;
      }
    }

    & .player-info-wrapper-container-4p-large::before {
      position: absolute !important;
      left: -156px !important;
      top: -120px !important;
      content: ' ' !important;
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png') !important;
      background-size: 261px 261px !important;
      height: 261px !important;
      width: 261px !important;

      @media only screen and (max-width: 850px) {
        background-size: 20vw 20vw !important;
        height: 20vw !important;
        width: 20vw !important;
        top: -93px !important;
        left: -108px !important;
      }
    }
    & .player-info-wrapper-container-4p-large::after {
      position: absolute !important;
      right: -156px !important;
      top: -120px !important;
      content: ' ' !important;
      background-image: url('../../../images/redesign/components/modal/mlargeStars.png') !important;
      background-size: 261px 261px !important;
      height: 261px !important;
      width: 261px !important;
      -webkit-transform: scaleX(-1) !important;
      transform: scaleX(-1) !important;
      z-index: 90 !important;
      @media only screen and (max-width: 850px) {
        background-size: 20vw 20vw !important;
        height: 20vw !important;
        width: 20vw !important;
        top: -93px !important;
        right: -108px !important;
      }
    }

    & .player-info-wrapper-container-large::before {
      position: absolute;
      left: -300px;
      top: -151px;
      content: ' ';
      background-image: url('../../../images/redesign/components/modal/xlargeStars.png');
      background-size: 397px 397px;
      height: 397px;
      width: 397px;

      @media only screen and (max-width: 1280px) {
        background-size: 150px 150px !important;
        height: 150px !important;
        width: 150px !important;
        left: -105px;
        top: -70px;
      }
    }
    & .player-info-wrapper-container-large::after {
      position: absolute;
      right: -300px;
      top: -151px;
      content: ' ';
      background-image: url('../../../images/redesign/components/modal/xlargeStars.png');
      background-size: 397px 397px;
      height: 397px;
      width: 397px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      z-index: 90;

      @media only screen and (max-width: 1280px) {
        background-size: 150px 150px !important;
        height: 150px !important;
        width: 150px !important;
        right: -105px;
        top: -70px;
      }
    }

    & .game-score-type-icon {
      display: none;
    }

    & .zole-win-large {
      position: absolute;
      //left: 300px;
      top: -25px;
      display: block;
      background-image: url('../../../images/redesign/game/zole-win-large.png');
      background-size: 150px 100px;
      background-position: center;
      background-repeat: no-repeat;
      width: 150px;
      height: 100px;
    }
    & .zole-win-small {
      position: absolute;
      // left: 300px;
      top: 0px;
      display: block;
      background-image: url('../../../images/redesign/game/zole-win-large.png');
      background-size: 150px 100px;
      background-position: center;
      background-repeat: no-repeat;
      width: 150px;
      height: 100px;
    }
    & .jani-win-large {
      position: absolute;
      left: 50%;
      top: 0px;
      display: block;
      background-image: url('../../../images/redesign/game/jani-win-large.png');
      background-size: 71px 67px;
      background-position: center;
      background-repeat: no-repeat;
      width: 71px;
      height: 67px;
      transform: translate(-50%, 0);
    }
    & .bezstikis-win-large {
      position: absolute;
      //left: 300px;
      top: -25px;
      display: block;
      background-image: url('../../../images/redesign/game/bezstikis-win-large.png');
      background-size: 150px 100px;
      background-position: center;
      background-repeat: no-repeat;
      width: 150px;
      height: 100px;
    }
    & .bezstikis-win-small {
      position: absolute;
      //left: 300px;
      top: 0px;
      display: block;
      background-image: url('../../../images/redesign/game/bezstikis-win-large.png');
      background-size: 150px 100px;
      background-position: center;
      background-repeat: no-repeat;
      width: 150px;
      height: 100px;
    }
    & .jani-win-small {
      position: absolute;
      left: 50%;
      top: 16px;
      display: block;
      background-image: url('../../../images/redesign/game/jani-win-large.png');
      background-size: 71px 67px;
      background-position: center;
      background-repeat: no-repeat;
      width: 71px;
      height: 67px;
      transform: translate(-50%, 0);
    }
  }
}



.holidays-modal {
  & .holiday-modal-body {
    //margin-top: 5%;
  }

  & .holiday-modal-img {
    width: 55%;
  }
}

.holiday-modal {
  //top: 15px!important;

  font-style: normal;
  font-weight: bold;

  width: 585px;
  height: 585px;
  max-width: 100%;
  margin: 0;
  border-radius: 50%;

  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

  text-align: center;
  transform: translate(-50%, -50%) !important;
  top: 50% !important;
  left: 50% !important;

  & .modal-content {
    border-radius: 50%;
    // height: 100%;
    // width: 100%;
    width: 585px;
    height: 585px;

    background-color: #2c5955;
    box-shadow: none;
    border: unset;
  }

  & .modal-header {
    border-radius: 0;
  }

  &-link {
    color: #FFD34E;
  }


  &-body {
    margin-top: 15%;
  //  font-size: 32px;
  //  font-weight: 800;
    width: 420px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;

  //  height: 200px;
  //  max-height: 200px;
  //  min-height: 150px;

  //  text-transform: uppercase;

    color: #fff;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: var(--xxxLargeFontSize);
    // font-size: 36px;
    line-height: 40px;

    & div {
    //  font-size: 28px;
    //  font-weight: 800;
      font-weight: bold;
      font-size: var(--xxLargeFontSize);
      // font-size: 36px;
    //  max-height: 150px;
    }
  }

  &-content {
    text-align: center;
    font-size: var(--xxLargeFontSize);
  }

  &-img {
    width: 35%;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;

    &-old {
      width: 60%;
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-footer {
    border: 0;
    width: 100%;
    display: block;
    margin-bottom: 5%;

    &-button {
      width: 30%;
      margin:auto;
      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &:hover {
        color: #fed65f;
        border-color: #fed65f;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #fed65f;
        border-color: #fed65f;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}

.event-section {
  position: fixed;;
  right: 10px !important;
  bottom: 0 !important;
  top: unset !important;
  font-style: normal; 
  font-size: var(--headerFontSize);
  z-index: 10000001;
  width: 341px;
  height: 421px;
  border-radius: 12px !important;
  background: #FED75F;

  & .event-header {
    padding: 20px !important;
    line-height: 25.6px;
    height: 161px;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  & .event-header .event-title {
    text-align: left !important;
    font-weight: 700;
    color: #000000;
  }
  & .event-header .event-sub-title {
    font-size: var(--largeFontSize);
    font-weight: 100;
    color: #FF0000;
    line-height: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  
  & .event-header .event-sub-spaces {
    margin-left: 10px;
    margin-right: 10px;
  }

  & .event-header .event-description {
    font-size: var(--largeFontSize);
    font-weight: 100;
    color: #000000;
    line-height: 22.4px;
  }

  & .event-body {
    width: 100%;
    height: 189px;
    text-align: center;
    padding: 0px !important;
    color: #000000
  }

  & .events-header-close {
    background-color: #000000;
    opacity: 0.7;
    width: 30px;
    height: 30px;
    color: #FFFFFF;
    border-radius: 30px;
    line-height: 25px;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 5px;
    text-align: center;
  }
  & .event-body img {
    width: 100%;
    height: 189px;
    object-fit: cover;
  }

  & .event-footer {
    width: 100%;
    display: block;
    height: 71px;
    padding: 12px 12px 20px 0px;
    display: flex;
    justify-content: left;
    align-items: left;
    & .event-footer-button {
      height: 47px;
      border-radius: 15px;
      font-size: var(--headerFontSize);
      // font-size: 17px;
      text-align: center;
      font-weight: 700;
      border-radius: 99px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
      border: 1px solid #ECECEC;
      & .openPage {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 0px !important;
      }
    }
  }
}

.event-section-mobile {
  right: 0px !important;
  transform: translateY(421px);
  transition: transform 0.5s ease;

  @media screen and (orientation: portrait) {
    max-width: 100%;
  }

  &-show {
    transform: translateY(0px);
  }

  & .event-body img {
    @media screen and (max-width: 1280px) and (orientation: landscape) {
      object-fit: cover;
    }
    @media screen and (orientation: portrait) {
      object-fit: cover;
    }
  }
}

.discount-event-section {
  position: fixed;;
  right: 10px !important;
  bottom: 0 !important;
  top: unset !important;
  font-style: normal; 
  font-size: var(--headerFontSize);
  z-index: 10000002;
  width: 341px;
  height: 421px;
  border-radius: 12px !important;
  background: #FED75F;
  & .event-header {
    padding: 20px !important;
    line-height: 25.6px;
    height: 161px;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  & .event-header .event-title {
    text-align: left !important;
    font-weight: 700;
    color: #000000;
  }

  & .event-header .event-sub-spaces {
    margin-left: 10px;
    margin-right: 10px;
  }

  & .event-header .event-sub-title {
    font-size: var(--largeFontSize);
    font-weight: 100;
    color: #FF0000;
    line-height: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  & .event-header .event-description {
    font-size: var(--largeFontSize);
    font-weight: 100;
    color: #000000;
    line-height: 22.4px;
  }

  & .event-body {
    width: 100%;
    height: 189px;
    text-align: center;
    padding: 0px !important;
    color: #000000
  }

  & .events-header-close {
    background-color: #000000;
    opacity: 0.7;
    width: 30px;
    height: 30px;
    color: #FFFFFF;
    border-radius: 30px;
    line-height: 25px;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 5px;
    text-align: center;
  }
  & .event-body img {
    width: 100%;
    height: 189px;
    object-fit: cover;
  }

  & .event-footer {
    width: 100%;
    display: block;
    height: 71px;
    padding: 12px 12px 20px 0px;
    display: flex;
    justify-content: left;
    align-items: left;
    & .event-footer-button {
      height: 47px;
      border-radius: 15px;
      font-size: var(--headerFontSize);
      // font-size: 17px;
      text-align: center;
      font-weight: 700;
      border-radius: 99px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
      border: 1px solid #ECECEC;
      & .openPage {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 0px !important;
      }
    }
  }
}

@keyframes slideIn {
  from {
    transform: translate(0px, 480px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

.discount-event-section-mobile {
  right: 0px !important;
  transform: translate(0px, 50vh) !important;
  transition: transform 0.5s ease 0.1s;
  will-change: transform;

  @media screen and (orientation: portrait) {
    width: 100%;
  }

  &-show {
    bottom: 0px !important;
    transform: translate(0px, 0px) !important;
  }

  & .event-body img {
    @media screen and (max-width: 1280px) and (orientation: landscape) {
      object-fit: cover;
    }
    @media screen and (orientation: portrait) {
      object-fit: cover;
    }
  }
}

.feedback-section {
  position: fixed;
  left: 5px !important;
  bottom: 10px !important;
  top: unset !important;
  font-style: normal;
  font-size: var(--headerFontSize);
  // z-index: 10000002;
  z-index: 110;
  width: 494px;
  height: auto;
  border-radius: 12px !important;
  border: 1px solid #fff;
  background: #2c5955;
  padding: 20px !important;
  
  & .feedback-header-original {
    line-height: 25.6px;
    height: 50px;
    position: relative;
    top: 0px;
    left: 0px;
    //left: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  & .feedback-header {
    line-height: 25.6px;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 10px;
    right: 10px;
    //left: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  & .feedback-header-original .feedback-title {
    text-align: left !important;
    font-weight: 700;
    color: #fff;
  }

  & .original-feedback-header {
    display: flex;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 50px;
    line-height: 25.6px;
    position: unset;
  }
  
  & .feedback-header .feedback-title {
    text-align: left !important;
    font-weight: 700;
    color: #fff;
  }

  & .feedback-body {
    width: 100%;
    height: 280px;
    text-align: left;
    padding: 0px !important;
    color: #fff
  }

  & .feedback-error-section {
    & .alert {
      margin-top: 21px;
    }
  }


  & .feedback-body-survey {
    height: auto;
  }

  & .feedback-body span {
    font-size: var(--xxxLargeFontSize);
    // font-size: 40px;
    color: #FED75F !important;
  }

  & .feedback-body .feedback-body-rating-comments textarea {
    height: 150px !important;
  }

  & .feedback-body .survey-body-rating-comments textarea {
    height: 70px !important;
    position: absolute;
    left: -20px;
  }
  
  & .feedback-body .feedback-body-rating-comments {
    position: relative;
    left: 0px;
    top: 0px;
  }

  & .feedback-body .survey-body-rating-comments {
    position: relative;
    left: 0px;
    top: 0px;
  }

  & .feedback-body .survey-body-question-answers {
    position: relative;
    left: 20px;
    top: 0px;
  }

  & .feedback-body .feedback-body-rating-comments .feedback-body-rating-comments-limit-characters {
    position: absolute;
    right: 0px;
    bottom: -74px;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #28514d;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .feedback-body .survey-body-question-answers .feedback-body-rating-comments-limit-characters {
    position: absolute;
    right: 20px;
    top: 80px;
  }

  & .feedback-body .feedback-body-rating-comments .feedback-body-rating-comments-limited-characters {
    position: absolute;
    right: 0px;
    bottom: -74px;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #a81211;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .feedback-body .survey-body-question-answers .feedback-body-rating-comments-limited-characters {
    position: absolute;
    right: 20px;
    top: 80px;
  }

  & .feedback-header-close {
    color: #FFFFFF;
    line-height: 25px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    text-align: center;
  }
  & .feedback-body img {
    width: 100%;
    height: 189px;
  }

  & .rating-stars-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .rating-stars-point-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & .rating-stars-point-label {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 10px;
  }

  & .feedback-footer {
    width: 100%;
    display: block;
    height: 71px;
    padding: 12px 12px 20px 0px;
    display: flex;
    justify-content: left;
    align-items: left;
    & .feedback-footer-button {
      width: 120px;
      height: 47px;
      border-radius: 15px;
      font-size: var(--headerFontSize);
      // font-size: 17px;
      text-align: center;
      font-weight: 700;
      border-radius: 99px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
      border: 1px solid #ECECEC;
      & .openPage {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 0px !important;
      }
    }
  }

  
  & .feedback-body .feedback-survey-question {
    & .title {
      font-size: var(--mLargeFontSize);
      font-weight: 600;
    }
    & .description {
      font-size: var(--largeFontSize);
      font-weight: 400;
    }
  }
}