// @import "../../redesignVariables";

$rowHeight: $menuRowHeight;

//$buttonPrimaryColour: #FFF;
//$buttonSecondaryColour: #000;
//$buttonBackgroundColour: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);

.root-class-design20 {
  .new-power-rankings {
    &-header-tutorial {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: -4px;
      right: 0px;
      & img {
        width: 56px;
        height: 56px;
        margin-top: 2px;
        cursor: pointer;
        opacity: $disabledLinkOpacity;
        float: right;
      }
    }

    &-scrollarea {
      // height: 418px;
      // max-height: 418px;

      &-body {
      }
    }

    &-join {
      &-message {
        display: inline-block;
        font-size: var(--largeFontSize);

        &-image {
          display: inline-block;
          margin-left: 4px;
          width: 15px;
          height: 15px;
        }
      }
    }

    &-tutorial-table {
      max-width: 100%;
      table-layout: fixed;
      width: 100%;

      &-scrollarea {
        margin-top: 10px;
        height: 500px;
      }

      &-col {
        overflow: hidden;
        color: #fff;

        font-size: var(--smallFontSize);
        line-height: 15px;
        text-align: center;
      }

      &-row {
        height: 34px;
        max-width: 100%;
        overflow: hidden;

        &-user {
          height: 34px;
          max-width: 100%;
          overflow: hidden;
          background: rgba(247, 180, 90, 0.3);
        }
      }

      &-header {
        width: 100%;
        height: 24px;
        max-width: 100%;
        overflow: hidden;

        border-bottom: 1px solid #fff;

        font-weight: bold;
        font-size: var(--defaultFontSize);

        &-col {
          color: #fff;
          //  border-bottom: 1px solid #fff;
          width: 15%;

          font-weight: bold;
          font-size: var(--defaultFontSize);
          padding-left: 5px;
          padding-right: 5px;
          text-align: center;
        }
      }
    }

    &-leaderboard {
      font-size: var(--headerFontSize);
      width: 100%;

      &-table {
        &-header {
          //  border-bottom: 1px solid #fff;
          text-align: center;
        }

        &-row {
          height: 34px;
          max-width: 100%;
          overflow: hidden;

          &-user {
            height: 34px;
            max-width: 100%;
            overflow: hidden;
            background: rgba(247, 180, 90, 0.3);
          }
        }

        &-col {
          text-align: center;
        }
      }

      &-user-image {
        width: 25px;
        height: 25px;
        margin-right: 5px;

        &-wrapper {
          display: inline-block;
        }
      }
    }

    &-user-image {
      width: 30px;
      height: 30px;
      border-radius: 20px;
      border: 1px solid #fff;
      background-color: #847753;
      box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
      overflow: hidden;
      padding: 0;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
      }
    }

    &-header {
      height: 35px;
      margin-bottom: 10px;
      font-weight: 600;

      &-switch-section {
        display: flex;
        flex-direction: row;
        margin-left: 50px;
        margin-right: 5px;
        margin-bottom: 28px;

        & label.active {
          opacity: 1;
        }

        & label.disable {
          opacity: 0.5;
        }

        & label {
          font-weight: 400;
          margin-right: 5px;
          font-size: var(--headerFontSize);
          line-height: 160%;
        }
      }

      &-image {
        display: inline-block;
        margin-right: 10px;
      }

      &-text {
        text-transform: uppercase;
        font-size: var(--largeFontSize);
        color: #fff;
        margin-top: 5px;
        display: inline-block;
      }

      &-button {
        width: auto;
        margin-left: 10px;
        margin-right: auto;

        background-color: #2c5955;
        border-radius: 15px;
        height: 30px;
        font-size: var(--defaultFontSize);
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        -webkit-box-shadow: 5px 5px 0px 0px #380000;
        -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 5px 5px 0px 0px #380000;

        &-active {
          color: #fff;
          background-color: #380000;
          border-color: #fff;
          box-shadow: none;
        }

        &:hover {
          color: #ffd34e;
          border-color: #ffd34e;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #ffd34e;
          border-color: #ffd34e;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }

      &-tutorial {
        width: 56px;
        height: 56px;
        margin-top: 2px;
        cursor: pointer;
      }
    }

    &-body {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      word-break: break-word;
      padding: 0 30px 10px 30px;
      align-items: flex-end;
      row-gap: 30px;
      padding-bottom: 100px;

      &-modal-content {
        font-size: var(--defaultFontSize);
      }

      & .player-info-player-background {
        width: 100% !important;
        & .player-info-player-name-wrapper {
          & .player-info-player-name {
            font-size: var(--xLargeFontSize);
            line-height: 140%;
          }
        }
      }

      & .player-info-player-image-wrapper {
        width: 100% !important;
      }

      &-item {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        // margin-top: 20px;
        // height: 350px;

        &-label {
          display: flex;
          justify-content: center;
          position: relative;
          &-key {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: linear-gradient(180deg, #333333 0%, #121212 100%);
            font-family: "Futura PT";
            font-style: normal;
            font-weight: 700;
            font-size: var(--xLargeFontSize);
            line-height: 26px;
            text-align: center;
            color: #fff;
            position: absolute;
            z-index: 20;
            line-height: 40px;
            vertical-align: middle;
          }
          &-addition {
            // width: 81px;
            // height: 36px;
            white-space: nowrap;
            padding: 7px 20px;
            border-radius: 88px;
            background-color: #fff;
            position: absolute;
            bottom: -72px;
            margin: 0;
            z-index: 10;
            color: #000;
            font-size: var(--headerFontSize);
            font-weight: 600;
            text-align: center;
            line-height: 140%;
            vertical-align: middle;
          }
        }

        &-photo {
          position: relative;
          width: 100%;
          // width: 122px;
          // height: 122px;
          display: flex;
          justify-content: center;
          margin-top: 77px;

          & .player-info-player-image-wrapper {
            max-width: 122px !important;
          }
        }

        &-name {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 30px;
          font-size: var(--xLargeFontSize);
          font-weight: 600;
        }

        &-score {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          font-size: var(--largeFontSize);
          justify-content: center;
          &-zole-coins {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            & img {
              width: 21px;
            }

            & label {
            }
          }

          &-zole-points {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            & img {
              width: 21px !important;
            }

            & label {
            }
          }
        }
      }
      &-item-lg {
        width: 32% !important;

        & .new-power-rankings-body-item-label {
          height: 60px;
          &-key {
            width: 60px;
            height: 100%;
            text-align: center;
            color: #000000;
            margin: 0;
            font-size: var(--xxxLargeFontSize);
            text-shadow: 1px 1px 0 #fff;
            -webkit-text-stroke: 1px white;
            vertical-align: middle;
            line-height: 60px;
          }
        }

        & .new-power-rankings-body-item-label-addition {
          white-space: nowrap;
          padding: 7px 20px;
          bottom: calc(-100% + 25px);
          margin: 0;
          font-size: var(--xLargeFontSize);
          line-height: 140%;
          vertical-align: middle;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & .new-power-rankings-body-item-photo {
          // width: 180px;
          width: 100%;
          // height: 180px;
          margin-top: 40px;
          & .player-info-player-image-wrapper {
            max-width: 180px !important;
          }
        }

        & .new-power-rankings-body-item-label-addition:before {
          position: absolute;
          left: -70px;
          top: -10px;
          content: " ";
          background-image: url("../../../../images/redesign/components/modal/modal_stars.png");
          background-size: 65px 65px;
          height: 65px;
          width: 65px;
        }

        & .new-power-rankings-body-item-label-addition:after {
          position: absolute;
          right: -70px;
          top: -10px;
          content: " ";
          background-image: url("../../../../images/redesign/components/modal/modal_stars.png");
          background-size: 65px 65px;
          height: 65px;
          width: 65px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        & .new-power-rankings-body-item-score {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          font-size: var(--largeFontSize);
          justify-content: center;
        }
        & .new-power-rankings-body-item-score-zole-coins {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        & .new-power-rankings-body-item-score-zole-coins img {
          width: 21px;
        }

        & .new-power-rankings-body-item-score-zole-points {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        & .new-power-rankings-body-item-score-zole-points img {
          width: 21px;
        }
      }

      &-item-lg-no-stars {
        width: 32% !important;

        & .new-power-rankings-body-item-label {
          height: 60px;
          &-key {
            width: 60px;
            height: 100%;
            text-align: center;
            color: #000000;
            margin: 0;
            font-size: var(--xxxLargeFontSize);
            text-shadow: 1px 1px 0 #fff;
            -webkit-text-stroke: 1px white;
            vertical-align: middle;
            line-height: 60px;
          }
        }

        & .new-power-rankings-body-item-label-addition {
          white-space: nowrap;
          padding: 7px 20px;
          bottom: calc(-100% + 25px);
          margin: 0;
          font-size: var(--xLargeFontSize);
          line-height: 140%;
          vertical-align: middle;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & .new-power-rankings-body-item-photo {
          // width: 180px;
          width: 100%;
          // height: 180px;
          margin-top: 40px;
          & .player-info-player-image-wrapper {
            max-width: 180px !important;
          }
        }

        & .new-power-rankings-body-item-score {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          font-size: var(--largeFontSize);
          justify-content: center;
        }
        & .new-power-rankings-body-item-score-zole-coins {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        & .new-power-rankings-body-item-score-zole-coins img {
          width: 21px;
        }

        & .new-power-rankings-body-item-score-zole-points {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        & .new-power-rankings-body-item-score-zole-points img {
          width: 21px;
        }
      }

      &-item-md {
        width: 28% !important;

        & .new-power-rankings-body-item-label {
          height: 60px;
          &-key {
            width: 60px;
            height: 100%;
            text-align: center;
            color: #000000;
            margin: 0;
            font-size: var(--xxxLargeFontSize);
            text-shadow: 1px 1px 0 #fff;
            -webkit-text-stroke: 1px white;
            vertical-align: middle;
            line-height: 60px;
          }
        }

        & .new-power-rankings-body-item-label-addition {
          // width: 91px;
          // height: 42px;
          white-space: nowrap;
          padding: 7px 20px;
          bottom: calc(-100% + 25px);
          margin: 0;
          font-size: var(--xLargeFontSize);
          line-height: 140%;
          vertical-align: middle;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & .new-power-rankings-body-item-photo {
          // width: 142px;
          // height: 156px;
          margin-top: 40px;
          width: 100%;
          & .player-info-player-image-wrapper {
            max-width: 156px !important;
          }
        }

        & .new-power-rankings-body-item-score {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          font-size: var(--largeFontSize);
          justify-content: center;
        }
        & .new-power-rankings-body-item-score-zole-coins {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        & .new-power-rankings-body-item-score-zole-coins img {
          width: 21px;
        }

        & .new-power-rankings-body-item-score-zole-points {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        & .new-power-rankings-body-item-score-zole-points img {
          width: 21px;
        }
      }

      &-item-sm {
        width: 20% !important;

        & .new-power-rankings-body-item-label {
          height: 60px;
          &-key {
            width: 60px;
            height: 100%;
            text-align: center;
            color: #000000;
            margin: 0;
            font-size: var(--xxxLargeFontSize);
            text-shadow: 1px 1px 0 #fff;
            -webkit-text-stroke: 1px white;
            vertical-align: middle;
            line-height: 60px;
          }
        }

        & .new-power-rankings-body-item-label-addition {
          // width: 91px;
          // height: 42px;
          white-space: nowrap;
          padding: 7px 20px;
          bottom: calc(-100% + 25px);
          margin: 0;
          font-size: var(--xLargeFontSize);
          line-height: 140%;
          vertical-align: middle;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & .new-power-rankings-body-item-photo {
          margin-top: 40px;
          width: 100%;
          & .player-info-player-image-wrapper {
            max-width: 135px !important;
          }
        }

        & .new-power-rankings-body-item-score {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          font-size: var(--largeFontSize);
          justify-content: center;
        }
        & .new-power-rankings-body-item-score-zole-coins {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        & .new-power-rankings-body-item-score-zole-coins img {
          width: 21px;
        }

        & .new-power-rankings-body-item-score-zole-points {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        & .new-power-rankings-body-item-score-zole-points img {
          width: 21px;
        }
      }

      &-item-lsm {
        width: 20% !important;
      }
    }

    &-table {
      width: 100%;

      &-header {
        font-weight: bold;
        font-size: var(--defaultFontSize);
        text-align: center;
        border-bottom: 1px solid #fff;

        &-col {
          margin-bottom: 3px;
          padding-bottom: 3px;
          padding-top: 5px;
          text-transform: capitalize;
        }
      }

      &-body {
        font-size: var(--defaultFontSize);
        text-align: center;
      }

      &-button {
        //  width: 100%;
        margin-left: auto;
        margin-right: auto;

        margin-top: 5px;
        margin-bottom: 5px;

        background-color: #2c5955;
        border-radius: 15px;
        height: 30px;
        font-size: var(--defaultFontSize);
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        -webkit-box-shadow: 5px 5px 0px 0px #380000;
        -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 5px 5px 0px 0px #380000;
        white-space: nowrap;

        &:hover {
          color: #ffd34e;
          border-color: #ffd34e;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #ffd34e;
          border-color: #ffd34e;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }

        &-col {
          //  width: 45%;
          display: inline-block;
          //  margin: auto;
          margin-left: 2.5%;
          margin-right: 2.5%;
        }
      }

      &-waiting {
      }

      &-status {
        &-text {
          display: inline-block;
        }

        &-spinner {
          display: inline-block;
          margin-left: 2px;
          width: 17px;
          height: 17px;
        }
      }
    }

    &-modal {
      & .modal-content .modal-body {
        font-size: var(--largeFontSize);
      }

      &-close {
        width: 21px;
        height: 21px;
        color: #ffd34e;
        cursor: pointer;
      }
    }
  }

  .new-power-rankings-section {
    .layout-subheader {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-left: 0;
      margin-right: 0;
      padding-top: 14px;
      padding-right: 11px;
      &-link {
        margin-left: 2px;
      }
    }
  }

  // .new-power-rankings-main-section {
  //   padding-top: 21px;
  // }

  .new-switch-wrapper > input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  .new-switch-wrapper > input[type="checkbox"] + .switch {
    transform: translateX(5px);
  }
  .new-switch-wrapper > input[type="checkbox"]:checked + .switch {
    transform: translateX(50%) translateX(-19px);
  }
  .new-switch-wrapper.large > input[type="checkbox"]:checked + .switch {
    transform: translateX(50%) translateX(-35px);
  }
  .new-switch-wrapper > input[type="checkbox"]:disabled + .switch {
    background: #ccc;
  }

  .new-switch-wrapper {
    border-radius: 20px;
    cursor: pointer;
    height: 19px;
    float: left;
    // overflow: hidden;
    position: relative;
    width: 48px;
    border: 1px solid #fff;
    background: rgba(0, 156, 16, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 99px;
    margin-top: 3px;
  }
  .new-switch-wrapper.large {
    height: 40px;
    width: 80px;
  }
  .new-switch-wrapper > .switch {
    color: #fff;
    display: inline-block;
    height: 100%;
    left: -100%;
    position: relative;
    transition: 100ms linear;
    width: 200%;
  }
  .new-switch-wrapper > .switch > .switch-handle {
    border-radius: 50%;
    display: inline-block;
    height: 17px;
    left: 48%;
    position: relative;
    top: -2px;
    width: 17px;
    z-index: 47;
  }
  .new-switch-wrapper.large > .switch > .switch-handle {
    height: 30px;
    width: 30px;
  }
  .switch-label {
    float: left;
    line-height: 2.5rem;
    margin-left: 10px;
  }
  .new-switch-wrapper.large + .switch-label {
    line-height: 4rem;
    margin-left: 15px;
  }

  .switch-handle:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: -4px;
    bottom: 0px;
    //  background-color: #FFFFFF;
    //  opacity: 0.7;
    background-color: #009c10;
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 99px;
  }

  .switch-handle:after {
    //  display: inline-block;
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 4px;
    background-image: url("../../../../images/redesign/components/slider/sliderTick.svg");
    background-size: 16px 16px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    //  height: 22px;
    //  width: 22px;
  }
}
.root-font-large .new-switch-wrapper {
  margin-top: 5px;
}

.root-font-xLarge .new-switch-wrapper {
  margin-top: 8px;
}

.root-font-large .new-switch-wrapper > .switch > .switch-handle {
  top: -7px;
}

.root-font-xLarge .new-switch-wrapper > .switch > .switch-handle {
  top: -12px;
}

.new-power-rankings-main-section {
  .new-power-rankings-scrollarea {
    width: calc(100% - 4px);
  }
}

.my-info-ratings-step-wrapper {
  justify-content: center;
}