$mobileLandScapeWidth: 1280px;

.root-class-design20 {
    @media screen and (orientation : portrait) {
        .layout-mobile-body-buy-page {
            .layout-mobile-body-top {
                margin-top: 45px;

                .layout-subheader {
                    text-align: center;
                    padding-top: 17px;
                    margin-bottom: 0;
                    display: block !important;
                    border-bottom: none;

                    .layout-subheader-title {
                        padding: 0;
                        margin: 0;
                        font-size: var(--largeFontSize);
                        font-weight: 600;
                        line-height: 136%;
                        font-family: 'Open Sans';
                    }


                }
            }

            .layout-mobile-body-main {
                padding-left: 3px !important;
                padding-right: 3px !important;

                .layout-subheader-title {
                    display: none;
                }

                div.subheader-menu {
                    padding: 8px 7px 0px 7px;
                    display: flex;
                    flex-wrap: nowrap;
                    column-gap: 15px;
                    overflow: auto hidden;
                    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

                    &::-webkit-scrollbar {
                        height: 4px;
                    }

                    .layout-subheader-link {
                        margin: 0;

                        button.layout-subheader-link-text {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: var(--defaultFontSize);
                            line-height: 136%;
                            color: #ffffff;
                            white-space: nowrap;
                            padding: 0px 0px 3px 0;
                            margin: 0;
                        }
                    }
                }

                .tab-content.buy-page-mobile-tab-content {
                    height: calc(100% - 40px);
                    overflow: hidden auto;

                    .buy-page-mobile-tab {
                        min-height: 100%;
                        flex-direction: column;
                        justify-content: center;

                        .buy-page-end-time-section {
                            margin-bottom: 40px;
                            margin-top: 15px !important;

                            &>div {
                                display: inline-flex !important;
                                border: 1px solid rgba(255, 255, 255, 0.1);
                                border-radius: 22px;
                                width: auto;
                                padding: 6px 16px;

                                .mr-1 {
                                    font-size: var(--largeFontSize);
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 160%;
                                    color: #FFFFFF;
                                }

                                .mr-1+div {
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: var(--largeFontSize);
                                    line-height: 160%;
                                    color: #FFFFFF;
                                }
                            }

                        }

                        .buy-page-mobile-row {
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: center;
                            align-items: center;
                            margin-left: 0;
                            margin-right: 0;
                            margin-bottom: 22px;
                            column-gap: 10px;

                            .col {
                                padding-left: 0;
                                padding-right: 0;
                                display: inline-flex;
                                flex-direction: column;
                                align-items: center;
                                flex: unset;
                                max-width: unset;
                                width: auto;

                                .buy-page-coins-imageWrapper {
                                    cursor: pointer;
                                    height: auto;

                                    img {
                                        width: 80px;
                                        height: auto;
                                        margin: 0 auto;
                                    }

                                    .buy-page-coins-bonus-image {
                                        transform: none;
                                        top: -15px;
                                        left: 25px;
                                    }
                                }

                                .buy-page-coins-wrapper {
                                    top: 0;
                                    display: flex;
                                    align-items: center;
                                    padding: 2px 22px;
                                    background: rgba(0, 0, 0, 0.3);
                                    border-radius: 88px;

                                    img {
                                        margin-right: 6px;
                                    }

                                    .buy-page-coins-amount {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 160%;
                                    }
                                }

                                .buy-page-coins-button-wrapper {
                                    top: 0;

                                    .layout-darkHighlighted-button {
                                        padding: 4px 8px;
                                        margin-top: 3px;

                                        &::before {
                                            margin-right: 5px;
                                        }

                                        &::after {
                                            margin-left: 5px;
                                        }
                                    }

                                    .layout-darkHighlighted-button+.layout-redHighlighted-button {
                                        margin-top: 3px;
                                        padding: 4px 8px;
                                        font-weight: 700;
                                        line-height: 136%;

                                        &::before {
                                            margin-right: 5px;
                                        }

                                        &::after {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .buy-page-mobile-tab.active {
                        display: flex;
                    }

                    .premium-packs-mobile-tab {
                        min-height: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .buy-page-row {
                            transform: unset;
                            top: 0;
                            display: flex;
                            flex-wrap: wrap;
                            row-gap: 10px;
                            margin-top: 0;
                            left: 0;
                            margin-bottom: unset;
                            width: 86%;
                            justify-content: space-evenly;

                            .col {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .buy-page-premium-imageWrapper {
                                    cursor: pointer;
                                    height: auto;

                                    img {
                                        width: 80px;
                                        object-fit: contain;
                                        height: auto;
                                        margin: 0 auto;
                                    }
                                }

                                .buy-page-premium-wrapper {
                                    top: -20px;

                                    .buy-page-premium-amount {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: var(--defaultFontSize);
                                        line-height: 160%;
                                        color: #FFFFFF;
                                    }

                                    .buy-page-info-imageWrapper {
                                        margin-left: 0px;
                                        width: auto;
                                        height: auto;
                                        @media only screen and (max-width: 690px) {
                                            top: -46px;
                                            right: 34%;
                                        }

                                        img {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            z-index: 9;
                                        }
                                    }
                                }

                                .buy-page-premium-button-wrapper {
                                    top: -17px;
                                    display: inline-flex;
                                    flex-direction: column;
                                    row-gap: 3px;
                                    align-items: center;
                                    margin: auto;
                                    width: 100%;
                                    padding-left: 10%;
                                    padding-right: 10%;

                                    .layout-darkHighlighted-button {
                                        margin-top: 0;
                                        padding: 4px 8px;
                                        line-height: 136%;
                                        width: 100%;

                                        &::before {
                                            margin-right: 5px;
                                        }

                                        &::after {
                                            margin-left: 5px;
                                        }
                                    }

                                    .layout-darkHighlighted-button+.layout-redHighlighted-button {
                                        padding: 4px 8px;
                                        font-weight: 700;
                                        line-height: 136%;
                                        width: 100%;

                                        &::before {
                                            margin-right: 5px;
                                        }

                                        &::after {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .transaction-history-tab {
                        padding: 11px 6px 11px 21px;
                        justify-content: flex-start;

                        .layout-body-main-scrollarea {
                            .transaction-history-table-row {
                                background-color: rgba($color: #fff, $alpha: 0.03);
                                border-radius: 12px;
                                margin-bottom: 1px;

                                .first-line {
                                    display: flex;
                                    padding-left: 9px;
                                    padding-right: 8px;
                                    padding-top: 8px;
                                    justify-content: space-between;

                                    .transaction-history-table-col {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: var(--defaultFontSize);
                                        line-height: 160%;
                                        color: #FFC900;

                                        img {
                                            width: 22px;
                                            object-fit: contain;
                                        }
                                    }

                                    .transaction-history-table-col+.transaction-history-table-col {
                                        color: #FFFFFF;
                                    }
                                }

                                .second-line {
                                    display: flex;
                                    justify-content: flex-end;
                                    flex-wrap: wrap;
                                    column-gap: 8px;
                                    padding-right: 7px;
                                    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);

                                    .transaction-history-table-col {
                                        white-space: nowrap;
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 160%;
                                        color: rgba($color: #ffffff, $alpha: 0.7);
                                        font-size: var(--largeFontSize);

                                        span {
                                            white-space: nowrap;
                                            font-size: var(--defaultFontSize);
                                            color: rgba($color: #ffffff, $alpha: 0.5);
                                        }

                                        span+span {
                                            white-space: nowrap;
                                            font-size: var(--largeFontSize);
                                            color: rgba($color: #ffffff, $alpha: 0.7);
                                        }
                                    }

                                }

                                .third-line {
                                    .transaction-history-table-col {
                                        padding-top: 4px;
                                        padding-bottom: 3px;

                                        .player-history-table-col-negative {
                                            margin: 0 auto;
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: var(--defaultFontSize);
                                            line-height: 160%;
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                            background: #D2110A;
                                            padding: 6px 13px;
                                        }

                                        .player-history-table-col-positive {
                                            margin: 0 auto;
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: var(--defaultFontSize);
                                            line-height: 160%;
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                            background: #009C10;
                                            padding: 6px 13px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .redeem-code-tab {
                        min-height: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .buy-page-redeemGiftCode {
                            .buy-page-redeemGiftCode-section {
                                display: flex;
                                flex-direction: column;
                                row-gap: 38.5px;

                                .buy-page-redeemGiftCode-section-message {
                                    width: auto;
                                    height: auto;

                                    .buy-page-redeemGiftCode-section-message-label {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: var(--headerFontSize);
                                        line-height: 136%;
                                        color: #FFFFFF;

                                        p {
                                            margin: 0;
                                            font-weight: 400;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-content1 {
                                            color: #fff;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-content2 {
                                            color: #fff;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-code {
                                            color: #FFC900;
                                        }
                                    }
                                }

                                .buy-page-redeemGiftCode-section-inputCode {
                                    width: auto;
                                    height: auto;

                                    .form-control:disabled,
                                    .form-control[readonly] {
                                        background-color: transparent;
                                    }

                                    .buy-page-redeemGiftCode-section-inputCode-col {

                                        .new-input-second-placeholder {
                                            padding-top: 0;
                                            padding-bottom: 0;
                                        }

                                        .inputInlineMessage-error {
                                            left: 9px;
                                            height: auto;

                                            p {
                                                margin: 0;
                                            }
                                        }

                                        .inputInlineMessage-success {
                                            left: 9px;
                                            height: auto;

                                            p {
                                                margin: 0;
                                            }
                                        }
                                    }
                                }

                                .buy-page-redeemGiftCode-section-buttonSubmit {
                                    width: auto;
                                    height: auto;

                                    .buy-page-redeemGiftCode-section-buttonSubmit-col {
                                        .buy-page-redeemGiftCode-section-buttonSubmit-col-button {
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 700;
                                            font-size: var(--largeFontSize);
                                            line-height: 136%;
                                            text-transform: uppercase;
                                            color: #000000;
                                            padding: 9px 15px;
                                            width: auto;
                                            height: auto;

                                            &::after {
                                                margin-left: 13px;
                                            }

                                            &::before {
                                                margin-right: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }


            }
        }

        .discount-event-section {
            display: none;
        }

        .discount-event-section.discount-event-section-mobile {
            background: #FFC900;
            width: calc(100vw - 57px);
            height: auto;
            left: 50%;
            transform: translateX(-50%);
            right: unset !important;
            display: block !important;
            bottom: 0px !important;


            .event-header {
                padding: 12px 20px !important;
                height: auto;

                .event-title {
                    font-weight: 600;
                    line-height: 1.35;
                    color: #0C0C0C;
                }

                .events-header-close {
                    background-image: url('../../../../images/redesign/mobile/icons/close-icon.svg');
                    color: transparent;
                    background-repeat: no-repeat;
                    background-color: rgba($color: #000000, $alpha: 0.7);
                    background-position: center;
                    opacity: 1;
                    right: 4px;
                    top: 4px;
                }

                .event-sub-title {

                    time,
                    label {
                        font-size: var(--defaultFontSize);
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        line-height: 160%;
                        margin-bottom: 0;
                    }
                }


            }

            .event-body {
                width: 100%;
                height: 34vh;
                text-align: left;
                overflow: hidden auto;

                .event-description {
                    margin-left: 20px;
                    font-size: var(--defaultFontSize);
                    font-weight: 400;
                    font-family: 'Open Sans';
                    line-height: 160%;
                    color: #0C0C0C;
                }

                img {
                    width: 100%;
                    object-fit: contain;
                    height: auto;
                }
            }

            .event-footer {
                height: auto;
                padding: 12px 0 !important;
                display: flex;
                justify-content: center;
                align-items: center;

                .event-footer-button {
                    width: auto;
                    height: auto;
                    padding: 9px 15px;
                    margin-left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &::after {
                        margin-left: 13px;
                    }

                    &::before {
                        margin-right: 13px;
                    }
                }
            }
        }

        .modal {
            .modal-dialog.premium-packs-explanation-modal {
                top: 30%;
                transform: translateY(-30%);

                .modal-content {
                    min-height: 170px;

                    .modal-body {
                        padding: 36px 53px 28px 22px;
                    }
                }
            }

            .modal-dialog.payment-type-content-modal {
                .modal-body {
                    border-radius: 17px 17px 0 0 !important;
                    padding: 41px 12px 39px 12px;

                    .payment-type-scrollarea {
                        max-height: calc(100vh - 400px);
                        overflow-y: scroll;
                        overflow-x: hidden;
                        padding-left: 5px;
                        padding-right: 5px;
                    }

                    .payment-type-message {
                        align-items: center;
                        font-size: var(--headerFontSize);
                        line-height: 160%;
                        flex-wrap: wrap;
                        margin-bottom: 21px;
                    }

                    .payment-type-row {
                        padding-left: 10px;
                        padding-right: 10px;

                        .payment-type-col {
                            &-select {
                                max-width: 50%;
                                flex: 0 0 50%;
                            }

                            .payment-type-radio-wrapper {
                                height: auto;

                                .payment-type-radio-content {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        .layout-mobile-body-buy-page {
            .layout-mobile-body-top {
                margin-top: 45px;

                .layout-subheader {
                    text-align: center;
                    padding-top: 17px;
                    margin-bottom: 0;
                    display: block !important;
                    border-bottom: none;

                    .layout-subheader-title {
                        padding: 0;
                        margin: 0;
                        font-size: var(--largeFontSize);
                        font-weight: 600;
                        line-height: 136%;
                        font-family: 'Open Sans';
                    }


                }
            }

            .layout-mobile-body-main {
                padding-left: 0px !important;
                padding-right: 0px !important;
                padding-top: 11px !important;

                .layout-subheader-title {
                    margin: 0;
                    padding: 0;
                    margin-left: 12px;
                }

                div.subheader-menu {
                    padding: 18px 7px 0px 13px;
                    display: flex;
                    flex-wrap: nowrap;
                    column-gap: 15px;
                    overflow: auto hidden;
                    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

                    &::-webkit-scrollbar {
                        height: 4px;
                    }

                    .layout-subheader-link {
                        margin: 0;

                        button.layout-subheader-link-text {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: var(--defaultFontSize);
                            line-height: 136%;
                            color: #ffffff;
                            white-space: nowrap;
                            padding: 0px 0px 3px 0;
                            margin: 0;
                        }
                    }
                }

                .tab-content.buy-page-mobile-tab-content {
                    height: calc(100% - 100px);
                    overflow: hidden auto;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    margin-right: 5px;

                    .buy-page-mobile-tab {
                        min-height: 100%;
                        flex-direction: column;
                        justify-content: center;

                        .buy-page-end-time-section {
                            margin-bottom: 12px;
                            margin-top: 15px !important;

                            &>div {
                                display: inline-flex !important;
                                border: 1px solid rgba(255, 255, 255, 0.1);
                                border-radius: 22px;
                                width: auto;
                                padding: 6px 16px;

                                .mr-1 {
                                    font-size: var(--largeFontSize);
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 160%;
                                    color: #FFFFFF;
                                }

                                .mr-1+div {
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: var(--largeFontSize);
                                    line-height: 160%;
                                    color: #FFFFFF;
                                }
                            }

                        }

                        .buy-page-mobile-row {
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: center;
                            align-items: center;
                            margin-left: 0;
                            margin-right: 0;
                            margin-bottom: 15px;
                            column-gap: 22px;
                            margin-top: 20px;

                            .col {
                                padding-left: 0;
                                padding-right: 0;
                                display: inline-flex;
                                flex-direction: column;
                                align-items: center;
                                flex: unset;
                                max-width: unset;
                                width: auto;

                                .buy-page-coins-imageWrapper {
                                    cursor: pointer;
                                    height: auto;

                                    img {
                                        width: 80px;
                                        height: auto;
                                        margin: 0 auto;
                                    }

                                    .buy-page-coins-bonus-image {
                                        transform: none;
                                        top: -15px;
                                        left: 25px;
                                    }
                                }

                                .buy-page-coins-wrapper {
                                    top: 0;
                                    display: flex;
                                    align-items: center;
                                    padding: 2px 22px;
                                    background: rgba(0, 0, 0, 0.3);
                                    border-radius: 88px;

                                    img {
                                        margin-right: 6px;
                                    }

                                    .buy-page-coins-amount {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 160%;
                                    }
                                }

                                .buy-page-coins-button-wrapper {
                                    top: 0;

                                    .layout-darkHighlighted-button {
                                        padding: 4px 8px;
                                        margin-top: 3px;
                                        display: block;

                                        &::before {
                                            margin-right: 5px;
                                        }

                                        &::after {
                                            margin-left: 5px;
                                        }
                                    }

                                    .layout-darkHighlighted-button+.layout-redHighlighted-button {
                                        margin-top: 3px;
                                        padding: 4px 8px;
                                        font-weight: 700;
                                        line-height: 136%;
                                        display: block;

                                        &::before {
                                            margin-right: 5px;
                                        }

                                        &::after {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .buy-page-mobile-tab.active {
                        display: flex;
                    }

                    .premium-packs-mobile-tab {
                        min-height: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .buy-page-row {
                            transform: unset;
                            top: 0;
                            display: flex;
                            flex-wrap: wrap;
                            row-gap: 10px;
                            margin-top: 0;
                            left: 0;
                            margin-bottom: unset;
                            width: 86%;
                            justify-content: space-evenly;

                            .col {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .buy-page-premium-imageWrapper {
                                    cursor: pointer;
                                    height: auto;

                                    img {
                                        width: 80px;
                                        object-fit: contain;
                                        height: auto;
                                        margin: 0 auto;
                                    }
                                }

                                .buy-page-premium-wrapper {
                                    top: -20px;

                                    .buy-page-premium-amount {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: var(--defaultFontSize);
                                        line-height: 160%;
                                        color: #FFFFFF;
                                    }

                                    .buy-page-info-imageWrapper {
                                        margin-left: 0px;
                                        width: auto;
                                        height: auto;
                                        top: -15px;

                                        @media only screen and (max-width: 900px) {
                                            top: -46px;
                                            right: 34%;
                                        }

                                        img {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            z-index: 9;
                                        }
                                    }
                                }

                                .buy-page-premium-button-wrapper {
                                    top: -17px;
                                    display: inline-flex;
                                    flex-direction: column;
                                    row-gap: 3px;
                                    align-items: center;
                                    margin: auto;
                                    width: 100%;
                                    padding-left: 10%;
                                    padding-right: 10%;

                                    .layout-darkHighlighted-button {
                                        margin-top: 0;
                                        padding: 4px 8px;
                                        line-height: 136%;
                                        width: 100%;

                                        &::before {
                                            margin-right: 5px;
                                        }

                                        &::after {
                                            margin-left: 5px;
                                        }
                                    }

                                    .layout-darkHighlighted-button+.layout-redHighlighted-button {
                                        padding: 4px 8px;
                                        font-weight: 700;
                                        line-height: 136%;
                                        width: 100%;

                                        &::before {
                                            margin-right: 5px;
                                        }

                                        &::after {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .transaction-history-tab {
                        padding: 11px 6px 11px 21px;
                        justify-content: flex-start;

                        .layout-body-main-scrollarea {
                            .transaction-history-table-row {
                                background-color: rgba($color: #fff, $alpha: 0.03);
                                border-radius: 12px;
                                margin-bottom: 1px;

                                .first-line {
                                    display: flex;
                                    padding-left: 9px;
                                    padding-right: 8px;
                                    padding-top: 8px;
                                    justify-content: space-between;

                                    .transaction-history-table-col {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: var(--defaultFontSize);
                                        line-height: 160%;
                                        color: #FFC900;

                                        img {
                                            width: 22px;
                                            object-fit: contain;
                                        }
                                    }

                                    .transaction-history-table-col+.transaction-history-table-col {
                                        color: #FFFFFF;
                                    }
                                }

                                .second-line {
                                    display: flex;
                                    justify-content: flex-end;
                                    flex-wrap: wrap;
                                    column-gap: 8px;
                                    padding-right: 7px;
                                    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);

                                    .transaction-history-table-col {
                                        white-space: nowrap;
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 160%;
                                        color: rgba($color: #ffffff, $alpha: 0.7);
                                        font-size: var(--largeFontSize);

                                        span {
                                            white-space: nowrap;
                                            font-size: var(--defaultFontSize);
                                            color: rgba($color: #ffffff, $alpha: 0.5);
                                        }

                                        span+span {
                                            white-space: nowrap;
                                            font-size: var(--largeFontSize);
                                            color: rgba($color: #ffffff, $alpha: 0.7);
                                        }
                                    }

                                }

                                .third-line {
                                    .transaction-history-table-col {
                                        padding-top: 4px;
                                        padding-bottom: 3px;

                                        .player-history-table-col-negative {
                                            margin: 0 auto;
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: var(--defaultFontSize);
                                            line-height: 160%;
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                            background: #D2110A;
                                            padding: 6px 13px;
                                        }

                                        .player-history-table-col-positive {
                                            margin: 0 auto;
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: var(--defaultFontSize);
                                            line-height: 160%;
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                            background: #009C10;
                                            padding: 6px 13px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .redeem-code-tab {
                        min-height: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .buy-page-redeemGiftCode {
                            .buy-page-redeemGiftCode-section {
                                display: flex;
                                flex-direction: column;
                                row-gap: 16px;

                                .buy-page-redeemGiftCode-section-message {
                                    width: auto;
                                    height: auto;

                                    .buy-page-redeemGiftCode-section-message-label {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: var(--headerFontSize);
                                        line-height: 136%;
                                        color: #FFFFFF;

                                        p {
                                            margin: 0;
                                            font-weight: 400;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-content1 {
                                            color: #fff;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-content2 {
                                            color: #fff;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-code {
                                            color: #FFC900;
                                        }
                                    }
                                }

                                .buy-page-redeemGiftCode-section-inputCode {
                                    width: auto;
                                    height: auto;

                                    .buy-page-redeemGiftCode-section-inputCode-col {
                                        .new-input-second-placeholder {
                                            padding-top: 0;
                                            padding-bottom: 0;
                                        }

                                        .inputInlineMessage-error {
                                            left: 9px;
                                        }

                                        .inputInlineMessage-success {
                                            left: 9px;
                                        }
                                    }
                                }

                                .buy-page-redeemGiftCode-section-buttonSubmit {
                                    width: auto;
                                    height: auto;

                                    .buy-page-redeemGiftCode-section-buttonSubmit-col {
                                        .buy-page-redeemGiftCode-section-buttonSubmit-col-button {
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 700;
                                            font-size: var(--largeFontSize);
                                            line-height: 136%;
                                            text-transform: uppercase;
                                            color: #000000;
                                            padding: 9px 15px;
                                            width: auto;
                                            height: auto;

                                            &::after {
                                                margin-left: 13px;
                                            }

                                            &::before {
                                                margin-right: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }


            }
        }

        .discount-event-section {
            display: none;
        }

        .discount-event-section.discount-event-section-mobile {
            background: #FFC900;
            width: 321px !important;
            height: auto;
            right: 128px !important;
            display: block !important;
            bottom: 0px !important;


            .event-header {
                padding: 12px 20px !important;
                height: auto;

                .event-title {
                    font-weight: 600;
                    line-height: 1.35;
                    color: #0C0C0C;
                }

                .events-header-close {
                    background-image: url('../../../../images/redesign/mobile/icons/close-icon.svg');
                    color: transparent;
                    background-repeat: no-repeat;
                    background-color: rgba($color: #000000, $alpha: 0.7);
                    background-position: center;
                    opacity: 1;
                    right: 4px;
                    top: 4px;
                }

                .event-sub-title {

                    time,
                    label {
                        font-size: var(--defaultFontSize);
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        line-height: 160%;
                        margin-bottom: 0;
                    }
                }


            }

            .event-body {
                width: 100%;
                height: 22vh;
                text-align: left;
                overflow: hidden auto;

                .event-description {
                    margin-left: 20px;
                    font-size: var(--defaultFontSize);
                    font-weight: 400;
                    font-family: 'Open Sans';
                    line-height: 160%;
                    color: #0C0C0C;
                }

                img {
                    width: 100%;
                    object-fit: contain;
                    height: auto;
                }
            }

            .event-footer {
                height: auto;
                padding: 12px 0 !important;
                display: flex;
                justify-content: center;
                align-items: center;

                .event-footer-button {
                    width: auto;
                    height: auto;
                    padding: 9px 15px;
                    margin-left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &::after {
                        margin-left: 13px;
                    }

                    &::before {
                        margin-right: 13px;
                    }
                }
            }
        }

        .modal {
            .modal-dialog.premium-packs-explanation-modal {
                top: 30%;
                transform: translateY(-30%);

                .modal-content {
                    min-height: 170px;

                    .modal-body {
                        padding: 36px 53px 28px 22px;
                    }
                }
            }

            .modal-dialog.payment-type-content-modal {
                .modal-body {
                    border-radius: 17px 17px 0 0 !important;
                    padding: 21px 12px 2px 12px;

                    &>div {
                        height: calc(33vh);
                        overflow: hidden auto;
                    }

                    .payment-type-message {
                        align-items: center;
                        font-size: var(--headerFontSize);
                        line-height: 160%;
                        flex-wrap: wrap;
                        margin-bottom: 21px;
                    }

                    .payment-type-row {
                        padding-left: 0;
                        padding-right: 0;
                        width: 100%;
                        margin: 0 auto;

                        .payment-type-col {
                            max-width: 50%;
                            flex: 0 0 50%;

                            .payment-type-radio-wrapper {
                                height: auto;

                                .payment-type-radio-content {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
                .modal-footer {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }
        }
    }
}

.root-class-design20-normal {
    @media screen and (orientation : portrait) {
        .layout-mobile-body-buy-page {
            .layout-mobile-body-main {
                .tab-content.buy-page-mobile-tab-content {
                    .buy-page-mobile-tab {

                        .buy-page-mobile-row {
                            .col {
                                .buy-page-coins-button-wrapper {
                                    .layout-darkHighlighted-button+.layout-redHighlighted-button {
                                        background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%) !important;

                                        &::before {
                                            background-image: url('../../../../images/redesign/common/karavs.svg');
                                        }

                                        &::after {
                                            background-image: url('../../../../images/redesign/common/karavs.svg');
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .premium-packs-mobile-tab {

                        .buy-page-row {
                            .col {
                                .buy-page-premium-wrapper {
                                    .buy-page-premium-amount {
                                        color: #FFD34E;
                                    }

                                }
                            }
                        }
                    }

                    .transaction-history-tab {
                        .layout-body-main-scrollarea {
                            .transaction-history-table-row {

                                .third-line {
                                    .transaction-history-table-col {
                                        .player-history-table-col-negative {
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                        }

                                        .player-history-table-col-positive {
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                        }
                                    }
                                }
                            }
                        }
                    }

                }


            }
        }

        .discount-event-section {
            display: none;
        }
    }

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        .layout-mobile-body-buy-page {
            .layout-mobile-body-main {
                .tab-content.buy-page-mobile-tab-content {
                    .buy-page-mobile-tab {

                        .buy-page-mobile-row {
                            .col {
                                .buy-page-coins-button-wrapper {
                                    .layout-darkHighlighted-button+.layout-redHighlighted-button {
                                        background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%) !important;

                                        &::before {
                                            background-image: url('../../../../images/redesign/common/karavs.svg');
                                        }

                                        &::after {
                                            background-image: url('../../../../images/redesign/common/karavs.svg');
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .premium-packs-mobile-tab {

                        .buy-page-row {
                            .col {
                                .buy-page-premium-wrapper {
                                    .buy-page-premium-amount {
                                        color: #FFD34E;
                                    }

                                }
                            }
                        }
                    }

                    .transaction-history-tab {
                        .layout-body-main-scrollarea {
                            .transaction-history-table-row {

                                .third-line {
                                    .transaction-history-table-col {
                                        .player-history-table-col-negative {
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                        }

                                        .player-history-table-col-positive {
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                        }
                                    }
                                }
                            }
                        }
                    }

                }


            }
        }

        .discount-event-section {
            display: none;
        }
    }
}

.root-class-design20-light {
    @media screen and (orientation : portrait) {
        .layout-mobile-body-buy-page {
            .layout-mobile-body-main {
                div.subheader-menu {
                    border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                    .layout-subheader-link {
                        button.layout-subheader-link-text {
                            color: #0c0c0c;
                        }
                    }
                }

                .tab-content.buy-page-mobile-tab-content {
                    .buy-page-mobile-tab {

                        .buy-page-end-time-section {
                            &>div {
                                border: 1px solid rgba(0, 0, 0, 0.1);

                                .mr-1 {
                                    color: #0c0c0c;
                                }

                                .mr-1+div {
                                    color: #0c0c0c;
                                }
                            }

                        }

                        .buy-page-mobile-row {
                            .col {
                                .buy-page-coins-wrapper {
                                    background: rgba(0, 0, 0, 0.3);

                                    .buy-page-coins-amount {
                                        color: #fff !important;
                                    }
                                }

                                .buy-page-coins-button-wrapper {
                                    .layout-redHighlighted-button {
                                        margin-top: 3px;
                                        padding: 4px 8px;
                                        font-weight: 700;
                                        line-height: 136%;
                                        background: rgba(255, 255, 255, 0.4) !important;
                                        color: #0C0C0C !important;
                                        white-space: nowrap;

                                        &::before {
                                            margin-right: 5px;
                                            background-image: url('../../../../images/redesign/common/karavs.svg');

                                        }

                                        &::after {
                                            margin-left: 5px;
                                            background-image: url('../../../../images/redesign/common/karavs.svg');

                                        }
                                    }

                                    .layout-darkHighlighted-button+.layout-redHighlighted-button {
                                        background: rgba(255, 255, 255, 0.4) !important;
                                        color: #0C0C0C !important;

                                        &::before {
                                            margin-right: 5px;
                                            background-image: url('../../../../images/redesign/common/karavs.svg');

                                        }

                                        &::after {
                                            margin-left: 5px;
                                            background-image: url('../../../../images/redesign/common/karavs.svg');

                                        }
                                    }
                                }
                            }
                        }
                    }


                    .premium-packs-mobile-tab {
                        .buy-page-row {
                            .col {
                                .buy-page-premium-wrapper {
                                    text-align: center;

                                    .buy-page-premium-amount {
                                        color: #0C0C0C;
                                    }
                                }

                                .buy-page-premium-button-wrapper {
                                    .layout-redHighlighted-button {
                                        &::before {
                                            text-decoration-line: unset;
                                        }

                                        &::after {
                                            text-decoration-line: unset;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .transaction-history-tab {
                        .layout-body-main-scrollarea {
                            .transaction-history-table-row {
                                background-color: rgba($color: #000, $alpha: 0.03);

                                .first-line {
                                    .transaction-history-table-col {
                                        color: #FF8A1A;
                                    }

                                    .transaction-history-table-col+.transaction-history-table-col {
                                        color: #000000;
                                    }
                                }

                                .second-line {
                                    border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                                    .transaction-history-table-col {
                                        color: rgba($color: #000, $alpha: 0.7);

                                        span {
                                            color: rgba($color: #000, $alpha: 0.5);
                                        }

                                        span+span {
                                            color: rgba($color: #000, $alpha: 0.7);
                                        }
                                    }

                                }

                                .third-line {
                                    .transaction-history-table-col {
                                        .player-history-table-col-negative {
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                        }

                                        .player-history-table-col-positive {
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .redeem-code-tab {
                        .buy-page-redeemGiftCode {
                            .buy-page-redeemGiftCode-section {
                                .buy-page-redeemGiftCode-section-message {
                                    .buy-page-redeemGiftCode-section-message-label {
                                        color: #0C0C0C;

                                        .buy-page-redeemGiftCode-section-message-label-content1 {
                                            color: #0C0C0C;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-content2 {
                                            color: #0C0C0C;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-code {
                                            color: #FF8A1A;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }


            }
        }

        .discount-event-section.discount-event-section-mobile {
            background: #FFC900;
            width: calc(100vw - 57px);
            height: auto;
            left: 50%;
            transform: translateX(-50%);
            right: unset !important;
            display: block !important;
            bottom: 0px !important;


            .event-header {
                padding: 12px 20px !important;
                height: auto;

                .event-title {
                    font-weight: 600;
                    line-height: 1.35;
                    color: #0C0C0C;
                }

                .events-header-close {
                    background-image: url('../../../../images/redesign/mobile/icons/close-icon.svg');
                    color: transparent;
                    background-repeat: no-repeat;
                    background-color: rgba($color: #000000, $alpha: 0.7);
                    background-position: center;
                    opacity: 1;
                    right: 4px;
                    top: 4px;
                }

                .event-sub-title {

                    time,
                    label {
                        font-size: var(--defaultFontSize);
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        line-height: 160%;
                        margin-bottom: 0;
                    }
                }


            }

            .event-body {
                width: 100%;
                height: 34vh;
                text-align: left;
                overflow: hidden auto;

                .event-description {
                    margin-left: 20px;
                    font-size: var(--defaultFontSize);
                    font-weight: 400;
                    font-family: 'Open Sans';
                    line-height: 160%;
                    color: #0C0C0C;
                }

                img {
                    width: 100%;
                    object-fit: contain;
                    height: auto;
                }
            }

            .event-footer {
                height: auto;
                padding: 12px 0 !important;
                display: flex;
                justify-content: center;
                align-items: center;

                .event-footer-button {
                    width: auto;
                    height: auto;
                    padding: 9px 15px;
                    margin-left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &::after {
                        margin-left: 13px;
                    }

                    &::before {
                        margin-right: 13px;
                    }
                }
            }
        }

        .modal {
            .modal-dialog.premium-packs-explanation-modal {

                .modal-content {

                    .modal-body {}

                    .modal-body+img {
                        filter: invert(1);
                    }
                }
            }

            .modal-dialog.payment-type-content-modal {
                .modal-footer+img {
                    filter: invert(1);
                }
            }
        }
    }

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        .layout-mobile-body-buy-page {
            .layout-mobile-body-main {
                div.subheader-menu {
                    border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                    .layout-subheader-link {
                        button.layout-subheader-link-text {
                            color: #0c0c0c;
                        }
                    }
                }

                .tab-content.buy-page-mobile-tab-content {
                    .buy-page-mobile-tab {

                        .buy-page-end-time-section {
                            &>div {
                                border: 1px solid rgba(0, 0, 0, 0.1);

                                .mr-1 {
                                    color: #0c0c0c;
                                }

                                .mr-1+div {
                                    color: #0c0c0c;
                                }
                            }

                        }

                        .buy-page-mobile-row {
                            .col {
                                .buy-page-coins-wrapper {
                                    background: rgba(0, 0, 0, 0.3);

                                    .buy-page-coins-amount {
                                        color: #fff !important;
                                    }
                                }

                                .buy-page-coins-button-wrapper {
                                    .layout-redHighlighted-button {
                                        margin-top: 3px;
                                        padding: 4px 8px;
                                        font-weight: 700;
                                        line-height: 136%;
                                        background: rgba(255, 255, 255, 0.4) !important;
                                        color: #0C0C0C !important;

                                        &::before {
                                            margin-right: 5px;
                                            background-image: url('../../../../images/redesign/common/karavs.svg');

                                        }

                                        &::after {
                                            margin-left: 5px;
                                            background-image: url('../../../../images/redesign/common/karavs.svg');

                                        }
                                    }

                                    .layout-darkHighlighted-button+.layout-redHighlighted-button {
                                        background: rgba(255, 255, 255, 0.4) !important;
                                        color: #0C0C0C !important;

                                        &::before {
                                            margin-right: 5px;
                                            background-image: url('../../../../images/redesign/common/karavs.svg');

                                        }

                                        &::after {
                                            margin-left: 5px;
                                            background-image: url('../../../../images/redesign/common/karavs.svg');

                                        }
                                    }
                                }
                            }
                        }
                    }


                    .premium-packs-mobile-tab {
                        .buy-page-row {
                            .col {
                                .buy-page-premium-wrapper {
                                    text-align: center;

                                    .buy-page-premium-amount {
                                        color: #0C0C0C;
                                    }
                                }

                                .buy-page-premium-button-wrapper {
                                    .layout-redHighlighted-button {
                                        &::before {
                                            text-decoration-line: unset;
                                        }

                                        &::after {
                                            text-decoration-line: unset;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .transaction-history-tab {
                        .layout-body-main-scrollarea {
                            .transaction-history-table-row {
                                background-color: rgba($color: #000, $alpha: 0.03);

                                .first-line {
                                    .transaction-history-table-col {
                                        color: #FF8A1A;
                                    }

                                    .transaction-history-table-col+.transaction-history-table-col {
                                        color: #000000;
                                    }
                                }

                                .second-line {
                                    border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                                    .transaction-history-table-col {
                                        color: rgba($color: #000, $alpha: 0.7);

                                        span {
                                            color: rgba($color: #000, $alpha: 0.5);
                                        }

                                        span+span {
                                            color: rgba($color: #000, $alpha: 0.7);
                                        }
                                    }

                                }

                                .third-line {
                                    .transaction-history-table-col {
                                        .player-history-table-col-negative {
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                        }

                                        .player-history-table-col-positive {
                                            color: rgba($color: #FFFFFF, $alpha: 1);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .redeem-code-tab {
                        .buy-page-redeemGiftCode {
                            .buy-page-redeemGiftCode-section {
                                .buy-page-redeemGiftCode-section-message {
                                    .buy-page-redeemGiftCode-section-message-label {
                                        color: #0C0C0C;

                                        .buy-page-redeemGiftCode-section-message-label-content1 {
                                            color: #0C0C0C;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-content2 {
                                            color: #0C0C0C;
                                        }

                                        .buy-page-redeemGiftCode-section-message-label-code {
                                            color: #FF8A1A;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }


            }
        }

        .modal {
            .modal-dialog.premium-packs-explanation-modal {

                .modal-content {

                    .modal-body {}

                    .modal-body+img {
                        filter: invert(1);
                    }
                }
            }

            .modal-dialog.payment-type-content-modal {
                .modal-footer+img {
                    filter: invert(1);
                }
            }
        }
    }
}
