
// @import "../../redesignVariables";
$mobileLgSize: 1012px;
$mobileSmSize: 574px;
.root-class-design20 {

  .cookies-new-section {
    position: fixed;
    left: 10px;
    top: unset !important;
    width: 550px;
    height: auto;
    bottom: 10px;
    z-index: 999999999;

    & .modal-body {
      padding-left: unset !important;
      padding-right: unset !important;
    }

    &-complete-cookies-body {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    &-complete-cookies-footer {
      width: 100%;
      height: 151px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 10px;
    }

    &-cookies-body {
      width: 100%;
      text-align: left;
      padding: 0px !important;
      color: #fff;
      border-bottom: 1px solid #868686;

      &-header {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        & .cookies-icon {
          width: auto;
          position: relative;
          &-clubs {
            position: absolute;
            right: 10px;
            top: 50px;
          }
          &-spades {
            position: absolute;
            right: 8px;
            top: 29px;
          }
          &-diamonds {
            position: absolute;
            right: 0px;
            top: 39px;
          }
        }
      }
      &-rating-stars {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & label {
          font-size: var(--headerFontSize);
        }

        & .rating-stars-section {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        & .rating-stars-point-section {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 50px;

          & span {
            font-size: var(--xxLargeFontSize);
          }
        }

        & .rating-stars-point-label {
          justify-content: center;
          align-items: center;
          display: flex;
          margin-top: 10px;
        }
      }
      &-rating-comments-section {
        padding: 10px 30px 0 30px;
        & p {
          margin-bottom: 0;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: var(--headerFontSize);
          line-height: 160%;
          color: #FFFFFF;
        }
        & label{
          color: #FFC900;
          cursor: pointer;
          font-size: var(--largeFontSize);
        }
      }

      &-rating-comments {
        position: relative;
        left: 0px;
        top: 0px;
        height: 82px;
        margin-top: 30px;
        background: #fff;
        border: 1px solid #868686;
        box-sizing: border-box;
        border-radius: 25px;

        & textarea {
          width: 100%;
          height: 80%;
          /* border: 1px solid #868686; */
          box-sizing: border-box;
          border-radius: 25px;
          border: none;
        }

        & label {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: var(--smallFontSize);
          line-height: 160%;
          text-align: right;
          color: #868686;
          position: absolute;
          right: 15px;
        }

        &-limited-characters-error {
          color: #a81211;
        }

        &-inputInlineMessage-danger {
          width: auto;
          height:  17px;
          position: absolute;
          bottom: -8px;
          left: 15px;
          font-size: var(--smallFontSize);
          background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
          border-radius: 99px;
          padding: 1px 6px 2px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          color: #fff;
          line-height: normal;
        }

        &-inputInlineMessage-success {
          width: auto;
          height:  17px;
          position: absolute;
          top: 26px;
          font-size: var(--smallFontSize);
          background: #009C10;
          border-radius: 99px;
          padding: 1px 6px 2px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          color: #fff;
          line-height: normal;
        }
      }
    }

    &-cookies-footer {
      width: 100%;
      display: block;
      height: 71px;
      padding: 12px 12px 20px 0px;
      column-gap: 13px;
      display: flex;
      justify-content: center;
      align-items: center;

      & .btn {
        justify-content: center;
        align-items: center;
        display: inline-block;
        vertical-align: middle;

        background: $footerButtonBackground;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4),
          0px 4px 20px rgba(0, 0, 0, 0.25),
          inset 0px 1px 0px rgba(255, 255, 255, 0.4);
        border-radius: 99px;

        color: $footerButtonColor;

        font-family: Open Sans;
        font-style: normal;
        font-weight: 700;
        font-size: var(--defaultFontSize);
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
        word-wrap: wrap;
        padding: 6px 15px;
        // width: 250px;
        cursor: pointer;
        &:before {
          display: inline-block;
          content: ' ';
          background-image: url('../../../../images/redesign/common/karavs.svg');
          background-size: 7px 7px;
          height: 7px;
          width: 7px;
          margin-right: 13px;
          margin-left: 0;
          vertical-align: middle;
          margin-bottom: 3px;
        }

        &:after {
          display: inline-block;
          content: ' ';
          background-image: url('../../../../images/redesign/common/karavs.svg');
          background-size: 7px 7px;
          height: 7px;
          width: 7px;
          margin-right: 0;
          margin-left: 13px;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }

      &-submit-button {

        /* Inside auto layout */

        flex: none !important;
        order: 0 !important;
        flex-grow: 0 !important;
        & .openPage {
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 0px !important;
        }
      }

      &-close-button {

        // background: rgba(0, 0, 0, 0.4) !important;
        background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)!important;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
        box-sizing: border-box !important;
        border-radius: 99px !important;
        color: #fff !important;
        /* Inside auto layout */

        flex: none !important;
        order: 1 !important;
        flex-grow: 0 !important;
        & .openPage {
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 0px !important;
        }
      }
    }
  }
  @media only screen and (max-width: $mobileLgSize) {
    & .cookies-new-section {
      bottom: 57px !important;
    }
  }
  @media only screen and (max-width: $mobileSmSize) {
    & .cookies-new-section {
      width: auto !important;
      min-width: 280px !important;
      bottom: 57px !important;
      left: 0px !important;

      & .inline-modal-close {
        right: 1px;
      }
      & .cookies-new-section-cookies-body {
        height: 320px;
      }
      & .cookies-new-section-cookies-footer {
        & button {
          display: flex;
          // width: 155px;
        }
      }
    }
  }

}

