// @import "../../redesignVariables";

// $defaultFontSize: 1.2vmin;

$rowHeight: $menuRowHeight;
$mobileLandScapeWidth: 1280px;

.root-class-design20 {
  @media screen and (orientation : portrait) {
    .top-table-mobile-menu-row-content {
      padding-top: 0px;
      padding-bottom: 0px;
      height: 50px;
    }

    .layout-mobile-body-top-page {

      .layout-mobile-body-main {
        height: calc(100vh - 370px) !important;
        padding: 0 !important;
        margin: 11px 0 0 0 !important;

        &-title {
          display: none;
        }
       
        .top-page-main-body-section {
          height: 100%;
          position: relative;
      
          .scrollArea-buttonUp {
            display: none;
          }
      
          .scrollArea-buttonDown {
            display: none;
          }
      
          .layout-body-main-table-header {
            padding-left: 0px;
            padding-right: 14px;
            padding-bottom: 11px;
            display: inline-block;
            min-width: 100%;
      
            &-wrapper {
              display: inline-flex;
              align-items: center;
              background-color: rgba(#ECECEC, 0.1);
              padding-top: 4px;
              padding-bottom: 4px;
              height: auto;
              position: relative;

              .top-table-row-player-image-frame {
                position: absolute;
                left: 57px;
                top: 5px;
              }

              .top-table-row-text-self {
                &-place {
                  min-width: 85px;
                  max-width: 85px;
                  font-size: var(--largeFontSize);
                  .layout-divider {
                    margin-left: 16px;
                  }
                }
      
                &-player {
                  max-width: 240px;
                  min-width: 200px;
                  position: absolute;
                  left: 95px;
                  top: 3px;
                  font-size: var(--defaultFontSize);
                }
      
                &-level {
                  max-width: 70px;
                  min-width: 70px;
      
                  .my-info-ratings-step-wrapper {
                    justify-content: flex-start;
                  }
                }
      
                &-rating {
                  //max-width: 65px;
                  min-width: 70px !important;
                  margin-top: 19px;
                  & img {
                    height: 2.2vmin !important;
                  }
                }
      
                &-balance {
                  //max-width: 80px;
                  min-width: 103px;
                  margin-top: 19px;
                  & img {
                    height: 2.2vmin !important;
                  }
                }
      
                &-points {
                  //max-width: 60px;
                  min-width: 60px;
                  margin-top: 19px;
                  font-size: var(--defaultFontSize);
                }
      
                &-rounds {
                  //max-width: 60px;
                  min-width: 97px;
                  margin-top: 19px;
                  font-size: var(--defaultFontSize);
                }
      
                &-bgwon {
                  //max-width: 130px;
                  min-width: 75px;
                  margin-top: 19px;
                  font-size: var(--defaultFontSize);
                }
      
                &-tblswon {
                  //max-width: 160px;
                  min-width: 85px;
                  margin-top: 19px;
                  font-size: var(--defaultFontSize);
                }
      
                &-sgwon {
                  //max-width: 220px;
                  min-width: 110px;
                  margin-top: 19px;
                  font-size: var(--defaultFontSize);
                }
      
                &-achvcnt {
                  min-width: 124px;
                  //max-width: 210px;
                  margin-top: 19px;
                  font-size: var(--defaultFontSize);
                }
      
                &-maxrndingame {
                  min-width: 96px;
                  //max-width: 200px;
                  margin-top: 19px;
                  font-size: var(--defaultFontSize);
                }
              }
      
              &>div {
                flex-grow: 1;
                flex-basis: 0;
              }
            }
      
            .top-filter-icons-section {
              opacity: 1;
              width: 4%;
      
              button {
                font-size: var(--largeFontSize);
                opacity: 0.5;
                font-weight: 450;
                font-family: 'Futura PT';
                padding-right: 1px;
              }
      
              .rooms-table-filter-icon {
                opacity: 1;
      
                img {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
      
          .top-page-main-body-wrapper {
            overflow-x: auto;
            overflow-y: hidden;
            //max-height: 100%;
            height: 100%;
            max-height: calc(100% - 37px);
      
            &::-webkit-scrollbar {
              height: 4px;
            }
      
            .top-table-scrollarea-body-header {
              padding: 15px 13px 14px 12px;
              overflow: unset;
              position: relative;
      
              // .layout-body-main-table-header-orderby::after,
              // .layout-body-main-table-header-orderby_asc_active::after,
              // .layout-body-main-table-header-orderby_desc_active::after {
              //   top: 5px;
              // }
      
              .layout-body-main-table-header {
                // &-orderby {
                //   white-space: nowrap;
                //   flex-grow: 1;
                //   flex-basis: 0;
                // }
                &-orderby::after {
                  content: '';
                  background: url('../../../../images/icons/sort.svg');
                  width: 13px;
                  height: 13px;
                  background-repeat: no-repeat;
                  position: absolute;
                  opacity: 1;
                  margin-left: 3px;
                  top: calc(50% - 6.5px);
                }
      
                &-orderby_asc_active {
                  // width: 100%;
                  height: 100%;
                  opacity: 1 !important;
                  color: #FFC900;
                  cursor: pointer;
                }
      
                &-orderby_asc_active::after {
                  content: '';
                  background: url('../../../../images/icons/sort_active.svg');
                  width: 13px;
                  height: 13px;
                  background-repeat: no-repeat;
                  position: absolute;
                  opacity: 1;
                  margin-left: 5px;
                  top: calc(50% - 6.5px);
                }
      
                &-orderby_desc_active {
                  // width: 100%;
                  height: 100%;
                  opacity: 1 !important;
                  color: #FFC900;
                  cursor: pointer;
                }
      
                &-orderby_desc_active::after {
                  content: '';
                  background: url('../../../../images/icons/sort_active.svg');
                  width: 13px;
                  height: 13px;
                  background-repeat: no-repeat;
                  position: absolute;
                  opacity: 1;
                  transform: scaleY(-1);
                  margin-left: 5px;
                  top: calc(50% - 6.5px);
                }
      
                &-place {
                  min-width: 85px;
                  max-width: 85px;
                  font-size: var(--largeFontSize);
                }
      
                &-player {
                  max-width: 65px;
                  min-width: 65px;
                }
      
                &-level {
                  max-width: 80px;
                  min-width: 80px;
                }
      
                &-rating {
                  //max-width: 60px;
                  min-width: 70px !important;
                }
      
                &-balance {
                  //max-width: 60px;
                  min-width: 103px;
                }
      
                &-block {
                  //max-width: 60px;
                  min-width: 120px;
                }

                &-mobile-friend {
                  //max-width: 60px;
                  min-width: 60px;
                }

                &-points {
                  //max-width: 60px;
                  min-width: 60px;
                }
      
                &-rounds {
                  //max-width: 220px;
                  min-width: 97px;
                }
      
                &-bgwon {
                  //max-width: 130px;
                  min-width: 75px;
                }
      
                &-tblswon {
                  //max-width: 160px;
                  min-width: 85px;
                }
      
                &-sgwon {
                  //max-width: 220px;
                  min-width: 110px;
                }
      
                &-achvcnt {
                  min-width: 124px;
                  //max-width: 210px;
                }
      
                &-maxrndingame {
                  min-width: 96px;
                  //max-width: 200px;
                }
              }
            }
      
            .top-table-custom-scroll-body {
              overflow-y: hidden;
              overflow-x: hidden;
              display: inline-block;
              margin-right: 4px;
              width: calc(100% - 4px);
              width: auto;
              position: unset;
              height: calc(100% - 110px) !important;
      
              .scrollbar-container.vertical {
                top: 5.5vw;
                right: 4px;
              }
      
              &::-webkit-scrollbar {}
      
              &::-webkit-scrollbar-thumb {
                display: none;
              }
      
              .menu-table-row-wrapper {
                padding-left: unset !important;
                padding-right: 14px;
                padding-bottom: 1px;
                display: inline-flex;
                height: 45px;
              }
      
              .menu-table-row-inner-wrapper {
                .top-table-row {
                  overflow: unset;
                  position: relative;
      
                  &-text {
                    &-place {
                      min-width: 85px;
                      max-width: 85px;
                      font-size: var(--defaultFontSize);
      
                      .layout-divider {
                        margin-left: 16px;
                      }
                      position: relative;

                      .top-table-row-player-image-frame {
                        position: absolute;
                        left: 47px;
                        top: -9px;
                      }
                    }
      
                    &-player {
                      max-width: 240px;
                      min-width: 200px;
                      position: absolute;
                      left: 85px;
                      top: -3px;
                    }
      
                    &-level {
                      max-width: 120px;
                      min-width: 100px;
      
                      .my-info-ratings-step-wrapper {
                        justify-content: flex-start;
                      }
                    }
      
                    &-rating {
                      //max-width: 65px;
                      min-width: 70px !important;
                      margin-top: 16px;

                      & img {
                        height: 2.2vmin !important;
                      }
                    }
      
                    &-balance {
                      //max-width: 80px;
                      min-width: 103px;
                      margin-top: 16px;

                      & img {
                        height: 2.2vmin !important;
                      }
                    }
      
                    &-points {
                      //max-width: 60px;
                      min-width: 60px;
                      margin-top: 20px;
                    }
      
                    &-rounds {
                      //max-width: 60px;
                      min-width: 97px;
                      margin-top: 20px;
                    }
      
                    &-bgwon {
                      //max-width: 130px;
                      min-width: 75px;
                      margin-top: 20px;
                    }
      
                    &-tblswon {
                      //max-width: 160px;
                      min-width: 85px;
                      margin-top: 20px;
                    }
      
                    &-sgwon {
                      //max-width: 220px;
                      min-width: 110px;
                      margin-top: 20px;
                    }
      
                    &-achvcnt {
                      min-width: 124px;
                      //max-width: 210px;
                      margin-top: 20px;
                    }
      
                    &-maxrndingame {
                      min-width: 96px;
                      //max-width: 200px;
                      margin-top: 20px;
                    }
      
                    &-block {
                      max-width: 60px;
                      min-width: 60px;
                      // position: absolute;
                      // right: 5px;
                      // flex: 0;
                      position: unset !important;
                    }

                    &-mobile-friend {
                      max-width: 60px;
                      min-width: 60px;
                      // position: absolute;
                      // right: 5px;
                      // flex: 0;
                      position: unset !important;
                    }
      
                  }
      
                  &>div {
                    flex-grow: 1;
                    flex-basis: 0;
                  }
                }
      
      
              }
      
            }
          }

          .top-table {
            background-color: $bodyTopBackgroundColour;
            // opacity: $bodyTopBackgroundOpacity;
        
            &-table {
              background-color: $bodyTopBackgroundColour;
              // opacity: $bodyTopBackgroundOpacity;
            }
        
            &-scrollarea {
              max-height: calc(100% - 40px);
              // height: auto!important;
              // max-height: 100%;
        
              &-body {
                height: auto;
        
                &-header.bg-black {
                  background-color: rgba($color: #000, $alpha: 0.95);
                }
        
                &-header {
                  white-space: wrap;
                  word-wrap: break-word;
                  overflow: hidden;
                  // height: 40px;
                  // background-color: rgba(0, 0, 0, 0.95);
                  padding-left: 34px;
                  padding-right: 34px;
                  display: flex;
                  flex-wrap: unset !important;
        
                  & .menu-table-row-text {
                    border-right: 1px solid rgba(255, 255, 255, 0.1);
                    width: 6.66%;
                    min-width: 60px;
                    text-align: center;
                  }
                }
        
                & .menu-table-row-inner-wrapper {
        
                  &>div {
        
                    &>.menu-table-row-text-last {
                      border-right: 0 !important;
                    }
        
                    &>.menu-table-row-text {
                      border-right: 1px solid rgba(255, 255, 255, 0.1);
                      width: 6.66%;
                      min-width: 60px;
                      text-align: center;
        
                      & .menu-table-row-text-positive {
                        background: #009C10;
                        border-radius: 44px;
                        width: max-content;
                        padding-left: 1.5vmin;
                        padding-right: 1.5vmin;
                      }
        
                      & .menu-table-row-text-negative {
                        background: #D2110A;
                        border-radius: 44px;
                        width: max-content;
                        padding-left: 1.5vmin;
                        padding-right: 1.5vmin;
                      }
                    }
                  }
                }
              }
            }
        
            &-millionaires-link {
              padding: 0 8px;
              line-height: 38px;
              // opacity: $disabledLinkOpacity;
              // color: rgba(255, 255, 255, 0.5);
            }
        
            &-active-link {
              color: #FFC900;
              opacity: 1;
            }
        
            &-row {
              //  opacity: 0.03;
              //  transition: opacity 3s linear;
              display: flex;
              vertical-align: middle;
        
              height: 100%;
              align-items: center;
              //  min-height: 42px;
        
              &-button-wrapper {
                text-align: right;
              }

              &-text {
                vertical-align: middle;
                display: inline-block;
                text-overflow: ellipsis;
        
                height: $rowHeight;
                line-height: $rowHeight;
                position: relative;
                white-space: nowrap;
                width: 99px;
                overflow: hidden;
        
                &-sm {
                  width: 6.66%;
                  // min-width: 60px;
                }
        
                &-slm {
                  width: 8.66%;
                  // min-width: 90px;
                }
        
                &-md {
                  width: 10%;
                  // min-width: 100px;
                }
        
                &-lgg {
                  width: 16.66%;
                  // min-width: 160px;
                }
        
                &-lsg {
                  width: 12.66%;
                  // min-width: 120px;
                }
        
                &-lmg {
                  width: 15.66%;
                  // min-width: 150px;
                }
        
                ;
              }
        
              &-player {
                vertical-align: middle;
                display: inline-block;
        
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;
        
                min-height: 36px;
        
                &-block {
                  &-light {
                    height: 3.4vmin;
                    width: 3.4vmin;
                    margin-top: 12px;
                    overflow: visible;
                    min-height: 58px;
                    min-width: 58px;
                    margin-left: -8px;
                    cursor: pointer;
                  }

                  &-normal,
                  &-dark {
                    height: 3.4vmin;
                    width: 3.4vmin;
                    margin-top: 1px;
                    overflow: visible;
                    min-height: 39px;
                    min-width: 39px;
                    margin-left: 6px;
                    cursor: pointer;
                  }

                }

                &-unblock {
                  &-light {
                    height: 3.4vmin;
                    width: 3.4vmin;
                    margin-top: 7px;
                    overflow: visible;
                    min-height: 65px;
                    min-width: 65px;
                    margin-left: -12px;
          
                    cursor: pointer;
                  }

                  &-normal,
                  &-dark {
                    height: 3.4vmin;
                    width: 3.4vmin;
                    margin-top: 1px;
                    overflow: visible;
                    min-height: 39px;
                    min-width: 39px;
                    margin-left: 6px;
          
                    cursor: pointer;
                  }

                }

                &-mobile-friend {
                  height: 3.4vmin;
                  width: 3.4vmin;
                  margin-top: 8px;
                  min-height: 60px;
                  min-width: 60px;
                  margin-right: -10px;
                  cursor: pointer;
        
                  cursor: pointer;
                }
        
                &-image {
                  width: 36px;
                  height: 36px;
        
                  min-width: 36px;
                  min-height: 36px;
        
                  border-radius: 50%;
        
                  &-default {
                    padding: 2px;
                  }
        
                  &-frame {
                    width: 36px;
                    height: 36px;
        
                    min-width: 36px;
                    min-height: 36px;
        
                    margin-right: 4px;
                    vertical-align: middle;
                    border-radius: 50%;
                    //  background-color: #FFF;
                    background-color: rgba(255, 255, 255, 0.2);
                    //  opacity: 0.2;
                    display: inline-block;
                    position: relative;
                  }
                }
              }
            }
          }
        }

        .top-search-popover {
          background-color: #000;
          color: #fff;
          border: 1px solid #fff;
          -webkit-box-shadow: 5px 5px 0px 0px #380000;
          -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 5px 5px 0px 0px #380000;
      
          &-body {
            color: #fff;
          }
        }
      
        .top-search-popover-light {
          background-color: #fff !important;
          color: $lightTextColour !important;
          border: 1px solid rgba(0, 0, 0, 0.25) !important;
          -webkit-box-shadow: 5px 5px 0px 0px #868686 !important;
          -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75) !important;
          box-shadow: 5px 5px 0px 0px #868686 !important;
      
          & .top-search-popover-body {
            color: $lightTextColour !important;
          }
        }
      }
    }

    .top-filter-dropdown {
      //top: calc(100vh - 385px) !important;
      //width: 300px !important;

      & .modal-content {
        min-height: unset !important;

        & .modal-body {
          padding-top: 15px !important;
        }
      }
      & .modal-header {
        display: none;
      }

      & .mobile-close {
        display: none;
      }
      & button {
        color: #fff;
      }

      & button:hover {
        color: #000;
      }
    }

    .top-time-dropdown {
      // top: calc(100vh - 300px) !important;
      // left: 30% !important;
      width: 236px !important;

      & .modal-content {
        min-height: unset !important;

        & .modal-body {
          padding-top: 15px !important;
        }
      }
      & .modal-header {
        display: none;
      }

      & .mobile-close {
        display: none;
      }
      & button {
        color: #fff;
      }
      & button:hover {
        color: #000;
      }
    }
    .top-search-dropdown {
      // top: calc(100vh - 220px) !important;
      // left: 30% !important;
      width: 236px !important;

      & .modal-content {
        min-height: unset !important;

        & .modal-body {
          padding-top: 15px !important;
        }
      }
      & .modal-header {
        display: none;
      }

      & .mobile-close {
        display: none;
      }
      & button {
        color: #fff !important;
      }
      & button:hover {
        color: #000;
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .top-table-mobile-menu-row-content {
      padding-top: 0px;
      padding-bottom: 0px;
      height: 50px;
    }
    .layout-mobile-body-top-page {

      .layout-mobile-body-main {
        &-title {
          display: block !important;
          //color: #FF8A1A;
          font-size: var(--largeFontSize);
          font-weight: 600;
        }
        .top-page-main-body-section {
          position: relative;
      
          .scrollArea-buttonUp {
            display: none;
          }
      
          .scrollArea-buttonDown {
            display: none;
          }
      
          .layout-body-main-table-header {
            padding-left: 0px;
            padding-right: 14px;
            padding-bottom: 11px;
            display: inline-block;
            min-width: 100%;
      
            &-wrapper {
              display: inline-flex;
              align-items: center;
              background-color: rgba(#ECECEC, 0.1);
              padding-top: 4px;
              padding-bottom: 4px;
              height: auto;
              position: relative;

              .top-table-row-player-image-frame {
                position: absolute;
                left: 57px;
                top: 5px;
              }

              .top-table-row-text-self {
                &-place {
                  min-width: 85px;
                  max-width: 85px;
                  font-size: var(--defaultFontSize);
                  .layout-divider {
                    margin-left: 16px;
                  }
                }
      
                &-player {
                  max-width: 240px;
                  min-width: 200px;
                  position: absolute;
                  left: 95px;
                  top: -2px;
                  font-size: var(--defaultFontSize);
                }
      
                &-level {
                  max-width: 70px;
                  min-width: 70px;
      
                  .my-info-ratings-step-wrapper {
                    justify-content: flex-start;
                  }
                }
      
                &-rating {
                  //max-width: 65px;
                  min-width: 184px !important;
                  margin-top: 13px;
                  & img {
                    height: 2.2vmin !important;
                  }

                  & .menu-table-row-player-icon-wrapper {
                    display: flex;
                    align-items: center;
                  }
                }
      
                &-balance {
                  //max-width: 85px;
                  min-width: 103px;
                  margin-top: 13px;
                  & img {
                    height: 2.2vmin !important;
                  }
                }
      
                &-points {
                  //max-width: 60px;
                  min-width: 60px;
                  margin-top: 16px;
                  font-size: var(--defaultFontSize);
                }
      
                &-rounds {
                  //max-width: 60px;
                  min-width: 97px;
                  margin-top: 16px;
                  font-size: var(--defaultFontSize);
                }
      
                &-bgwon {
                  //max-width: 130px;
                  min-width: 75px;
                  margin-top: 16px;
                  font-size: var(--defaultFontSize);
                }
      
                &-tblswon {
                  //max-width: 160px;
                  min-width: 85px;
                  margin-top: 16px;
                  font-size: var(--defaultFontSize);
                }
      
                &-sgwon {
                  //max-width: 220px;
                  min-width: 110px;
                  margin-top: 16px;
                  font-size: var(--defaultFontSize);
                }
      
                &-achvcnt {
                  min-width: 124px;
                  //max-width: 210px;
                  margin-top: 16px;
                  font-size: var(--defaultFontSize);
                }
      
                &-maxrndingame {
                  min-width: 96px;
                  //max-width: 200px;
                  margin-top: 16px;
                  font-size: var(--defaultFontSize);
                }
              }
      
              &>div {
                flex-grow: 1;
                flex-basis: 0;
              }
            }
      
            .top-filter-icons-section {
              opacity: 1;
              width: 4%;
      
              button {
                font-size: var(--largeFontSize);
                opacity: 0.5;
                font-weight: 450;
                font-family: 'Futura PT';
                padding-right: 1px;
              }
      
              .rooms-table-filter-icon {
                opacity: 1;
      
                img {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
      
          .top-page-main-body-wrapper {
            overflow-x: auto;
            overflow-y: hidden;
            //max-height: 100%;
            height: 100%;
            max-height: calc(100% - 37px);

            &::-webkit-scrollbar {
              height: 4px;
            }
      
            .top-table-scrollarea-body-header {
              padding: 15px 13px 14px 12px;
              overflow: unset;
              position: relative;
      
              // .layout-body-main-table-header-orderby::after,
              // .layout-body-main-table-header-orderby_asc_active::after,
              // .layout-body-main-table-header-orderby_desc_active::after {
              //   top: 5px;
              // }
      
              .layout-body-main-table-header {
                // &-orderby {
                //   white-space: nowrap;
                //   flex-grow: 1;
                //   flex-basis: 0;
                // }
                &-orderby::after {
                  content: '';
                  background: url('../../../../images/icons/sort.svg');
                  width: 13px;
                  height: 13px;
                  background-repeat: no-repeat;
                  position: absolute;
                  opacity: 1;
                  margin-left: 3px;
                  top: calc(50% - 6.5px);
                }
      
                &-orderby_asc_active {
                  // width: 100%;
                  height: 100%;
                  opacity: 1 !important;
                  color: #FFC900;
                  cursor: pointer;
                }
      
                &-orderby_asc_active::after {
                  content: '';
                  background: url('../../../../images/icons/sort_active.svg');
                  width: 13px;
                  height: 13px;
                  background-repeat: no-repeat;
                  position: absolute;
                  opacity: 1;
                  margin-left: 5px;
                  top: calc(50% - 6.5px);
                }
      
                &-orderby_desc_active {
                  // width: 100%;
                  height: 100%;
                  opacity: 1 !important;
                  color: #FFC900;
                  cursor: pointer;
                }
      
                &-orderby_desc_active::after {
                  content: '';
                  background: url('../../../../images/icons/sort_active.svg');
                  width: 13px;
                  height: 13px;
                  background-repeat: no-repeat;
                  position: absolute;
                  opacity: 1;
                  transform: scaleY(-1);
                  margin-left: 5px;
                  top: calc(50% - 6.5px);
                }
      
                &-place {
                  min-width: 85px;
                  max-width: 85px;
                  font-size: var(--largeFontSize);
                }
      
                &-player {
                  max-width: 65px;
                  min-width: 65px;
                }
      
                &-level {
                  max-width: 80px;
                  min-width: 80px;
                }
      
                &-rating {
                  //max-width: 60px;
                  min-width: 184px !important;
                }
      
                &-balance {
                  //max-width: 65px;
                  min-width: 103px;
                }
      
                &-block {
                  //max-width: 60px;
                  min-width: 120px;
                }

                &-mobile-friend {
                  //max-width: 60px;
                  min-width: 60px;
                }

                &-points {
                  //max-width: 60px;
                  min-width: 60px;
                }
      
                &-rounds {
                  //max-width: 220px;
                  min-width: 97px;
                }
      
                &-bgwon {
                  //max-width: 130px;
                  min-width: 75px;
                }
      
                &-tblswon {
                  //max-width: 160px;
                  min-width: 85px;
                }
      
                &-sgwon {
                  //max-width: 220px;
                  min-width: 110px;
                }
      
                &-achvcnt {
                  min-width: 124px;
                  //max-width: 210px;
                }
      
                &-maxrndingame {
                  min-width: 96px;
                  //max-width: 200px;
                }
              }
            }
      
            .top-table-custom-scroll-body {
              overflow-y: hidden;
              overflow-x: hidden;
              display: inline-block;
              margin-right: 4px;
              width: calc(100% - 4px);
              width: auto;
              position: unset;
              height: calc(100% - 110px) !important;

              .scrollbar-container.vertical {
                top: 5.5vw;
                right: 4px;
              }
      
              &::-webkit-scrollbar {}
      
              &::-webkit-scrollbar-thumb {
                display: none;
              }
      
              .menu-table-row-wrapper {
                padding-left: unset !important;
                padding-right: 14px;
                padding-bottom: 1px;
                display: inline-flex;
                height: 45px;
              }
      
              .menu-table-row-inner-wrapper {
                .top-table-row {
                  overflow: unset;
                  position: relative;
      
                  &-text {
                    &-place {
                      min-width: 85px;
                      max-width: 85px;
                      font-size: var(--defaultFontSize);
      
                      .layout-divider {
                        margin-left: 16px;
                      }
                      position: relative;

                      .top-table-row-player-image-frame {
                        position: absolute;
                        left: 47px;
                        top: -9px;
                      }
                    }
      
                    &-player {
                      max-width: 240px;
                      min-width: 200px;
                      position: absolute;
                      left: 85px;
                      top: -2px;
                    }
      
                    &-level {
                      max-width: 120px;
                      min-width: 100px;
      
                      .my-info-ratings-step-wrapper {
                        justify-content: flex-start;
                      }
                    }
      
                    &-rating {
                      //max-width: 65px;
                      min-width: 184px !important;
                      margin-top: 16px;

                      & img {
                        height: 2.2vmin !important;
                      }
                    }
      
                    &-balance {
                      //max-width: 85px;
                      min-width: 103px;
                      margin-top: 16px;

                      & img {
                        height: 2.2vmin !important;
                      }
                    }
      
                    &-points {
                      //max-width: 60px;
                      min-width: 60px;
                      margin-top: 20px;
                    }
      
                    &-rounds {
                      //max-width: 60px;
                      min-width: 97px;
                      margin-top: 20px;
                    }
      
                    &-bgwon {
                      //max-width: 130px;
                      min-width: 75px;
                      margin-top: 20px;
                    }
      
                    &-tblswon {
                      //max-width: 160px;
                      min-width: 85px;
                      margin-top: 20px;
                    }
      
                    &-sgwon {
                      //max-width: 220px;
                      min-width: 110px;
                      margin-top: 20px;
                    }
      
                    &-achvcnt {
                      min-width: 124px;
                      //max-width: 210px;
                      margin-top: 20px;
                    }
      
                    &-maxrndingame {
                      min-width: 96px;
                      //max-width: 200px;
                      margin-top: 20px;
                    }
      
                    &-block {
                      // position: absolute;
                      // right: 5px;
                      // flex: 0;
                      max-width: 60px;
                      min-width: 60px;
                      position: unset !important;
                    }
      
                    &-mobile-friend {
                      // position: absolute;
                      // right: 5px;
                      // flex: 0;
                      max-width: 60px;
                      min-width: 60px;
                      position: unset !important;
                    }
                  }
      
                  &>div {
                    flex-grow: 1;
                    flex-basis: 0;
                  }
                }
      
      
              }
      
            }
          }

          .top-table {
            background-color: $bodyTopBackgroundColour;
            // opacity: $bodyTopBackgroundOpacity;
        
            &-table {
              background-color: $bodyTopBackgroundColour;
              // opacity: $bodyTopBackgroundOpacity;
            }
        
            &-scrollarea {
              max-height: calc(100% - 40px);
              // height: auto!important;
              // max-height: 100%;
        
              &-body {
                height: auto;
        
                &-header.bg-black {
                  background-color: rgba($color: #000, $alpha: 0.95);
                }
        
                &-header {
                  white-space: wrap;
                  word-wrap: break-word;
                  overflow: hidden;
                  // height: 40px;
                  // background-color: rgba(0, 0, 0, 0.95);
                  padding-left: 34px;
                  padding-right: 34px;
                  display: flex;
                  flex-wrap: unset !important;
        
                  & .menu-table-row-text {
                    border-right: 1px solid rgba(255, 255, 255, 0.1);
                    width: 6.66%;
                    min-width: 60px;
                    text-align: center;
                  }
                }
        
                & .menu-table-row-inner-wrapper {
        
                  &>div {
        
                    &>.menu-table-row-text-last {
                      border-right: 0 !important;
                    }
        
                    &>.menu-table-row-text {
                      border-right: 1px solid rgba(255, 255, 255, 0.1);
                      width: 6.66%;
                      min-width: 60px;
                      text-align: center;
        
                      & .menu-table-row-text-positive {
                        background: #009C10;
                        border-radius: 44px;
                        width: max-content;
                        padding-left: 1.5vmin;
                        padding-right: 1.5vmin;
                      }
        
                      & .menu-table-row-text-negative {
                        background: #D2110A;
                        border-radius: 44px;
                        width: max-content;
                        padding-left: 1.5vmin;
                        padding-right: 1.5vmin;
                      }
                    }
                  }
                }
              }
            }
        
            &-millionaires-link {
              padding: 0 8px;
              line-height: 38px;
              // opacity: $disabledLinkOpacity;
              // color: rgba(255, 255, 255, 0.5);
            }
        
            &-active-link {
              color: #FFC900;
              opacity: 1;
            }
        
            &-row {
              //  opacity: 0.03;
              //  transition: opacity 3s linear;
              display: flex;
              vertical-align: middle;
        
              height: 100%;
              align-items: center;
              //  min-height: 42px;
        
              &-button-wrapper {
                text-align: right;
              }

              &-text {
                vertical-align: middle;
                display: inline-block;
                text-overflow: ellipsis;
        
                height: $rowHeight;
                line-height: $rowHeight;
                position: relative;
                white-space: nowrap;
                width: 99px;
                overflow: hidden;

                &-sm {
                  width: 6.66%;
                  // min-width: 60px;
                }
        
                &-slm {
                  width: 8.66%;
                  // min-width: 90px;
                }
        
                &-md {
                  width: 10%;
                  // min-width: 100px;
                }
        
                &-lgg {
                  width: 16.66%;
                  // min-width: 160px;
                }
        
                &-lsg {
                  width: 12.66%;
                  // min-width: 120px;
                }
        
                &-lmg {
                  width: 15.66%;
                  // min-width: 150px;
                }
        
                ;
              }
        
              &-player {
                vertical-align: middle;
                display: inline-block;
        
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;
        
                min-height: 36px;
        
                &-block {
                  &-light {
                    height: 3.4vmin;
                    width: 3.4vmin;
                    margin-top: 12px;
                    overflow: visible;
                    min-height: 58px;
                    min-width: 58px;
                    margin-left: -8px;
                    cursor: pointer;
                  }

                  &-normal,
                  &-dark {
                    height: 3.4vmin;
                    width: 3.4vmin;
                    margin-top: 1px;
                    overflow: visible;
                    min-height: 39px;
                    min-width: 39px;
                    margin-left: 6px;
                    cursor: pointer;
                  }

                }

                &-unblock {
                  &-light {
                    height: 3.4vmin;
                    width: 3.4vmin;
                    margin-top: 7px;
                    overflow: visible;
                    min-height: 65px;
                    min-width: 65px;
                    margin-left: -12px;
          
                    cursor: pointer;
                  }

                  &-normal,
                  &-dark {
                    height: 3.4vmin;
                    width: 3.4vmin;
                    margin-top: 1px;
                    overflow: visible;
                    min-height: 39px;
                    min-width: 39px;
                    margin-left: 6px;
          
                    cursor: pointer;
                  }

                }

                &-mobile-friend {
                  height: 3.4vmin;
                  width: 3.4vmin;
                  margin-top: 8px;
                  min-height: 60px;
                  min-width: 60px;
                  margin-right: -10px;
                  cursor: pointer;
                }

                &-friend {
                  height: 3.4vmin;
                  width: 3.4vmin;
                  margin-top: 0.1vmin;
                  min-height: 38px;
                  min-width: 38px;
                  margin-right: -2px;
        
                  cursor: pointer;
                }
        
                &-image {
                  width: 36px;
                  height: 36px;
        
                  min-width: 36px;
                  min-height: 36px;
        
                  border-radius: 50%;
        
                  &-default {
                    padding: 2px;
                  }
        
                  &-frame {
                    width: 36px;
                    height: 36px;
        
                    min-width: 36px;
                    min-height: 36px;
        
                    margin-right: 4px;
                    vertical-align: middle;
                    border-radius: 50%;
                    //  background-color: #FFF;
                    background-color: rgba(255, 255, 255, 0.2);
                    //  opacity: 0.2;
                    display: inline-block;
                    position: relative;
                  }
                }
              }
            }
          }
        }

        .top-search-popover {
          background-color: #000;
          color: #fff;
          border: 1px solid #fff;
          -webkit-box-shadow: 5px 5px 0px 0px #380000;
          -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 5px 5px 0px 0px #380000;
      
          &-body {
            color: #fff;
          }
        }
      
        .top-search-popover-light {
          background-color: #fff !important;
          color: $lightTextColour !important;
          border: 1px solid rgba(0, 0, 0, 0.25) !important;
          -webkit-box-shadow: 5px 5px 0px 0px #868686 !important;
          -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75) !important;
          box-shadow: 5px 5px 0px 0px #868686 !important;
      
          & .top-search-popover-body {
            color: $lightTextColour !important;
          }
        }
        
      }
    }

    .top-filter-dropdown {
      & button:hover {
        color: #000;
      }

    }

    .top-time-dropdown {
      & button:hover {
        color: #000;
      }
    }
    .top-search-dropdown {
      & button:hover {
        color: #000;
      }
    }
  }
}

.root-class-design20-light {
  @media screen and (orientation : portrait) {
    .top-search-dropdown {
      & button {
        color: black !important;
      }
      & button:hover {
        color: #000;
      }
    }
    .layout-mobile-body-myinfo-page {

      .layout-mobile-body-main {
        .my-info-gifts-title {
          color: #000;
        }

        .my-info-results-scrollarea-body::-webkit-scrollbar-thumb {
          background-color: #0C0C0C !important;
        }
      }
    }

    .layout-body-main-table-header-orderby::after {
      background-image: url('../../../../images/redesign/light-mode/icons/sort.svg') !important;
      background-repeat: no-repeat;
    }
  
    .layout-body-main-table-header-orderby_asc_active {
      color: #FF8A1A !important;
  
      &::after {
        background-image: url('../../../../images/redesign/light-mode/icons/sort_active.svg') !important;
        background-repeat: no-repeat;
      }
    }

  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .top-search-dropdown {
      & button {
        color: black !important;
      }
      & button:hover {
        color: #000;
      }
    }
    .layout-mobile-body-myinfo-page {

      &-title {
        display: block !important;
        //color: #FF8A1A;
        font-size: var(--largeFontSize);
        font-weight: 600;
      }

      .layout-mobile-body-main {
        .my-info-gifts-title {
          color: #000;
        }
      }

      .my-info-results-scrollarea-body::-webkit-scrollbar-thumb {
        background-color: #0C0C0C !important;
      }
    }


    .layout-body-main-table-header-orderby::after {
      background-image: url('../../../../images/redesign/light-mode/icons/sort.svg') !important;
      background-repeat: no-repeat;
    }
  
    .layout-body-main-table-header-orderby_asc_active {
      color: #FF8A1A !important;
  
      &::after {
        background-image: url('../../../../images/redesign/light-mode/icons/sort_active.svg') !important;
        background-repeat: no-repeat;
      }
    }
  }
}


.root-class-design20 {

  
}

.root-class-design20-dark {
  @media screen and (orientation : portrait) {
    .top-search-dropdown {
      & button {
        color: black !important;
      }
      & button:hover {
        color: black;
      }
    }
  }
  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .top-search-dropdown {
      & button {
        color: black !important;
      }
      & button:hover {
        color: black;
      }
    }
  }

  .top-table-scrollarea-body-header {
    // background-color: rgba($color: #000, $alpha: 0.95);
    color: rgba(255, 255, 255, 0.5);

    &>div {
      font-size: var(--largeFontSize);
      font-family: 'Futura PT';
      font-weight: 450;
    }
  }

  .top-table-custom-scroll-body {
    &::-webkit-scrollbar {
      background-color: #000;
    }
  }

  .layout-dark {
    .layout-body-content {
      background: rgba(0, 0, 0, 0.95);
      border-radius: 17px 17px 0 0;
    }
  }

  .layout-body-main-table-header-wrapper {
    .layout-body-main-table-header-lg {
      color: #fff;
    }
  }


}

.root-class-design20-normal {
  @media screen and (orientation : portrait) {
    .top-search-dropdown {
      & button {
        color: black !important;
      }
      & button:hover {
        color: black;
      }
    }
  }
  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .top-search-dropdown {
      & button {
        color: black !important;
      }
      & button:hover {
        color: black;
      }
    }
  }
  .top-table-custom-scroll-body {
    &::-webkit-scrollbar {
      background-color: rgb(37, 54, 120);
    }
  }

  .layout-normal {
    .layout-body-content {
      background: $normalBodyTopBackgroundColour;
      border-radius: 17px 17px 0 0;
    }
  }
}

.root-class-design20-light {
  .top-table-custom-scroll-body {
    &::-webkit-scrollbar {
      background-color: rgb(236, 236, 236);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  .layout-light {
    .layout-body-content {
      background: $lightBodyTopBackgroundColour;
      border-radius: 17px 17px 0 0;
    }
  }

  .top-page-main-body-section {
    .top-page-main-body-wrapper {
      .layout-body-main-table-header {
        .layout-body-main-table-header-wrapper {
          .top-table-row-text-self-place {
            color: #0C0C0C;
          }

          .top-table-row-text-self-player {
            .top-table-row-text {
              color: #0C0C0C;
            }
          }

          .top-table-row-text-self-rating {
            .menu-table-row-player-icon-text {
              color: #0C0C0C !important;
            }
          }

          .top-table-row-text-self-balance {
            .menu-table-row-player-icon-text {
              color: #0C0C0C !important;
            }
          }

          & > div {
            color: #0C0C0C !important;
          }

        }
      }
    }
  }
}

.top-table-row-text-lg:last-of-type {
  width: 14%;
}

.root-class-design20.root-class-design20-light {
  .layout-body-main-table-header-wrapper {
    background-color: #a2a2a2;
  }
}

.root-class-design20-dark {
  .top-table-row-text-self-place {
    color: rgba($color: #fff, $alpha: 0.5);
  }
}