// @import "../../redesignVariables";

$rowHeight: $menuRowHeight;

//$buttonPrimaryColour: #FFF;
//$buttonSecondaryColour: #000;
//$buttonBackgroundColour: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);

.root-class-design20 {

  .tournaments-empty-msg-new {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    font-size: var(--mLargeFontSize);
    font-weight: normal;
  }

  .tournaments-tutorial-modal.modal-dialog {
    .tournaments-tutorial-table-scrollarea-new {
      height: calc(100vh - 350px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .tournament {

    &-header-tutorial {
      width: 56px;
      height: 56px;
      margin-top: 2px;
      cursor: pointer;
      // opacity: $disabledLinkOpacity;
      float: right;
    }

    &-scrollarea {
      max-height: calc(100% - 55px);
      height: auto;
      overflow: auto;
      width: calc(100% - 4px);

      & .menu-table-row-wrapper {
        height: auto !important;
      }
    }

    &-state {
      border: 1px solid rgba(255, 255, 255, 0.7);
      text-align: center;
      text-transform: uppercase;
      padding-left: 1vmin;
      padding-right: 1vmin;
      display: inline-block;
      line-height: 2vmin;

      &-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      &-pending {
        background: #FFFFFF;
        border-radius: 4px;
        color: $ratingDarkColor;
      }

      &-registration {
        background: #019245;
        border-radius: 4px;
        color: $ratingLightColor;
      }

      &-active {
        background: #BE1D2C;
        border-radius: 4px;
        color: $ratingLightColor;
      }

      &-closed {
        background: #868686;
        border-radius: 4px;
        color: $ratingDarkColor;
      }
    }

    &-table {

      &-header {
        &-row {
          padding-left: 30px;
        }
      }

      &-row {
        padding-left: 15px;
        height: auto;

        & .active-tournaments {
          background: rgba(255, 255, 255, 0.03);
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          margin-bottom: 8px;
          width: 99%;
          height: auto !important;

          &-info {
            display: flex;
            flex-direction: column;

            &-section {
              display: flex;
              flex-direction: column;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              margin-bottom: 10px;
              position: relative;

              &-title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 7px 17px 0px 17px;

                &-left {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
                  position: relative;
                  top: 50%;

                  &-img {
                    width: 30px;
                    margin-right: 10px;
                    cursor: pointer;
                  }

                  &-title {
                    font-weight: 600;
                    font-size: var(--largeFontSize);
                    line-height: 160%;
                    color: #FFC900;
                    margin-right: 10px;
                  }

                  &-private {
                    font-weight: 600;
                    font-size: var(--defaultFontSize);
                    line-height: 160%;
                    color: #FFFFFF;
                  }
                }

                &-right {
                  &-content {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 6px 1px;
                    grid-gap: 10px;
                    gap: 10px;
                    background: #D2110A;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: var(--smallFontSize);
                    line-height: 160%;
                  }
                }
              }

              &-time {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 0px 17px 5px 17px;

                &-rounds {
                  font-weight: 400;
                  font-size: var(--smallFontSize);
                  color: #FFFFFF;
                  opacity: 0.5;
                  margin-right: 5px;
                }

                &-roundsValue {
                  font-weight: 600;
                  font-size: var(--mSmallFontSize);
                  color: #FFFFFF;
                  margin-right: 5px;
                }

                &-label {
                  font-weight: 400;
                  font-size: var(--smallFontSize);
                  color: #FFFFFF;
                  opacity: 0.5;
                  margin-right: 5px;
                }

                &-content {
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  color: #FFFFFF;
                  opacity: 0.7;
                }
              }
            }

            &-section::after {
              height: 23px;
              width: 100%;
              content: ' ';
              opacity: 0.2;
              position: absolute;
              bottom: -23px;
              z-index: 1;
              background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, #000 100%);
              position: absolute;
              bottom: -23px;
            }


            &-button {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              margin-bottom: 12px;
              z-index: 2;

              & .notification-footer-button {
                margin: unset !important;
                height: 40px;
              }
            }
          }

          &-sponsor {
            display: flex;
            justify-content: center;
            align-items: center;
            // border-top: 1px solid rgba(255, 255, 255, 0.1);
            position: relative;

            &-section {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin-top: 12px;
              margin-bottom: 12px;

              & span {
                margin-bottom: 4px;
              }

              & img {
                width: 60%;
                border-radius: 6px;
              }
            }

            &-two::after {
              content: ' ';
              width: 1px;
              height: 100%;
              position: absolute;
              border-right: 1px solid rgba(255, 255, 255, 0.1);
            }
          }

          & .plan-tournaments-info-section-title-right {
            & div {
              background: #019245;
              color: #fff;
            }


            &-confirmed {
              & div {
                background: #FFFFFF;
                color: #000;
              }
            }
          }

          & .history-tournaments-info-section-title-right {
            & div {
              background: #868686;
              color: #000000;
              font-family: 'Open Sans';
              font-weight: 600;
              font-size: var(--defaultFontSize);
              line-height: 160%;
              text-transform: uppercase;
              padding: 1px 6px;
              border-radius: 5px;
            }
          }

        }
      }

      &-col {

        text-align: left;

        &-faded {
          opacity: 0.5;
        }

        &-parties {
          text-align: center;
        }

        &-name {
          color: $highlightedTextColor;
        }
      }

      &-button-col {
        padding-top: 2px;
        padding-right: 2px;
        float: right;
      }

      &-button-left-col {
        padding-top: 2px;
        padding-right: 2px;
        float: left;
      }
    }

    &-players {

      &-scrollarea {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 400px);
        min-height: 300px;
      }

      &-player {
        height: 280px;
        min-height: 280px;
      }

      & .tournament-player-info {
        position: absolute;
        width: 100%;
        height: 100%;

        &-background {
          width: 100%;
          height: 100%;

          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center bottom;
        }

        &-player {
          position: absolute;
          bottom: 5%;
          width: 70%;
          margin-left: 15%;
          margin-right: 15%;

          //  display: none;

          &-background {
            position: relative;
          }

          &-image {
            //  position: relative;

            background-size: cover;
            background-repeat: no-repeat;
            //  width: 100%;
            //  height: 100%;

            box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
            filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            margin: 12px;
            border-radius: 50%;

            &-background {
              position: absolute;
              width: 100%;
              height: 100%;
              background-image: url('../../../../images/redesign/player/UserFrame.png');
              background-size: cover;
              background-repeat: no-repeat;
              top: 0;
            }

            &-overlay {
              position: absolute;
              width: 100%;
              height: 100%;
              background-image: url('../../../../images/redesign/player/UserOverlay.png');
              background-size: cover;
              background-repeat: no-repeat;
              top: 0;
            }

            &-wrapper {
              position: relative;
              width: 50%;
              min-width: 140px;
              max-width: 18vmin;
              margin-left: auto;
              margin-right: auto;

              &:before {
                content: "";
                display: block;
                padding-top: 100%;
                /* initial ratio of 1:1*/
              }
            }


          }

          &-name {
            display: inline-block;
            justify-content: center;
            align-items: center;
            padding: 7px 20px;

            /* Black */
            color: $buttonPrimaryColour;
            background: $buttonSecondaryColour;
            border-radius: 88px;
            width: 100%;

            &-wrapper {
              position: relative;
              text-align: center;
              font-size: var(--headerFontSize);
            }
          }

          &-info-wrapper {
            position: relative;
            text-align: center;
            font-size: var(--headerFontSize);
          }

          &-wrapper {
            position: relative;
            text-align: center;
            font-size: var(--headerFontSize);
            margin-left: auto;
            margin-right: auto;

            padding: 2px 10px;
            color: #FFF;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 88px;
          }

          &-balance {
            display: inline-block;
            position: relative;
            text-align: center;
            font-size: var(--defaultFontSize);
            //  float: left;

            color: $buttonPrimaryColour;
            padding-right: 3px;
            //  width: 6vmin;
            //  background: $buttonSecondaryColour;

            &-icon {
              display: inline-block;
              text-align: center;
              width: auto;
              height: $headerFontSize;
              padding-left: 3px;
              padding-right: 3px;
              //  float: left;
            }
          }

          &-rating {
            display: inline-block;
            position: relative;
            text-align: center;
            font-size: var(--defaultFontSize);
            //  float: left;

            color: $buttonPrimaryColour;

            //  width: 6vmin;
            //  background: $buttonSecondaryColour;
            &-icon {
              display: inline-block;
              text-align: center;
              width: auto;
              height: $headerFontSize;
              padding-left: 3px;
              padding-right: 3px;
              //  float: left;
            }
          }
        }

      }
    }

  }

  .tournaments-participate-content {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tournament-icon {
    display: inline-block;
    padding-left: 2px;
  }

  .menu-topTab-tournament {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tournament-info-section {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 40px;
    padding-right: 30px;

    &-main {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 20px;
        padding: 10px 20px 10px 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        font-weight: 400;
        font-size: var(--largeFontSize);
        line-height: 160%;
        color: #FFFFFF;
        opacity: 1;
      }

      &-row:last-child {
        border-bottom: none;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;

      &-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        & span {
          font-weight: 600;
          font-size: var(--largeFontSize);
          line-height: 140%;
          color: #ECECEC;
          margin-bottom: 3px;
        }

        & img {
          border: 1px solid #FFFFFF;
          box-shadow: 4px 4px 0px #380000;
          border-radius: 12px;
        }
      }
    }
  }

}

.root-is-app-frame {
  .tournament-players-scrollarea {
    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
      min-height: 150px;
      height: calc(100vh - 250px);
    }
    @media screen and (orientation : portrait) {
      min-height: 200px;
    }
  }
}

.root-is-not-app-frame {
  .tournament-players-scrollarea {
    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
      min-height: 75px;
      height: calc(100vh - 255px) !important;
    }
    // @media screen and (orientation : portrait) {
    //   height: calc(100vh - 250px) !important;
    // }
  }
}

.future-tournaments-empty-entries {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--mLargeFontSize);
  font-weight: 400;
  height: 100%;
}

.tournament-header-tutorial {
  transform: scale(1.5);
}

.tournament-table-row .active-tournaments-info-section-title-left-img {
  transform: scale(2.5);
}

.root-class-design20-light {
  .tournament-header-tutorial {
    transform: scale(1);
  }

  .tournament-table-row .active-tournaments-info-section-title-left-img {
    transform: scale(1.5);
  }
}

.tournament-player-info-userlevel-badge {
  font-family: 'Futura PT';
  font-weight: 700;
  font-size: var(--xxxLargeFontSize);
  line-height: 1.28;
  text-align: center;
  background: linear-gradient(180deg, #333333 0%, #121212 100%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  text-shadow: 1px 1px 0 #fff;
  -webkit-text-stroke: 1px white;
  color: #000;
}

.tournament-player-info-userlevel-badge-1 {
  top: -12px;
  z-index: 100;
}

.tournament-player-info-userlevel-badge-2 {
  top: 10px;
}

.tournament-player-info-userlevel-badge-3 {
  top: 26px;
}

.tournament-leaderboard-highlight {
  &-light {
    background-color: rgba(0,0,0,0.2) !important;
    border-radius: 20px;
  }
  &-normal,
  &-dark {
    background-color: rgba(255,255,255,0.2) !important;
    border-radius: 20px;
  }
}
.tournament-scrollarea.tournament-leaderboard {
  //max-height: calc(100% - 90px);
  max-height: 19vh;
  background-color: unset !important;

  .menu-table-row-wrapper {
    .menu-table-row-inner-wrapper {
      // background-color: rgba(255, 255, 255, 0.03) !important;
      padding-left: 15px;
      padding-right: 15px;

      .row {
        >div:first-of-type {
          color: rgba($color: #fff, $alpha: 0.5);
          font-size: var(--largeFontSize);
          font-weight: 450;
          font-family: 'Futura PT';

          &::before {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/karavs.svg');
            background-size: 8px 8px;
            background-repeat: no-repeat;
            background-position: center;
            width: 8px;
            margin-right: 5.38px;
            margin-left: 3px;
            vertical-align: middle;
            height: 8px;
          }
        }

        .col,
        >div {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: var(--defaultFontSize);
          list-style: 160%;
          padding-top: 12.5px;
          padding-bottom: 12.5px;
        }
      }
    }
  }
}

.tournament-leaderboard-table-wrapper {
  .tournament-table-header {
    &>div {
      font-family: 'Futura PT';
      font-weight: 450;
      font-size: var(--largeFontSize);
      line-height: 1.29;
    }
  }
}

.root-class-design20-light {
  .tournament-leaderboard-table-wrapper {
    .tournament-table-header.layout-body-main-table-header {
      background-color: unset;
    }
  }
}

.root-class-design20-light {
  .tournament-scrollarea.tournament-leaderboard {
    .menu-table-row-wrapper {
      .menu-table-row-inner-wrapper {
        .row {
          >div:first-of-type {
            color: #0C0C0C;
          }
        }
      }
    }
  }
}

.tournament-player-info-background-1 {
  &::before {
    position: absolute;
    left: -46px;
    top: -36px;
    content: ' ';
    background-image: url('../../../../images/redesign/components/modal/modal_stars.png');
    background-size: 65px 65px;
    height: 65px;
    width: 65px;
  }

  &::after {
    position: absolute;
    right: -46px;
    top: -36px;
    content: ' ';
    background-image: url('../../../../images/redesign/components/modal/modal_stars.png');
    background-size: 65px 65px;
    height: 65px;
    width: 65px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.tournament-player-info-player-glow-1 {
  background-image: url('../../../../images/redesign/bonus-page/wheel-glow.png');
  width: 300px;
  height: 300px;
  background-size: 300px 300px;
  top: -20px;
  left: -20px;
  position: absolute;
}

.tournament-player-info {
  .tournament-player-info-player-image-emotions {
    position: absolute;
    width: 60px;
    background-image: url('../../../../images/redesign/player/emotions.png');
    height: 60px;
    background-repeat: no-repeat;
    top: 10px;
    left: -20px;
  }

  .tournament-player-info-player-image-level {
    width: 100%;
    position: absolute;
    top: 0;

    .tournament-player-info-ratings-step-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tournament-player-info-ratings-step {
      // border: 1px solid rgba(255, 255, 255, 0.7);
      text-align: center;
      padding-left: 6px;
      padding-right: 6px;
      line-height: 160%;
      font-size: var(--mSmallFontSize);
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
      color: #fff;

      &-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      &-weak {
        background: #FFFFFF;
        opacity: 0.3;
        border-radius: 4px;
        color: $ratingDarkColor;
      }

      &-beginner {
        background: #FFF001;
        opacity: 0.3;
        border-radius: 4px;
        color: $ratingDarkColor;
      }

      &-untrained {
        background: #019245;
        opacity: 0.3;
        border-radius: 4px;
        color: $ratingLightColor;
      }

      &-medium {
        background: #662B8D;
        opacity: 0.3;
        border-radius: 4px;
        color: $ratingLightColor;
      }

      &-good {
        background: #293991;
        opacity: 0.3;
        border-radius: 4px;
        color: $ratingLightColor;
      }

      &-great {
        background: #784A28;
        opacity: 0.3;
        border-radius: 4px;
        color: $ratingLightColor;
      }

      &-fan {
        background: #BE1D2C;
        opacity: 0.3;
        border-radius: 4px;
        color: $ratingLightColor;
      }

      &-pro {
        background: #000000;
        opacity: 0.3;
        border-radius: 4px;
        color: $ratingLightColor;
        border: unset;
      }

      &-divider {
        height: 5px;
        width: 2px;
        background: #FFFFFF;
        opacity: 0.2;
      }

      &-active {
        opacity: 1;
      }

      &-name {
        text-align: center;
        color: $ratingNameColor;
        opacity: 0.3;
      }
    }
  }
}

.root-class-design20 {

  .tournament-leaderboard-top3 {
    .tournament-player-info-player-3 {
      .tournament-player-info-player-image-wrapper {
        min-width: 122px;
      }
    }


    .tournament-player-info-player-2 {
      .tournament-player-info-player-image-wrapper {
        min-width: 130px;
      }
    }

    .tournament-player-info-player-1 {
      .tournament-player-info-player-image-wrapper {
        min-width: 145px;
      }
    }

    .tournament-player-info-player-name-wrapper {
      top: -10px;
      margin-bottom: -7px;
    }
  }

}

.root-class-design20 {
  .tournament-table-row {
    .active-tournaments {
      .history-tournaments-info-section-title-right {
        div {
          background-color: #fff;
        }
      }
    }
  }
}

.tournament-scrollarea.tournament-leaderboard {
  .menu-table-row-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.tournament-leaderboard-table-wrapper {
  .layout-body-main-table-header.tournament-table-header {
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
  }
}

.root-class-design20-light {
  .tournament-scrollarea.tournament-leaderboard {
    .menu-table-row-wrapper {
      .menu-table-row-inner-wrapper {
        //background-color: rgba(0, 0, 0, 0.03) !important;
      }
    }
  }

  .tournament-leaderboard-top3 {
    .tournament-player-info-player-name {
      background-color: #fff !important;
      color: #0C0C0C !important;
    }

    .tournament-player-info-player-wrapper {
      background: rgba(255, 255, 255, 0.6) !important;

      &>div {
        color: #000000 !important;
        font-size: var(--largeFontSize);
        line-height: 160%;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
      }
    }

    .tournament-player-info-player-image-background {
      background-image: url('../../../../images/redesign/light-mode/player/UserFrame.png') !important;
      border-radius: 50%;
    }
  }
  
  .tournament-player-info-player-image-background {
    background-image: url('../../../../images/redesign/light-mode/player/UserFrame.png') !important;
    border-radius: 50%;
  }

  .tournament-player-info {
    .tournament-player-info-player-image-emotions {
      background-image: url('../../../../images/redesign/light-mode/player/emotions.svg') !important;
    }

    .tournament-player-info-userlevel-badge {
      background: linear-gradient(180deg, #CDC8C8 0%, #F2F0F0 100%);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      font-weight: 900;
    }
  }
}