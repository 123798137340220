$layoutPrimaryButtonColour: #FFF;
$layoutPrimaryButtonBackground: rgba(0, 0, 0, 0.4);

$layoutHighlightedButtonColour: #000;
$layoutHighlightedButtonBackground: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);

$layoutLinkButtonColour: #FFF;
$layoutLinkButtonBackground: transparent;

$sectionDividerSize: 0.7vmin;

$lightLayoutLinkButtonColour: #0C0C0C;

$layoutRedHighlightedButtonBackground: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
$layoutRedHighlightedButtonColor: #fff;

$layoutdarkHighlightedButtonBackground: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
$layoutdarkHighlightedButtonColor: #fff;
$layoutdarkHighlightedButtonBorderColor: #0C0C0C;
$mobileSmSize: 574px;

$mobileLandScapeWidth: 1280px;

// @import "../redesignVariables";

.root-class-design20-fullscreen-menu {
  width: 99% !important;
  height: 100%;
  //  min-width: 65vw;
  //  min-width: 680px;
  //  min-height: 610px;
  //  min-height: 710px;
  //  max-width: 85vw;
  margin-left: auto;
  margin-right: auto;

  .layout {

    &-body {
      //  max-height: 50vh;
      //  height: 50vh;
      //  min-height: 300px;

      //  &-main {

      //    &-scrollarea {
      //      height: calc(100% - 69px);
      //      min-height: calc(100% - 69px);
      //    }
      //  }
    }
  }
}

@media screen and (max-width: $mobileSmSize) {
  .root-class-design20-fullscreen-menu {
    min-width: auto;
  }

  & .layout-subheader-mobile {
    margin-bottom: 30px;
  }

  & .landing-top-scrollarea-body {
    touch-action: auto;
    margin-bottom: 10px;
  }

  & .contact-support-chat-body-scroll-area-content {
    touch-action: auto;
  }
}
.scrollarea-touch-action {
  touch-action: auto !important;
}
.root-class-design20-light {
  .triangle-down {
    border-top: 6px solid black !important;
  }

  .top-leaderboard-modal {
    &-section {
      &-title {
        color: black !important;
      }

      &-description {
        color: rgb(90, 90, 90) !important;
      }
    }
  }
}
.root-class-design20 {
  min-width: 650px;
  height: 100vh;
  width: 100vw;

  @media all and (orientation: portrait) {
    //  background-size: 100% auto;
    //  height: auto!important;
    width: 100vw !important;
    position: relative;
  }

  .triangle-down {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid white;
    display: inline-flex;
    align-items: center;
    margin-left: 5px;
    margin-bottom: 3px;
  }

  .top-leaderboard-modal {
    &-wrapper {
      position: absolute;
      left: 9vw;
      top: calc(12vh + 70px) !important;

      .modal-body {
        border-radius: 0px !important;
      }
    }

    &-section {
      margin-bottom: 15px;

      &-title {
        font-weight: 700;
        text-align: left;
        font-size: var(--largeFontSize);
        color: white;
        margin-bottom: 9px;
      }

      &-description {
        font-size: var(--defaultFontSize);
        font-weight: 400;
        text-align: left;
        color: rgb(201, 201, 201);
        margin-top: 5px;

        &-first-msg {
          font-weight: 700;
        }
      }

      &-toggle-wrapper {
        margin-bottom: 13px;
      }
    }
  }

  .top-settings-section-language {
    &-new {
      height: unset !important;
      top: -1px !important
    }
  }

  .game-bg-old {
    background-image: url("../../../images/Menu/zole_bg.webp") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .game-bg {
    background-image: url('../../../images/redesign/layout/game screen bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .light-game-bg {
    background-image: url('../../../images/redesign/light-mode/layout/carpet.webp');
  }


  .game-login-bg {
    background-image: url('../../../images/redesign/layout/new_bg_2.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .game-bg.loading-bg::after {
    background-image: url('../../../images/redesign/landing-view/zoleLogo.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 60px;
    height: 100px !important;
    left: calc(100vw / 2 - 20px);
    top: calc(100vh / 3 - 40px);
    content: ' ';
  }

  .lobby-bg-table {
    background-image: url('../../../images/redesign/layout/Table.webp');
    background-size: auto 75%;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    @media all and (orientation: portrait) {
      //  position: absolute;
      //  background-size: 170% auto;
    }
  }

  .lobby-bg-table-chair {
    background-image: url('../../../images/redesign/layout/table_1.webp');
    background-size: 100% 130%;
    background-repeat: no-repeat;
    background-position: 50% -10%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    @media all and (orientation: portrait) {
      //  position: absolute;
      //  background-size: 170% auto;
    }
  }

  .lobby-bg-card {
    background-image: url('../../../images/redesign/layout/card_bg_1.webp');
    box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
    background-size: initial;
    background-repeat: no-repeat;
    background-position: 50% 8%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    opacity: 0.8;

    @media all and (orientation: portrait) {
      //  position: absolute;
      //  background-size: 170% auto;
    }
  }

  .blur-bg-table {
    background-image: url('../../../images/redesign/landing-view/new_bg_1.webp');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    @media all and (orientation: portrait) {
      //  position: absolute;
      //  background-size: 170% auto;
    }
  }

  .game-bg-table {
    background-image: url('../../../images/redesign/layout/table-3-player-c.webp'); //table-3-player



    background-size: auto 75%;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    &-mobile {
      top: -200px;
    }

    @media all and (orientation: portrait) {
      //  position: absolute;
      //  background-size: 170% auto;
    }
  }

  .game-bg-table-four-player {
    background-image: url('../../../images/redesign/layout/table-4-player-c.webp'); //table-4-player
    background-size: auto 83%;
    //  background-image: url('../../../images/redesign/layout/table-3-player-c.png');
    //  background-size: auto 78%;
  }

  .game-bg-table-two-player {
    background-image: url('../../../images/redesign/layout/table-2-player-c.webp'); //table-2-player
    background-size: auto 91%;
  }

  .last-round-popover {
    z-index: 1000 !important;
  }

  .layout {

    &-float-right {
      float: right;
      display: inline-block;
      display: flex;
      flex-direction: row-reverse;
    }

    &-float-left {
      float: left;
      display: inline-block;
    }

    &-offset-left {
      padding-left: 10px;
    }

    &-justify-right {
      justify-content: right;
    }

    &-align-right {
      text-align: right;
    }

    &-divider {
      display: inline-block;
      content: ' ';
      background-image: url('../../../images/redesign/common/karavs.svg');
      background-size: 8px 8px;
      background-repeat: no-repeat;
      background-position: center;
      //  height: $sectionDividerSize;
      width: 8px;
      margin-right: 5px;
      margin-left: 3px;
      vertical-align: middle;
      height: 8px;
    }

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: var(--headerFontSize);
    line-height: 22px;
    position: absolute;
    width: 100%;
    height: 67vh;
    top: 12vh;
    //  max-width: 1800px;
    position: relative;
    margin-left: auto;
    margin-right: auto;


    &-header {
      //  margin-top: 90px;
      //  border: 1px solid yellow;
      text-align: left;
      text-transform: uppercase;
      margin-left: 15px;

      &-right {

        & .top-settings-section {
          float: right;
          opacity: $headerInactiveOpacity;
        }
      }

      &-link {
        display: inline-block;
        margin-right: 19px;
        position: relative;

        &-text {
          color: $headerTextColour;
          opacity: $headerInactiveOpacity;
          cursor: pointer;
          padding-left: 0;
          padding-right: 0;

          &-active {
            opacity: 1;
            border-bottom: 2px solid $headerActiveBorderColour;
          }
        }

        &-notification::after {
          content: '';
          width: 10px;
          height: 10px;
          background-color: rgba(255, 0, 0, 1);
          position: absolute;
          border-radius: 50%;
          margin-top: 3px;
          margin-left: 5px;
          right: -7px;
          top: -3px;
        }
      }
    }

    &-subheader {
      //  margin-top: 90px;
      //  border: 1px solid yellow;
      text-align: left;
      // margin: 0px;
      // margin-left: 15px;
      // margin-right: 15px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 8px;
      padding-top: 12px;
      z-index: 100;
      //  border-bottom: 1px solid gray;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &-title {
        margin-left: 20px;
        margin-top: 10px;
        font-family: 'Futura PT';
        font-style: normal;
        font-weight: 450;
        font-size: var(--xLargeFontSize);
        line-height: 1.27;
        color: #FFC900;
      }

      &-right {

        & .top-settings-section {
          float: right;
          opacity: $headerInactiveOpacity;
        }
      }

      &-title {
        max-width: 50%;
        display: inline-block;
        padding: 5px 8px;
        color: $highlightedTextColor;
        font-size: var(--xLargeFontSize);
        // font-size: 22px;
      }

      &-link {
        display: inline-block;
        margin-left: 5px;
        margin-bottom: -1px;

        &-text {
          color: $headerTextColour;
          opacity: $headerInactiveOpacity;
          margin: 0px 6px;
          padding: 6px 0px;
          cursor: pointer;
          text-decoration: none;
          position: relative;
          font-size: var(--headerFontSize);

          &-active {
            opacity: 1;
            border-bottom: 1px solid $headerActiveBorderColour;
          }

          &-notification::after {
            content: '';
            width: 10px;
            height: 10px;
            background-color: red;
            position: absolute;
            border-radius: 50%;
            margin-top: 3px;
            margin-left: 5px;
            right: 0px;
            top: -4px;
          }
        }

        &:hover {
          color: $headerTextColour;
        }

        &:active {
          color: $headerTextColour;
        }

        &:focus {
          color: $headerTextColour;
        }
      }

      #newGame-button {
        padding: 9px 15px;
        font-weight: 700;
        line-height: 130%;
        font-size: var(--largeFontSize);
        display: flex;
        align-items: center;

        &::before {
          margin-right: 13px;
        }

        &::after {
          margin-left: 13px;
        }
      }

      .top-search-section {
        .custom-dropdown {
          .custom-dropdown-link {
            font-size: var(--largeFontSize);
            margin-top: 1px;
          }
        }
      }
    }

    &-body {
      border-radius: 17px 17px 0 0;
      position: relative;
      height: 100%;
      //  min-height: 300px;
      //  border: 1px solid red;

      font-size: var(--defaultFontSize);

      &-background {
        // background-color: $bodyTopBackgroundColour;
      }

      &-content {
        position: relative;
        //  top: -56px;
        //  padding-top: 56px;
        //  padding-bottom: 85px;
        //  height: 100%;
        //  min-height: 100%;
        //  overflow: hidden;

        height: calc(100% - 56px - 8vh);
        min-height: calc(100% - 56px - 8vh);
        //  padding-left: 15px;
      }

      &-pane {
        height: 100%;
        min-height: 100%;
        position: absolute;
        width: 100%;
        top: 0px;
        // overflow: hidden;
      }

      &-top {
        // background-color: $bodyTopBackgroundColour;
        //  opacity: $bodyTopBackgroundOpacity;
        border-radius: 17px 17px 0 0;
        position: relative;
        min-height: 69px;

        &-light {
          background-color: $lightBodyTopBackgroundColour;
        }
      }

      &-top.layout-body-top-buy-page {
        height: auto;
      }

      &-main {
        // position: relative;
        height: calc(100% - 65px);
        min-height: calc(100% - 65px);
        display: flex;
        flex-direction: column;
        font-size: var(--defaultFontSize);

        @media (max-height: 905px) {
          height: calc(100% - 87px);
          min-height: calc(100% - 87px);
        }

        &-rooms-with-campaign {
          height: calc(100% - 185px);
          min-height: calc(100% - 185px);
        }

        & .spinner-loading-section {
          width: 100%;
          height: 100%;
          min-height: 350px;
          background-color: rgba(0, 0, 0, 0.95);
        }

        &-table-header {
          &-wrapper {
            overflow: hidden;
            width: 100%;
            margin: 0;
            background-color: rgba(0, 0, 0, 0.95);
            ;
            height: 40px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            border-radius: 44px;
            flex-direction: row;

            & > div {
              // opacity: 0.5;
              padding-left: 0px;
              padding-right: 2px;
            }
          }

          &-orderby::after {
            content: '';
            background: url('../../../images/icons/sort.svg');
            width: 13px;
            height: 13px;
            background-repeat: no-repeat;
            position: absolute;
            top: 5px;
            opacity: 1;
            margin-left: 3px;
          }

          &-orderby_asc_active {
            // width: 100%;
            height: 100%;
            opacity: 1 !important;
            color: #FFC900;
            cursor: pointer;
          }

          &-orderby_asc_active::after {
            content: '';
            background: url('../../../images/icons/sort_active.svg');
            width: 13px;
            height: 13px;
            background-repeat: no-repeat;
            position: absolute;
            top: 5px;
            opacity: 1;
            margin-left: 5px;
          }

          &-orderby_desc_active {
            // width: 100%;
            height: 100%;
            opacity: 1 !important;
            color: #FFC900;
            cursor: pointer;
          }

          &-orderby_desc_active::after {
            content: '';
            background: url('../../../images/icons/sort_active.svg');
            width: 13px;
            height: 13px;
            background-repeat: no-repeat;
            position: absolute;
            top: 5px;
            opacity: 1;
            transform: scaleY(-1);
            margin-left: 5px;
          }

          &-slm {
            width: 8.66%;
            // min-width: 90px;
          }

          &-lg-bgWon,
          &-lg-sgWon,
          &-lg-tblsWon,
          &-lg-achvCnt,
          &-lg-maxRndInGame {
            position: relative;
            padding-right: 8px;

            &::after {
              right: 0;
              top: 5px !important;
            }
          }

          &-lgg {
            width: 16.66%;
            // min-width: 100px;
          }

          &-lsg {
            width: 12.66%;
            // min-width: 120px;
          }

          &-lmg {
            width: 15.66%;
            // min-width: 100px;
          }

          ;
        }

        &-scrollarea {
          max-height: calc(100% - 10px);
          width: calc(100% - 4px);
          overflow: auto;
        }

        &-scrollarea + .menu-table-row-filler-out {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: var(--mLargeFontSize);
          font-weight: 400;
        }

        &-row {
        }
      }

      &-ok-button {
        background-color: #171717 !important;
      }

      &-radio {
        &-outer-circle {
          float: right;
          margin: 3px;
          width: 14px;
          height: 14px;
          border: 1px solid #7D7D7D;
          border-radius: 50%;
        }

        &-inner-circle {
          margin: 3px;
          width: 6px;
          height: 6px;
          background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
          border-radius: 50%;
        }
      }

      &-sorting-arrow {
        margin: 5px;
        border: solid #7D7D7D;
        border-width: 0 3px 3px 0;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        float: right;
      }

      &-sorting-arrow-active {
        margin: 5px;
        border: solid red;
        border-width: 0 3px 3px 0;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        float: right;
      }
    }

    &-footer {
      background-color: $bodyTopBackgroundColour;
      //  opacity: $bodyTopBackgroundOpacity;
      border-radius: 0 0 17px 17px;
      //  height: 85px;
      height: 8vh;
      min-height: 106px;
      margin: 0;
      //  margin-left: 15px;

      &-bonus {
        //  background-color: $bodyTopBackgroundColour;
        //  opacity: $bodyTopBackgroundOpacity;
        display: flex;
        justify-content: flex-start;
      }

      &-shop {
        //  background-color: $bodyTopBackgroundColour;
        //  opacity: $bodyTopBackgroundOpacity;
        display: flex;
        justify-content: flex-end;
      }

      &-link {
        text-align: center;
        height: 100%;
        position: relative;

        &-image {
          z-index: 20;
          position: relative;
          height: 75%;
          width: auto;
          max-width: 100%;
          top: 4px;
          margin-right: auto;
          margin-left: auto;
          cursor: pointer;
        }

        &-button {
          z-index: 10;
          justify-content: center;
          align-items: center;
          display: inline-block;
          position: relative;
          top: -25px;
          margin-right: auto;
          margin-left: auto;
          margin-top: -4px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: var(--defaultFontSize);
          line-height: 160%;
          text-transform: uppercase;
          background: $buttonBackgroundColour;
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;
          border-radius: 99px;
          //  height: 30px;

          color: $buttonPrimaryColour;
          //  font-size: 14px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          padding: 6px 15px;
          vertical-align: middle;
          min-width: 94px;
          cursor: pointer;
          min-height: 30px;

          &:before {
            display: inline-block;
            content: ' ';
            background-image: url('../../../images/redesign/common/kreists.svg');
            background-size: 10px 10px;
            height: 10px;
            width: 10px;
            margin-right: 13px;
          }

          &:after {
            display: inline-block;
            content: ' ';
            background-image: url('../../../images/redesign/common/kreists.svg');
            background-size: 10px 10px;
            height: 10px;
            width: 10px;
            margin-left: 13px;
          }
        }
      }
    }

    & .player-info {
      position: absolute;
      width: 100%;
      height: 29vh;

      &-popover {
        z-index: 99999;
      }

      &-background {
        width: 100%;
        height: 100%;
        background-image: url('../../../images/redesign/player/chair-back 1.webp');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center bottom;

        &-shadow {
          position: absolute;
          top: 0;
          background-image: url('../../../images/redesign/player/player-shadow.webp');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 100%;
          //  margin-left: 25%;
          //  margin-right: 25%;
          height: 100%;
          pointer-events: none;

          &-light {
            background: #FFFFFF;
            opacity: 0.5;
            filter: blur(50px);
          }

          &-normal {
            background-image: none;
          }
        }

        &-shadow2 {
          position: absolute;
          top: 22%;
          background-image: url('../../../images/redesign/player/player-shadow2.webp');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 130%;
          margin-left: -10%;
          height: 70%;
          pointer-events: none;

          &-light {
            background: #FFFFFF;
            opacity: 0.5;
            filter: blur(50px);
          }

          &-normal {
            background-image: none;
          }
        }
      }

      &-player {
        position: absolute;
        top: 5%;
        width: 70%;
        margin-left: 15%;
        margin-right: 15%;
        //  display: none;

        &-background {
          position: relative;

          &-active-gift {
            position: absolute;
            left: calc(50% - 174px);
            top: 33px;
            width: 85px;
            height: 85px;
            object-fit: contain;

            &-remove {
              position: absolute;
              left: calc(50% - 100px);
              top: 13px;
              width: 24px;
              height: 24px;
              cursor: pointer;
              border-radius: 50%;
            }
          }
        }

        &-image {
          //  position: relative;

          background-size: cover;
          background-repeat: no-repeat;
          //  width: 100%;
          //  height: 100%;

          box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
          filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: 12px;
          border-radius: 50%;

          @media screen and (max-height: 750px) {
            margin: 10px;
          }

          @media screen and (max-height: 650px) {
            margin: 8px;
          }

          @media screen and (max-height: 550px) {
            margin: 6px;
          }

          &-background {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url('../../../images/redesign/player/UserFrame.png');
            background-size: cover;
            background-repeat: no-repeat;
            top: 0;
          }

          &-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url('../../../images/redesign/player/UserOverlay.png');
            background-size: cover;
            background-repeat: no-repeat;
            top: 0;
          }

          &-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url('../../../images/redesign/player/UserOverlay.png');
            background-size: cover;
            background-repeat: no-repeat;
            top: 0;
            left: 0;
          }

          &-emotions {
            position: absolute;
            width: 60px;
            height: 60px;
            background-image: url('../../../images/redesign/player/emotions.png');
            background-repeat: no-repeat;
            top: 10px;
            left: -20px;
          }

          &-level {
            width: 100%;
            position: absolute;
            top: 0;
          }

          &-wrapper {
            position: relative;
            width: 50%;
            min-width: 85px;
            max-width: 18vmin;
            margin-left: auto;
            margin-right: auto;

            &:before {
              content: "";
              display: block;
              padding-top: 100%;
              /* initial ratio of 1:1*/
            }
          }
        }

        &-name {
          display: inline-block;
          justify-content: center;
          align-items: center;
          padding: 7px 20px;
          /* Black */
          color: $buttonPrimaryColour;
          background: $buttonSecondaryColour;
          border-radius: 88px;

          @media screen and (max-height: 650px) {
            padding: 5px 16px;
          }

          @media screen and (max-height: 550px) {
            padding: 2px 10px;
          }

          &-wrapper {
            position: relative;
            text-align: center;
            font-size: var(--headerFontSize);
            line-height: $headerFontSize;
            margin-top: -10px;
            margin-bottom: 3px;
          }
        }

        &-info-wrapper {
          position: relative;
          text-align: center;
          font-size: var(--headerFontSize);
          margin-left: auto;
          margin-right: auto;
        }

        &-wrapper {
          position: relative;
          text-align: center;
          font-size: var(--headerFontSize);
          margin-left: auto;
          margin-right: auto;

          @media screen and (max-height: 650px) {
            font-size: var(--smallFontSize);
          }
        }

        &-balance {
          display: inline-block;
          position: relative;
          text-align: center;
          font-size: var(--defaultFontSize);
          //  float: left;

          color: $buttonPrimaryColour;
          padding-right: 3px;
          //  width: 6vmin;
          //  background: $buttonSecondaryColour;

          @media screen and (max-height: 650px) {
            font-size: var(--smallFontSize);
          }

          &-orange {
            color: #FF8A1A;
          }

          &-red {
            color: #D2110A;
          }

          &-icon {
            display: inline-block;
            text-align: center;
            width: auto;
            height: $headerFontSize;
            padding-left: 3px;
            padding-right: 3px;
            //  float: left;

            @media screen and (max-height: 650px) {
              height: $smallFontSize;
            }
          }
        }

        &-balance-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          &-section {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &-rating {
          display: inline-block;
          position: relative;
          text-align: center;
          font-size: var(--defaultFontSize);
          //  float: left;

          color: $buttonPrimaryColour;
          //  width: 6vmin;
          //  background: $buttonSecondaryColour;

          @media screen and (max-height: 650px) {
            font-size: var(--smallFontSize);
          }

          &-icon {
            display: inline-block;
            text-align: center;
            width: auto;
            height: $headerFontSize;
            padding-left: 3px;
            padding-right: 3px;
            //  float: left;

            @media screen and (max-height: 650px) {
              height: $smallFontSize;
            }
          }
        }
      }

      &-light {
        & .player-info-player-balance-wrapper-section {
          background: rgba(255, 255, 255, 0.6);
          border-radius: 88px;
        }

        & .player-info-player-balance {
          color: #000;
        }

        & .player-info-player-rating {
          color: #000;
        }

        & .player-info-player-name {
          background: #FFFFFF;
          color: #000;
        }

        & .player-info-player-image-background {
          background-image: url('../../../images/redesign/light-mode/player/UserFrame.png');
          border-radius: 50%;
        }

        & .player-edit-pencil {
          background: linear-gradient(180deg, #FFFFFF 0%, #DFDFDF 100%) !important;
        }
      }

      &-normal {
        & .player-info-player-balance-wrapper-section {
          background: rgba(0, 0, 0, 0.3);
          border-radius: 88px;
        }

        & .player-info-player-balance {
          color: $normalTextColour;
        }

        & .player-info-player-rating {
          color: $normalTextColour;
        }

        & .player-info-player-name {
          background: $normalBackgroundColor;
          color: $normalTextColour;
        }

        & .player-info-player-image-background {
          background-image: url('../../../images/redesign/player/UserFrame.png');
          border-radius: 50%;
        }
      }
    }


    &-player {
      height: 85px;
    }

    &-default-button {
      //  display: flex;
      //  flex-direction: column;
      justify-content: center;
      align-items: center;
      display: inline-block;
      background: $layoutPrimaryButtonBackground !important;
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      border-radius: 99px;
      // height: calc(100% - 4px);

      color: $layoutPrimaryButtonColour;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      padding: 9px 8px;
      // vertical-align: middle;
      // margin-top: 2px;
      // margin-bottom: 2px;

      min-width: 100px;
      //  min-width: 100%;

      &-wrapper {
        height: 100%;
      }

      &:hover {
        border: 1px solid #fff;
        background: $layoutPrimaryButtonBackground;
        text-decoration: none;
      }

      &:active {
        border: 1px solid #fff;
        background: $layoutPrimaryButtonBackground;
        text-decoration: none;
      }
      // &:focus {
      //   background: $layoutPrimaryButtonBackground;
      //   text-decoration: none;
      // }

      &:before {
        display: inline-block;
        content: ' ';
        background-image: url('../../../images/redesign/common/karavs.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        margin-right: 7px;
        float: unset;
        margin-bottom: 3px;
        vertical-align: middle;
      }

      &:after {
        display: inline-block;
        content: ' ';
        background-image: url('../../../images/redesign/common/karavs.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        margin-left: 7px;
        float: unset;
        margin-bottom: 3px;
        vertical-align: middle;
      }

      &-light {
        background: $lightLayoutDefaultButtonBackgroundColor;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: bold;
        color: $lightTextColour;
      }
    }

    &-default-button-important {
      background: $layoutPrimaryButtonBackground !important;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
      box-sizing: border-box !important;
      border-radius: 99px !important;
      color: $layoutPrimaryButtonColour !important;
      font-size: var(--defaultFontSize) !important;
      text-decoration: none !important;
    }

    &-highlighted-button {
      //  display: flex;
      //  flex-direction: column;
      justify-content: center;
      align-items: center;
      display: inline-block;
      vertical-align: middle;
      //  height: 47px;
      height: calc(100% - 4px);
      background: $layoutHighlightedButtonBackground !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4) !important;
      border-radius: 99px;
      color: $layoutHighlightedButtonColour !important;
      //  background: rgba(0, 0, 0, 0.4);
      //  border: 1px solid rgba(255, 255, 255, 0.1);
      //  box-sizing: border-box;
      //  border-radius: 99px;

      font-family: Open Sans;
      font-style: normal;
      font-weight: 900;
      font-size: var(--mSmallFontSize);
      line-height: 23px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      padding: 3px 8px;
      min-width: 15%;

      &:hover {
        background: $layoutHighlightedButtonBackground;
        text-decoration: none;
      }

      &:active {
        background: $layoutHighlightedButtonBackground;
        text-decoration: none;
      }

      &:focus {
        background: $layoutHighlightedButtonBackground;
        text-decoration: none;
      }

      &:before {
        display: inline-block;
        content: ' ';
        background-image: url('../../../images/redesign/common/karavs.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        //  margin-right: 12px;
        //  margin-left: 6px;
        vertical-align: middle;
        float: unset;
        margin-right: 7px;
      }

      &:after {
        display: inline-block;
        content: ' ';
        background-image: url('../../../images/redesign/common/karavs.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        //  margin-right: 6px;
        //  margin-left: 12px;
        vertical-align: middle;
        float: unset;
        margin-left: 7px;
      }
    }


    &-link-button {
      //  display: flex;
      //  flex-direction: column;
      justify-content: center;
      align-items: center;
      display: inline-block;
      background: $layoutLinkButtonBackground;
      //  border: 1px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      //  border-radius: 99px;

      color: $layoutLinkButtonColour;
      font-size: var(--defaultFontSize);
      cursor: pointer;
      //  text-decoration: none;
      //  text-transform: uppercase;

      padding: 5px 8px;
      vertical-align: middle;
      //  margin-top: 3px;

      &:hover {
        background: $layoutLinkButtonBackground;
        text-decoration: none;
      }

      &:active {
        background: $layoutLinkButtonBackground;
        text-decoration: none;
      }

      &:focus {
        background: $layoutLinkButtonBackground;
        text-decoration: none;
      }
    }

    &-link-button {
      justify-content: center;
      align-items: center;
      display: inline-block;
      background: $layoutLinkButtonBackground;
      box-sizing: border-box;
      opacity: $disabledLinkOpacity;
      // color: rgba($layoutLinkButtonColour, $disabledLinkOpacity);
      // color: $layoutLinkButtonColour;
      font-size: var(--largeFontSize);
      line-height: 160%;
      cursor: pointer;
      padding: 5px 8px;
      vertical-align: middle;
      text-decoration: underline;

      &:hover {
        background: $layoutLinkButtonBackground;
        text-decoration: none;
      }

      &:active {
        background: $layoutLinkButtonBackground;
        text-decoration: none;
      }

      &:focus {
        background: $layoutLinkButtonBackground;
        text-decoration: none;
      }
    }

    &-redHighlighted-button {
      justify-content: center;
      align-items: center;
      display: inline-block;
      vertical-align: middle;
      //  height: 47px;
      height: calc(100% - 4px);
      background: $layoutRedHighlightedButtonBackground !important;
      border-radius: 99px;
      color: $layoutRedHighlightedButtonColor !important;
      //  background: rgba(0, 0, 0, 0.4);
      //  border: 1px solid rgba(255, 255, 255, 0.1);
      //  box-sizing: border-box;
      //  border-radius: 99px;

      font-family: Open Sans;
      font-style: normal;
      font-weight: 900;
      font-size: var(--defaultFontSize);
      line-height: 23px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      padding: 3px 8px;
      min-width: 15%;

      &:hover {
        background: $layoutRedHighlightedButtonBackground;
        text-decoration: none;
      }

      &:active {
        background: $layoutRedHighlightedButtonBackground;
        text-decoration: none;
      }

      &:focus {
        background: $layoutRedHighlightedButtonBackground;
        text-decoration: none;
      }

      &:before {
        display: inline-block;
        content: ' ';
        background-image: url('../../../images/redesign/common/kreists.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        //  margin-right: 12px;
        //  margin-left: 6px;
        vertical-align: middle;
        float: unset;
        margin-right: 7px;
      }

      &:after {
        display: inline-block;
        content: ' ';
        background-image: url('../../../images/redesign/common/kreists.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        //  margin-right: 6px;
        //  margin-left: 12px;
        vertical-align: middle;
        float: unset;
        margin-left: 7px;
      }
    }

    &-darkHighlighted-button {
      //  display: flex;
      //  flex-direction: column;
      justify-content: center;
      align-items: center;
      display: inline-block;
      vertical-align: middle;
      //  height: 47px;
      height: calc(100% - 4px);
      background: $layoutdarkHighlightedButtonBackground !important;
      border-radius: 99px;
      border: 1px solid $layoutdarkHighlightedButtonBorderColor !important;
      color: $layoutdarkHighlightedButtonColor !important;
      //  background: rgba(0, 0, 0, 0.4);
      //  border: 1px solid rgba(255, 255, 255, 0.1);
      //  box-sizing: border-box;
      //  border-radius: 99px;

      font-family: Open Sans;
      font-style: normal;
      font-weight: 700;
      font-size: var(--defaultFontSize);
      line-height: 135%;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      margin-top: 9px;
      padding: 6px 15px;
      width: max-content;
      min-width: 15%;

      &:hover {
        background: $layoutdarkHighlightedButtonBackground;
        text-decoration: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
      }

      &:active {
        background: $layoutdarkHighlightedButtonBackground;
        text-decoration: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
      }

      &:focus {
        background: $layoutdarkHighlightedButtonBackground;
        text-decoration: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
      }

      &:before {
        display: inline-block;
        content: ' ';
        background-image: url('../../../images/redesign/common/karavs.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        //  margin-right: 12px;
        //  margin-left: 6px;
        vertical-align: middle;
        float: unset;
        margin-right: 13px;
      }

      &:after {
        display: inline-block;
        content: ' ';
        background-image: url('../../../images/redesign/common/karavs.svg');
        background-size: 7px 7px;
        height: 7px;
        width: 7px;
        //  margin-right: 6px;
        //  margin-left: 12px;
        vertical-align: middle;
        float: unset;
        margin-left: 13px;
      }
    }
  }

  & .bottom-bar {
    position: fixed;
    bottom: $smallFontSize;
    left: 15px;
    width: 85%;
    color: $primaryTextColour;
    font-size: var(--smallFontSize);

    &-links {
      & a {
        background: #000;
        background: rgba(0,0,0,$disabledLinkOpacity);
        padding: 5px 5px;

        & span {
          font-size: var(--defaultFontSize);
          opacity: $disabledLinkOpacity;
        }
      }

      & a:first-child {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      & a:last-child {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
  }

  & .game-clock {
    position: fixed;
    bottom: 16px !important;
    right: 14px !important;
    width: 15%;
    min-width: 40px;
    font-size: var(--defaultFontSize);
    text-align: right;
    opacity: $disabledLinkOpacity;
    color: $primaryTextColour;

    & span {
      background: #000;
      padding: 5px 15px;
      border-radius: 30px;
    }
  }
  // default modal settings
  /*  .modal {
  //  border: 1px solid red;

    &-dialog {
    //  border: 1px solid red;
    }

    &-content {
      background: transparent;
      opacity: 1;
      box-shadow: none;
      border-radius: 0;
      border: 0;

      & .modal-header {
      //  border: 1px solid red;
        position: relative;
        top: 25px;

        & .modal-title {
        //  border: 1px solid red;

          width: auto;
          background: #FFF;
          color: #000;
          border-radius: 50px;
          border: 0;
          margin-left: auto;
          margin-right: auto;
          z-index: 500;
          padding: 2px 12px;

          &:before {
            position: absolute;
            left: 0;
            content: ' ';
            background-image: url('../../../images/redesign/components/modal/modal_stars.png');
            background-size: 20px 20px;
            height: 20px;
            width: 20px;
          }

          &:after {
            position: absolute;
            right: 0;
            content: ' ';
            background-image: url('../../../images/redesign/components/modal/modal_stars.png');
            background-size: 20px 20px;
            height: 20px;
            width: 20px;
          }
        }
      }



      & .modal-body {
      //  border: 1px solid red;
        padding-top: 45px;
        background: #000;
        opacity: 1;
        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
        border-radius: 17px 17px 0 0;
        border: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        z-index: 400;
      }

      & .modal-footer {
        background: #000;
        opacity: 1;
        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
        border-radius: 0 0 17px 17px;
        border: 0;
        z-index: 500;

        // default modal footer button
        & .button {
          border: 1px solid red;
        }
      }
    }
  }  */

  & .help-loading-section {
    background: #000000;
    opacity: 0.95;
    box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
    border-radius: 17px;
    max-width: 550px;
    position: fixed;
    right: 10px;
    bottom: 10px;

    &-header {
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 20px;

      &-menu {
        margin-bottom: 0px;
        margin-right: 15px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: var(--largeFontSize);
        line-height: 20px;
        text-align: center;
        text-transform: capitalize;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &-active {
          color: #FFFFFF;
          border-bottom: 1px solid red; //#FFC900;
        }
      }
    }

    &-body {
      width: 100%;

      &-description {
        display: none;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: var(--largeFontSize);
        line-height: 160%;
        color: #FFFFFF;

        &-active {
          display: block;
        }
      }

      &-description li {
        position: relative;
      }

      &-description li::before {
        position: absolute;
        left: -19px;
        top: 8px;
        width: 8px;
        height: 8px;
        content: '';
        background-image: url('../../../images/redesign/landing-view/slidePaginationActive.svg');
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 999;
      }

      &-description li::marker {
        width: 8px;
        height: 8px;
        content: '';
        background-image: url('../../../images/redesign/landing-view/slidePaginationActive.svg');
        z-index: 1;
      }
    }
  }

  & .input-col-message {
    border-radius: 17px;
    font-size: var(--defaultFontSize);
  }

  & .latest-version-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-body {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .rdw-link-decorator-wrapper a {
        font-size: var(--largeFontSize);
        color: #FFC900;
        text-decoration: underline;

        &:hover {
          color: #FFC900;
        }
      }

      & .rdw-editor-main {
        color: #fff
      }

      & .rdw-left-aligned-block {
        position: relative;
      }

      &-footer {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: var(--headerFontSize);
        line-height: 160%;
        text-decoration-line: underline;
        color: #FFC900;
        cursor: pointer;
      }
    }
    // & .rdw-left-aligned-block::before {
    //   position: absolute;
    //   left: 0px;
    //   top: 5px;
    // }
  }

  .mobile-detector-modal {
    width: 100%;
    min-height: 450px;
    height: 450px;

    & .body-section {
      width: 100%;
      height: 60%;
      border-bottom: 1px solid #1E1E1E;
      ;

      & .mobile-icon {
        width: 100%;
        height: 50%;
      }

      & .mobile-content {
        width: 100%;
        height: 50%;
        padding: 0 30px;

        & p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: var(--mLargeFontSize);
          line-height: 160%;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }

    & .footer-section {
      width: 100%;
      height: 40%;

      & .download-content {
        width: 100%;
        height: 40%;

        & p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: var(--headerFontSize);
          line-height: 23px;
          text-align: center;
          text-transform: uppercase;
          color: #FFFFFF;
        }
      }

      & .download-icons {
        width: 100%;
        height: 50%;

        & img {
          width: 135px;
          height: 40px;
        }
      }
    }
  }

  & .player-edit-pencil {
    & img {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }

  & .update-profile-section {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex: 1;
    z-index: 1000;
    flex-direction: column;
    min-height: 95px;

    &-changePass {
      min-height: 193px !important;
      height: 193px !important;
      overflow: hidden;
    }

    &-tabs {
      display: flex;
      align-items: center;
    }

    &-avatar {
      margin-top: 26px;
      align-items: center;

      & div {
        & div {
          & label {
            cursor: pointer;
            width: 100%;
            line-height: unset !important;
          }
        }
      }

      & .edit-photo-action {
        & .cancel-button {
          margin-top: 14px;
          color: red;
          text-decoration: underline;
          cursor: pointer;
          font-size: var(--defaultFontSize);
        }

        & .ok-button {
          margin-top: 14px;
          color: red;
          text-decoration: underline;
          cursor: pointer;
          font-size: var(--defaultFontSize);
        }
      }
    }

    &-add-email {
      margin-top: 24px;

      & .add-email-button {
        width: 320px;
        margin: 0px;
        height: 50px !important;
        display: flex;
        justify-content: center;
        word-break: break-word;
      }

      & .add-email-section {
        display: flex;
        width: 365px;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
      }

      & .d-flex {
        justify-content: space-between;

        .add-name-section {
          display: inline-block;
          width: auto;
          justify-content: space-between;
          align-items: center;
          margin-top: 8px;

          .update-profile-email-error {
            width: auto;
          }
        }

        .add-surname-section {
          display: inline-block;
          width: auto;
          justify-content: space-between;
          align-items: center;
          margin-top: 8px;

          .update-profile-email-error {
            width: auto;
          }
        }
      }

      & .add-dob-section {
        display: flex;
        flex-direction: row;
        width: 365px;
        align-items: center;
        margin-top: 8px;
        position: relative;


        & .rw-state-focus > .rw-widget-container {
          box-shadow: unset !important;
        }

        & .custom-birthday {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 85%;
          background: #fff;
          border: 1px solid white;
          left: 3px;
          z-index: 100;
          position: absolute;
          top: 3px;

          &-day {
            width: 19px;
            padding: 0px;
            font-size: 12px;
            border: none;
            padding: 2px 1px;
            text-align: center;
          }

          &-day:focus {
            background-color: #473BE7;
            pointer-events: none;
            cursor: none;
            color: #fff;
          }

          &-month {
            width: 23px;
            padding: 0px;
            font-size: 12px;
            border: none;
            padding: 2px 1px;
            text-align: center;
          }

          &-month:focus {
            background-color: #473BE7;
            pointer-events: none;
            cursor: none;
            color: #fff;
          }

          &-year {
            width: 39px;
            padding: 0px;
            font-size: 12px;
            border: none;
            padding: 2px 1px;
            text-align: center;
          }

          &-year:focus {
            background-color: #473BE7;
            pointer-events: none;
            cursor: none;
            color: #fff;
          }

          &-slot {
            width: 10px;
            font-size: 12px;
            color: #000;
          }
        }

        input {
          color: #000;
        }
      }
    }

    & .change-email-section {
      font-size: var(--headerFontSize);
    }

    & .bottom-button-center {
      flex: 1 1;
    }

    & .update-profile-email-error {
      position: relative;
      width: 320px;

      .edit-profile-input,
      .new-design-alert {
        width: auto;
        height: 17px;
        position: absolute;
        top: -13px;
        left: 16px;
        font-size: var(--smallFontSize);
        background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
        border-radius: 99px;
        padding: 1px 6px 2px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        color: #fff;
        line-height: normal;
        border: none;
      }
    }

    & .confirm-section {
      font-size: var(--mLargeFontSize);
      margin: 0 36px;
    }
  }

  & .my-info-achievements-link-text {
    font-size: var(--headerFontSize);

    &-capitalize::first-letter {
      text-transform: uppercase;
    }
  }

  & .my-info-achievements-link-text::first-letter {
    text-transform: uppercase;
  }

  & .achievements-unlock-modal {
    width: 100%;
    height: 410px;

    &-content {
      width: 100%;
      height: 50px;
      margin-top: 16px;

      & .subheader {
        color: #ffc900;
        font-size: var(--xxLargeFontSize);
      }

      & p {
        font-size: var(--mLargeFontSize);
        margin-bottom: 5px;
      }
    }

    &-scrollarea {
      width: 100%;
      height: 375px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      font-size: var(--defaultFontSize);
      // justify-content: center;

      &-section {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        height: 375px;
        // border-bottom: 5px solid rgba(#ffffff, 0.1);
      }

      &-light {
        .scrollbar {
          background: rgba(grey, 0.6) !important;
        }

        &-section {
          border-bottom: 5px solid rgba(grey, 0.2) !important;
        }
      }

      &-normal {
        .scrollbar {
          background: rgba(#ffffff, 0.4) !important;
        }

        &-section {
          border-bottom: 5px solid rgba(#ffffff, 0.1) !important;
        }
      }

      &-dark {
        .scrollbar {
          background: rgba(#ffffff, 0.4) !important;
        }

        &-section {
          border-bottom: 5px solid rgba(#ffffff, 0.1) !important;
        }
      }

      & .my-info-achievements-column {
        width: 294px;
        height: 100%;
        margin-bottom: 23px !important;
      }

      & .my-info-achievements-column-div {
        width: 100%;
        height: 265px;
        position: relative;
        overflow: hidden;

        & .achievement-img-bg {
          width: 75%;
          height: 70%;
          background: #65BEFF;
          opacity: 0.2;
          border-radius: 50%;
          position: absolute;
          bottom: -12%;
          left: 50%;
          transform: translateX(-50%);
        }

        & img {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0px;
        }

        & .signle-achievement-img {
          width: 80%;
          height: 60%;
          position: absolute;
          bottom: -100px;
        }
      }

      & .my-info-achievements-column-div::before {
        position: absolute;
        left: -25px;
        bottom: 0px;
        content: ' ';
        background-image: url('../../../images/redesign/landing-view/landing_modal_stars.svg');
        background-size: 200px 200px;
        height: 200px;
        width: 200px;
        z-index: 999;
      }

      & .my-info-achievements-column-div::after {
        position: absolute;
        right: -30px;
        bottom: 0px;
        content: ' ';
        background-image: url('../../../images/redesign/landing-view/landing_modal_stars.svg');
        background-size: 200px 200px;
        height: 200px;
        width: 200px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }

      & .my-info-achievements-column-info {
        width: 100%;
        height: 70px;
      }
    }
  }

  @media screen and (max-width: 1280px) and (orientation: landscape) {
    & .achievement-unlock {
      .modal-content {
        .modal-body {
          padding-top: 15px !important;
          padding-bottom: 1px;
        }

        .modal-footer {
          padding-top: 1px !important;
          padding-bottom: 1px !important;
        }
      }
    }

    & .achievements-unlock-modal {
      width: 100%;
      height: unset;

      &-content {
        width: 100%;
        height: auto;
        margin-top: 4px;

        & .subheader {
          color: #ffc900;
          font-size: var(--defaultFontSize);
        }

        & p {
          font-size: var(--smallFontSize);
          margin: 1px;
        }
      }

      &-scrollarea {
        width: 100%;
        height: unset; //
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-size: var(--defaultFontSize);
        // justify-content: center;

        &-section {
          flex: 0 0 auto;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          height: unset; //
          // border-bottom: 5px solid rgba(#ffffff, 0.1);
        }

        &-light {
          .scrollbar {
            background: rgba(grey, 0.6) !important;
          }

          &-section {
            border-bottom: 5px solid rgba(grey, 0.2) !important;
          }
        }

        &-normal {
          .scrollbar {
            background: rgba(#ffffff, 0.4) !important;
          }

          &-section {
            border-bottom: 5px solid rgba(#ffffff, 0.1) !important;
          }
        }

        &-dark {
          .scrollbar {
            background: rgba(#ffffff, 0.4) !important;
          }

          &-section {
            border-bottom: 5px solid rgba(#ffffff, 0.1) !important;
          }
        }

        & .my-info-achievements-column {
          width: 284px;
          height: 100%;
          margin-bottom: unset !important;
        }

        & .my-info-achievements-column-div {
          width: 100%;
          height: 30px;
          position: relative;
          overflow: hidden;

          & .achievement-img-bg {
            width: 50%;
            height: 70%;
            background: #65BEFF;
            opacity: 0.2;
            border-radius: 50%;
            position: absolute;
            bottom: -12%;
            left: 50%;
            transform: translateX(-50%);
          }

          & img {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0px;
          }

          & .signle-achievement-img {
            width: unset !important;
            height: 85px !important;
            //position: absolute;
            // bottom: unset !important; //-100px;

            bottom: 0px !important; //-100px;
            top: unset !important;
          }
        }

        & .my-info-achievements-column-div::before {
          position: absolute;
          left: 20px;
          bottom: 0px;
          content: ' ';
          background-image: url('../../../images/redesign/landing-view/landing_modal_stars.svg');
          background-size: 80px 80px;
          height: 80px;
          width: 80px;
          z-index: 999;
        }

        & .my-info-achievements-column-div::after {
          position: absolute;
          right: 20px;
          bottom: 0px;
          content: ' ';
          background-image: url('../../../images/redesign/landing-view/landing_modal_stars.svg');
          background-size: 80px 80px;
          height: 80px;
          width: 80px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        & .my-info-achievements-column-info {
          width: 100%;
          height: auto;
          margin-top: 1px !important;
          font-size: var(--smallFontSize);
          height: 48px;
        }
      }
    }
  }

  & .notification-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &-light {
    //Notification Modal
    & .notification-body {
      color: #000 !important;
    }
    //Mobile Detector
    & .mobile-detector-modal .body-section .mobile-content p {
      color: $lightTextColour !important;
    }

    & .mobile-detector-modal .footer-section .download-content p {
      color: $lightTextColour !important;
    }
    //Lading screen
    & .game-bg {
      background-image: url('../../../images/redesign/light-mode/layout/carpet.webp');
    }

    & .top-row-new {
      background: linear-gradient(360deg, rgba(255, 255, 255, 0) 14.25%, #FFFFFF 100%) !important;
    }

    & .top-settings-new-section {
      color: rgba(0, 0, 0, 0.7) !important;

      &-active {
        color: $lightTextColour !important;
      }
    }

    & .new-design-landing-container-sign-bg {
      background-image: url('../../../images/redesign/light-mode/landing-view/new_bg_1.webp') !important;
      filter: blur(9px);
    }

    & .new-design-landing-container-sign-dg {
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%) !important;
    }

    & .sign-row-content-description {
      color: white !important; //$lightTextColour 0.7 opacity
    }

    & .new-design-landing-container-top-bg {
      background-image: url('../../../images/redesign/light-mode/layout/carpet.webp') !important;
    }

    & .new-design-landing-container-top-dg {
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%) !important;
    }

    & .new-design-landing-container-top-footer {
      background-color: $lightBodyTopBackgroundColour !important;
    }

    & .new-design-landing-container-top-footer::before {
      background: linear-gradient(180deg, rgba(236, 236, 236, 0.95) 0%, rgba(255, 255, 255, 0) 100%) !important;
    }

    & .menu-table-row-player-icon-text {
      color: #000 !important;
    }

    & .landing-top-list-body-column {
      color: #000 !important;
    }

    & .new-design-landing-container-blog {
      background-color: $lightBodyTopBackgroundColour !important;

      &-list-title {
        color: $lightTextColour !important;
      }

      & .splide-slide-content-title {
        color: $lightTextColour !important;
      }

      & .splide-slide-content-description {
        color: $lightTextColour !important;
      }

      & .splide-slide-content-description {
        color: $lightTextColour !important;
      }
    }

    & .new-design-landing-container-mobile {
      background-image: url('../../../images/redesign/light-mode/layout/carpet.webp') !important;

      & .new-design-landing-container-mobile-bd {
        background: linear-gradient(180deg, rgba(236, 236, 236, 0.95) 0%, rgba(255, 255, 255, 0) 100%) !important;
      }

      & .new-design-landing-container-mobile-list-body-row-content .description {
        color: white !important; //$lightTextColour
      }
    }

    & .new-design-landing-container-footer {
      background-color: $lightBodyTopBackgroundColour !important;

      & .new-design-landing-container-footer-first {
        background-color: $lightBodyTopBackgroundColour !important;

        & .support-content {
          color: $lightTextColour !important;
        }
      }

      & .help-menu a {
        color: $lightTextColour !important;
      }

      & .help-develop {
        color: $lightTextColour !important;
      }
    }
    //Rules screen
    & .zole-rules-container {
      background-color: $lightBodyTopBackgroundColour !important;

      & .zole-rules-page-title {
        color: $lightTextColour !important;
      }

      & .zole-rules-page-header-link {
        color: rgba(0, 0, 0, 0.9) !important;

        &-active {
          color: $lightTextColour !important;
        }
      }
    }
    //Contacts us screen
    & .contacts-container {
      background-color: $lightBodyTopBackgroundColour !important;
    }
    //Blog screen
    & .blogs-container {
      background-color: $lightBodyTopBackgroundColour !important;

      & .blog-article-back-dg {
        background: linear-gradient(180deg, rgba(236, 236, 236, 0.95) 0%, rgba(255, 255, 255, 0) 100%) !important;
      }

      & .blogs-page-title {
        color: $lightTextColour !important;
      }

      & .blogs-page-first-section-item {
        & .title {
          color: $lightTextColour !important;
        }

        & .description {
          color: $lightTextColour !important;
        }
      }

      & .blogs-article-page-first-section {
        & .title {
          color: $lightTextColour !important;
        }

        & .description {
          color: $lightTextColour !important;
        }

        & .date time {
          color: $lightTextColour !important;
        }
      }
    }
    //Login screen
    & .new-design-login-container-body {
      background: linear-gradient(180deg, rgba(236, 236, 236, 0.95) 0%, rgba(255, 255, 255, 0) 100%) !important;

      & .new-design-login-container-body-content-section {
        background-color: $lightBodyTopBackgroundColour !important;
      }

      & .new-design-login-container-body-content-section-form {
        background-color: $lightBodyTopBackgroundColour !important;
      }

      & .new-design-login-container-body-content-section-form-footer {
        background: rgba(#868686, 0.2);
        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
      }

      & .title {
        color: $lightTextColour !important;
      }

      & .social button {
        color: $lightTextColour !important;
      }

      & .new-design-login-container-body-content-section-form-footer .section label {
        color: $lightTextColour !important;
      }
    }
    //Forgot password
    & .forgot-password-section-content-result {
      color: $lightTextColour !important;
    }
    //Sign up
    & .register-password-section-content-input .rules {
      color: $lightTextColour !important;
    }
    //Mobile screen
    & .new-design-landing-mobile-navigation .left-part {
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%) !important;
    }

    & .new-design-landing-mobile-navigation .right-part {
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%) !important;
    }

    & .new-design-landing-mobile-navigation-section {
      background-color: rgba(236, 236, 236, 0.95) !important;

      & .mobile-navigation-link {
        background-color: rgba(236, 236, 236, 0.95) !important;
        box-shadow: 0px 4px 22px rgba(255, 255, 255, 0.8) !important;
      }

      & .new-design-landing-container-footer-first {
        background-color: rgba(236, 236, 236, 0.95) !important;
      }
    }
    //Loading screen
    & .loadingScreen {
      background-image: url('../../../images/redesign/light-mode/layout/carpet.webp') !important;

      & .loadingScreen {
        &-domain {
          color: #000;
        }
      }
    }

    & .game-bg {
      background-image: url('../../../images/redesign/light-mode/layout/carpet.webp');
    }

    & .lobby-bg-table {
      background-image: url('../../../images/redesign/light-mode/layout/Table.webp');
    }

    & .help-loading-section {
      background: $lightBackgroundColor;
    }

    & .help-loading-section-header-menu-active {
      color: $lightTextColour;
    }

    & .help-loading-section-body-description {
      color: $lightTextColour;
    }

    & .tutorial-result-content {
      color: $lightTextColour !important;
    }
    //Rooms screen
    & .menu-table-row-inner-wrapper {
      box-shadow: 0 0 0 500px rgba(236, 236, 236, 0.95) !important;
      //background-color: rgba(0, 0, 0, 0.03) !important;
    }

    & .menu-row-top-table {
      background: rgba(0, 0, 0, 0.03);
    }

    & .menu-table-row-filler-out {
      background-color: $lightBodyTopBackgroundColour !important;
      flex-grow: 1;
    }

    & .layout-footer {
      background-color: $lightBodyTopBackgroundColour;
    }

    & .rooms-table-filter-button {
      color: #0C0C0C !important;
    }

    & .rooms-table-filter-icon {
      opacity: 1 !important;
    }
    //& .rooms-table-info-modal-text {
    //color: #0C0C0C !important;
    //display: inline-block;
    //}
    & .rooms-table-info-modal-highlighted {
      color: #d0a300 !important;
    }

    & .rooms-table-row-player-image-default {
      padding: 2px !important;
    }

    & .rooms-table-row-player-image-frame {
      border: unset !important
    }

    & .switch-select-option-active .switch-select-option-name {
      color: $lightTextColour !important;
    }
    //& .rooms-table-info-modal-text {
    //  font-size: var(--headerFontSize) !important;
    //}
    & .rooms-table-row-player-waiting {
      opacity: 1 !important;
    }

    & .private-room-button::before {
      background-image: url('../../../images/redesign/light-mode/rooms-table/lock.svg') !important;
    }
    //Top screen
    & .layout-header-link-text {
      color: rgba(#000, 0.7) !important;
      opacity: 1;
    }

    & .layout-header-link-text-active {
      color: #000 !important;
    }

    & .layout-body-top {
      // background-color: $lightBodyTopBackgroundColour;
    }

    & .layout-body-background {
      // box-shadow: 0 0 0 500px rgba(236, 236, 236, 0.95);
      background-color: rgba(255, 255, 255, 0.95);
    }

    & .layout-body-main-table-header {
      background-color: $lightBodyTopBackgroundColour;

      > div {
        opacity: 1 !important;
      }

      & .layout-float-right {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    & .top-table-scrollarea-body-header {
      // background-color: $lightBodyTopBackgroundColour !important;
      color: rgba(0, 0, 0, 0.5);
    }

    & .top-table-row-player-image-frame {
      background: linear-gradient(180deg, #FFFFFF 0%, #ECECEC 100%) !important;

      & .top-table-row-player-image-default {
        border: 2px solid #aaaaaa;
      }
    }

    & .top-table-row-player-image-frame-landing {
      background: rgba(255, 255, 255, 0.2) !important;

      & .top-table-row-player-image-default-landing {
        border: none;
      }
    }

    & .spinner-loading-section {
      width: 100%;
      height: 100%;
      min-height: 350px;
      background-color: $lightBodyTopBackgroundColour !important;
    }

    & .active {
      color: $lightTextColour;
    }

    & .layout-body-top {
      & .custom-dropdown-toggle {
        border: 1px solid #868686 !important;
        color: $lightTextColour;
      }

      & .custom-dropdown-link {
        color: $lightTextColour;
      }

      & .custom-dropdown-menu {
        background-color: rgba(236, 236, 236, 0.95);
        color: $lightTextColour;
        border: 1px solid rgba(0, 0, 0, 0.25) !important;

        & button {
          color: $lightTextColour;
        }

        & .dropdown-item:hover {
          background: rgba(0, 0, 0, 0.4) !important;
        }
      }

      & .switch-select-option-name {
        color: $lightTextColour;
      }

      & .top-search-button {
        color: $lightTextColour;
      }
    }

    & .layout-link-button {
      color: $lightTextColour;
    }

    & .bonus-page-spin-button button {
      font-size: var(--largeFontSize);
      font-weight: 700;
    }

    & .user-settings-button {
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }

    & .player-history-table-header-col {
      color: $lightTextColour !important;
    }

    & .player-history-table-col {
      color: $lightTextColour !important;
    }

    & .buy-page-coins-amount {
      color: $lightTextColour !important;
    }

    & .player-history-tab-link-text {
      color: $lightTextColour !important;
    }
    // & .player-history-tab-link-text-active {
    //   border-bottom: 1px solid $lightHighlightedTextColor !important;
    //   color: $lightTextColour !important;
    //   &-light {
    //     border-bottom: 2px solid #d2110a !important;
    //     color: $lightTextColour !important;
    //   }
    //   &-normal {
    //     border-bottom: 2px solid $lightHighlightedTextColor !important;
    //     color: $lightTextColour !important;
    //   }
    //   &-dark {
    //     border-bottom: 2px solid $lightHighlightedTextColor !important;
    //     color: $lightTextColour !important;
    //   }
    // }

    & .player-history-table-col-positive {
      color: $lightSecondaryTextColour !important;
    }

    & .player-history-table-col-negative {
      color: $lightSecondaryTextColour !important;
    }
    //Rules-page
    & .zole-rules-page-body {
      color: $lightTextColour !important;
    }

    & .zole-rules-page .page-title {
      color: $lightTextColour !important;
    }
    //Power rankings screen
    & .new-power-rankings-body-item-label {
      &-key {
        background: linear-gradient(180deg, #CDC8C8 0%, #F2F0F0 100%) !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      }
    }

    & .new-power-rankings-body-item-photo {
      & .player-info-player-image-background {
        background-image: url('../../../images/redesign/light-mode/player/UserFrame.png');
        border-radius: 50%;
      }
    }

    & .player-info-player-name {
      background: #fff !important;
      color: #000 !important;
    }

    & .layout-subheader-link-text {
      //color: #000;
      color: red;
    }

    & .new-power-rankings-body-item-score-section {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 88px;

      & label {
        margin-bottom: 0px !important;
      }
    }

    & .new-power-rankings-body-item-lg .new-power-rankings-body-item-label {
      &-key {
        -webkit-text-stroke: 0.5px white !important;
      }
    }

    & .new-power-rankings-body-item-md .new-power-rankings-body-item-label {
      &-key {
        -webkit-text-stroke: 0.5px white !important;
      }
    }

    & .new-power-rankings-body-item-sm .new-power-rankings-body-item-label {
      &-key {
        -webkit-text-stroke: 0.5px white !important;
      }
    }

    & .new-power-rankings-body-item-label {
      &-key {
        color: $lightTextColour !important;
        font-family: sans-serif !important;
        font-weight: 700 !important;
      }

      &-addition {
        color: $lightTextColour !important;
        font-family: sans-serif !important;
        font-weight: 700 !important;
      }
    }

    & .new-power-rankings-body-item-score-section label {
      color: $lightSecondaryTextColour !important;
    }

    & .power-rankings-level-step {
      // color: $lightSecondaryTextColour !important;
    }
    //My Info screen
    & .my-info-ratings {
      background: linear-gradient(180deg, #F1F1F1 0%, #DADADA 100%);
      border: 1px solid #FFFFFF;
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
    }

    & .my-info-ratings-title {
      background: #000;
      color: #fff;
    }

    & .my-info-results-scrollarea-body-header {
      background-color: $lightBodyTopBackgroundColour !important;
    }
    //Ignored player list
    & .menu-table-row-player-image-frame {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
    //Game Logs of My Info screen
    & .new-game-logs {
      // background-color: $lightBodyTopBackgroundColour !important;
      & .new-game-logs-table-row td {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    //Achievements of My Info screen
    & .my-info-achievements-link-text {
      color: $lightTextColour !important;
      opacity: 0.7;
    }

    & .my-info-achievements-link-active {
      opacity: 1;
      border-left: 1px solid #D2110A;
    }

    & .scrollbar {
      background: rgba(0, 0, 0, 0.3) !important;
      border-radius: 4px !important;
    }

    & .switch-select-option {
      background: linear-gradient(180deg, #D6D6D6 0%, #FFFFFF 100%) !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), 0px 4px 20px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.4) !important;
    }

    & .switch-select-option-name {
      color: $lightTextColour !important;
      opacity: 1 !important;
    }

    & .switch-select-option-active {
      background: linear-gradient(180deg, #FF7A00 0%, #FF8A1A 100%) !important;
    }

    & .money-history-table-header-col {
      color: #0C0C0C;
    }

    & .player-history-table-col {
      color: #0C0C0C !important;
    }

    & .player-history-table-col-highlight {
      color: $lightHighlightedTextColor !important;
    }

    & .spin-timer {
      color: $lightHighlightedTextColor !important;
    }

    & .layout-default-button {
      color: $lightTextColour;
      background: rgba(255, 255, 255, 0.4);
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    & .bonus-page-complete-button button {
      min-width: unset;
    }
    //Cookie and Feedback modal
    & .inline-title {
      background: $lightTextColour;
      color: $lightSecondaryTextColour;
    }

    & .inline-modal-body {
      background: $lightBodyTopBackgroundColour;
      color: $lightTextColour;
    }

    & .cookies-new-section-cookies-body {
      color: $lightTextColour;
    }

    & .cookies-new-section-cookies-body-rating-comments-section p {
      color: $lightTextColour !important;
    }
    //Common modal
    & .modal-title {
      background: $lightTextColour !important;
      color: $lightSecondaryTextColour !important;
    }

    & .modal-body {
      background: rgba(255, 255, 255, 1) !important;
      color: $lightTextColour !important;
      box-shadow: none !important;
    }

    & .modal-close-img {
      background-image: url('../../../images/redesign/light-mode/components/modal/close.png');
    }

    & .modal-footer {
      background: #fff !important;
      border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
      box-shadow: none !important;
    }

    & .about-us-modal-text {
      color: $lightTextColour !important;
    }
    //Game Room
    & .player-name {
      background: $lightBackgroundSecondColor !important;
      color: $lightTextColour !important;
    }

    & .player-balance {
      background: rgba(255, 255, 255, 0.6) !important;
      color: $lightTextColour !important;
    }

    & .player-info-player-image-background {
      background-image: url('../../../images/redesign/light-mode/player/UserFrame.png') !important;
      border-radius: 50%;
    }

    & .score-table {
      color: rgba($lightTextColour, 0.5) !important;
      border-radius: 17px 17px 17px 0px !important;

      &-top {
        background: rgba(255, 255, 255, 0.8) !important;
      }

      &-table {
        border-top: 1px solid rgba($lightTextColour, 0.1) !important;
      }

      &-names-name {
        color: rgba($lightTextColour, 0.7) !important
      }

      &-id-col {
        color: rgba($lightTextColour, 0.5) !important;
      }

      &-col {
        color: rgba($lightTextColour, 1) !important;
      }

      &-points {
        border-top: 1px solid rgba($lightTextColour, 0.1) !important;
      }

      &-points-col:not(:first-child) {
        color: $lightTextColour !important;
      }

      &-bottom {
        background: rgba(255, 255, 255, 0.5) !important;
        border-radius: 0px 17px 17px 17px;

        & .score-table-points-col:not(:first-child) {
          color: rgba($lightTextColour, 0.7) !important
        }
      }
    }

    & .room-chat {
      &-close-button-icon {
        background-image: url('../../../images/redesign/light-mode/game/close.svg') !important;
      }

      &-bar {
        &-left {
          background: rgba(255, 255, 255, 0.8) !important;
          color: $lightTextColour !important;
        }

        &-input {
          color: $lightTextColour !important;
        }

        &-input::placeholder {
          color: rgba($lightTextColour, 0.7) !important
        }

        &-button-send {
          color: $lightTextColour !important;
          font-weight: bold;
        }
      }

      &-message-other {
        background: rgba(255, 255, 255, 0.8) !important;
        color: $lightTextColour !important;
      }
    }

    & .end-result-table-header-col {
      color: $lightTextColour !important;
    }

    & .end-result-table-col {
      color: $lightTextColour !important;

      & .strikeout::after {
        border-bottom: 2px solid $lightTextColour;
      }
    }

    & .notification-points-section-title {
      color: $lightHighlightedTextColor !important;
    }

    & .notification-points-section-points {
      color: $lightTextColour !important;
    }

    & .notification-points-section-bottom {
      color: $lightTextColour !important;
    }

    & .new-game-logs-table-row {
      & .strikeout::after {
        border-bottom: 2px solid $lightTextColour !important;
      }
    }

    & .player-info-new-modal {
      & .player-info-new-body {
        background: $lightBodyTopBackgroundColour !important;
        color: #000 !important;

        & .player-info-new-tab-link a {
          color: #000 !important;
        }

        & .player-info-new-statistics-percent label {
          color: #000 !important;
        }

        & .giftsHistory-row-circle {
          border: 1px solid #000;
        }
      }

      & .player-info-new-player-wrapper {
        display: flex;
        justify-content: center;

        & .player-info-new-player-balance {
          color: #000 !important;
        }

        & .player-info-new-player-rating {
          color: #000 !important;
        }
      }
    }

    & .last-round-popover {
      background: $lightBodyTopBackgroundColour !important;
      color: #000 !important;
    }

    & .game-stats-inner {
      background: rgba(255, 255, 255, 0.5);
      //border-radius: 88px;
      padding: 0 10px;
    }

    & .game-stats-label {
      color: rgba(0, 0, 0, 0.7) !important;
    }

    & .game-stats-value {
      color: #000 !important;
    }

    & .select-type-body {
      background: $lightBodyTopBackgroundColour !important;

      & .layout-default-button {
        color: #000 !important;
      }
    }

    & .new-design-automation-section {
      background: $lightBodyTopBackgroundColour !important;
      color: #000 !important;
    }

    & .online-stats {
      background: rgba(255, 255, 255, 0.5) !important;
      border-radius: 88px !important;
    }


    & .menu-background-bg {
      background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    }

    & .online-stats-text {
      color: $normalSecondaryTextColour !important;
      opacity: 0.7 !important;
    }

    & .online-stats-count {
      color: $lightTextColour !important;
    }

    & .bottom-bar-links a {
      background: rgba(255, 255, 255, 0.5);
    }

    & input + .slider {
      //background: rgba(0, 0, 0, 0.5);
    }

    & input + .slider:before {
      //background: rgba(0, 0, 0, 1);
    }

    & .switch-text {
      font-size: var(--headerFontSize) !important;
    }

    & .modal-body {
      border-radius: 17px 17px 0px 0px !important;
    }

    & .modal-footer {
      margin-top: 0;
    }

    & .game-stats-inner {
      background: rgba(255, 255, 255, 0.5) !important;
      //border-radius: 88px !important;
    }

    & .end-result-party-modal-party-log-time {
      color: $lightTextColour;
    }

    & .previous-round-text {
      color: $lightTextColour !important;
      opacity: 0.7 !important;
    }

    & .previous-round-value {
      color: $lightTextColour !important;
    }

    & .previous-round-tricks-text {
      color: $lightTextColour !important;
      opacity: 0.7 !important;
    }

    & .previous-round-player-name {
      color: $lightTextColour !important;
    }

    & .select-type-text {
      color: $lightTextColour !important;
    }

    & .chat-only-label {
      // color: $lightTextColour !important;
    }

    & .game-clock {
      color: $lightTextColour;
      opacity: 1;

      & span {
        background: rgba(255, 255, 255, 0.5);
      }
    }

    & .bottom-bar-links > a:hover > span {
      color: black; //#FF8A1A;
      opacity: 1;
    }

    & .room-chat-bar-button-support {
      width: 32px !important;
      height: 32px !important;
      object-fit: contain;
      border: 1px solid rgba(#0C0C0C, 0.2);
      border-radius: 50%;
    }

    & .tournament-scrollarea {
      & .menu-table-row-inner-wrapper {
        box-shadow: none !important;
        // background-color: unset !important;
      }

      & .tournament-table-row {
        & .active-tournaments {
          background-color: rgba(0, 0, 0, 0.03) !important;
        }

        & .active-tournaments-info-section-title-left-title {
          color: $lightHighlightedTextColor;
        }

        & .active-tournaments-info-section-title-left-private {
          color: $lightTextColour;
        }

        & .active-tournaments-info-section-time-rounds {
          color: $lightTextColour;
        }

        & .active-tournaments-info-section-time-roundsValue {
          color: $lightTextColour;
        }

        & .active-tournaments-info-section-time-label {
          color: $lightTextColour;
        }

        & .active-tournaments-info-section-time-content {
          color: $lightTextColour;
        }
      }
    }

    & .tournament-info-section-main-row {
      color: #000 !important;
    }

    & .tournament-info-section-right-row {
      & span {
        color: #000 !important;
      }
    }
  }

  &-normal {
    //Lading screen

    & .top-row-new {
      // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 14.25%, #FFFFFF 100%) !important;
      background: linear-gradient(360deg, rgba(#253678, 0) 14.25%, #253678 100%) !important;
    }

    & .new-design-landing-container-sign-bg {
      background-image: url('../../../images/redesign/normal-mode/landing-view/new_bg_1.webp') !important;
      filter: blur(9px);
    }

    & .new-design-landing-container-sign-dg {
      background: linear-gradient(180deg, #253678 0%, rgba(255, 255, 255, 0) 100%) !important;
    }

    & .new-design-landing-container-top-bg {
      background-image: url('../../../images/redesign/normal-mode/layout/carpet.webp') !important;
    }

    & .new-design-landing-container-top-dg {
      background: linear-gradient(180deg, #253678 0%, rgba(#253678, 0) 100%) !important;
    }

    & .new-design-landing-container-top-footer {
      background-color: $normalBackgroundColor !important;
    }

    & .new-design-landing-container-top-footer::before {
      background: linear-gradient(180deg, #253678 0%, rgba(#253678, 0) 100%) !important;
    }

    & .menu-table-row-inner-wrapper-noback {
      // background-color: rgba(#253678, 0.8) !important;
    }

    & .landing-top-list-body-column {
      color: $normalTextColour !important;
    }

    & .new-design-landing-container-blog {
      background-color: $normalBackgroundColor !important;
    }

    & .new-design-landing-container-mobile {
      background-image: url('../../../images/redesign/normal-mode/layout/carpet.webp') !important;

      & .new-design-landing-container-mobile-bd {
        background: linear-gradient(180deg, #253678 0%, rgba(#253678, 0) 100%) !important;
      }

      & .new-design-landing-container-mobile-list-body-row-content .description {
        color: white !important; //$normalTextColour
      }
    }

    & .new-design-landing-container-footer {
      background-color: $normalBackgroundColor !important;

      & .new-design-landing-container-footer-first {
        background-color: $normalBackgroundColor !important;
      }
    }
    //Rules screen
    & .zole-rules-container {
      background-color: $normalBackgroundColor !important;
    }
    //Contacts us screen
    & .contacts-container {
      background-color: $normalBackgroundColor !important;
    }
    //Blog screen
    & .blogs-container {
      background-color: $normalBackgroundColor !important;

      & .blog-article-back-dg {
        background: linear-gradient(180deg, #253678 0%, rgba(#253678, 0) 100%) !important;
      }
    }
    //Login screen
    & .new-design-login-container-body {
      background: linear-gradient(180deg, #253678 0%, rgba(#253678, 0) 100%) !important;

      & .new-design-login-container-body-content-section {
        background-color: $normalBackgroundColor !important;
      }

      & .new-design-login-container-body-content-section-form {
        background-color: $normalBackgroundColor !important;
      }

      & .new-design-login-container-body-content-section-form-footer {
        background: rgba(#868686, 0.2);
        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
      }
    }
    //Mobile screen
    & .new-design-landing-mobile-navigation .left-part {
      background: linear-gradient(180deg, #253678 100%, rgba(#253678, 0) 100%) !important;
    }

    & .new-design-landing-mobile-navigation .right-part {
      background: linear-gradient(180deg, #253678 100%, rgba(#253678, 0) 100%) !important;
    }

    & .new-design-landing-mobile-navigation-section {
      background-color: rgba(#253678, 0.95) !important;

      & .mobile-navigation-link {
        background-color: rgba(#253678, 0.95) !important;
        box-shadow: 0px 4px 22px rgba(#253678, 0.8) !important;
      }

      & .new-design-landing-container-footer-first {
        background-color: rgba(#253678, 0.95) !important;
      }
    }

    & .online-stats {
      background: rgba(255, 255, 255, 0.5) !important;
      border-radius: 88px !important;
    }
    //Loading screen part
    & .loadingScreen {
      background-image: url('../../../images/redesign/normal-mode/layout/carpet.webp') !important;
    }

    & .game-bg {
      background-image: url('../../../images/redesign/normal-mode/layout/carpet.webp');
    }

    & .help-loading-section {
      background: $normalBackgroundColor;
    }

    & .help-loading-section-header-menu-active {
      color: $normalTextColour;
    }

    & .help-loading-section-body-description {
      color: $normalTextColour;
    }
    //Rooms menu screen  part
    & .menu-table-row-inner-wrapper {
      box-shadow: 0 0 0 500px rgba(#253678, $menuRowBackgroundOpacity) !important;
      // background-color: rgba(#253678, 0.8) !important;
    }

    & .menu-row-top-table {
      background: rgba(255, 255, 255, 0.03);
    }

    & .menu-table-row-filler-out {
      background-color: $normalBodyTopBackgroundColour !important;
      flex-grow: 1;
    }

    & .layout-footer {
      background-color: $normalBodyTopBackgroundColour;
    }

    & .rooms-table-filter-button {
      color: $normalTextColour !important;
    }

    & .rooms-table-filter-icon {
      opacity: 1 !important;
    }

    & .rooms-table-row-player-image-default {
      padding: 2px !important;
    }

    & .rooms-table-row-player-image-frame {
      border: unset !important
    }

    & .switch-select-option-active .switch-select-option-name {
      color: $normalSecondaryTextColour !important;
    }
    //& .rooms-table-info-modal-text {
    //  font-size: var(--headerFontSize) !important;
    //}
    & .rooms-table-row-player-waiting {
      opacity: 1 !important;
    }
    //Top screen
    // & .layout-body-top {
    //   background-color: $normalBodyTopBackgroundColour;
    // }
    & .layout-body-background {
      // box-shadow: 0 0 0 500px rgba(236, 236, 236, 0.95);
      // background-color: $normalBodyTopBackgroundColour;
    }

    & .layout-body-main-table-header {
      background-color: $normalBodyTopBackgroundColour;
      color: $normalTextColour;
      opacity: 1 !important;

      > div {
        opacity: 1 !important;
      }

      & .layout-float-right {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    & .top-table-scrollarea-body-header {
      // background-color: $normalBodyTopBackgroundColour !important;
      color: $normalTextColour;
    }

    & .active {
      color: $normalTextColour;
    }

    & .layout-body-top {
      & .custom-dropdown-toggle {
        border: 1px solid #868686 !important;
        color: $normalTextColour;
      }

      & .custom-dropdown-link {
        color: $normalTextColour;
      }

      & .custom-dropdown-menu {
        background: rgba(37, 54, 120, 0.95);
        border: 1px solid rgba(255, 255, 255, 0.5) !important;
        color: $normalTextColour;

        & button {
          color: $normalTextColour;
        }

        & .dropdown-item:hover {
          background: rgba(0, 0, 0, 0.4) !important;
        }
      }

      & .switch-select-option-name {
        color: $normalTextColour;
      }

      & .top-search-button {
        color: $normalTextColour;
      }
    }

    & .layout-link-button {
      color: $normalTextColour;
    }

    & .switch-select-option-name {
      color: $normalTextColour !important;
    }
    //Power rankings screen
    & .player-info-player-name {
      background: $normalBackgroundColor !important;
    }

    & .new-power-rankings-body-item-score-section {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 88px;

      & label {
        margin-bottom: 0px !important;
      }
    }

    & .spinner-loading-section {
      width: 100%;
      height: 100%;
      min-height: 350px;
      background-color: $normalBodyTopBackgroundColour !important;
    }
    //My Info screen
    & .my-info-ratings {
      background: linear-gradient(180deg, #253678 0%, #222222 100%);
      border: 1px solid #253678;
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
    }

    & .my-info-ratings-title {
      background: $normalTextColour;
      color: #000;
    }

    & .my-info-results-scrollarea-body-header {
      background-color: $normalBackgroundColor !important;
    }
    //Game Logs of My Info screen
    // & .new-game-logs {
    //   background-color: $normalBackgroundColor !important;
    // }
    //Cookie and Feedback modal
    & .inline-modal-body {
      background: $normalBackgroundColor;
    }

    & .cookies-new-section-cookies-body {
      color: $normalTextColour;
    }

    & .cookies-new-section-cookies-body-rating-comments-section p {
      color: $normalTextColour !important;
    }
    //Common modal
    & .modal-body {
      background: $normalBackgroundColor !important;
      color: $normalTextColour !important;
      box-shadow: none !important;
    }

    & .modal-close-img {
      // background-image: url('../../../images/redesign/light-mode/components/modal/close.png');
    }

    & .modal-footer {
      background: $normalBackgroundColor !important;
      border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
      box-shadow: none !important;
    }
    //Game Room
    & .player-name {
      background: $normalBackgroundColor !important;
    }

    & .score-table {
      color: rgba(#0C0C0C, 0.5) !important;
      border-radius: 17px 17px 17px 0px !important;

      &-top {
        background: rgba(255, 255, 255, 0.8) !important;
      }

      &-table {
        border-top: 1px solid rgba(#0C0C0C, 0.1) !important;
      }

      &-names-name {
        color: rgba(#0C0C0C, 0.7) !important
      }

      &-id-col {
        color: rgba(#0C0C0C, 0.5) !important;
      }

      &-col {
        color: rgba(#0C0C0C, 1) !important;
      }

      &-points {
        border-top: 1px solid rgba(#0C0C0C, 0.1) !important;
      }

      &-points-col:not(:first-child) {
        color: #0C0C0C !important;
      }

      &-bottom {
        background: rgba(255, 255, 255, 0.5) !important;
        border-radius: 0px 17px 17px 17px;

        & .score-table-points-col:not(:first-child) {
          color: rgba(#0C0C0C, 0.7) !important
        }
      }
    }

    & .room-chat {
      &-close-button-icon {
        background-image: url('../../../images/redesign/light-mode/game/close.svg') !important;
      }

      &-bar {
        &-left {
          background: rgba(255, 255, 255, 0.8) !important;
          color: #0C0C0C !important;
        }

        &-input {
          color: #0C0C0C !important;
        }

        &-input::placeholder {
          color: rgba(#0C0C0C, 0.7) !important
        }

        &-button-send {
          color: #0C0C0C !important;
          font-weight: bold;
        }
      }

      &-message-other {
        background: rgba(255, 255, 255, 0.8) !important;
        color: #0C0C0C !important;
      }
    }

    & .player-info-new-modal {
      & .player-info-new-body {
        background: $normalBackgroundColor !important;
      }

      & .player-info-new-player-wrapper {
        display: flex;
        justify-content: center;
      }
    }

    & .last-round-popover {
      background: $normalBackgroundColor !important;
      color: #000 !important;
    }

    & .game-stats-inner {
      background: rgba(255, 255, 255, 0.5) !important;
      border-radius: 88px;
      padding: 0 10px;
    }

    & .game-stats-label {
      opacity: 1 !important;
    }

    & .game-stats-label {
      color: rgba(0, 0, 0, 0.85) !important;
    }

    & .game-stats-value {
      color: #000 !important;
    }

    & .select-type-body {
      background: $normalBackgroundColor !important;
    }

    & .new-design-automation-section {
      background: $normalBackgroundColor !important;
    }

    & .menu-background-bg {
      background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, #253678 100%);
    }

    & .online-stats-text {
      color: $normalSecondaryTextColour !important;
      opacity: 0.7 !important;
      font-weight: 550 !important;
    }

    & .online-stats-count {
      color: $normalSecondaryTextColour !important;
      font-weight: 550 !important;
    }

    & .modal-body {
      border-radius: 17px 17px 17px 17px !important;
    }

    & .modal-footer {
      margin-top: -13px;
    }

    & .room-chat-bar-button-support {
      width: 32px !important;
      height: 32px !important;
      object-fit: contain;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      background-color: rgba(#ECECEC, 0.2);
    }

    & .tournament-scrollarea {
      // background-color: rgba(37, 54, 120, 0.95) !important;

      & .menu-table-row-inner-wrapper {
        box-shadow: none !important;
        // background-color: unset !important;
      }
    }
  }

  &-dark {
    & .spinner-loading-section {
      width: 100%;
      height: 100%;
      min-height: 350px;
      background-color: rgba(0, 0, 0, 0.95);
    }

    & .new-power-rankings-body-item-score-section {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 88px;

      & label {
        margin-bottom: 0px !important;
      }
    }

    & .new-rooms-table-section,
    & .new-top-table-section {
      .menu-table-row-inner-wrapper {
        background-color: rgba(255, 255, 255, 0.03);
      }
    }
  }

  & .private-room-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
  }

  & .private-room-button::before {
    display: inline-block;
    content: ' ';
    background-image: url('../../../images/redesign/rooms-table/lock.svg');
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
    margin-left: 7px;
    float: unset;
  }

  & .private-room-button::after {
    display: inline-block;
    content: ' ';
    background-image: none;
    background-size: unset;
    height: unset;
    width: unset;
    margin-left: 7px;
    float: unset;
  }
}

.root-class-design20-normal {
  .layout {
    & .player-info {
      &-player-background-active-gift-remove {
        filter: invert(1);
      }
    }
  }
}

.root-class-design20-dark {
  .layout {
    & .player-info {
      &-player-background-active-gift-remove {
        border: 2px solid #828282;
      }
    }
  }
}

.root-is-app-frame {
  & .achievement-unlock {
    .modal-content {
      .modal-body {
        padding-top: 30px !important;
        padding-bottom: 5px;
      }
      .modal-footer {
        padding-top: 5px !important;
        padding-bottom: 10px !important;
      }
    }
  }
  & .achievements-unlock-modal {
    &-content {
      & .subheader {
        font-size: var(--headerFontSize);
      }

      & p {
        font-size: var(--defaultFontSize);
      }
    }

    &-scrollarea {

      & .my-info-achievements-column {
        width: 284px;
      }

      & .my-info-achievements-column-div {
        height: 200px;
      }

      & .my-info-achievements-column-div::before {
        left: 0px;
        background-size: 120px 120px;
        height: 120px;
        width: 120px;
      }

      & .my-info-achievements-column-div::after {
        right: 0px;
        background-size: 120px 120px;
        height: 120px;
        width: 120px;
      }

      & .my-info-achievements-column-info {
        font-size: var(--defaultFontSize);
        height: 54px;
      }
    }
  }
}
.player-info-popover {
  z-index: 99999;
}

.icon-popover {
  //max-width: 90vw; // in case there needs to be larger content

  width: auto;
  height: auto;
  background-color: white;
  max-width: auto;
  border: none;
  border-radius: 18px;
  box-shadow: 0px 12px 22px 0px rgba(0, 0, 0, 0.7) !important;

  &-body {
    width: 100%;
    height: 100%;
    color: black;
    font-size: var(--defaultFontSize);
    font-weight: 400;
    font-family: Open Sans;
    font-style: normal;
    text-align: center;
    padding: 10px 30px;
  }

  & .popover-inner {
    width: 100%;
    height: 100%;
  }


}

.new-design-popover {
  width: 385px;
  height: auto;
  background-color: #000;
  box-shadow: none !important;
  max-width: 385px;

  & .popover {
    width: 385px;
    height: 100%;
    max-width: 385px;
    left: 105px !important;
    border-radius: 18px !important;
    box-shadow: none !important;
  }

  & .popover .arrow {
    left: 18px !important;
  }

  & .popover-inner {
    width: 100%;
    height: 100%;
  }

  &-body {
    width: 100%;
    height: 100%;
    display: flex;
    color: #fff;
    flex-direction: column;
    padding: 20px;

    &-header {
      width: 100%;
      height: 30%;
      font-size: var(--mLargeFontSize);
      font-weight: 600;
    }

    &-main {
      width: 100%;
      height: 40%;
      font-size: var(--headerFontSize);
      font-weight: 400;
    }

    &-footer {
      // width: 100%;
      height: 30%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      &-purchase-button {
        margin-top: 10px;
        width: auto;
        height: 30px;
        max-width: auto;
        /* left: 160px; */
        /* top: 309px; */
        background: #F29100;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        box-shadow: 4px 4px 0px #380000;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }

      &-reset-button {
        margin-top: 10px;
        width: auto;
        height: 30px;
        max-width: auto;
        background: #C44C4D;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        box-shadow: 4px 4px 0px #380000;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-highlighted-button {
        //  display: flex;
        //  flex-direction: column;
        justify-content: center;
        align-items: center;
        display: inline-block;
        vertical-align: middle;

        background: $layoutHighlightedButtonBackground;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
        border-radius: 99px;

        color: $layoutHighlightedButtonColour;


        //  background: rgba(0, 0, 0, 0.4);
        //  border: 1px solid rgba(255, 255, 255, 0.1);
        //  box-sizing: border-box;
        //  border-radius: 99px;

        font-family: Open Sans;
        font-style: normal;
        font-weight: 900;
        font-size: var(--defaultFontSize);
        // line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;


        padding: 3px 8px;

        min-width: 15%;

        &:hover {
          background: $layoutHighlightedButtonBackground;
          text-decoration: none;
          color: black;
        }

        &:active {
          background: $layoutHighlightedButtonBackground;
          text-decoration: none;
          color: black;
        }

        &:focus {
          background: $layoutHighlightedButtonBackground;
          text-decoration: none;
          color: black;
        }

        &:before {
          display: inline-block;
          content: ' ';
          background-image: url('../../../images/redesign/common/karavs.svg');
          background-size: 7px 7px;
          height: 7px;
          width: 7px;
          //  margin-right: 12px;
          //  margin-left: 6px;
          vertical-align: middle;
          float: unset;
          margin-right: 7px;
        }

        &:after {
          display: inline-block;
          content: ' ';
          background-image: url('../../../images/redesign/common/karavs.svg');
          background-size: 7px 7px;
          height: 7px;
          width: 7px;
          //  margin-right: 6px;
          //  margin-left: 12px;
          vertical-align: middle;
          float: unset;
          margin-left: 7px;
        }
      }
    }
  }
}

.new-chat-rude-popover {
  background-color: #000;
  box-shadow: none !important;
}

.noPadding {
  padding: unset !important;
}

.public-DraftStyleDefault-block {
  display: inline !important;
}

// .layout-body-main-shop-menu {
//   margin-top: 50px;
// }
.bonus-page-spin-button-disabled {
  cursor: default !important;
}

.player-history-table-row-new {
  height: auto !important;
}

.logout-confirm-modal-body {
  row-gap: 13px;
  font-size: var(--headerFontSize);
}

.room-ended-modal-new {
  &-message {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 25px;

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: var(--xLargeFontSize);
      text-align: center;
      color: #FFC900;
    }

    &-coins {
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;

      &-coin {
        width: 28px;
        height: 28px;
        margin-right: 6px;
        display: inline-block;
      }

      &-label {
        display: inline-block;
        font-size: var(--largeFontSize);
        vertical-align: middle;
      }
    }

    &-description {
      font-size: var(--defaultFontSize);
      font-style: normal;
      font-weight: 400;
      text-align: center;
    }
  }

  &-buy-options {
    margin-bottom: 25px;
  }

  &-money-pack {
    margin-top: 35px;
  }

  &-button {
    background: none !important;
    box-shadow: none !important;
    border-radius: 0% !important;
    // color: white !important;
    height: auto !important;
    text-transform: none !important;

    &-light {
      color: $lightTextColour !important;
    }

    &-active-dark {
      border-bottom: 2px solid #FFC900;
      text-decoration: none !important;
    }
    &:hover {
      border-bottom: 2px solid #FFC900;
      text-decoration: none !important;
    }
    &-active-normal {
      border-bottom: 2px solid #FFC900;
      text-decoration: none !important;
    }
    &:hover {
      border-bottom: 2px solid #FFC900;
      text-decoration: none !important;
    }
    &-active-light {
      border-bottom: 2px solid #D2110A;
      text-decoration: none !important;
    }
    &:hover {
      border-bottom: 2px solid #D2110A;
      text-decoration: none !important;
    }
  }

  &-button::before,
  &-button::after {
    background-image: none !important;
  }

  &-reset-top-distance {
    top: auto !important;
  }

  &-money-pack-row {
    width: 100%;
    margin: 0 auto;

    @media only screen and (max-width: 992px) {
      width: 98%;
    }

    @media only screen and (max-width: 640px) {
      width: 98%;
    }
  }

  &-money-pack-col {
    width: 20%;
    max-width: 20%;
    flex-basis: unset;
    flex-grow: unset;
    margin-bottom: 15px;

    @media only screen and (max-width: 992px) {
      width: 25%;
      max-width: 25%;
    }

    @media only screen and (max-width: 640px) {
      width: 50%;
      max-width: 50%;
    }
  }

  &-premium-pack-row {
    width: 60%;
    margin: 0 auto;

    @media only screen and (max-width: 992px) {
      width: 75%;
    }

    @media only screen and (max-width: 640px) {
      width: 98%;
    }
  }

  &-premium-pack-col {
    width: 33%;
    max-width: 33%;
    flex-basis: unset;
    flex-grow: unset;
    margin-bottom: 15px;

    @media only screen and (max-width: 992px) {
      width: 33%;
      max-width: 33%;
    }

    @media only screen and (max-width: 640px) {
      width: 50%;
      max-width: 50%;
    }
  }
}

.root-class-design20-light {
  .top-page-main-body-section {
    .layout-body-main-table-header {
      &-wrapper {
        color: #fff;

        .menu-table-row-player-icon-text {
          color: #fff !important;
        }
      }
    }
  }

  .layout-body-background {
    background-color: initial !important;

  }
}

.root-class-design20-light {
  .layout-subheader {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .player-info-player-image-emotions {
    background-image: url('../../../images/redesign/light-mode/player/emotions.svg') !important;
  }

  .layout-subheader-link-text-active {
    border-bottom: 1px solid #D2110A;
  }

  .layout-header-link-text-active {
    border-bottom: 2px solid #D2110A;
  }

  .layout-subheader-link-text {
    color: #0c0c0c;
    opacity: 0.7;
  }
}

.layout-body-main-table-header-text-wrapper {
  cursor: pointer;
}
.layout-body-main-table-header-orderby::after {
  cursor: pointer;
}
.root-class-design20-light {
  .layout-body-main-table-header-orderby::after {
    background-image: url('../../../images/redesign/light-mode/icons/sort.svg');
    background-repeat: no-repeat;
  }

  .layout-body-main-table-header-orderby_asc_active {
    color: #FF8A1A !important;

    &::after {
      background-image: url('../../../images/redesign/light-mode/icons/sort_active.svg');
      background-repeat: no-repeat;
    }
  }
}

.root-class-design20 {
  .layout-default-button {
    color: white;
  }
  .new-top-table-section {
    .layout-subheader {
      border-bottom: none;
    }
  }
}

// .root-class-design20-dark {
//   .layout-body-main {
//     background-color: rgba(0, 0, 0, 0.95) !important;
//   }
// }

// .root-class-design20-normal {
//   .layout-body-main {
//     background-color: rgba(37, 54, 120, 0.95) !important;
//   }
// }

// .root-class-design20-light {
//   .layout-body-main {
//     background-color: rgba(236, 236, 236, 0.95) !important;
//   }
// }
// .root-class-design20-light {
//   .new-power-rankings-section {
//     .layout-subheader {
//       border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
//     }
//   }
//   .new-top-table-section {
//     .layout-subheader {
//       border-bottom: none !important;
//     }
//   }

// }

.weekly-status-layout-body-main-table-header {
  margin-left: 26px;
  margin-right: 18px;
}

.new-top-table-section {
  .layout-subheader {
    border-bottom: none;
  }
}

.joyride-enter-room-zone {
  position: absolute;
  right: 0px;
  top: 125px;
  width: 180px;
  height: calc(100% - 100px);
  pointer-events: none;
}

.joyride-enter-room-zone-mobile {
  position: absolute;
  right: 0px;
  top: 40px;
  width: 80px;
  height: calc(100vh - 100px);

  @media screen and (orientation : portrait) {
    height: calc(100vh - 380px);
  }
}

.root-class-design20-light {

  .top-settings-section-old-design,
  .top-settings-section-logout,
  .profile-body-footer,
  .layout-subheader-title,
  .change-email-section {
    color: #FF8A1A !important;
  }

  .score-table-pules-header-player {
    color: rgba($color: #0c0c0c, $alpha: 0.7);
  }

  .form-control:focus {
    border-bottom-color: #d2110a !important;
  }
}

.root-class-design20 {
  .new-power-rankings-section {
    .layout-body-main {
      height: calc(100% - 100px);
      min-height: calc(100% - 100px);
    }
  }
}

.forgot-password-section-content-result {
  label {
    font-size: var(--headerFontSize);
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: #fff;
  }
}

.change-email-password-section-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: 40px;
  width: 75%;
  margin-left: 10px;

  &>div:first-of-type {
    column-gap: 10px;
  }
}

.root-class-design20-light {
  .weekly-status-container {
    .layout-body-main-table-header {
      background-color: unset;
    }
  }
}

.register-password-section-content {
  .common-form {
    .register-password-section-content-input {
      .input-col-message {
        width: auto;
        height: 17px;
        position: absolute;
        top: 38px;
        left: 16px;
        font-size: var(--smallFontSize);
        background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
        border-radius: 99px;
        padding: 1px 6px 2px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        color: #fff;
        line-height: normal;
        border: none;
      }
    }
  }
}

.landing-top-table-scrollarea-body {
  & .scrollbar {
    background: rgba(255, 255, 255, 0.4) !important;
    border-radius: 8px !important;
    height: 8px !important;
  }
}

.strikeout-extension::before,
.strikeout-extension::after {
  content: '\00a0';
}

.strikeout-new-light,
.strikeout-new-normal,
.strikeout-new-dark {
  // position: relative;
  text-decoration: line-through;
}
.strikeout-new-light{
  color: $lightTextColour;
}
.strikeout-new-dark{
  color: $normalTextColour;
}
.strikeout-new-normal{
  color: $normalTextColour;
}
// .strikeout-new-light::after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   left: -3px;
//   right: -3px;
//   height: 2px;
//   background-color: $lightTextColour;
// }
// .strikeout-new-normal::after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   left: -3px;
//   right: -3px;
//   height: 2px;
//   background-color: $normalTextColour;
// }
// .strikeout-new-dark::after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   left: -3px;
//   right: -3px;
//   height: 2px;
//   background-color: $normalTextColour;
// }

.report-player-section {
  padding: 7% 5% 0 5%;
  font-size: var(--defaultFontSize);
}

.new-input-current-length {
  position: absolute;
  right: 0px;
  bottom: -15px;
  z-index: 9999;
  color: #868686;
  font-size: var(--mSmallFontSize);
}

.new-hint-modal {
  & .modal-title {
    position: relative;
    padding: 2px 12px;
    width: auto;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--headerFontSize);
    font-weight: bold;
  }
}


.my-hints {
  &-item {
    height: 450px;
    overflow-y: auto;

    &-in-app-frame {
      height: 440px !important;
    }
    &-img {
      width: 100%;
      // min-height: 240px;
      position: relative;
      min-width: 100%;
      & img {
        width: 100%;
        // height: 300px;
        // min-height: 240px;
        min-width: 100%;
        object-fit: cover;
        object-position: center;
        // border-radius: 0px 22px 22px 22px;
      }
    }
    &-content {
      line-break: auto;
      // word-wrap: break-word;
      font-size: 80%;
      padding: 5px;
      text-align: center;
      font-size: var(--defaultFontSize);
    }

    &-check-show {
      padding: 0 1rem;
    }
  }

  @media (max-width: 992px) {
    &-item {
      height: 350px;
    }
  }
}

.weekly-status-layout-body-main-table-header-mobile {
  font-size: var(--defaultFontSize);
  background-color: unset !important;
}

.break-all {
  word-break: break-all;
}

.survey-header-new-section {
  display: flex;
  justify-content: center !important;
  align-items: flex-start !important;
  padding-left: 20px;
  padding-right: 20px;

  & .title {
    color: rgba(255, 201, 0, 1);
    font-weight: 600;
    font-size: 24px;
  }
}

.survey-body-new-section {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.root-class-design20-light {
  .survey-header-new-section {
    & .description {
      color: black;
    }
  }

  .survey-body-question-answers {
    color: black;
  }
}



.campaign-game-section {
  & .room-chat {
    bottom: 120px !important;
  }

  & .room-chat-bar {
    bottom: 73px !important;
  }

  & .score-table {
    bottom: 73px !important;
  }


  @media screen and (max-width: $mobileLandScapeWidth) and (orientation: landscape) {
    .quit-round-icon {
      left: 23%;
      top: unset;
      bottom: calc(100px + 8vmin + 120px);
    }
  }

  /*
  & .quit-round-icon {
    left: 23%;
    top: unset;
    bottom: calc(100px + 8vmin + 120px);
  } */
}

.root-is-app-frame {
  .campaign-game-section {
    @media screen and (max-width: $mobileLandScapeWidth) {
      & .room-chat-bar {
        bottom: 113px !important;
      }

      & .room-chat {
        bottom: 155px !important;
      }

      & .room-chat-closed {
        bottom: 205px !important;
      }
    }
  }
}

.root-is-not-app-frame {
  .campaign-game-section {
    @media screen and (max-width: $mobileLandScapeWidth) and (orientation: landscape) {
      .quit-round-icon {
        left: 23%;
        top: unset;
        bottom: calc(100px + 8vmin + 120px);
      }
    }
  }
}

@media screen and (max-width: $mobileLandScapeWidth) and (orientation: landscape) {
  .root-is-not-app-frame {
    .campaign-game-section {
      .quit-round-icon {
        left: 23%;
        top: unset;
        bottom: calc(100px + 8vmin + 120px);
      }
    }
  }

  .root-is-app-frame {
    .campaign-game-section {
      .quit-round-icon {
        left: 23%;
        top: unset;
        bottom: calc(100px + 8vmin + 120px);
      }
    }
  }
}

/*
.root-is-app-frame {
  .campaign-game-section {
    @media screen and (max-width: $mobileLandScapeWidth) and (orientation: landscape) {
      .quit-round-icon {
        left: 23%;
        top: unset;
        bottom: calc(100px + 8vmin + 120px);
      }
    }
  }
} */

.event-tournament-restrictions {
  position: absolute;
  bottom: 8px;
  right: 12px;
  color: #000;
  cursor: pointer;
  &:hover {
    text-decoration: dashed;
  }

  &-hover {
    background-color: #fff;
    border-radius: 1.5vmin 1.5vmin 1.5vmin 1.5vmin;
    width: 15vw;
    min-width: 100px;
    height: 120px;
    box-shadow: none;
    font-size: var(--defaultFontSize);
    border: 0;
    color: #000;
    transform: translateY(-50%);
    pointer-events: none;
    position: absolute;
    bottom: -20px;
    padding: 20px;
    right: 10px;

    &::before {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 20px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      transform: rotate(180deg);
    }

    & label {
      color: #D2110A;
      margin-bottom: 0px !important;
    }
  }

  &-hover-mobile {
    background-color: #fff;
    border-radius: 1.5vmin 1.5vmin 1.5vmin 1.5vmin;
    width: 250px;
    min-width: 100px;
    height: 120px;
    box-shadow: none;
    font-size: var(--defaultFontSize);
    border: 0;
    color: #000;
    transform: translateY(-50%);
    pointer-events: none;
    position: absolute;
    bottom: -20px;
    padding: 20px;
    right: 10px;

    &::before {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 20px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      transform: rotate(180deg);
    }

    & label {
      color: #D2110A;
      margin-bottom: 0px !important;
    }
  }
}

.disabled-button {
  opacity: 0.7;
  cursor: default;
}
