
/*
$borderColor: rgba(255, 255, 255, 0.2);
$textColor: #FFF;
$backgroundColor: transparent;
$menuBackgroundColor: #000; */

// @import "../../redesignVariables";

.custom-dropdown {

  & .custom-dropdown-toggle {
    background: $customDropdownBackgroundColor !important;
    border: 1px solid $customDropdownBorderColor !important;
    color: $customDropdownTextColor;
    border-radius: 30px;
    font-weight: 400;
    font-size: var(--defaultFontSize);
    line-height: 22px;
    min-width: 10vmin;
    text-align: left;
    height: 38px;
    padding: 6px 15px;
    opacity: $disabledLinkOpacity;

    &:after {
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      background: unset;
      background-repeat: unset;
      background-position: unset;
      width: unset;
      height: unset;
      vertical-align: middle;
      background-size: unset;
      margin-left: 0;
      margin-top: 0.8em;
      float: right;
    }
  }

  & .custom-dropdown-link {
    justify-content: center;
    align-items: center;
    display: inline-block;

    background: $customDropdownBackgroundColor !important;
    border: 0;
    box-sizing: border-box;
    color: $customDropdownTextColor;
    font-size: var(--defaultFontSize);
    padding: 0px 8px;
    vertical-align: middle;
    min-width: 7vmin;
    line-height: 38px;
    text-decoration: underline;
    text-align: right;
    opacity: $disabledLinkOpacity;

    &:hover {
      background: $customDropdownBackgroundColor;
      border: 0;
    }

    &:active {
      background: $customDropdownBackgroundColor;
      border: 0;
    }

    &:focus {
      background: $customDropdownBackgroundColor;
      border: 0;
    }
  }

  & .custom-dropdown-menu {
    border-radius: 0;
    background: $customDropdownMenuBackgroundColor;
    border: 1px solid $customDropdownBorderColor !important;
    overflow: hidden;

    &-wider {
      width: 330px;
    }
  }

}
