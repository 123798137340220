$nameColor: #0C0C0C;
$bgColor: #FFF;
$commentColor: #868686;
$fontSize: 1.2vmin;
$mobileSmSize: 575px;

.root-class-design20 {
  .blogs-container {
    width: 100%;
    height: auto;
    min-height: 50vh;
    background-color: #0C0C0C;

    @media screen and (max-width: $mobileLandScapeWidth) and (orientation: landscape) {
      .blogs-page-first-section-item {
        //width: 38% !important;
        display: inline-block !important;
        margin-left: 1%;
        margin-right: 1%;
        vertical-align: top;
      }
    }
    & .blogs-page {
      width: 80%;
      margin-top: 150px;
      &-title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: var(--xxxLargeFontSize);
        font-size: 24px;
        line-height: 140%;
        color: #FFFFFF;
      }

      &-first-section {
        width: 100%;
        height: auto;

        &-item {
          width: 100%;

          & .blog-image {
            width: 100%;
            //height: 350px;
            position: relative;
            &-bg {
              width: 100%;
              height: 100%;
              border-radius: 0px 22px 22px 22px;
            }
            &-dg {
              width: 100%;
              height: 100%;
              border-radius: 0px 12px 12px 12px;
              border: 12px solid rgba(255, 255, 255, 0.1);
              position: absolute;
              top: 0px;
              left: 0px;
            }
          }
          @media only screen and (max-width: $mobileSmSize) {
            & .blog-image {
              // height: 160px !important;
            }

            & .blog-image + .title {
              margin-top: 6px !important;
              & + .description {
                margin-top: 6px !important;
                & + .footer {
                  margin-top: 20px !important;
                }
              }
            }
          }

          & .medium-img {
            //height: 240px;
          }

          & .sm-img {
            //height: 160px;
          }

          & .title {
            width: 100%;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: var(--xxLargeFontSize);
            line-height: 140%;
            color: #FFFFFF;
          }

          & .sm-title {
            font-size: var(--xLargeFontSize);
          }

          & .description {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: var(--largeFontSize);
            line-height: 160%;
            color: #FFFFFF;
          }

          & .bg-description {
            height: 78px;

            & .rdw-editor-main {
              color: #fff;
            }
            overflow: hidden;
          }

          & .md-description {
            height: 130px;
            overflow: hidden;
            font-size: var(--largeFontSize);
          }

          & .sm-description {
            height: 110px;
            overflow: hidden;
            font-size: var(--largeFontSize);
          }


          & .footer {
            width: 100%;
            & .read-article {
              width: 160px;
              height: 30px;
              background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
              border: 1px solid #0C0C0C;
              border-radius: 99px;
              position: relative;
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 700;
              font-size: var(--defaultFontSize);
              line-height: 18px;
              text-align: center;
              text-transform: uppercase;
              color: #FFFFFF;
              cursor: pointer;
            }
            & .read-article::after {
              position: absolute;
              right: 8px;
              top: 11px;
              content: ' ';
              background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
              background-size: 8px 8px;
              height: 8px;
              width: 8px;
            }
            & .read-article::before {
              position: absolute;
              left: 8px;
              top: 11px;
              content: ' ';
              background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
              background-size: 8px 8px;
              height: 8px;
              width: 8px;
            }
            @media only screen and (max-width: $mobileSmSize) {
              & .read-article {
                display: none;
              }
            }
          }
        }

        & .load-more {
          width: 216px;
          height: 47px;
          background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
          border: 1px solid #0C0C0C;
          border-radius: 99px;
          position: relative;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: var(--defaultFontSize);
          line-height: 18px;
          text-align: center;
          text-transform: uppercase;
          color: #FFFFFF;
          cursor: pointer;
        }
        & .load-more::after {
          position: absolute;
          right: 26px;
          top: 18px;
          content: ' ';
          background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
          background-size: 8px 8px;
          height: 8px;
          width: 8px;
        }
        & .load-more::before {
          position: absolute;
          left: 26px;
          top: 18px;
          content: ' ';
          background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
          background-size: 8px 8px;
          height: 8px;
          width: 8px;
        }
      }

      @media only screen and (max-width: $mobileSmSize) {
        &-first-section {
          & > div {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }

    }

    @media only screen and (max-width: $mobileSmSize) {
      & .blogs-page {
        margin-top: 14px !important;
        width: 100%;

        &-title {
          padding-left: 20px !important;
          padding-right: 20px !important;
          margin-bottom: 27px !important;
          //font-size: var(--xxLargeFontSize);
          font-size: 24px;
        }

        .blogs-page-first-section {
          .blogs-page-first-section-item {
            margin-top: 0 !important;
          }
        }
      }
    }

    & .blog-article-section {
      width: 100%;
      height: 100%;
      min-height: 2000px;
      position: relative;
      & .blog-article-back {
        background-image: url('../../../../images/redesign/landing-view/game_screen_bg.webp');
        width: 100%;
        height: 450px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0px;
        &-dg {
          position: absolute;
          bottom: 0px;
          background: linear-gradient(180deg, #0C0C0C 0%, rgba(0, 0, 0, 0) 100%);
          width: 100%;
          height: 100PX;
          transform: rotate(-180deg);
        }
      }

      & .blogs-article-page {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 75%;
        position: absolute;
        top: 0px;
        margin-top: 113px;
        & .blogs-page-title {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: var(--xxxLargeFontSize);
          line-height: 140%;
          color: #FFFFFF;
          display: none;
        }
        @media only screen and (max-width: $mobileSmSize) {
          & .blogs-page-title {
            display: flex !important;
            padding-left: 20px !important;
            padding-right: 20px !important;
            margin-bottom: 22px !important;
            font-size: var(--xxLargeFontSize);
          }
        }

        &-first-section {
          width: 100%;
          height: auto;

          & > div {

          }

          &-item {
            width: 100%;

            & .blog-image {
              width: 100%;
              position: relative;
              &-bg {
                width: 100%;
                height: 100%;
                border-radius: 0px 22px 22px 22px;
              }
              &-dg {
                width: 100%;
                height: 100%;
                border-radius: 0px 22px 22px 22px;
                border: 12px solid rgba(255, 255, 255, 0.1);
                position: absolute;
                top: 0px;
                left: 0px;
              }
            }

            & .medium-img {
              height: 240px;
            }

            & .sm-img {
              //height: 160px;
              height: auto;
            }

            & .title {
              width: 100%;
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 600;
              font-size: var(--xxxLargeFontSize);
              line-height: 140%;
              color: #FFFFFF;
            }

            & .sm-title {
              font-size: var(--xLargeFontSize);
              white-space: normal;
            }

            & .description {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: var(--headerFontSize);
              line-height: 160%;
              color: #FFFFFF;
            }

            & .bg-description {
              height: 78px;
              overflow: hidden;
            }

            & .md-description {
              height: 130px;
              overflow: hidden;
              font-size: var(--largeFontSize);
            }

            & .sm-description {
              height: 104px;
              overflow: hidden;
              font-size: var(--largeFontSize);
            }

            & .blog-article-content {
              // padding-left: 0px;
              // padding-right: 0px;

              & .title {
                width: 100%;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: var(--xxxLargeFontSize);
                line-height: 140%;
                color: #FFFFFF;
              }

              & .description {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: var(--mLargeFontSize);
                line-height: 160%;
                color: #FFFFFF;
                // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              }
            }
            
            @media only screen and (max-width: $mobileSmSize) {
              & .blog-article-content {
                padding-left: 20px !important;
                padding-right: 20px !important;
                & .title {
                  font-size: var(--xxLargeFontSize);
                  margin-top: 15px !important;
                }

                & .description {
                  margin-top: 19px !important;
                }
              }
            }


            & .blog-article-share {
              // padding-left: 0px;
              // padding-right: 0px;

              & .date {
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                margin-top: 50px;
                padding-bottom: 21px;
                margin-bottom: 21px;
                & p {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 600;
                  font-size: var(--largeFontSize);
                  line-height: 140%;
                  color: #868686;
                  margin-bottom: 1px;
                }

                & time {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--headerFontSize);
                  line-height: 160%;
                  color: #FFFFFF;
                }
              }

              & .share {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                & .share-link {
                  display: flex;
                  flex-direction: row;

                  .share-link-item {
                    border: 1px solid #1084BF;
                    box-shadow: 0px 2px 6px rgba(0, 0, 0, 10%);
                    width: 44px;
                    height: 44px;
                    margin-right: 5px;
                    border-radius: 50%;
                    cursor: pointer;
                  }
                  .share-link-item-twitter {
                    border: 1px solid white;
                  }
                  .share-link-item-facebook {
                    border: 1px solid #3C5A9A;
                  }
                  .share-link-item-draugiem {
                    border: 1px solid #FB6501;
                  }
                }
              }

            }


            & .footer {
              width: 100%;
              & .read-article {
                width: 160px;
                height: 30px;
                background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
                border: 1px solid #0C0C0C;
                border-radius: 99px;
                position: relative;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: var(--defaultFontSize);
                line-height: 18px;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
                cursor: pointer;
              }
              & .read-article::after {
                position: absolute;
                right: 8px;
                top: 11px;
                content: ' ';
                background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
                background-size: 8px 8px;
                height: 8px;
                width: 8px;
              }
              & .read-article::before {
                position: absolute;
                left: 8px;
                top: 11px;
                content: ' ';
                background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
                background-size: 8px 8px;
                height: 8px;
                width: 8px;
              }
            }
          }

          & .load-more {
            width: 216px;
            height: 47px;
            background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
            border: 1px solid #0C0C0C;
            border-radius: 99px;
            position: relative;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: var(--defaultFontSize);
            line-height: 18px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            cursor: pointer;
          }
          & .load-more::after {
            position: absolute;
            right: 14px;
            top: 18px;
            content: ' ';
            background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
            background-size: 8px 8px;
            height: 8px;
            width: 8px;
          }
          & .load-more::before {
            position: absolute;
            left: 14px;
            top: 18px;
            content: ' ';
            background-image: url('../../../../images/redesign/landing-view/slidePaginationActive.svg');
            background-size: 8px 8px;
            height: 8px;
            width: 8px;
          }
        }
      }
      @media only screen and (max-width: $mobileSmSize) {
        & .blogs-article-page {
          width: 100% !important;
          margin-top: 20px !important;
        }
      }
    }
    @media only screen and (max-width: $mobileSmSize) {
      & .blog-article-section {
        min-height: 3300px;
        & .blog-image {
          // height: 239px !important;
        }
        & .blog-article-back {
          display: none;
        }
      }
    }
  }
}
