$mobileLandScapeWidth: 1280px;

.root-class-design20 {
    @media screen and (orientation : portrait) {
        .modal {
            .modal-dialog.room-create-modal {
                .modal-content {
                    .modal-header {}

                    .modal-body {
                        .new-game {
                            width: 100%;
                            .new-game-body {
                                margin-left: 0;
                                max-height: calc(100vh - 380px);
                                margin-right: 11px;
                                overflow-y: auto;
                                overflow-x: hidden;

                                .switch-select-option-name {
                                    white-space: nowrap;
                                }

                                &>div {
                                    margin-left: 19px;

                                    &>div.col {
                                        padding-left: 0;
                                        padding-right: 0;

                                        .new-game-title {
                                            padding: 0;
                                            margin-bottom: 10px;
                                        }

                                        .create-room-switch {
                                            // width: -webkit-fill-available;
                                            max-width: 100%;
                                            overflow-x: auto;
                                            vertical-align: middle;
                                            filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.2));

                                            .switch-select {
                                                //margin-bottom: 20px;
                                                margin-right: 10px;

                                                overflow-x: auto;
                                                overflow-y: hidden;
                                                display: flex;
                                                // flex-wrap: nowrap;
                                                
                                                &-option {
                                                    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.4) !important;
                                                }

                                                .switch-select-option-name {
                                                    white-space: nowrap;
                                                }

                                                &::-webkit-scrollbar {
                                                    height: 4px;
                                                }
                                            }
                                        }

                                        .new-game-title+div {
                                            div.display-inline {
                                                margin-left: 0;
                                            }

                                            div.display-inline.ml-3 {
                                                margin-left: 0 !important;
                                                margin-top: 12px;
                                                margin-bottom: 15px;
                                            }

                                            div.display-inline:last-of-type {
                                                display: block;
                                                margin-top: 11px;
                                                margin-bottom: 20px;
                                            }
                                        }
                                    }
                                }
                            }

                            .modal-footer {
                                margin-top: 10px;
                            }
                        }
                    }

                }
            }
        }
    }

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        .modal {
            .modal-dialog.room-create-modal {
                .modal-content {
                    .modal-header {}

                    .modal-body {
                        padding-top: 32px;

                        .new-game {
                            width: 100%;
                            .new-game-body {
                                margin-left: 0;
                                height: 45vh;
                                margin-right: 7px;
                                overflow-y: auto;
                                overflow-x: hidden;
                                &-in-app-frame {
                                    height: 68vh !important;
                                }

                                .switch-select-option-name {
                                    white-space: nowrap;
                                }

                                &>div {
                                    margin-left: 32px;

                                    &>div.col {
                                        padding-left: 0;
                                        padding-right: 0;

                                        .new-game-title {
                                            padding: 0;
                                            margin-bottom: 10px;
                                        }

                                        .create-room-switch {
                                            max-width: 100%;
                                            overflow-x: auto;
                                            vertical-align: middle;
                                            filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.2));
                                            .switch-select {
                                                //margin-bottom: 20px;
                                                margin-right: 10px;

                                                overflow-x: auto;
                                                overflow-y: hidden;
                                                display: flex;
                                                //flex-wrap: nowrap;

                                                &-option {
                                                    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.4) !important;
                                                }

                                                .switch-select-option-name {
                                                    white-space: nowrap;
                                                }

                                                &::-webkit-scrollbar {
                                                    height: 4px;
                                                }
                                            }
                                        }

                                        .new-game-title+div {
                                            div.display-inline {
                                                margin-left: 0;
                                            }

                                            div.display-inline.ml-3 {
                                                margin-left: 0 !important;
                                                margin-top: 12px;
                                                margin-bottom: 15px;
                                            }

                                            div.display-inline:last-of-type {
                                                display: block;
                                                margin-top: 11px;
                                                margin-bottom: 20px;
                                            }
                                        }
                                    }
                                }
                            }

                            .modal-footer {
                                margin-top: 10px;
                            }
                        }
                    }

                }
            }
        }
    }
}

.root-class-design20-normal {}

.root-class-design20-light {
    @media screen and (orientation : portrait) {
        .modal {
            .modal-dialog.room-create-modal {
                .modal-content {
                    .modal-header {}

                    .modal-body {}

                    .modal-body+img.mobile-close {
                        filter: invert(1);
                    }
                }
            }
        }
    }
}
